/**
 * Created by louidorpc on 9/12/20.
 */
/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization, ageYearDif} from "../../../constants/utils";
import {app} from '../../../constants/config';


const TableRecordingCall = props => {

    const {language, isLoadingSv, createService, updateService, persons, person_id, emCalls}=props;


    let rateBd = "";

    const columns = [
        {
            title: 'Date',
            field: 'created_date', type: 'text'
        },
        {
            title: 'Abonné',
            field: 'cl_name', type: 'text'
        },
        {
            title: 'Agent',
            field: 'creator', type: 'text'
        },
        {
            title: 'Client',
            field: 'cl_client', type: 'text',

        },
        {
            title: 'Numéro Client',
            field: 'cl_client_number', type: 'text',
        },
        {
            title: 'Feedback',
            field: 'cl_feedback', type: 'text'
        },
        {
            title: 'Commentaire',
            field: 'cl_comment', type: 'text',

        }
    ]
    return (
        <MaterialTable
            title="Urgence"
            columns={columns}
            data={emCalls ? emCalls : []}
            icon='unfold_less'
            localization={Localization(language)}
            options={{
                headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF'
                },
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true
            }}
            actions={[{
                icon: row => (
                    <i className="mdi mdi-eye pr-2"
                       data-toggle="modal"
                       data-target="#slideRightModal"
                       title="view"></i>
                ),
                tooltip: 'View',
                onClick: (e, row) => {

                }
            },
                rowData => {

                }
            ]
            }
        />
    )
}


export default TableRecordingCall