import {combineReducers} from "redux"
import {reducer as formReducer} from "redux-form"
import settingReducer from "./setting"
import loginReducer from "./login"
import personReducer from "./person"
import siteReducer from "./sites"
import statisticReducer from "./statistic"
import serviceReducer from "./service"
import positionReducer from "./position"
import entrepriseReducer from "./entreprise"
import clientReducer from "./client"
import extensionReducer from "./extension"
import agentReducer from "./agent"
import campaignReducer from "./campaign"
import prefixReducer from "./prefix"
import pbxReducer from "./pbx"
import clientPbxReducer from "./clientPbx"
import feedbackReducer from "./feedback"
import eventReducer from "./event"
import linkReducer from "./links"
import daysreportReducer from "./daysreport"
import monthreportReducer from "./monthreport"
import agentPbxReducer from "./agentPbx"


export default combineReducers({
    form: formReducer,
    settingReducer,
    loginReducer,
    personReducer,
    siteReducer,
    statisticReducer,
    serviceReducer,
    positionReducer,
    entrepriseReducer,
    clientReducer,
    extensionReducer,
    agentReducer,
    campaignReducer,
    prefixReducer,
    pbxReducer,
    clientPbxReducer,
    feedbackReducer,
    eventReducer,
    linkReducer,
    daysreportReducer,
    monthreportReducer,
    agentPbxReducer

});