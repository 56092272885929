/**
 * Created by louidorpc on 9/9/20.
 */
import React from 'react'
import TableService from './serviceTable'

const Service = ({language, createService, updateService, services, isLoadingSv, person_id,deleteService}) => {

    return (
        <TableService language={language} createService={createService} updateService={updateService}
                      services={services} isLoadingSv={isLoadingSv} person_id={person_id} deleteService={deleteService}/>



    )
}

export default Service;