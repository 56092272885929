import types from "../actions/type"

const defaultState = {
    isLoadingPBX: false,
    isLoadingIC: false,
    isLoadingOC: false,
    message: null,
    reports: [],
    incomingCalls: [],
    outgoingCalls: [],
    msuic: null,
    phone: null,
    criteria: null,
    isLoadingUIC: false
}

const extensionReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_ALL_PBX_REPORTS:
            return {
                ...state,
                isLoadingPBX: action.payload.isLoadingPBX,
                criteria: action.payload.criteria,
                reports: []
            };

        case types.GET_ALL_PBX_REPORTS_SUCCESS:
            return {
                ...state,
                reports: action.payload.reports,
                isLoadingPBX: action.payload.isLoadingPBX
            };


        case types.GET_ALL_PBX_REPORTS_FAILLED:
            return {
                ...state,
                reports: [],
                isLoadingPBX: action.payload.isLoadingPBX
            };


        case types.GET_ALL_INCOMING_CALL_REPORTS:
            return {
                ...state,
                isLoadingIC: action.payload.isLoadingIC,
                criteria: action.payload.criteria,
                incomingCalls: []
            };

        case types.GET_ALL_INCOMING_CALL_REPORTS_SUCCESS:
            return {
                ...state,
                incomingCalls: action.payload.incomingCalls,
                isLoadingIC: action.payload.isLoadingIC
            };

        case types.GET_ALL_INCOMING_CALL_REPORTS_FAILLED:
            return {
                ...state,
                incomingCalls: [],
                isLoadingIC: action.payload.isLoadingIC
            };


        case types.GET_ALL_OUTGOING_CALL_REPORTS:
            return {
                ...state,
                isLoadingOC: action.payload.isLoadingOC,
                criteria: action.payload.criteria,
                outgoingCalls: []
            };

        case types.GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS:
            return {
                ...state,
                outgoingCalls: action.payload.outgoingCalls,
                isLoadingOC: action.payload.isLoadingOC
            };

        case types.GET_ALL_OUTGOING_CALL_REPORTS_FAILLED:
            return {
                ...state,
                outgoingCalls: [],
                isLoadingOC: action.payload.isLoadingOC
            };


        case types.UPDATE_INCOMING_CALL:
            return {
                ...state,
                isLoadingUIC: action.payload.isLoadingUIC,
                phone: action.payload.phone,
            };

        case types.UPDATE_INCOMING_CALL_SUCCESS:
            return {
                ...state,
                msuic: action.payload.msuic,
                incomingCalls: state.incomingCalls.map(v => v.uniqueid === action.payload.data.uniqueid ? action?.payload?.data : v),
                isLoadingUIC: action.payload.isLoadingUIC,
            };

        case types.UPDATE_INCOMING_CALL_FAILED:
            return {
                ...state,
                msuic: action.payload.msuic,
                isLoadingUIC: action.payload.isLoadingUIC,
            };


        default:
            return state

    }
}


export default extensionReducer