/**
 * Created by louidorpc on 8/4/20.
 */
import React from 'react'
import Configuration from '../../components/config'
import { getFeedback, resetFeedback } from "../../actions/creator/feedback";


const ConfigComponent = props => {

    const {
        sr: { session, isLoadingS }, actions: {
            getAllEntreprises, getAllLinks, createLink, updateLink, deleteLink, getLink
        }, person: { ps, isLoading, mups, cms }, intl, st: { sites },
    } = props;

    if (session !== null)
        return (
            <Configuration session={session}
                getAllLinks={getAllLinks}
                getAllEntreprises={getAllEntreprises}
                createLink={createLink}
                updateLink={updateLink}
                deleteLink={deleteLink}
                getLink={getLink}
            />
        )
    else return <div></div>
}


export default ConfigComponent;