import axios from "axios"
import { app } from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 700000,
});

export const getAllMonthreports = criteria => {
    return instance.get(`/monthreport/index.php?startDate=${criteria?.startDate}&endDate=${criteria?.endDate}`)
};

export const getAllMonthreportsbyclient = criteria => {
    return instance.get(`/monthreport/client/index.php?id=${criteria?.id}&startDate=${criteria?.startDate}&endDate=${criteria?.endDate}`)
};


export const createMonthreport = position => {
    return instance.post(`/monthreport/create/index.php`, position)
};


export const updateMonthreport = position => {
    return instance.put(`/monthreport/update/index.php`, position)
};


export const deleteMonthreport = id => {
    return instance.get(`/monthreport/delete/index.php?id=${id}`)
};
