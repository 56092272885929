import { put, take, call, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/creator/agent";
import types from "../actions/type";
import * as api from "../services/agent";
import moment from 'moment';

export const handleGetAllAgentSessions = function* (action) {

    try {
        const { pid } = action.payload;
        const getT = yield call(api.getAllAgentSessions, pid);

        let result = getT.data;

        if (result.length > 0) {
            result.map((event, index) => {
                result[index].title = `${event.username} ==> ext: ${event.extension}`;
                result[index].desc = `Desc of event ${index + 1}`;
                result[index].start = new moment(event.start);
                result[index].end = new moment(event.end);

            });

            let ev = result;

            yield put(actions.getAllAgentSessionSuccess(ev))

        } else {
            yield put(actions.getAllAgentSessionFailled(getT.data.message))
        }

    } catch (error) {
        yield put(actions.getAllAgentSessionFailled("error"))
    }
};


export const handleChangeView = function* (action) {
    try {
        const { view } = action.payload;
        yield put(actions.changeViewSuccess(view))
    } catch (e) {

    }
};

export const handleChangeEvent = function* (action) {
    try {
        const { event } = action.payload;
        yield put(actions.changeEventsSuccess(event))
    } catch (e) {

    }
};

export const handleGetSelectedDataFromCalendar = function* (action) {
    try {
        const { tsData } = action.payload;
        yield put(actions.getSelectedDataFromCalendarSuccess(tsData))
    } catch (e) {

    }
};


export const handleGetAllSessionsByAgent = function* (action) {

    try {

        const { id } = action.payload;

        const getPs = yield call(api.getAllSessionsByAgent, id);
        if (getPs.data.length > 0) {
            yield put(actions.getAllSessionByAgentSuccess(getPs.data))
        } else {
            yield put(actions.getAllSessionByAgentFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllSessionByAgentFailled("error"))
    }
};


export const handleChangePhoneQty = function* (action) {
    const { pQty } = action.payload
    yield put(actions.changePhoneQtySuccess(pQty))
};


export const handleCreateAssingCampaign = function* (action) {
    const { cac } = action.payload;
    try {
        const upPs = yield call(api.createAssignCampaign, cac);
        if (upPs.data.message === "success" || upPs.data.message > 0) {
            yield put(actions.createAssignCampainSuccess(cac.language))
        } else {
            yield put(actions.createAssignCampainFailled({ lg: cac.language, mcac: upPs.data.message }))
        }
    } catch (error) {
        yield put(actions.createAssignCampainFailled({ lg: cac.language, mcac: 'save_error' }))
    }
};


export const handleGetAllRecordingCall = function* () {

    try {


        const getPs = yield call(api.getAllRecordingCall);
        if (getPs.data.length > 0) {
            yield put(actions.getAllRecordingCallSuccess(getPs.data))
        } else {
            yield put(actions.getAllRecordingCallFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllRecordingCallFailled("error"))
    }
};


export const handleCreateEmergencyCall = function* (action) {
    const { cac } = action.payload;
    try {
        const upPs = yield call(api.createEmergencyCall, cac);
        if (upPs.data.message === "success") {
            yield put(actions.createEmergencyCallSuccess(cac.language))
            yield put(actions.getAllEmergencyCall())
        } else {
            yield put(actions.createEmergencyCallFailled({ lg: cac.language, mcac: upPs.data.message }))
        }
    } catch (error) {
        yield put(actions.createEmergencyCallFailled({ lg: cac.language, mcac: 'save_error' }))
    }
};

export const handleEditEmergencyCall = function* (action) {
    const { cac } = action.payload;
    try {
        const upPs = yield call(api.editEmergencyCall, cac);
        if (upPs.data.message === "success") {
            yield put(actions.editEmergencyCallSuccess(cac.language))
            yield put(actions.getAllEmergencyCall())
        } else {
            yield put(actions.editEmergencyCallFailled({ lg: cac.language, mcac: upPs.data.message }))
        }
    } catch (error) {
        yield put(actions.editEmergencyCallFailled({ lg: cac.language, mcac: 'edit_error' }))
    }
};

export const handleDeleteEmergencyCall = function* (action) {
    const { cac } = action.payload;

    try {
        const upPs = yield call(api.deleteEmergencyCall, cac?.id);
        if (upPs.data.message === "success") {
            yield put(actions.deleteEmergencyCallSuccess(cac.language))
            yield put(actions.getAllEmergencyCall())
        } else {
            yield put(actions.deleteEmergencyCallFailled({ lg: cac.language, mcac: upPs.data.message }))
        }
    } catch (error) {
        yield put(actions.deleteEmergencyCallFailled({ lg: cac.language, mcac: 'edit_error' }))
    }
};


export const handleResetEmergency = function* () {
    yield put(actions.resetEmergencyCallSuccess())
};


export const handleGetAllEmergencyCalls = function* () {

    try {


        const getPs = yield call(api.getAllEmergencyCalls);
        if (getPs.data.length > 0) {
            yield put(actions.getAllEmergencyCallSuccess(getPs.data))
        } else {
            yield put(actions.getAllEmergencyCallFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllEmergencyCallFailled("error"))
    }
};


export const handleGetAllPhones = function* ({ payload }) {
    const { phone } = payload;

    try {


        const getPs = yield call(api.getAllPhones, phone);
        if (getPs.data.length > 0) {
            yield put(actions.getAllPhonesSuccess(getPs.data))
        } else {
            yield put(actions.getAllPhonesFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPhonesFailled("error"))
    }
};


export const agentSaga = [
    takeLatest(types.GET_ALL_AGENT_SESSIONS, handleGetAllAgentSessions),
    takeLatest(types.GET_ALL_SESSIONS_BY_AGENT, handleGetAllSessionsByAgent),
    takeLatest(types.CALENDAR_EVENT, handleChangeEvent),
    takeLatest(types.CALENDAR_CHANGE_VIEW, handleChangeView),
    takeLatest(types.GET_SELECTED_DATE_FROM_CALENDAR, handleGetSelectedDataFromCalendar),
    takeLatest(types.CHANGE_PHONE_QTY, handleChangePhoneQty),
    takeLatest(types.CREATE_ASSIGN_CAMPAIGN, handleCreateAssingCampaign),
    takeLatest(types.GET_ALL_RECORDING_CALL, handleGetAllRecordingCall),
    takeLatest(types.CREATE_EMERGENCY_CALL, handleCreateEmergencyCall),
    takeLatest(types.EDIT_EMERGENCY_CALL, handleEditEmergencyCall),
    takeLatest(types.RESET_EMERGENCY_CALL, handleResetEmergency),
    takeLatest(types.GET_ALL_EMERGENCY_CALL, handleGetAllEmergencyCalls),
    takeLatest(types.GET_ALL_PHONE_BY_PHONE, handleGetAllPhones),
    takeLatest(types.DELETE_EMERGENCY_CALL, handleDeleteEmergencyCall),
];
