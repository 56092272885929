import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl";


class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            path: this.props.path
        }
    }


    switchMenu = name => {

        if (name === '/') {
            this.setState({ path: '/' })
        }
        else if (name === '/admin') {
            this.setState({ path: '/admin' })
        }
        else if (name === '/crm') {
            this.setState({ path: '/crm' })
        }
        else if (name === '/agent') {
            this.setState({ path: '/agent' })
        } else if (name === '/campaign') {
            this.setState({ path: '/campaign' })
        } else if (name === '/note') {
            this.setState({ path: '/note' })
        } else if (name === '/call_report') {
            this.setState({ path: '/call_report' })
        } else if (name === '/pbx') {
            this.setState({ path: '/pbx' })
        }
        else if (name === '/reports') {
            this.setState({ path: '/reports' })
        }
        else if (name === '/feedback') {
            this.setState({ path: '/feedback' })
        } else if (name === '/experience') {
            this.setState({ path: '/experience' })
        }
        else if (name === '/calendar') {
            this.setState({ path: '/calendar' })
        }
        else if (name === '/entreprises') {
            this.setState({ path: '/entreprises' })
        }
        else if (name === '/config') {
            this.setState({ path: '/config' })
        }
        else if (name === '/followup') {
            this.setState({ path: '/followup' })
        }
        else if (name === '/monthreport') {
            this.setState({ path: '/monthreport' })
        } else if (name === '/daysreport') {
            this.setState({ path: '/daysreport' })
        }
        else if (name === '/urgence') {
            this.setState({ path: '/urgence' })
        }
        else if (name === '/profile') {
            this.setState({ path: '/profile' })
        }
    }

    render() {

        const { userinfo } = this.props;
        const { path } = this.state;


        return (
            <div className="admin-sidebar-wrapper js-scrollbar">
                <ul className="menu">

                    <Link to="/" onClick={() => this.switchMenu('/')}>
                        <li class={`menu-item ${path === '/' ? 'active' : ''} `}>
                            <a href="kanban.html" class=" menu-link">
                                <span class="menu-label">
                                    <span class="menu-name"><FormattedMessage id={`app.label.home`}
                                        defaultMessage={`Label Name`} /> </span>
                                </span>
                                <span class="menu-icon">
                                    <i class="fa fa-placeholder mdi mdi-home-account"></i>
                                </span>
                            </a>
                        </li>
                    </Link>


                    {userinfo.position_name === "Superviseur" && userinfo.entr_name === "Seedlee" ? <>

                        <Link to="/agent" onClick={() => this.switchMenu('/agent')}>
                            <li class={`menu-item ${path === '/agent' ? 'active' : ''} `}>
                                <a href="kanban.html" class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name">
                                            <FormattedMessage id={`app.label.agent`}
                                                defaultMessage={`Agent`} /> </span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-face-agent"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>

                        <Link to="/call_report" onClick={() => this.switchMenu('/call_report')}>
                            <li class={`menu-item ${path === '/call_report' ? 'active' : ''} `}>
                                <a href="kanban.html" class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name">
                                            <FormattedMessage id={`app.label.call_report`}
                                                defaultMessage={`Rapport des appels`} /> </span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-phone-log"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>

                        <Link to="/pbx" onClick={() => this.switchMenu('/pbx')}>
                            <li class={`menu-item ${path === '/pbx' ? 'active' : ''} `}>
                                <a href="kanban.html" class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name">
                                            <FormattedMessage id={`app.label.pbx`}
                                                defaultMessage={`PBX`} /> </span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-phone-classic"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>

                    </> : null}

                    {userinfo.position_name === "Administrateur" && userinfo.entr_name === "Seedlee" || userinfo.entr_name === "Support Technique" ?
                        <>
                            <Link to="/admin" onClick={() => this.switchMenu('/admin')}>
                                <li class={`menu-item ${path === '/admin' ? 'active' : ''} `}>
                                    <a href="kanban.html" class=" menu-link">
                                        <span class="menu-label">
                                            <span class="menu-name">
                                                <FormattedMessage id={`app.label.administration`}
                                                    defaultMessage={`Administration`} /> </span>
                                        </span>
                                        <span class="menu-icon">
                                            <i class="fa fa-placeholder mdi mdi-home-account"></i>
                                        </span>
                                    </a>
                                </li>
                            </Link>
                            <Link to="/agent" onClick={() => this.switchMenu('/agent')}>
                                <li class={`menu-item ${path === '/agent' ? 'active' : ''} `}>
                                    <a href="kanban.html" class=" menu-link">
                                        <span class="menu-label">
                                            <span class="menu-name">
                                                <FormattedMessage id={`app.label.agent`}
                                                    defaultMessage={`Agent`} /> </span>
                                        </span>
                                        <span class="menu-icon">
                                            <i class="fa fa-placeholder mdi mdi-face-agent"></i>
                                        </span>
                                    </a>
                                </li>
                            </Link>

                            <Link to="/call_report" onClick={() => this.switchMenu('/call_report')}>
                                <li class={`menu-item ${path === '/call_report' ? 'active' : ''} `}>
                                    <a href="kanban.html" class=" menu-link">
                                        <span class="menu-label">
                                            <span class="menu-name">
                                                <FormattedMessage id={`app.label.call_report`}
                                                    defaultMessage={`Rapport des appels`} /> </span>
                                        </span>
                                        <span class="menu-icon">
                                            <i class="fa fa-placeholder mdi mdi-phone-log"></i>
                                        </span>
                                    </a>
                                </li>
                            </Link>

                            <Link to="/pbx" onClick={() => this.switchMenu('/pbx')}>
                                <li class={`menu-item ${path === '/pbx' ? 'active' : ''} `}>
                                    <a href="kanban.html" class=" menu-link">
                                        <span class="menu-label">
                                            <span class="menu-name">
                                                <FormattedMessage id={`app.label.pbx`}
                                                    defaultMessage={`Rapport de CRM`} /> </span>
                                        </span>
                                        <span class="menu-icon">
                                            <i class="fa fa-placeholder mdi mdi-phone-classic"></i>
                                        </span>
                                    </a>
                                </li>
                            </Link>
                            <Link to="/feedback" onClick={() => this.switchMenu('/feedback')}>
                                <li class={`menu-item ${path === '/feedback' ? 'active' : ''} `}>
                                    <a href="#" class=" menu-link">
                                        <span class="menu-label">
                                            <span class="menu-name">
                                                <FormattedMessage id={`app.label.feedback`}
                                                    defaultMessage={`Courriel interne`} /> </span>
                                        </span>
                                        <span class="menu-icon">
                                            <i class="fa fa-placeholder mdi mdi-frequently-asked-questions"></i>
                                        </span>
                                    </a>
                                </li>
                            </Link>
                            <Link to="/experience" onClick={() => this.switchMenu('/experience')}>
                                <li class={`menu-item ${path === '/experience' ? 'active' : ''} `}>
                                    <a href="#" class=" menu-link">
                                        <span class="menu-label">
                                            <span class="menu-name">
                                                <FormattedMessage id={`app.label.exp_cleint`}
                                                    defaultMessage={`Expérience des clients`} /> </span>
                                        </span>
                                        <span class="menu-icon">
                                            <i class="fa fa-placeholder mdi mdi-comment-multiple"></i>
                                        </span>
                                    </a>
                                </li>
                            </Link>
                            <Link to="/followup" onClick={() => this.switchMenu('/followup')}>
                                <li class={`menu-item ${path === '/followup' ? 'active' : ''} `}>
                                    <a href="#" class=" menu-link">
                                        <span class="menu-label">
                                            <span class="menu-name">
                                                <FormattedMessage id={`app.label.followup`}
                                                    defaultMessage={`Rapport de Suivi `} /> </span>
                                        </span>
                                        <span class="menu-icon">
                                            <i class="fa fa-placeholder mdi mdi-comment-multiple"></i>
                                        </span>
                                    </a>
                                </li>
                            </Link>
                            <Link to="/config" onClick={() => this.switchMenu('/config')}>
                                <li class={`menu-item ${path === '/config' ? 'active' : ''} `}>
                                    <a href="#" class=" menu-link">
                                        <span class="menu-label">
                                            <span class="menu-name">
                                                <FormattedMessage id={`app.label.config`}
                                                    defaultMessage={`Configuration`} /> </span>
                                        </span>
                                        <span class="menu-icon">
                                            <i class="fa fa-placeholder mdi mdi-settings"></i>
                                        </span>
                                    </a>
                                </li>
                            </Link>
                            <Link to="/urgence" onClick={() => this.switchMenu('/urgence')}>
                                <li class={`menu-item ${path === '/urgence' ? 'active' : ''} `}>
                                    <a href="#" class=" menu-link">
                                        <span class="menu-label">
                                            <span class="menu-name">
                                                <FormattedMessage id={`app.label.urgence`}
                                                    defaultMessage={`Urgence`} /> </span>
                                        </span>
                                        <span class="menu-icon">
                                            <i class="fa fa-placeholder mdi mdi-alert"></i>
                                        </span>
                                    </a>
                                </li>
                            </Link>

                        </> : null}

                    {userinfo.position_name === "Agent" && userinfo.entr_name === "Seedlee" ? <>
                        {/*<Link to="/campaign" onClick={() => this.switchMenu('/campaign')}>*/}
                        {/*    <li class={`menu-item ${path === '/campaign' ? 'active' : ''} `}>*/}
                        {/*        <a href="kanban.html" class=" menu-link">*/}
                        {/*<span class="menu-label">*/}
                        {/*        <span class="menu-name">*/}
                        {/*            <FormattedMessage id={`app.label.campaign`}*/}
                        {/*                              defaultMessage={`campaign`}/> </span>*/}
                        {/*</span>*/}
                        {/*            <span class="menu-icon">*/}
                        {/*    <i class="fa fa-placeholder mdi mdi-phone-check"></i>*/}
                        {/*</span>*/}
                        {/*        </a>*/}
                        {/*    </li>*/}
                        {/*</Link>*/}

                        <Link to="/profile" onClick={() => this.switchMenu('/profile')}>
                            <li class={`menu-item ${path === '/profile' ? 'active' : ''} `}>
                                <a href="kanban.html" class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name"><FormattedMessage id={`app.label.profile`}
                                            defaultMessage={`Label Name`} /> </span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-home-account"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>

                        <Link to="/note" onClick={() => this.switchMenu('/note')}>
                            <li class={`menu-item ${path === '/note' ? 'active' : ''} `}>
                                <a href="kanban.html" class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name">
                                            <FormattedMessage id={`app.label.note_form`}
                                                defaultMessage={`Forme de note`} /> </span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-note-plus"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>
                        <Link to="/entreprises" onClick={() => this.switchMenu('/entreprises')}>
                            <li class={`menu-item ${path === '/entreprises' ? 'active' : ''} `}>
                                <a href="#" class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name">
                                            <FormattedMessage id={`app.label.entreprises`}
                                                defaultMessage={`entreprises`} /> </span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-office-building"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>

                    </> : null}


                    {userinfo.person_type === "client" && userinfo.entr_name !== "Support Technique" ? <>
                        <Link to="/crm" onClick={() => this.switchMenu('/crm')}>
                            <li class={`menu-item ${path === '/crm' ? 'active' : ''} `}>
                                <a href="kanban.html" class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name">
                                            <FormattedMessage id={`app.label.crm`}
                                                defaultMessage={`CRM`} /> </span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-phone-check"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>
                        <Link to="/reports" onClick={() => this.switchMenu('/reports')}>
                            <li class={`menu-item ${path === '/reports' ? 'active' : ''} `}>
                                <a class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name">
                                            Rapport des appels</span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-phone-check"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>
                        <Link to="/feedback" onClick={() => this.switchMenu('/feedback')}>
                            <li class={`menu-item ${path === '/feedback' ? 'active' : ''} `}>
                                <a href="#" class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name">
                                            <FormattedMessage id={`app.label.feedback`}
                                                defaultMessage={`Courriel interne`} /> </span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-frequently-asked-questions"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>
                        <Link to="/experience" onClick={() => this.switchMenu('/experience')}>
                            <li class={`menu-item ${path === '/experience' ? 'active' : ''} `}>
                                <a href="#" class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name">
                                            <FormattedMessage id={`app.label.exp_cleint`}
                                                defaultMessage={`Expérience des clients`} /> </span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-comment-multiple"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>

                        <Link to="/followup" onClick={() => this.switchMenu('/followup')}>
                            <li class={`menu-item ${path === '/followup' ? 'active' : ''} `}>
                                <a href="#" class=" menu-link">
                                    <span class="menu-label">
                                        <span class="menu-name">
                                            <FormattedMessage id={`app.label.followup`}
                                                defaultMessage={`Rapport de Suivi `} /> </span>
                                    </span>
                                    <span class="menu-icon">
                                        <i class="fa fa-placeholder mdi mdi-comment-multiple"></i>
                                    </span>
                                </a>
                            </li>
                        </Link>

                    </> : null}


                    <Link to="/calendar" onClick={() => this.switchMenu('/calendar')}>
                        <li class={`menu-item ${path === '/calendar' ? 'active' : ''} `}>
                            <a href="#" class=" menu-link">
                                <span class="menu-label">
                                    <span class="menu-name">
                                        <FormattedMessage id={`app.label.calendar`}
                                            defaultMessage={`Calendrier`} /> </span>
                                </span>
                                <span class="menu-icon">
                                    <i class="fa fa-placeholder mdi mdi-calendar"></i>
                                </span>
                            </a>
                        </li>
                    </Link>

                    <Link to="/daysreport" onClick={() => this.switchMenu('/daysreport')}>
                        <li class={`menu-item ${path === '/daysreport' ? 'active' : ''} `}>
                            <a href="#" class=" menu-link">
                                <span class="menu-label">
                                    <span class="menu-name">
                                        <FormattedMessage id={`app.label.calendar`}
                                            defaultMessage={`Rapport Journalier`} /> </span>
                                </span>
                                <span class="menu-icon">
                                    <i class="fa fa-placeholder mdi mdi-calendar"></i>
                                </span>
                            </a>
                        </li>
                    </Link>
                    <Link to="/monthreport" onClick={() => this.switchMenu('/monthreport')}>
                        <li class={`menu-item ${path === '/monthreport' ? 'active' : ''} `}>
                            <a href="#" class=" menu-link">
                                <span class="menu-label">
                                    <span class="menu-name">
                                        <FormattedMessage id={`app.label.calendar`}
                                            defaultMessage={`Rapport Mensuel`} /> </span>
                                </span>
                                <span class="menu-icon">
                                    <i class="fa fa-placeholder mdi mdi-calendar"></i>
                                </span>
                            </a>
                        </li>
                    </Link>



                </ul>
            </div>
        );
    }
}
export default Menu

