/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization} from "../../../constants/utils";
import {Extension} from '../../../constants/model'


const ExtensionTable = props => {

    const {language, createExtension, updateExtensionById, extensions, person_id}=props;


    let rateBd = "";

    const columns = [
        {
            title: 'ID',
            field: 'id',
            type: 'text',
            editable: 'never',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF'
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            title: getIntlMsg(intl(language), `app.label.extension_number`),
            field: 'extension_number', type: 'text',

        },
        {
            title: getIntlMsg(intl(language), `app.label.extension_group`),
            field: 'extension_group', type: 'text',
            lookup: {"Supervisor": "Supervisor", "Agent": "Agent"},

        }, {
            title: getIntlMsg(intl(language), `app.label.extension_status`),
            field: 'extension_status',
            type: 'text',
            editable: 'never',
            render: row => (row.extension_status === "available" ?
                <span class="badge badge-soft-success"> {getIntlMsg(intl(language), `app.label.available`)} </span> :
                <span class="badge badge-soft-danger badge-light">  {getIntlMsg(intl(language), `app.label.unavailable`)}</span>)

        },
        {
            title: getIntlMsg(intl(language), `app.label.creator`),
            field: 'username', type: 'text',
            editable: 'never',
        },
        {
            title: getIntlMsg(intl(language), `app.label.created_date`),
            field: 'date_created', type: 'text',
            editable: 'never',
        }
    ]
    return (
        <MaterialTable
            title={getIntlMsg(intl(language), `app.label.extension_list`)}
            columns={columns}
            data={extensions ? extensions : []}
            icon='unfold_less'
            localization={Localization(language)}
            options={{
                headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF'
                },
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true
            }}
            editable={{
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {

                            var entity = new Extension({
                                extension_number: newData.extension_number,
                                extension_group: newData.extension_group,
                                language: language,
                                creator: person_id
                            })
                            createExtension(entity);
                            resolve();
                        }, 1000);
                    })
                ,
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {

                            var entity = new Extension({
                                id: newData.id,
                                extension_number: newData.extension_number,
                                extension_group: newData.extension_group,
                                language: language,
                                creator: person_id
                            })
                            updateExtensionById(entity);
                            resolve();
                        }, 1000)
                    }),
            }}

        />
    )
}


export default ExtensionTable