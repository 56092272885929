/**
 * Created by louidorpc on 1/25/21.
 */
/**
 * Created by louidorpc on 1/9/21.
 */
import React from 'react';
import ClientRepport from '../../components/client/clientRepport';
import createHistory from 'history/createBrowserHistory';

const RepportNote = props => {

    const { actions: { getLinkByEntreprise } } = props
    const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);
    const history = createHistory();


    if (infouser.person_type === "client") {

        return (
            <ClientRepport infouser={infouser} getLinkByEntreprise={getLinkByEntreprise} />
        )
    } else {
        history.goBack('/')
    }


}

export default RepportNote;