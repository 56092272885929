import {put, take, call, takeLatest} from "redux-saga/effects";
import  * as actions from "../actions/creator/sites";
import types from "../actions/type";
import * as api from "../services/sites";


export const handleResetStateSite = function*() {
    yield put(actions.resetStateSiteSuccess())
};


export const handleGetSiteById = function*(action) {

    try {
        const {sid} = action.payload;
        const getS = yield call(api.getSiteById, sid);
        if (getS.data.message === "success") {
            yield put(actions.getSiteByIdSuccess(getS.data))
        } else {
            yield put(actions.getSiteByIdFailled(getS.data.message))
        }

    } catch (error) {
        yield put(actions.getSiteByIdFailled("error"))
    }
};


export const handleGetSites = function*(action) {

    try {
        const getS = yield call(api.getSites);
        if (getS.data.length > 0) {
            yield put(actions.getSitesSuccess(getS.data))
        } else {
            yield put(actions.getSitesFailled(getS.data.message))
        }

    } catch (error) {
        yield put(actions.getSitesFailled("error"))
    }
};


export const handleUpdateSiteById = function*(action) {

    try {
        const {usid} = action.payload;
        const upS = yield call(api.updateSite, usid);
        if (upS.data.message === "success") {
            yield put(actions.updateSiteByIdSuccess(upS.data.message))

        } else {
            yield put(actions.updateSiteByIdFailled(upS.data.message))
        }

    } catch (error) {
        yield put(actions.updateSiteByIdFailled("error"))
    }
};


export const handleCreateSite = function*(action) {

    try {
        const {site} = action.payload;
        const upS = yield call(api.createSite, site);
        if (upS.data.message === "success") {
            yield put(actions.createSiteSuccess(upS.data.message))

        } else {
            yield put(actions.createSiteFailled(upS.data.message))
        }

    } catch (error) {
        yield put(actions.createSiteFailled("error"))
    }
};


export const siteSaga = [
    takeLatest(types.GET_SITES, handleGetSites),
    takeLatest(types.GET_SITE_BY_ID, handleGetSiteById),
    takeLatest(types.UPDATE_PERSON_BY_ID, handleUpdateSiteById),
    takeLatest(types.RESET_SITES, handleResetStateSite),
    takeLatest(types.CREATE_SITE, handleCreateSite)
];
