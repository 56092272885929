import {put, take, call, takeLatest} from "redux-saga/effects";
import  * as actions from "../actions/creator/campaign";
import types from "../actions/type";
import * as api from "../services/campaign";


export const handleGetAssignedCampaign = function*(action) {

    try {

        const {pid} = action.payload;

        const getPs = yield call(api.getAllAssignedCampaignByAgent, pid);
        if (getPs.data.length > 0) {
            yield put(actions.getAllAssignedCampaignByAgentSuccess(getPs.data))
        } else {
            yield put(actions.getAllAssignedCampaignByAgentFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllAssignedCampaignByAgentFailled("error"))
    }
};


export const handleGetAssignedPhoneByAgent = function*(action) {

    try {

        const {pPid} = action.payload;

        const getPs = yield call(api.getAllAssignedPhoneByAgent, pPid);
        if (getPs.data.length > 0) {
            yield put(actions.getAllPhoneAssignedCampaignByAgentSuccess(getPs.data))
        } else {
            yield put(actions.getAllPhoneAssignedCampaignByAgentFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPhoneAssignedCampaignByAgentFailled("error"))
    }
};



export const campaignSaga = [
    takeLatest(types.GET_ASSIGNED_CAMPAIGN_BY_AGENT, handleGetAssignedCampaign),
    takeLatest(types.GET_ASSIGN_PHONE_BY_AGENT, handleGetAssignedPhoneByAgent),

];
