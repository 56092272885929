/**
 * Created by louidorpc on 8/4/20.
 */
import React from 'react'
import MonthReportComponent from '../../components/admin/monthreport'


const MOnthReportContainer = props => {

    const {
        sr: { session }, actions: {
            getAllEntreprises,
            getAllMonthReports,
            createMonthReport,
            getAllMonthReportsByClient,
            editMonthReport,
            resetMonthReport,
            deleteMonthReport
        } } = props;

    if (session !== null)
        return (
            <MonthReportComponent session={session}
                getAllEntreprises={getAllEntreprises}
                getAllMonthReports={getAllMonthReports}
                createMonthReport={createMonthReport}
                getAllMonthReportsByClient={getAllMonthReportsByClient}
                editMonthReport={editMonthReport}
                resetMonthReport={resetMonthReport}
                deleteMonthReport={deleteMonthReport}

            />
        )
    else return <div></div>
}


export default MOnthReportContainer;