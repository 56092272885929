/**
 * Created by louidorpc on 11/4/20.
 */
import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getAllAssignedCampaignByAgent = pid => {
    return instance.get(`/campaign/assign/agent/index.php?person_id=${pid}`)
};

export const getAllAssignedPhoneByAgent = pid => {
    return instance.get(`/campaign/assign/agent/phone/index.php?person_id=${pid.id}&assign_campaign_code=${pid.code}`)
};
