/**
 * Created by louidorpc on 9/12/20.
 */
import React from 'react'
import  {app} from '../../../constants/config'
import MaterialTable from 'material-table';
import  {getIntlMsg, intl, Localization} from '../../../constants/utils'
import Loader from '../../../common/loader'


const PhoneModal = ({numbers, language, isLoadingPC, numberList, show, display, removeModal, phones}) => {


    const columns = [

        {
            title: getIntlMsg(intl(language), `app.label.entr_name`),
            field: 'entr_name', type: 'text',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF'
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            title: getIntlMsg(intl(language), `app.label.fullname`),
            field: 'firstname', type: 'text',
            render: row =>(row.firstname+" "+row.lastname)
        },
        {
            title: getIntlMsg(intl(language), `app.label.phone`),
            field: 'phone', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.campaign_name`),
            type: 'text',
            field: "campaign_name",
        },
    ]
    return (
        <div class="modal fade modal-slide-right" id="slideRightModal" tabindex="-1" role="dialog"
             aria-labelledby="slideRightModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-lg" role="document">
                <div class="modal-content modal-lg">
                    <div class="modal-header">
                        <h5 class="modal-title"
                            id="slideRightModalLabel">Information sur le Client</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => removeModal()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">


                        {phones && phones.length > 0 ?
                            <MaterialTable
                                title={'Liste'}
                                columns={columns}
                                data={phones ? phones : []}
                                icon='unfold_less'
                                localization={Localization(language)}
                                options={{
                                    headerStyle: {
                                        backgroundColor: '#01579b',
                                        color: '#FFF'
                                    },
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    exportAllData: true
                                }}
                            />
                            : <div style={{margin: '0px'}}><Loader/></div>}


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={() => removeModal()}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PhoneModal;