/**
 * Created by louidorpc on 10/27/20.
 */
import React, {useState} from 'react'
import {getIntlMsg, intl, Localization, ageYearDif} from "../constants/utils";
import * as XLSX from 'xlsx';
import {FormattedMessage} from "react-intl";

import swal from 'sweetalert'


const AllertDiagolg = (d) => {

    swal({
        title: `${d} lignes`,
        text: "Votre fichier doit inferieur ou egale a 10000 lignes",
        icon: "info",
        button: "Ok",
    }).then((value) => {
    });

    // resetStateSignin();

}

const UploadCSV = ({getData, language, entreprise_id, mc, resetStateClient, reset}) => {


    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [listof, setListOf] = useState([]);

    const processData = dataString => {

        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        if (dataStringLines.length <= 10005) {
            for (let i = 1; i < dataStringLines.length; i++) {
                const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
                if (headers && row.length == headers.length) {
                    const obj = {entreprise_id: entreprise_id};
                    for (let j = 0; j < headers.length; j++) {
                        let d = row[j];
                        if (d.length > 0) {
                            if (d[0] == '"')
                                d = d.substring(1, d.length - 1);
                            if (d[d.length - 1] == '"')
                                d = d.substring(d.length - 2, 1);
                        }
                        if (headers[j]) {
                            obj[headers[j]] = d;
                        }
                    }

                    // remove the blank rows
                    if (Object.values(obj).filter(x => x).length > 0) {
                        list.push(obj);
                    }
                }
            }
        } else {
            AllertDiagolg(dataStringLines.length)
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: getIntlMsg(intl(language), 'app.label.' + c),
            selector: c,
        }));

        setData(list);
        setListOf(list)
        setColumns(columns);
        getData(list)
    }

    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type: 'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, {header: 1});
            processData(data);
        };
        reader.readAsBinaryString(file);
    }

    const onClearForm = () => {
        // setListOf([])
        getData([])
        reset('campaign');
        resetStateClient();

    }

    if (mc === "success") {
        onClearForm()
    }

    // if (mc !== "success") {
    //     if (data.length > 0) {
    //         getData(listof)
    //     }
    // }


    return (
        <div className="col-md-12">
            <h6>{getIntlMsg(intl(language), `app.label.upload_csv_file`)}</h6>
            <input
                class="btn btn-success btn-cta waves-effect text-center m-b-20"
                type="file"
                accept=".csv,.xlsx,.xls"
                onChange={(e)=>handleFileUpload(e)}
            />
        </div>
    )
}

export default UploadCSV;