import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import actions from "../actions/creator"
import Signup from "./signup"
import { injectIntl } from 'react-intl';

const mapStateToProps = ({loginReducer,intl,history }) => ({
    login: loginReducer,
    intl:intl,
    history:history

});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default (injectIntl(connect(mapStateToProps, mapDispatchToProps)(Signup)))
