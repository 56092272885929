import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import actions from "../actions/creator"
import Agent from "./agent"
import {injectIntl} from 'react-intl';

const mapStateToProps = ({settingReducer, intl, history, agentReducer, clientReducer, personReducer, entrepriseReducer}) => ({
    intl: intl,
    history: history,
    sr: settingReducer,
    ag: agentReducer,
    cl: clientReducer,
    person: personReducer,
    ...entrepriseReducer,

});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default (injectIntl(connect(mapStateToProps, mapDispatchToProps)(Agent)))
