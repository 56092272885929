/**
 * Created by louidorpc on 9/12/20.
 */
import React from 'react'
import  {app} from '../../constants/config'
import MaterialTable from 'material-table';
import  {getIntlMsg, intl, Localization} from '../../constants/utils'
import Loader from '../../common/loader'


const CampainModal = ({numbers, language, isLoadingPC, numberList, show, display, removeModal}) => {


    const columns = [

        {
            title: getIntlMsg(intl(language), `app.label.code`),
            field: 'campaing_code', type: 'text',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF'
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            title: getIntlMsg(intl(language), `app.label.firstname`),
            field: 'firstname', type: 'text',
        }, {
            title: getIntlMsg(intl(language), `app.label.lastname`),
            field: 'lastname', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.campaign_name`),
            field: 'phone', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.status`),
            type: 'text',
            field: "status",
            render: row => (row.status === "+0 " ?
                <span class="badge badge-soft-success"> {getIntlMsg(intl(language), `app.label.new_client`)} </span> :
                null)
        },
    ]
    return (
        <div class={`modal fade modal-slide-right  ${show}`} id="slideRightModal" tabindex="-1" role="dialog"
             aria-labelledby="slideRightModalLabel" aria-hidden="true" style={{display: display}}>
            <div class="modal-dialog  modal-lg" role="document">
                <div class="modal-content modal-lg">
                    <div class="modal-header">
                        <h5 class="modal-title"
                            id="slideRightModalLabel">{getIntlMsg(intl(language), 'app.label.campaign_phone_list')}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>removeModal()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">


                        {numberList && numberList.length > 0 ?
                            <MaterialTable
                                title={'Liste'}
                                columns={columns}
                                data={numberList ? numberList : []}
                                icon='unfold_less'
                                localization={Localization(language)}
                                options={{
                                    headerStyle: {
                                        backgroundColor: '#01579b',
                                        color: '#FFF'
                                    },
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    exportAllData: true
                                }}
                                actions={[{
                                    icon: row => (
                                        <i className="mdi mdi-eye"
                                           data-target="#slideRightModal"></i>
                                    ),
                                    tooltip: 'View'
                                }]
                                }
                            />
                            : <div style={{margin: '0px'}}><Loader/></div>}


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={()=>removeModal()}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CampainModal;