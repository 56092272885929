import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {connect, useDispatch, useSelector} from 'react-redux'
import {Field, reduxForm, reset, initialize, change} from 'redux-form'
import {useEffect} from "react";
import {FileInput} from '../../../../common/FileInput'
import {renderField} from "../../../../common/FormComponents";
import MultiSelect from "@khanacademy/react-multi-select";
import {renderError} from './validator'


const onClearForm = (reset, resetEntreprise, resetFile) => {
    reset('entreprise');
    resetFile();
    resetEntreprise();
}

const putData = () => {

}

const Form = props => {
    // if (msEnt === "success") onClearForm(reset, resetEntreprise, resetFile)
    const {entrepriseInfo, handleSetDocumentFile} =props;

    const [selected, setSelected] = useState([]);
    const dispatch = useDispatch();


    const {items} = useSelector(state => ({
        items: state.entrepriseReducer.entrepriseInfo ? state.entrepriseReducer.entrepriseInfo.documents : [],

    }));


    const handleSelected = (selected) => {
        dispatch(change('entreprise', 'documents', selected));
        setSelected(selected)
    }


    const options = [
        {label: "Carte d’identité de la personne de contact", value: "Carte d’identité de la personne de contact"},
        {label: "Contrat de prestation service", value: "Contrat de prestation service"},
        {label: "Patente et matricule fiscale", value: "Patente et matricule fiscale"},
    ];


    const [inputList, setInputList] = useState([{}]);


    const removeItem = i => {
        const list = [...inputList];
        list.splice(i, 1);
        setInputList(list);
        props.handleSetDocumentFile(list)
        // dispatch(change('entreprise', 'service_ab', list));
    }

    const handleInputChange = (e, index) => {
        console.log("_______-----------____________-", e)
        const {name, uploadedFile, fileType} = e;
        const list = [...inputList];
        list[index] = e;
        setInputList(list);
        //   console.log("____________+++++++++++++++++______", list)
    };

    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        //  setClientList(list)
        props.handleSetDocumentFile(list)
        // dispatch(change('entreprise', 'service_ab', list));
        removeItem(index);
    };

    const handleAddClick = () => {
        setInputList([...inputList, ""]);
        // setClientList(inputList)
        props.handleSetDocumentFile(inputList)
    };


    //

    // entrepriseInfo && entrepriseInfo.documents ? dispatch(change('entreprise', 'documents', selected));
    //   setSelected(entrepriseInfo && entrepriseInfo.documents ? entrepriseInfo.documents : [])


    return (
        <React.Fragment>
            <div className="row m-2">
                <h5>Documents annexés au présent formulaire :</h5>

                <div className="col-md-12 pb-4">

                    <Field name="documents"
                           type="select"
                           component={() => (<MultiSelect
                               options={options}
                               selected={selected.length > 0 ? selected : items}
                               onSelectedChanged={selected => handleSelected(selected)}
                               overrideStrings={{
                                   selectSomeItems: "Sélectionnez certains éléments ...",
                                   allItemsAreSelected: "Tous les éléments sont sélectionnés",
                                   selectAll: "Tout sélectionner",
                                   search: "Rechercher",
                               }}
                           />)}
                    />


                </div>
                {/*<div className="form-group floating-label show-label col-md-12">*/}

                {/*<Field*/}
                {/*className="form-control"*/}
                {/*id="inputEmail6"*/}
                {/*name="signature"*/}
                {/*component={renderField}*/}
                {/*type="text"*/}
                {/*label="app.label.signature"*/}
                {/*placeholder="app.label.signature"*/}
                {/*/>*/}

                {/*</div>*/}

                <div className="form-group floating-label show-label col-md-12">

                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="sign_date"
                        component={renderField}
                        type="date"
                        label="app.label.date"
                        placeholder="app.label.date"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-12">

                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="sign_name"
                        component={renderField}
                        type="text"
                        label="app.label.sign_name"
                        placeholder="app.label.sign_name"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-12">

                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="sign_fonction"
                        component={renderField}
                        type="text"
                        label="app.label.sign_fonction"
                        placeholder="app.label.sign_fonction"
                    />

                </div>


                <div className="col-md-12">

                    <h5><a href="#">Téleverser les documents</a></h5>
                    {inputList.map((x, i) => {
                        return (
                            <div className="row">
                                <div className="">
                                    <span className="mr-3"><Field name="uploadFile" component={renderError}/></span>
                                    <Field name="uploadFile" component={FileInput} type="file" id="uploadFile"
                                           value={x}
                                           property={x ? 'required' : null}
                                           onChange={e => handleInputChange(e, i)}
                                    />{' '} <label
                                    htmlFor="byMail"></label>


                                </div>
                                <div className="form-group col-md-4">
                                    <div className="btn-box float-right">
                                        {inputList.length !== 1 && <button
                                            className="mr10 mr-2 pb-2 btn btn-danger"
                                            onClick={() => handleRemoveClick(i)}>Supprimer</button>}
                                        {inputList.length - 1 === i &&
                                        <button onClick={handleAddClick} className="btn btn-info pt-2">Ajouter</button>}
                                    </div>
                                </div>
                            </div>

                        );
                    })}
                    {/*<div style={{marginTop: 20}}>{JSON.stringify(inputList)}</div>*/}
                </div>


            </div>
            {/*<div class="error-handling-messages">*/}
            {/*{msEnt != "success" ?*/}
            {/*<div id="error"> {msEnt}</div> : null}*/}
            {/*</div>*/}

        </React.Fragment>

    );
}
export default Form;


