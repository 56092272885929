import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { connect, useSelector } from 'react-redux'
import { validate } from "./validator";
import { renderField } from "../../../../../../common/FormComponents";
import moment from 'moment'


const onClearForm = (reset, resetStateClient) => {
    reset('cleint');
    resetStateClient();
}

const Form = props => {
    const { handleSubmit, reset, pristine, submitting, intl, isLoading, isLoadingCA, selectedData } = props;
    // if (mc === "success") onClearForm(reset, resetStateClient)


    const clientTypes = ["Particulier", "Entreprise"]

    const [isEntreprise, setIsEntreprise] = useState(false);

    const handleChangeTypeServiceType = type => {

        if (type === "Entreprise") {
            setIsEntreprise(true)
        } else {
            setIsEntreprise(false)
        }
    }

    const { entreprises } = useSelector(state => ({
        entreprises: state?.entrepriseReducer?.entreprises || [],
    }));


    return (
        <React.Fragment>
            {selectedData?.id ?
                <form onSubmit={handleSubmit}>
                    <div className="form-row m-4">
                        <div className="form-group floating-label show-label col-md-12">
                            <div class="card m-b-30">
                                <div class="card-body">
                                <ul class="list-group list-group-flush p-6">
                                    <li class="list-group-item p-3"><b>Nom du client :</b>{selectedData?.client_name} </li>
                                    <li class="list-group-item  p-3"><b>Telephone du client : </b> {selectedData?.client_phone}</li>
                                    <li class="list-group-item  p-3"><b>Type de client : </b> {selectedData?.service_type}</li>
                                   {selectedData?.client_entreprise? <li class="list-group-item  pb-4"><b>Entreprise : </b>
                                    {selectedData?.client_entreprise}</li>:null}
                                    <li class="list-group-item  p-3"><b>Description : </b>   <p class="card-text">{selectedData?.description}</p></li>
                            
                                </ul>
                                  

                                </div>
                            </div>

                        </div>

                        

                    </div>

                </form> :
                <div className="form-row m-4">
                    <div className="form-group floating-label show-label col-md-12">
                        <div class="alert alert-danger" role="alert">
                            Vous devez cliquer sur un evenement existant
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>

    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: { ...props.cInfo ? { ...props.cInfo } : null }

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'task',
    enableReinitialize: true,
    validate,
})(Form));

