import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import actions from "../actions/creator"
import Login from "./login"
import {injectIntl} from 'react-intl';

const mapStateToProps = ({loginReducer, intl, history, siteReducer, extensionReducer}) => ({
    login: loginReducer,
    intl: intl,
    history: history,
    st: siteReducer,
    ...extensionReducer

});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default (injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login)))
