import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { connect, useSelector } from 'react-redux'
import { validate, normalizeCin, normalizePhone } from "./validator";
import { renderField } from "../../../../common/FormComponents";
import { getIntlMsg } from "../../../../constants/utils"


const onClearForm = (reset, resetStatePerson) => {
    reset('person');
    resetStatePerson();
}

const Form = props => {

    const { handleSubmit, personInfo, reset, pristine, submitting, intl, isLoading, history, sn, message, form, mups, services, person_id, resetStatePerson, positions, entreprises, getAllExtensions } = props;


    const [position, setPosition] = useState("")


    const handleChangePosition = (e) => {
        setPosition(e)
    }

    const { extensions } = useSelector(state => ({
        extensions: state?.extensionReducer?.extensions || [],
    }));


    console.log("+++++++++++++", position)




    if (mups === "success") onClearForm(reset, resetStatePerson)
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-row m-2">

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="id"
                        component={renderField}
                        disabled={true}
                        type="text"
                        label="app.label.code"
                        placeholder="app.label.person_id"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="firstname"
                        component={renderField}
                        type="text"
                        label="app.label.firstname"
                        placeholder="app.label.firstname"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="lastname"
                        component={renderField}
                        type="text"
                        label="app.label.lastname"
                        placeholder="app.label.lastname"
                    />

                </div>

                <div className="form-group floating-label show-label  col-md-6">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="gender"
                        component={renderField}
                        type="select"
                        label="app.label.gender"
                        placeholder="app.label.gender"
                        options={[{ id: "m", name: "app.label.female" }, { id: "f", name: "app.label.male" }]}
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="birth_date"
                        component={renderField}
                        type="date"
                        label="app.label.birthdate"
                        placeholder="app.label.birthdate"
                    />

                </div>


                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="phone"
                        component={renderField}
                        normalize={normalizePhone}
                        type="text"
                        value="polo"
                        label="app.label.phone"
                        placeholder="app.label.phone"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="address"
                        component={renderField}
                        type="text"
                        label="app.label.address"
                        placeholder="app.label.address"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="email"
                        component={renderField}
                        type="email"
                        label="app.label.email"
                        placeholder="app.label.email"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="nif_cin"
                        component={renderField}
                        normalize={normalizeCin}
                        type="text"
                        label="app.label.nif_cin"
                        placeholder="app.label.nif_cin"
                    />

                </div>

                <div className="form-group floating-label show-label  col-md-6">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="position_id"
                        component={renderField}
                        type="select"
                        label="app.label.person_position"
                        placeholder="app.label.person_position"
                        onChange={(e) => handleChangePosition(e.target.value)}
                        options={positions ? positions.map(value => ({ id: value.id, name: value.position_name })) : []}
                    />
                </div>

                {position === 2 || personInfo?.extension !=null && <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="extension"
                        component={renderField}
                        type="select"
                        label="app.label.extension_number"
                        placeholder="app.label.extension_number"
                        options={extensions ? extensions.map(value => ({ id: value.extension_number, name: value.extension_number })) : []}
                    />

                </div>}

                <div className="form-group floating-label show-label  col-md-6">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="service_id"
                        component={renderField}
                        type="select"
                        label="app.label.service"
                        placeholder="app.label.sercice"
                        options={services ? services.map(value => ({ id: value.id, name: value.service_name })) : []}
                    />

                </div>

                <div className="form-group floating-label show-label  col-md-6">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="entreprise_id"
                        component={renderField}
                        type="select"
                        label="app.label.company"
                        placeholder="app.label.company"
                        options={entreprises ? entreprises.map(value => ({ id: value.id, name: value.entr_name })) : []}
                    />

                </div>


                <div class="col-md-12">
                    <button type="submit"
                        class="btn btn-success btn-cta waves-effect text-center m-b-20 float-right"
                        disabled={pristine || submitting}>
                        <FormattedMessage id={`app.label.save`} defaultMessage={`Label Name`} />
                    </button>
                    {isLoading == true ?
                        <div id="loader">
                            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                        </div> : null}

                </div>
            </div>
            <div class="error-handling-messages">
                {message != "success" ?
                    <div id="error"> {message}</div> : null}
            </div>

        </form>


    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: props.personInfo ? props.personInfo : null

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'person',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(Form));

