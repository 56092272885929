/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getIntlMsg, intl} from "../../constants/utils";
import  {injectIntl, createIntl, createIntlCache} from "react-intl"


toast.configure()


export const updateExtensionById = ext => ({
    type: types.UPDATE_EXTENSION,
    payload: {isLoadingEXT: true, ext: ext}
});


export const updateExtensionByIdSuccess = mext => {

    toast.success(getIntlMsg(intl(mext), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_EXTENSION_SUCCESS,
        payload: {isLoadingEXT: false, mext: 'success'}
    }
};


export const updateExtensionByIdFailled = mext => {

    toast.error(getIntlMsg(intl(mext), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_EXTENSION_FAILLED,
        payload: {isLoadingEXT: true, mext: mext}
    }
};


export const createExtension = ext => ({
    type: types.CREATE_EXTENSION,
    payload: {isLoadingEXT: true, ext: ext}
});


export const createExtensionSuccess = mext => {

    toast.success(getIntlMsg(intl(mext), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_EXTENSION_SUCCESS,
        payload: {isLoadingEXT: false, mext: 'success'}
    }
};


export const createExtensionFailled = ms => {

    toast.error(getIntlMsg(intl(ms.lg), ms.mext), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_EXTENSION_FAILLED,
        payload: {isLoadingEXT: true, mext: ms.mext}
    }
};


export const getAllExtensions = () => ({
    type: types.GET_ALL_EXTENSIONS,
    payload: {isLoadingEXT: true}
});

export const getAllExtensionsSuccess = extensions => ({
    type: types.GET_ALL_EXTENSIONS_SUCCESS,
    payload: {isLoadingEXT: false, extensions: extensions}
});


export const getAllExtensionsFailled = () => ({
    type: types.GET_ALL_EXTENSIONS_FAILLED,
    payload: {isLoadingEXT: false}
});


export const getAllExtensionAvailable = () => ({
    type: types.GET_ALL_EXTENSIONS_AVAILABLE,
    payload: {isLoadingEXT: true}
});

export const getAllExtensionAvailableSuccess = extensionsA => ({
    type: types.GET_ALL_EXTENSIONS_AVAILABLE_SUCCESS,
    payload: {isLoadingEXT: false, extensionsA: extensionsA}
});


export const getAllExtensionAvailableFailled = () => ({
    type: types.GET_ALL_EXTENSIONS_AVAILABLE_FAILLED,
    payload: {isLoadingEXT: false}
});


