/**
 * Created by louidorpc on 9/12/20.
 */
import React from 'react'
import ClientForm from './clientForm'
import  {app} from '../../../constants/config'
import  {getIntlMsg, intl} from '../../../constants/utils'

const PersonModal = ({personInfo, services, language, positions, entreprises, previewFile, submitSetting, resetStateClient, mc}) => {

    return (
        <div class="modal fade modal-slide-right" id="slideRightModal" tabindex="-1" role="dialog"
             aria-labelledby="slideRightModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-lg" role="document">
                <div class="modal-content modal-lg">
                    <div class="modal-header">
                        <h5 class="modal-title"
                            id="slideRightModalLabel">{getIntlMsg(intl(language), 'app.label.client_form')}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="" style={{margin: 'auto'}}>
                            <label class="avatar-input">
                                    <span class="avatar avatar-xl">
                                        <img

                                            src={personInfo ? app.BASE_IMAGE_URL + '/models/images/' + personInfo.photo : "/assets/img/avatar.png"}
                                            id="filePreviewPers"
                                            class="avatar-img rounded-circle"/>
                                         <span class="avatar-input-icon rounded-circle">
                                        <i class="mdi mdi-upload mdi-24px"></i>
                                    </span>
                                    </span>
                                <input type="file" name="file" class="avatar-file-picker"
                                       onChange={previewFile}/>
                                <p align="center">Photo</p>
                            </label>

                        </div>
                        <ClientForm services={services} positions={positions} entreprises={entreprises}
                                    personInfo={personInfo} onSubmit={submitSetting}
                                    mc={mc}
                                    resetStateClient={resetStateClient}/>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PersonModal;