/**
 * Created by louidorpc on 9/12/20.
 */
/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React, {useState} from 'react'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization, ageYearDif} from "../../../constants/utils";
import {app} from '../../../constants/config';
import * as XLSX from 'xlsx';
import {FormattedMessage} from "react-intl";
import DataTable from 'react-data-table-component';
import {Person, Client, Customer} from '../../../constants/model'
import UploadCSV from '../../../common/uploadCsv'


const TablePerson = props => {

    const {language, isLoadingSv, createService, updateService, customers, person_id, getPersonInfo, createCustomer, entreprise_id}=props;


    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [listof, setListOf] = useState([]);

    const getData = (d, c, l) => {
        setColumns(c)
        setData(d)
        setListOf(l)
    }


    const handleSaveCustomer = lis => {

        const personEntity = new Customer({
            customer: lis,
            language: language,
            entreprise_id: entreprise_id
        });
        createCustomer(personEntity);

    }


    let rateBd = "";

    const columnss = [
        {
            title: getIntlMsg(intl(language), `app.label.fullname`),
            field: 'fullname', type: 'text',
            render: rowData => (
                <>
                <span class="avatar avatar-sm">
                                        <img

                                            src={rowData.photo ? app.BASE_IMAGE_URL + '/models/images/' + rowData.photo : "/assets/img/avatar.png"}
                                            class="avatar-img rounded-circle"/>


                </span>
                <span>  {rowData.firstname},{rowData.lastname}</span>
                </>
            ),
            width: '20%'

        },
        {
            title: getIntlMsg(intl(language), `app.label.phone`),
            field: 'phone', type: 'text'
        },
        {
            title: getIntlMsg(intl(language), `app.label.email`),
            field: 'email', type: 'text'
        },
        {
            title: getIntlMsg(intl(language), `app.label.address`),
            field: 'address', type: 'text'
        },
        {
            title: 'Age',
            type: 'text',
            render: rowData => <span>{ageYearDif(rowData ? rowData.birth_date : 0, language)}</span>
        }
    ]
    return (

        <div className="row">

            <div className="col-md-4">
                <UploadCSV entreprise_id={entreprise_id} language={language} getData={getData}/>
                {data.length > 0 ?
                    <button type="submit"
                            class="btn btn-success btn-cta waves-effect text-center m-b-5 float-right"
                            onClick={() => handleSaveCustomer(listof)}>
                        <FormattedMessage id={`app.label.save`} defaultMessage={`Label Name`}/>
                    </button> : null}

                <DataTable
                    pagination
                    highlightOnHover
                    columns={columns}
                    data={data}
                />
            </div>
            <div className="col-md-8">
                <MaterialTable
                    title={getIntlMsg(intl(language), `app.label.client_list`)}
                    columns={columnss}
                    data={customers ? customers : []}
                    icon='unfold_less'
                    localization={Localization(language)}
                    options={{
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },
                        actionsColumnIndex: -1,
                        exportButton: true,
                        exportAllData: true
                    }}
                    actions={[{
                        icon: row => (
                            <i className="mdi mdi-eye pr-2"
                               data-toggle="modal"
                               data-target="#slideRightModal"
                               title="view"></i>
                        ),
                        tooltip: 'View',
                        onClick: (e, row) => {
                            getPersonInfo(row);
                        }
                    }]
                    }
                />
            </div>
        </div>
    )
}


export default TablePerson