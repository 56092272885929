/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import {getIntlMsg, intl} from "../../constants/utils";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()


export const getAllEntreprises = () => ({
    type: types.GET_ALL_ENTREPRISES,
    payload: {isLoadingE: true}
});


export const getAllEntreprisesSuccess = msEnt => ({
    type: types.GET_ALL_ENTREPRISES_SUCCESS,
    payload: {isLoadingE: false, entreprises: msEnt}
});

export const getAllEntreprisesFailled = msEnt => ({
    type: types.GET_ALL_ENTREPRISES_FAILLED,
    payload: {isLoadingE: true, msEnt: msEnt}
});


export const createEntreprise = entreprise => ({
    type: types.CREATE_ENTREPRISE,
    payload: {
        isLoadingE: true, entreprise: entreprise
    }
});


export const createEntrepriseSuccess = msEnt => {

    toast.success(getIntlMsg(intl(msEnt), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_ENTREPRISE_SUCCESS,
        payload: {isLoadingE: false, msEnt: 'success'}
    };
}

export const createEntrepriseFailled = ms => {

    toast.error(getIntlMsg(intl(ms.locale), ms.msEnt), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_ENTREPRISE_FAILLED,
        payload: {isLoadingE: true, msEnt: ms.msEnt}
    };
}


export const updateEntreprise = upent => ({
    type: types.UPDATE_ENTREPRISE,
    payload: {
        isLoadingE: true, upent: upent
    }
});


export const updateEntrepriseSuccess = msEnt => {

    toast.success(getIntlMsg(intl(msEnt), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_ENTREPRISE_SUCCESS,
        payload: {isLoadingE: false, msEnt: 'success'}
    };
}

export const updateEntrepriseFailled = ms => {

    toast.error(getIntlMsg(intl(ms.locale), ms.msEnt), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_ENTREPRISE_FAILLED,
        payload: {isLoading: true, msEnt: ms.msEnt}
    };
}


export const getEntrepriseInfo = entrepriseInfo => ({
    type: types.GET_ENTREPRISE_INFO,
    payload: {
        isLoadingE: true, entrepriseInfo: entrepriseInfo
    }
});

export const getEntrepriseInfoSuccess = entrepriseInfo => ({
    type: types.GET_ENTREPRISE_INFO_SUCCESS,
    payload: {
        isLoadingE: true, entrepriseInfo: entrepriseInfo
    }
});

export const resetEntreprise = () => ({
    type: types.RESET_ENTREPRISE,
});

export const resetEntrepriseSuccess = () => ({
    type: types.RESET_ENTREPRISE_SUCCESS,
    payload: {
        entrepriseInfo: null, msEnt: null
    }
});


export const getAllDocuments = entr_name => ({
    type: types.GET_ALL_DOCUMENTS,
    payload: {
        isLoadingE: true, entr_name: entr_name
    }
});

export const getAllDocumentSuccess = documents=> ({
    type: types.GET_ALL_DOCUMENTS_SUCCESS,
    payload: {
        isLoadingE: true, documents: documents
    }
});

export const getAllDocumentFailled = ms => ({
    type: types.GET_ALL_DOCUMENTS_FAILLED,
    payload: {
        isLoadingE: false, ms: ms
    }
});



