import types from "../actions/type"

const defaultState = {
    isLoadingEXT: false,
    message: null,
    extensions: [],
    ext: null,
    mext: null,
    extensionsA:[]
}

const extensionReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_ALL_EXTENSIONS:
        case types.GET_ALL_EXTENSIONS_AVAILABLE:
            return {
                ...state,
                isLoadingEXT: action.payload.isLoadingEXT
            };

        case types.GET_ALL_EXTENSIONS_AVAILABLE_SUCCESS:
            return {
                ...state,
                extensionsA: action.payload.extensionsA,
                isLoadingEXT: action.payload.isLoadingEXT
            };


        case types.GET_ALL_EXTENSIONS_SUCCESS:
            return {
                ...state,
                extensions: action.payload.extensions,
                isLoadingEXT: action.payload.isLoadingEXT
            };

        case types.GET_ALL_EXTENSIONS_FAILLED:
        case types.GET_ALL_EXTENSIONS_AVAILABLE_FAILLED:
            return {
                ...state,
                mext: action.payload.mext,
                isLoadingEXT: action.payload.isLoadingEXT,
                extensionsA:[],
                extensions:[]
            };


        case types.CREATE_EXTENSION:
            return {
                ...state,
                isLoadingEXT: action.payload.isLoadingEXT,
                ext: action.payload.ext
            };

        case types.UPDATE_EXTENSION:
            return {
                ...state,
                isLoadingEXT: action.payload.isLoadingEXT,
                ext: action.payload.ext
            };

        case types.CREATE_EXTENSION_SUCCESS:
        case types.UPDATE_EXTENSION_SUCCESS:
            return {
                ...state,
                mext: action.payload.mext,
                isLoadingEXT: action.payload.isLoadingEXT
            };

        case types.CREATE_EXTENSION_FAILLED:
        case types.UPDATE_EXTENSION_FAILLED:
            return {
                ...state,
                mext: action.payload.mpo,
                isLoadingEXT: action.payload.isLoadingEXT
            };


        default:
            return state

    }
}


export default extensionReducer