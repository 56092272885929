import React from 'react'
import {SigninAgentModel} from '../../constants/model';
import FormAgent from './form';

const SigninAgent = ({SwitchToSignup, isLoading, message, msna, signinAgent, resetStateSignin, intl, extensions,getAllExtensionAvailable}) => {


    const submit = values => {

        const snEntity = new SigninAgentModel({...values});
        signinAgent(snEntity);
    };


    return (
        <FormAgent
            onSubmit={submit}
            message={message}
            intl={intl}
            resetStateSignin={resetStateSignin}
            isLoading={isLoading}
            SwitchToSignup={SwitchToSignup}
            msna={msna}
            extensions={extensions}
            getAllExtensionAvailable={getAllExtensionAvailable}
        />
    )
}
export default SigninAgent;