/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import { toast } from 'react-toastify';
import { getIntlMsg, intl } from "../../constants/utils";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()


export const getAllMonthReportsByClient = criteria => ({
    type: types.GET_ALL_MONTH_REPORTS_BY_CLIENT,
    payload: { isLoadingDr: true, criteria: criteria }
});

export const getAllMonthReports = criteria => ({
    type: types.GET_ALL_MONTH_REPORTS,
    payload: { isLoadingDr: true,criteria }
});


export const getAllMonthReportsSuccess = monthreports => ({
    type: types.GET_ALL_MONTH_REPORTS_SUCCESS,
    payload: { isLoadingDr: false, monthreports: monthreports }
});


export const getAllMonthReportsByClientSuccess = clientMonthreports => ({
    type: types.GET_ALL_MONTH_REPORTS_BY_CLIENT_SUCCESS,
    payload: { isLoadingDr: false, clientMonthreports: clientMonthreports }
});

export const getAllMonthReportsFailed = ms => ({
    type: types.GET_ALL_MONTH_REPORTS_FAILED,
    payload: { isLoadingDr: true, ms: ms }
});

export const getAllMonthReportsByClientFailed = ms => ({
    type: types.GET_ALL_MONTH_REPORTS_BY_CLIENT_FAILED,
    payload: { isLoadingDr: true, ms: ms }
});


export const createMonthReport = monthreport => ({
    type: types.CREATE_MONTH_REPORT,
    payload: {
        isLoadingDr: true, monthreport: monthreport
    }
});


export const createMonthReportSuccess = ms => {

    toast.success(getIntlMsg(intl('fr'), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_MONTH_REPORT_SUCCESS,
        payload: { isLoadingDr: false, ms: 'success' }
    };
}

export const createMonthReportFailed = ms => {

    toast.error(getIntlMsg(intl('fr'), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_MONTH_REPORT_FAILED,
        payload: { isLoadingDr: true, ms: ms }
    };
}

export const resetMonthReport = () => ({
    type: types.RESET_MONTH_REPORT
});

export const resetMonthReportSuccess = () => ({
    type: types.RESET_MONTH_REPORT_SUCCESS,
    payload: {
         ms: null
    }
});


export const editMonthReport = monthreport => ({
    type: types.EDIT_MONTH_REPORT,
    payload: {
        isLoadingDr: true, monthreport: monthreport
    }
});

export const deleteMonthReport = monthreport => ({
    type: types.DELETE_MONTH_REPORT,
    payload: {
        isLoadingDr: true, monthreport: monthreport
    }
});

export const editMonthReportSuccess = ms => {

    toast.success(getIntlMsg(intl('fr'), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.EDIT_MONTH_REPORT_SUCCESS,
        payload: { isLoadingDr: false, ms: 'success' }
    };
}


export const deleteMonthReportSuccess = ms => {

    toast.success(getIntlMsg(intl('fr'), `app.label.deleteSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_MONTH_REPORT_SUCCESS
    };
}


export const editMonthReportFailed = ms => {

    toast.error(getIntlMsg(intl('fr'), `app.label.editError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.EDIT_MONTH_REPORT_FAILED,
        payload: { isLoadingDr: true, ms: ms }
    };
}

export const deleteMonthReportFailed = ms => {

    toast.error(getIntlMsg(intl('fr'), `app.label.deleteError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_MONTH_REPORT_FAILED,
    };
}
