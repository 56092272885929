import React from "react";
import { FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { connect } from 'react-redux'
import { validate } from "./validator";
import { renderField } from "../../../common/FormComponents";


const onClearForm = (reset, resetStateSignup) => {
    reset('signup');
    resetStateSignup();

}

const Form = props => {
    const { handleSubmit, valid, reset, pristine, submitting, resetStateSignup, intl, isLoading, history, message, form, SwitchToSignin, sites } = props;

    if (message === "success signup") onClearForm(reset, resetStateSignup)
    return (
        <form onSubmit={handleSubmit} className="form-row">
            <div className="form-group floating-label col-md-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="fullname"
                    component={renderField}
                    type="text"
                    label="app.label.fullname"
                    placeholder="app.label.fullname"
                />

            </div>
            <div className="form-group floating-label col-md-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="username"
                    component={renderField}
                    type="text"
                    label="app.label.username"
                    placeholder="app.label.username"
                />

            </div>

            <div className="form-group floating-label col-md-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="email"
                    component={renderField}
                    type="email"
                    label="app.label.email"
                    placeholder="app.label.email"
                />

            </div>

            <div className="form-group floating-label show-label  col-md-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="language"
                    component={renderField}
                    type="select"
                    label="app.label.language"
                    placeholder="app.label.language"
                    options={[{ id: "fr", name: "fr" }, { id: "en", name: "en" }]}
                />

            </div>


            <div className="form-group floating-label col-md-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="password"
                    component={renderField}
                    type="password"
                    label="app.label.password"
                    placeholder="app.label.password"
                />

            </div>

            <div class="col-md-12">
                <button type="submit"
                    className={`btn btn-primary btn-md btn-block waves-effect text-center float-right`}
                    disabled={pristine || submitting}>
                    <FormattedMessage id={'app.label.signup'} />
                </button>
                {isLoading == true ?
                    <div id="loader">
                        <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                    </div> : null}

            </div>

            <p class="text-inverse text-left p-4">Si vous avez un compte, 
                <span className="ml-1 text-c-red"><a href="#" onClick={() => SwitchToSignin('signin')}><b>Connectez-vous</b></a></span></p>
            <div class="error-handling-messages">
                {message != "success" ?
                    <div id="error"> {message}</div> : null}
            </div>
        </form>

    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: null,

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'signup',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(Form));

