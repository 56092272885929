/**
 * Created by louidorpc on 11/4/20.
 */
import axios from "axios"
import { app } from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 700000,
});

export const getAllAgentSessions = pid => {
    return instance.get(`/agent/index.php?complet_date=${pid.complet_date}`)
};


export const getAllSessionsByAgent = pid => {
    return instance.get(`/agent/session/index.php?person_id=${pid}`)
};

export const createAssignCampaign = cac => {
    return instance.post(`/campaign/assign/index.php`, cac)

};


export const createEmergencyCall = cac => {
    return instance.post(`/agent/emergency/create/index.php`, cac)

};

export const editEmergencyCall = cac => {
    return instance.put(`/agent/emergency/edit/index.php`, cac)

};

export const deleteEmergencyCall = id => {
    return instance.get(`/agent/emergency/delete/index.php?id=${id}`)

};



export const getAllEmergencyCalls = () => {
    return instance.get(`/agent/emergency/calls.php`)
};

export const getAllRecordingCall = () => {
    return instance.get(`/agent/recordingcall/index.php`)
};


export const getAllPhones = phone => {
    return instance.get(`/agent/phones/index.php?phone=${phone}`)
};
