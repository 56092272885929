/**
 * Created by louidorpc on 8/4/20.
 */
import React from 'react'
import Admin from '../../components/admin'
import {deleteService} from "../../actions/creator/service";
import {deletePosition} from "../../actions/creator/position";


const Home = props => {

    const {
        sr: {session, isLoadingS},
        actions: {
            getPersonById,
            getAllTatistic,
            getAllServices,
            createService,
            updateService,
            getAllPositions,
            createPosition,
            updatePosition,
            resetEntreprise,
            getAllPersons,
            getPersonInfo,
            getAllEntreprises,
            createEntreprise,
            updateEntreprise,
            getEntrepriseInfo,
            updatePersonById,
            resetStatePerson,
            changeUserStatus,
            createClient,
            resetStateClient,
            getAllClients,
            updateExtensionById,
            createExtension,
            getAllExtensions,
            updateUserById,
            getAllDocuments,
            updatePrefixById,
            createPrefix,
            getAllPrefix,
            getAllPrefixAvailable,
            deleteService,
            deletePosition,
            deletePrefix
        },
        person: {ps, isLoading, persons, personInfo, mups},
        intl,
        stat: {stats, isLoadingSt},
        sv: {isLoadingSv, services},
        positions,
        entreprises,
        isLoadingE,
        entrepriseInfo,
        msEnt,
        mc,
        clients,
        extensions,
        documents,
        prefix,
        prefixA
    } = props;

    if (session !== null)
        return (
            <Admin session={session}
                   getPersonById={getPersonById}
                   ps={ps} isLoading={isLoading}
                   intl={intl}
                   isLoadingS={isLoadingS}
                   getAllTatistic={getAllTatistic}
                   stats={stats}
                   isLoadingSt={isLoadingSt}
                   isLoadingSv={isLoadingSv}
                   getAllServices={getAllServices}
                   createService={createService}
                   updateService={updateService}
                   services={services}
                   positions={positions}
                   getAllDocuments={getAllDocuments}
                   documents={documents}

                   updatePersonById={updatePersonById}

                   getAllPositions={getAllPositions}
                   createPosition={createPosition}
                   updatePosition={updatePosition}

                   getAllEntreprises={getAllEntreprises} createEntreprise={createEntreprise}
                   updateEntreprise={updateEntreprise} getEntrepriseInfo={getEntrepriseInfo}
                   entrepriseInfo={entrepriseInfo} entreprises={entreprises} isLoadingE={isLoadingE}
                   msEnt={msEnt}
                   resetEntreprise={resetEntreprise}
                   persons={persons}
                   getAllPersons={getAllPersons}
                   getPersonInfo={getPersonInfo}
                   personInfo={personInfo}
                   mups={mups}
                   resetStatePerson={resetStatePerson}

                   changeUserStatus={changeUserStatus}
                   createClient={createClient}
                   resetStateClient={resetStateClient}
                   mc={mc}
                   clients={clients}
                   getAllClients={getAllClients}

                   updateExtensionById={updateExtensionById}
                   createExtension={createExtension}
                   getAllExtensions={getAllExtensions}
                   extensions={extensions}
                   updateUserById={updateUserById}
                   {...{updatePrefixById, createPrefix, getAllPrefix, getAllPrefixAvailable, prefix, prefixA}}
                   deleteService={deleteService}
                   deletePosition={deletePosition}
                   deletePrefix={deletePrefix}

            />
        )
    else return <div></div>
}


export default Home;