/**
 * Created by louidorpc on 1/9/21.
 */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';


const EmbededCampaignReport = props => {

    const { getLinkByType } = props;

    const [getLink, setLInk] = useState(null)

    useEffect(() => {
        getLinkByType('REPORT_ADMIN')
    }, [])

    const { linkByType } = useSelector(state => ({
        linkByType: state?.linkReducer?.linkByType || [],
    }));

    const displayComponent = (link) => {

        return (<embed
            src={`${link}`}
            width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>)

    }


    useEffect(() => {

        setLInk({ ...linkByType[0], index: 0 })

    }, [linkByType])

    return (
        // https://app.smartsheet.com/b/publish?EQBCT=f14332029eeb4c248d612a1742d89700
        // <embed src="https://app.smartsheet.com/b/publish?EQBCT=f14332029eeb4c248d612a1742d89700" width="100%" style={{height: 'calc(100vh - 70px)'}}></embed>


        <section className="admin-content">

            <div className="container-fluid bg-dark m-b-30">
                <div className="row  p-b-60 p-t-20">
                    <div className="col-md-6 text-white my-auto text-md-right p-b-30">

                        <div className="">
                            <h2 className="opacity-75"><a href="#!">
                                Rapport des appels</a>
                            </h2>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid pull-up">
                <div className="row ">
                    <div className="col-lg-12">
                        <div className="card m-b-30">
                            <div className="card-header">
                                <div className="card-title"></div>
                            </div>
                            <div className="card-body">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">

                                    {linkByType.length > 0 ? linkByType.map((v, index) =>

                                        <li className="nav-item">
                                            <a className={`nav-link ${index == getLink?.index ? 'active' : ''}`} id="contact-tab-z" data-toggle="tab"
                                                href={v.link}
                                                onClick={() => setLInk({ ...v, index: index == 0 ? 0 : index })}
                                                role="tab"
                                                aria-controls="contact" aria-selected="false"><i
                                                    className="mdi mdi-office-building"> {v.entr_name}</i></a>
                                        </li>) : null}


                                </ul>
                                <div className="tab-content" id="myTabContent1">

                                   <div className={`tab-pane fade active show`} id={getLink?.index} role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        {getLink ? displayComponent(getLink?.link) : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default EmbededCampaignReport;