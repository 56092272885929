const types = {

    CHANGE_LOCALE_REQUEST: "CHANGE_LOCALE_REQUEST",
    CHANGE_LOCALE_REQUEST_SUCCESS: "CHANGE_LOCALE_REQUEST_SUCCESS",
    CHANGE_LOCALE_REQUEST_FAILLED: "CHANGE_LOCALE_REQUEST_FAILLED",

    GET_USER_SESSION_INFO: "GET_USER_SESSION_INFO",
    GET_USER_SESSION_SUCCESS: "GET_USER_SESSION_SUCCESS",
    GET_USER_SESSION_FAILLED: "GET_USER_SESSION_FAILLED",

    SWITCH_TO_SIGNIN: "SWITCH_TO_SIGNIN",
    SWITCH_TO_SIGNUP: "SWITCH_TO_SIGNUP",
    LOGIN_SWITCH: "LOGIN_SWITCH",

    SIGNIN: "SIGNIN",
    SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
    SIGNIN_FAILLED: "SIGNIN_FAILLED",
    RESET_STATE_SIGNIN: "RESET_STATE_SIGNIN",
    RESET_STATE_SIGNIN_SUCCESS: "RESET_STATE_SIGNIN_SUCCESS",

    SIGNUP: "SIGNUP",
    SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
    SIGNUP_FAILLED: "SIGNUP_FAILLED",
    RESET_STATE_SIGNUP: "RESET_STATE_SIGNUP",
    RESET_STATE_SIGNUP_SUCCESS: "RESET_STATE_SIGNUP_SUCCESS",

    PERSON_RESET_STATE: "PERSON_RESET_STATE",
    PERSON_RESET_STATE_SUCCES: "PERSON_RESET_STATE_SUCCES",

    GET_PERSON_BY_ID: "GET_PERSON_BY_ID",
    GET_PERSON_BY_ID_SUCCES: "GET_PERSON_BY_ID_SUCCES",
    GET_PERSON_BY_ID_FAILLED: "GET_PERSON_BY_ID_FAILLED",


    UPDATE_PERSON_BY_ID: "UPDATE_PERSON_BY_ID",
    UPDATE_PERSON_BY_ID_SUCCESS: "UPDATE_PERSON_BY_ID_SUCCESS",
    UPDATE_PERSON_BY_ID_FAILLED: "UPDATE_PERSON_BY_ID_FAILLED",


    GET_SITES: "GET_SITES",
    GET_SITES_SUCCESS: "GET_SITES_SUCCESS",
    GET_SITES_FAILLED: "GET_SITES_FAILLED",

    GET_SITE_BY_ID: "GET_SITE_BY_ID",
    GET_SITE_BY_ID_SUCCESS: "GET_SITE_BY_ID_SUCCESS",
    GET_SITE_BY_ID_FAILLED: "GET_SITE_BY_ID_FAILLED",

    CREATE_SITE: "CREATE_SITE",
    CREATE_SITE_SUCCESS: "CREATE_SITE_SUCCESS",
    CREATE_SITE_FAILLED: "CREATE_SITE_FAILLED",

    UPDATE_SITE: "UPDATE_SITE",
    UPDATE_SITE_SUCCESS: "UPDATE_SITE_SUCCESS",
    UPDATE_SITE_FAILLED: "UPDATE_SITE_FAILLED",

    RESET_SITES: "RESET_SITES",
    RESET_SITES_SUCCESS: "RESET_SITES_SUCCESS",


    GET_ALL_STATISTIC: "GET_ALL_STATISTIC",
    GET_ALL_STATISTIC_SUCCESS: "GET_ALL_STATISTIC_SUCCESS",
    GET_ALL_STATISTIC_FAILLED: "GET_ALL_STATISTIC_FAILLED",


    GET_ALL_SERVICES: "GET_ALL_SERVICES",
    GET_ALL_SERVICES_SUCCESS: "GET_ALL_SERVICES_SUCCESS",
    GET_ALL_SERVICES_FAILLED: "GET_ALL_SERVICES_FAILLED",

    CREATE_SERVICE: "CREATE_SERVICE",
    CREATE_SERVICE_SUCCESS: "CREATE_SERVICE_SUCCESS",
    CREATE_SERVICE_FAILLED: "CREATE_SERVICE_FAILLED",

    UPDATE_SERVICE: "UPDATE_SERVICE",
    UPDATE_SERVICE_SUCCESS: "UPDATE_SERVICE_SUCCESS",
    UPDATE_SERVICE_FAILLED: "UPDATE_SERVICE_FAILLED",

    DELETE_SERVICE: "DELETE_SERVICE",
    DELETE_SERVICE_SUCCESS: "DELETE_SERVICE_SUCCESS",
    DELETE_SERVICE_FAILLED: "DELETE_SERVICE_FAILLED",


    GET_ALL_POSITIONS: "GET_ALL_POSITIONS",
    GET_ALL_POSITIONS_SUCCESS: "GET_ALL_POSITIONS_SUCCESS",
    GET_ALL_POSITIONS_FAILLED: "GET_ALL_POSITIONS_FAILLED",

    CREATE_POSITION: "CREATE_POSITION",
    CREATE_POSITION_SUCCESS: "CREATE_POSITION_SUCCESS",
    CREATE_POSITION_FAILLED: "CREATE_POSITION_FAILLED",

    UPDATE_POSITION: "UPDATE_POSITION",
    UPDATE_POSITION_SUCCESS: "UPDATE_POSITION_SUCCESS",
    UPDATE_POSITION_FAILLED: "UPDATE_POSITION_FAILLED",

    DELETE_POSITION: "DELETE_POSITION",
    DELETE_POSITION_SUCCESS: "DELETE_POSITION_SUCCESS",
    DELETE_POSITION_FAILLED: "DELETE_POSITION_FAILLED",


    GET_ALL_ENTREPRISES: "GET_ALL_ENTREPRISES",
    GET_ALL_ENTREPRISES_SUCCESS: "GET_ALL_ENTREPRISES_SUCCESS",
    GET_ALL_ENTREPRISES_FAILLED: "GET_ALL_ENTREPRISES_FAILLED",

    CREATE_ENTREPRISE: "CREATE_ENTREPRISE",
    CREATE_ENTREPRISE_SUCCESS: "CREATE_ENTREPRISE_SUCCESS",
    CREATE_ENTREPRISE_FAILLED: "CREATE_ENTREPRISE_FAILLED",

    UPDATE_ENTREPRISE: "UPDATE_ENTREPRISE",
    UPDATE_ENTREPRISE_SUCCESS: "UPDATE_ENTREPRISE_SUCCESS",
    UPDATE_ENTREPRISE_FAILLED: "UPDATE_ENTREPRISE_FAILLED",

    GET_ENTREPRISE_INFO: "GET_ENTREPRISE_INFO",
    GET_ENTREPRISE_INFO_SUCCESS: "GET_ENTREPRISE_INFO_SUCCESS",

    RESET_ENTREPRISE: "RESET_ENTREPRISE",
    RESET_ENTREPRISE_SUCCESS: "RESET_ENTREPRISE_SUCCESS",

    GET_ALL_PERSONS: "GET_ALL_PERSONS",
    GET_ALL_PERSONS_SUCCESS: "GET_ALL_PERSONS_SUCCESS",
    GET_ALL_PERSONS_FAILLED: "GET_ALL_PERSONS_FAILLED",


    GET_PERSON_INFO: "GET_PERSON_INFO",
    GET_PERSON_INFO_SUCCESS: "GET_PERSON_INFO_SUCCESS",

    CHANGE_USER_STATUS: "CHANGE_USER_STATUS",
    CHANGE_USER_STATUS_SUCCESS: "CHANGE_USER_STATUS_SUCCESS",
    CHANGE_USER_STATUS_FAILLED: "CHANGE_USER_STATUS_FAILLED",


    GET_ALL_CLIENTS: "GET_ALL_CLIENTS",
    GET_ALL_CLIENTS_SUCCESS: "GET_ALL_CLIENTS_SUCCESS",
    GET_ALL_CLIENTS_FAILLED: "GET_ALL_CLIENTS_FAILLED",


    CREATE_CLIENT: "CREATE_CLIENT",
    CREATE_CLIENT_SUCCESS: "CREATE_CLIENT_SUCCESS",
    CREATE_CLIENT_FAILLED: "CREATE_CLIENT_FAILLED",


    UPDATE_CLIENT: "UPDATE_CLIENT",
    UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
    UPDATE_CLIENT_FAILLED: "UPDATE_CLIENT_FAILLED",


    CLIENT_RESET_STATE: "CLIENT_RESET_STATE",
    CLIENT_RESET_STATE_SUCCES: "CLIENT_RESET_STATE_SUCCES",


    CREATE_CUSTOMER: "CREATE_CUSTOMER",
    CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
    CREATE_CUSTOMER_FAILLED: "CREATE_CUSTOMER_FAILLED",


    GET_ALL_CUSTOMERS: "GET_ALL_CUSTOMERS",
    GET_ALL_CUSTOMERS_SUCCESS: "GET_ALL_CUSTOMERS_SUCCESS",
    GET_ALL_CUSTOMERS_FAILLED: "GET_ALL_CUSTOMERS_FAILLED",


    CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
    CREATE_CAMPAIGN_SUCCESS: "CREATE_CAMPAIGN_SUCCESS",
    CREATE_CAMPAIGN_FAILLED: "CREATE_CAMPAIGN_FAILLED",


    GET_ALL_CAMPAIGN: "GET_ALL_CAMPAIGN",
    GET_ALL_CAMPAIGN_SUCCESS: "GET_ALL_CAMPAIGN_SUCCESS",
    GET_ALL_CAMPAIGN_FAILLED: "GET_ALL_CAMPAIGN_FAILLED",


    GET_ALL_PHONE_CAMPAIGN_BY_CODE: "GET_ALL_PHONE_CAMPAIGN_BY_CODE",
    GET_ALL_PHONE_CAMPAIGN_BY_CODE_SUCCESS: "GET_ALL_PHONE_CAMPAIGN_BY_CODE_SUCCESS",
    GET_ALL_PHONE_CAMPAIGN_BY_CODE_FAILLED: "GET_ALL_PHONE_CAMPAIGN_BY_CODE_FAILLED",


    CREATE_EXTENSION: "CREATE_EXTENSION",
    CREATE_EXTENSION_SUCCESS: "CREATE_EXTENSION_SUCCESS",
    CREATE_EXTENSION_FAILLED: "CREATE_EXTENSION_FAILLED",


    GET_ALL_EXTENSIONS: "GET_ALL_EXTENSIONS",
    GET_ALL_EXTENSIONS_SUCCESS: "GET_ALL_EXTENSIONS_SUCCESS",
    GET_ALL_EXTENSIONS_FAILLED: "GET_ALL_EXTENSIONS_FAILLED",

    UPDATE_EXTENSION: "UPDATE_EXTENSION",
    UPDATE_EXTENSION_SUCCESS: "UPDATE_EXTENSION_SUCCESS",
    UPDATE_EXTENSION_FAILLED: "UPDATE_EXTENSION_FAILLED",


    SIGNIN_AGENT: "SIGNIN_AGENT",
    SIGNIN_AGENT_SUCCESS: "SIGNIN_AGENT_SUCCESS",
    SIGNIN_AGENT_FAILLED: "SIGNIN_AGENT_FAILLED",


    SIGNOUT_AGENT: "SIGNOUT_AGENT",
    SIGNOUT_AGENT_SUCCESS: "SIGNOUT_AGENT_SUCCESS",
    SIGNOUT_AGENT_FAILLED: "SIGNOUT_AGENT_FAILLED",


    GET_ALL_EXTENSIONS_AVAILABLE: "GET_ALL_EXTENSIONS_AVAILABLE",
    GET_ALL_EXTENSIONS_AVAILABLE_SUCCESS: "GET_ALL_EXTENSIONS_AVAILABLE_SUCCESS",
    GET_ALL_EXTENSIONS_AVAILABLE_FAILLED: "GET_ALL_EXTENSIONS_AVAILABLE_FAILLED",


    CALENDAR_CHANGE_VIEW: "CALENDAR_CHANGE_VIEW",
    CALENDAR_CHANGE_VIEW_SUCCESS: "CALENDAR_CHANGE_VIEW_SUCCESS",
    CALENDAR_EVENT: "CALENDAR_EVENT",
    CALENDAR_EVENT_SUCCESS: "CALENDAR_EVENT_SUCCESS",

    GET_SELECTED_DATE_FROM_CALENDAR: "GET_SELECTED_DATE_FROM_CALENDAR",
    GET_SELECTED_DATE_FROM_CALENDAR_SUCCESS: "GET_SELECTED_DATE_FROM_CALENDAR_SUCCESS",


    GET_ALL_AGENT_SESSIONS: "GET_ALL_AGENT_SESSIONS",
    GET_ALL_AGENT_SESSIONS_SUCCESS: "GET_ALL_AGENT_SESSIONS_SUCCESS",
    GET_ALL_AGENT_SESSIONS_FAILLED: "GET_ALL_AGENT_SESSIONS_FAILLED",


    GET_ALL_SESSIONS_BY_AGENT: "GET_ALL_SESSIONS_BY_AGENT",
    GET_ALL_SESSIONS_BY_AGENT_SUCCESS: "GET_ALL_SESSIONS_BY_AGENT_SUCCESS",
    GET_ALL_SESSIONS_BY_AGENT_FAILLED: "GET_ALL_SESSIONS_BY_AGENT_FAILLED",


    GET_CAMPAIGNS: "GET_CAMPAIGNS",
    GET_CAMPAIGNS_SUCCESS: "GET_CAMPAIGNS_SUCCESS",
    GET_CAMPAIGNS_FAILLED: "GET_CAMPAIGNS_FAILLED",

    GET_CAMPAIGNS_BY_ID: "GET_CAMPAIGNS_BY_ID",
    GET_CAMPAIGNS_BY_ID_SUCCESS: "GET_CAMPAIGNS_BY_ID_SUCCESS",
    GET_CAMPAIGNS_BY_ID_FAILLED: "GET_CAMPAIGNS_BY_ID_FAILLED",


    CHANGE_PHONE_QTY: "CHANGE_PHONE_QTY",
    CHANGE_PHONE_QTY_SUCCESS: "CHANGE_PHONE_QTY_SUCCESS",


    CREATE_ASSIGN_CAMPAIGN: "CREATE_ASSIGN_CAMPAIGN",
    CREATE_ASSIGN_CAMPAIGN_SUCCESS: "CREATE_ASSIGN_CAMPAIGN_SUCCESS",
    CREATE_ASSIGN_CAMPAIGN_FAILLED: "CREATE_ASSIGN_CAMPAIGN_FAILLED",


    GET_ASSIGNED_CAMPAIGN_BY_AGENT: "GET_ASSIGNED_CAMPAIGN_BY_AGENT",
    GET_ASSIGNED_CAMPAIGN_BY_AGENT_SUCCESS: "GET_ASSIGNED_CAMPAIGN_BY_AGENT_SUCCESS",
    GET_ASSIGNED_CAMPAIGN_BY_AGENT_FAILLED: "GET_ASSIGNED_CAMPAIGN_BY_AGENT_FAILLED",

    GET_CAMPAIGN_ITEM: "GET_CAMPAIGN_ITEM",
    GET_CAMPAIGN_ITEM_SUCCESS: "GET_CAMPAIGN_ITEM_SUCCESS",


    EDIT_CAMPAIGN: "EDIT_CAMPAIGN",
    EDIT_CAMPAIGN_SUCCESS: "EDIT_CAMPAIGN_SUCCESS",
    EDIT_CAMPAIGN_FAILLED: "EDIT_CAMPAIGN_FAILLED",

    GET_ALL_CAMPAIGNS_TO_CALENDAR: "GET_ALL_CAMPAIGNS_TO_CALENDAR",
    GET_ALL_CAMPAIGNS_TO_CALENDAR_SUCCESS: "GET_ALL_CAMPAIGNS_TO_CALENDAR_SUCCESS",
    GET_ALL_CAMPAIGNS_TO_CALENDAR_FAILLED: "GET_ALL_CAMPAIGNS_TO_CALENDAR_FAILLED",


    GET_ALL_RECORDING_CALL: "GET_ALL_RECORDING_CALL",
    GET_ALL_RECORDING_CALL_SUCCESS: "GET_ALL_RECORDING_CALL_SUCCESS",
    GET_ALL_RECORDING_CALL_FAILLED: "GET_ALL_RECORDING_CALL_FAILLED",


    CREATE_EMERGENCY_CALL: "CREATE_EMERGENCY_CALL",
    CREATE_EMERGENCY_CALL_SUCCESS: "CREATE_EMERGENCY_CALL_SUCCESS",
    CREATE_EMERGENCY_CALL_FAILLED: "CREATE_EMERGENCY_CALL_FAILLED",


    EDIT_EMERGENCY_CALL: "EDIT_EMERGENCY_CALL",
    EDIT_EMERGENCY_CALL_SUCCESS: "EDIT_EMERGENCY_CALL_SUCCESS",
    EDIT_EMERGENCY_CALL_FAILLED: "EDIT_EMERGENCY_CALL_FAILLED",


    RESET_EMERGENCY_CALL: "RESET_EMERGENCY_CALL",
    RESET_EMERGENCY_CALL_SUCCESS: "RESET_EMERGENCY_CALL_SUCCESS",


    GET_ALL_EMERGENCY_CALL: "GET_ALL_EMERGENCY_CALL",
    GET_ALL_EMERGENCY_CALL_SUCCESS: "GET_ALL_EMERGENCY_CALL_SUCCESS",
    GET_ALL_EMERGENCY_CALL_FAILLED: "GET_ALL_EMERGENCY_CALL_FAILLED",

    DELETE_EMERGENCY_CALL: "DELETE_EMERGENCY_CALL",
    DELETE_EMERGENCY_CALL_SUCCESS: "DELETE_EMERGENCY_CALL_SUCCESS",
    DELETE_EMERGENCY_CALL_FAILLED: "DELETE_EMERGENCY_CALL_FAILLED",

    GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE: "GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE",
    GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE_SUCCESS: "GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE_SUCCESS",
    GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE_FAILLED: "GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE_FAILLED",


    GET_ALL_REPORTS: "GET_ALL_REPORTS",
    GET_ALL_REPORTS_SUCCESS: "GET_ALL_REPORTS_SUCCESS",
    GET_ALL_REPORT_FAILLED: "GET_ALL_REPORT_FAILLED",

    GET_ALL_PHONE_BY_PHONE: "GET_ALL_PHONE_BY_PHONE",
    GET_ALL_PHONE_BY_PHONE_SUCCESS: "GET_ALL_PHONE_BY_PHONE_SUCCESS",
    GET_ALL_PHONE_BY_PHONE_FAILLED: "GET_ALL_PHONE_BY_PHONE_FAILLED",


    GET_USER_BY_ID: "GET_USER_BY_ID",
    GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
    GET_USER_BY_ID_FAILLED: "GET_USER_BY_ID_FAILLED",


    UPDATE_USER_BY_ID: "UPDATE_USER_BY_ID",
    UPDATE_USER_BY_ID_SUCCESS: "UPDATE_USER_BY_ID_SUCCESS",
    UPDATE_USER_BY_ID_FAILLED: "UPDATE_USER_BY_ID_FAILLED",


    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILLED: "CHANGE_PASSWORD_FAILLED",


    GET_ALL_DOCUMENTS: "GET_ALL_DOCUMENTS",
    GET_ALL_DOCUMENTS_SUCCESS: "GET_ALL_DOCUMENTS_SUCCESS",
    GET_ALL_DOCUMENTS_FAILLED: "GET_ALL_DOCUMENTS_FAILLED",


    GET_ALL_RECORDING_CALL_BY_ENTR_ID: "GET_ALL_RECORDING_CALL_BY_ENTR_ID",
    GET_ALL_RECORDING_CALL_BY_ENTR_ID_SUCCESS: "GET_ALL_RECORDING_CALL_BY_ENTR_ID_SUCCESS",
    GET_ALL_RECORDING_CALL_BY_ENTR_ID_FAILLED: "GET_ALL_RECORDING_CALL_BY_ENTR_ID_FAILLED",


    GET_ALL_PREFIX: "GET_ALL_PREFIX",
    GET_ALL_PREFIX_SUCCESS: "GET_ALL_PREFIX_SUCCESS",
    GET_ALL_PREFIX_FAILLED: "GET_ALL_PREFIX_SUCCESS_FAILLED",

    CREATE_PREFIX: "CREATE_PREFIX",
    CREATE_PREFIX_SUCCESS: "CREATE_PREFIX_SUCCESS",
    CREATE_PREFIX_FAILLED: "CREATE_PREFIX_FAILLED",


    UPDATE_PREFIX: "UPDATE_PREFIX",
    UPDATE_PREFIX_SUCCESS: "UPDATE_PREFIX_SUCCESS",
    UPDATE_PREFIX_FAILLED: "UPDATE_PREFIX_FAILLED",

    DELETE_PREFIX: "DELETE_PREFIX",
    DELETE_PREFIX_SUCCESS: "DELETE_PREFIX_SUCCESS",
    DELETE_PREFIX_FAILLED: "DELETE_PREFIX_FAILLED",



    GET_ALL_PREFIX_AVAILABLE: "GET_ALL_PREFIX_AVAILABLE",
    GET_ALL_PREFIX_AVAILABLE_SUCCESS: "GET_ALL_PREFIX_AVAILABLE_SUCCESS",
    GET_ALL_PREFIX_AVAILABLE_FAILLED: "GET_ALL_PREFIX_AVAILABLE_FAILLED",

    GET_ALL_PREFIX_BY_ID: "GET_ALL_PREFIX_BY_ID",
    GET_ALL_PREFIX_BY_ID_SUCCESS: "GET_ALL_PREFIX_BY_ID_SUCCESS",
    GET_ALL_PREFIX_BY_ID_FAILLED: "GET_ALL_PREFIX_BY_ID_FAILLED",

    GET_ASSIGN_PHONE_BY_AGENT:'GET_ASSIGN_PHONE_BY_AGENT',
    GET_ASSIGN_PHONE_BY_AGENT_SUCCESS:'GET_ASSIGN_PHONE_BY_AGENT_SUCCESS',
    GET_ASSIGN_PHONE_BY_AGENT_FAILLED:'GET_ASSIGN_PHONE_BY_AGENT_FAILLED',

    RESET_STATE_RESET_PASSWORD:'RESET_STATE_RESET_PASSWORD',
    RESET_STATE_RESET_PASSWORD_SUCCESS:'RESET_STATE_RESET_PASSWORD_SUCCESS',

    RESET_PASSWORD:'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS:'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILLED:'RESET_PASSWORD_FAILLED',


    DELETE_CAMPAIGN:'DELETE_CAMPAIGN',
    DELETE_CAMPAIGN_SUCCESS:'DELETE_CAMPAIGN_SUCCESS',
    DELETE_CAMPAIGN_FAILLED:'DELETE_CAMPAIGN_FAILLED',



    GET_ALL_PBX_REPORTS:'GET_ALL_PBX_REPORTS',
    GET_ALL_PBX_REPORTS_SUCCESS:'GET_ALL_PBX_REPORTS_SUCCESS',
    GET_ALL_PBX_REPORTS_FAILLED:'GET_ALL_PBX_REPORTS_FAILLED',


    GET_ALL_INCOMING_CALL_REPORTS:'GET_ALL_INCOMING_CALL_REPORTS',
    GET_ALL_INCOMING_CALL_REPORTS_SUCCESS:'GET_ALL_INCOMING_CALL_REPORTS_SUCCESS',
    GET_ALL_INCOMING_CALL_REPORTS_FAILLED:'GET_ALL_INCOMING_CALL_REPORTS_FAILLED',


    CLIENT_GET_ALL_INCOMING_CALL_REPORTS:'CLIENT_GET_ALL_INCOMING_CALL_REPORTS',
    CLIENT_GET_ALL_INCOMING_CALL_REPORTS_SUCCESS:'CLIENT_GET_ALL_INCOMING_CALL_REPORTS_SUCCESS',
    CLIENT_GET_ALL_INCOMING_CALL_REPORTS_FAILLED:'CLIENT_GET_ALL_INCOMING_CALL_REPORTS_FAILLED',

    AGENT_GET_ALL_INCOMING_CALL_REPORTS:'AGENT_GET_ALL_INCOMING_CALL_REPORTS',
    AGENT_GET_ALL_INCOMING_CALL_REPORTS_SUCCESS:'AGENT_GET_ALL_INCOMING_CALL_REPORTS_SUCCESS',
    AGENT_GET_ALL_INCOMING_CALL_REPORTS_FAILLED:'AGENT_GET_ALL_INCOMING_CALL_REPORTS_FAILLED',


    GET_ALL_OUTGOING_CALL_REPORTS:'GET_ALL_OUTGOING_CALL_REPORTS',
    GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS:'GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS',
    GET_ALL_OUTGOING_CALL_REPORTS_FAILLED:'GET_ALL_OUTGOING_CALL_REPORTS_FAILLED',

    CLIENT_GET_ALL_OUTGOING_CALL_REPORTS:'CLIENT_GET_ALL_OUTGOING_CALL_REPORTS',
    CLIENT_GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS:'CLIENT_GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS',
    CLIENT_GET_ALL_OUTGOING_CALL_REPORTS_FAILLED:'CLIENT_GET_ALL_OUTGOING_CALL_REPORTS_FAILLED',


    AGENT_GET_ALL_OUTGOING_CALL_REPORTS:'AGENT_GET_ALL_OUTGOING_CALL_REPORTS',
    AGENT_GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS:'AGENT_GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS',
    AGENT_GET_ALL_OUTGOING_CALL_REPORTS_FAILLED:'AGENT_GET_ALL_OUTGOING_CALL_REPORTS_FAILLED',


    CLIENT_GET_ALL_PBX_REPORTS:'CLIENT_GET_ALL_PBX_REPORTS',
    CLIENT_GET_ALL_PBX_REPORTS_SUCCESS:'CLIENT_GET_ALL_PBX_REPORTS_SUCCESS',
    CLIENT_GET_ALL_PBX_REPORTS_FAILLED:'CLIENT_GET_ALL_PBX_REPORTS_FAILLED',


    AGENT_GET_ALL_PBX_REPORTS:'AGENT_GET_ALL_PBX_REPORTS',
    AGENT_GET_ALL_PBX_REPORTS_SUCCESS:'AGENT_GET_ALL_PBX_REPORTS_SUCCESS',
    AGENT_GET_ALL_PBX_REPORTS_FAILLED:'AGENT_GET_ALL_PBX_REPORTS_FAILLED',




    UPDATE_INCOMING_CALL:'UPDATE_INCOMING_CALL',
    UPDATE_INCOMING_CALL_SUCCESS:'UPDATE_INCOMING_CALL_SUCCESS',
    UPDATE_INCOMING_CALL_FAILED:'UPDATE_INCOMING_CALL_FAILED',

    CREATE_FEEDBACK:'CREATE_FEEDBACK',
    CREATE_FEEDBACK_SUCCESS:'CREATE_FEEDBACK_SUCCESS',
    CREATE_FEEDBACK_FAILED:'CREATE_FEEDBACK_FAILED',


    GET_FEEDBACK:'GET_FEEDBACK',
    GET_FEEDBACK_SUCCESS:'GET_FEEDBACK_SUCCESS',
    GET_FEEDBACK_FAILED:'GET_FEEDBACK_FAILED',

    RESET_FEEDBACK:'RESET_FEEDBACK',
    RESET_FEEDBACK_SUCCESS:'RESET_FEEDBACK_SUCCESS',



    UPDATE_FEEDBACK:'UPDATE_FEEDBACK',
    UPDATE_FEEDBACK_SUCCESS:'UPDATE_FEEDBACK_SUCCESS',
    UPDATE_FEEDBACK_FAILED:'UPDATE_FEEDBACK_FAILED',

    GET_FEEDBACK_STATISTIC:'GET_FEEDBACK_STATISTIC',
    GET_FEEDBACK_STATISTIC_SUCCESS:'GET_FEEDBACK_STATISTIC_SUCCESS',
    GET_FEEDBACK_STATISTIC_FAILED:'GET_FEEDBACK_STATISTIC_FAILED',



    GET_ALL_CALENDAR_EVENT:'GET_ALL_CALENDAR_EVENT',
    GET_ALL_CALENDAR_EVENT_SUCCESS:'GET_ALL_CALENDAR_EVENT_SUCCESS',
    GET_ALL_CALENDAR_EVENT_FAILLED:'GET_ALL_CALENDAR_EVENT_FAILLED',


    CREATE_CALENDAR_EVENT:'CREATE_CALENDAR_EVENT',
    CREATE_CALENDAR_EVENT_SUCCESS:'CREATE_CALENDAR_EVENT_SUCCESS',
    CREATE_CALENDAR_EVENT_FAILED:'CREATE_CALENDAR_EVENT_FAILED',

  
    UPDATE_CALENDAR_EVENT:'UPDATE_CALENDAR_EVENT',
    UPDATE_CALENDAR_EVENT_SUCCESS:'UPDATE_CALENDAR_EVENT_SUCCESS',
    UPDATE_CALENDAR_EVENT_FAILED:'UPDATE_CALENDAR_EVENT_FAILED',


    GET_CALENDAR_EVENT_INFO:'GET_CALENDAR_EVENT_INFO',
    GET_CALENDAR_EVENT_INFO_SUCCESS:'GET_CALENDAR_EVENT_INFO_SUCCESS',

    CALENDAR_EVENT_CHANGE_STATUS:'CALENDAR_EVENT_CHANGE_STATUS',
    CALENDAR_EVENT_CHANGE_STATUS_SUCCESS:'CALENDAR_EVENT_CHANGE_STATUS_SUCCESS',


    PUBLISH_CALENDAR_EVENT:'PUBLISH_CALENDAR_EVENT',
    PUBLISH_CALENDAR_EVENT_SUCCESS:'PUBLISH_CALENDAR_EVENT_SUCCESS',
    PUBLISH_CALENDAR_EVENT_FAILED:'PUBLISH_CALENDAR_EVENT_FAILED',


    
    GET_ALL_CALENDAR_EVENT_BY_ENTREPRISE:'GET_ALL_CALENDAR_EVENT_BY_ENTREPRISE',
    GET_ALL_CALENDAR_EVENT_BY_ENTREPRISE_SUCCESS:'GET_ALL_CALENDAR_EVENT_BY_ENTREPRISE_SUCCESS',
    GET_ALL_CALENDAR_EVENT_BY_ENTREPRISE_FAILLED:'GET_ALL_CALENDAR_EVENT_BY_ENTREPRISE_FAILLED',

    GET_ALL_CALENDAR_EVENT_BY_AGENT:'GET_ALL_CALENDAR_EVENT_BY_AGENT',
    GET_ALL_CALENDAR_EVENT_BY_AGENT_SUCCESS:'GET_ALL_CALENDAR_EVENT_BY_AGENT_SUCCESS',
    GET_ALL_CALENDAR_EVENT_BY_AGENT_FAILLED:'GET_ALL_CALENDAR_EVENT_BY_AGENT_FAILLED',

    VIEW_CALENDAR_EVENT:'VIEW_CALENDAR_EVENT',
    VIEW_CALENDAR_EVENT_SUCCESS:'VIEW_CALENDAR_EVENT_SUCCESS',
    VIEW_CALENDAR_EVENT_FAILLED:'VIEW_CALENDAR_EVENT_FAILLED',

    GET_ALL_LINKS:"GET_ALL_LINKS",
    GET_ALL_LINKS_SUCCESS:"GET_ALL_LINKS_SUCCESS",
    GET_ALL_LINKS_FAILED:"GET_ALL_LINKS_FAILED",

    GET_LINK:"GET_LINK",
    GET_LINK_SUCCESS:"GET_LINK_SUCCESS",
    GET_LINK_FAILED:"GET_LINK_FAILED",

    CREATE_LINK:"CREATE_LINK",
    CREATE_LINK_SUCCESS:"CREATE_LINK_SUCCESS",
    CREATE_LINK_FAILED:"CREATE_LINK_FAILED",

    UPDATE_LINK:"UPDATE_LINK",
    UPDATE_LINK_SUCCESS:"UPDATE_LINK_SUCCESS",
    UPDATE_LINK_FAILED:"UPDATE_LINK_FAILED",

    DELETE_LINK:"DELETE_LINK",
    DELETE_LINK_SUCCESS:"DELETE_LINK_SUCCESS",
    DELETE_LINK_FAILED:"DELETE_LINK_FAILED",


    GET_LINK_BY_TYPE:"GET_LINK_BY_TYPE",
    GET_LINK_BY_TYPE_SUCCESS:"GET_LINK_BY_TYPE_SUCCESS",
    GET_LINK_BY_TYPE_FAILED:"GET_LINK_BY_TYPE_FAILED",


    GET_LINK_BY_ENTREPRISE:"GET_LINK_BY_ENTREPRISE",
    GET_LINK_BY_ENTREPRISE_SUCCESS:"GET_LINK_BY_ENTREPRISE_SUCCESS",
    GET_LINK_BY_ENTREPRISE_FAILED:"GET_LINK_BY_ENTREPRISE_FAILED",


    GET_ALL_DAYS_REPORTS:"GET_ALL_DAYS_REPORTS",
    GET_ALL_DAYS_REPORTS_SUCCESS:"GET_ALL_DAYS_REPORTS_SUCCESS",
    GET_ALL_DAYS_REPORTS_FAILED:"GET_ALL_DAYS_REPORTS_FAILED",


    CREATE_DAYS_REPORT:"CREATE_DAYS_REPORT",
    CREATE_DAYS_REPORT_SUCCESS:"CREATE_DAYS_REPORT_SUCCESS",
    CREATE_DAYS_REPORT_FAILED:"CREATE_DAYS_REPORT_FAILED",


    EDIT_DAYS_REPORT:"EDIT_DAYS_REPORT",
    EDIT_DAYS_REPORT_SUCCESS:"EDIT_DAYS_REPORT_SUCCESS",
    EDIT_DAYS_REPORT_FAILED:"EDIT_DAYS_REPORT_FAILED",  
    
    DELETE_DAYS_REPORT:"DELETE_DAYS_REPORT",
    DELETE_DAYS_REPORT_SUCCESS:"DELETE_DAYS_REPORT_SUCCESS",
    DELETE_DAYS_REPORT_FAILED:"DELETE_DAYS_REPORT_FAILED",


    RESET_DAYS_REPORT:"RESET_DAYS_REPORT",
    RESET_DAYS_REPORT_SUCCESS:"RESET_DAYS_REPORT_SUCCESS",


    GET_ALL_DAYS_REPORTS_BY_CLIENT:"GET_ALL_DAYS_REPORTS_BY_CLIENT",
    GET_ALL_DAYS_REPORTS_BY_CLIENT_SUCCESS:"GET_ALL_DAYS_REPORTS_BY_CLIENT_SUCCESS",
    GET_ALL_DAYS_REPORTS_BY_CLIENT_FAILED:"GET_ALL_DAYS_REPORTS_BY_CLIENT_FAILED",



    GET_ALL_MONTH_REPORTS:"GET_ALL_MONTH_REPORTS",
    GET_ALL_MONTH_REPORTS_SUCCESS:"GET_ALL_MONTH_REPORTS_SUCCESS",
    GET_ALL_MONTH_REPORTS_FAILED:"GET_ALL_MONTH_REPORTS_FAILED",


    CREATE_MONTH_REPORT:"CREATE_MONTH_REPORT",
    CREATE_MONTH_REPORT_SUCCESS:"CREATE_MONTH_REPORT_SUCCESS",
    CREATE_MONTH_REPORT_FAILED:"CREATE_MONTH_REPORT_FAILED",


    GET_ALL_MONTH_REPORTS_BY_CLIENT:"GET_ALL_MONTH_REPORTS_BY_CLIENT",
    GET_ALL_MONTH_REPORTS_BY_CLIENT_SUCCESS:"GET_ALL_MONTH_REPORTS_BY_CLIENT_SUCCESS",
    GET_ALL_MONTH_REPORTS_BY_CLIENT_FAILED:"GET_ALL_MONTH_REPORTS_BY_CLIENT_FAILED",



    EDIT_MONTH_REPORT:"EDIT_MONTH_REPORT",
    EDIT_MONTH_REPORT_SUCCESS:"EDIT_MONTH_REPORT_SUCCESS",
    EDIT_MONTH_REPORT_FAILED:"EDIT_MONTH_REPORT_FAILED",  
    
    DELETE_MONTH_REPORT:"DELETE_MONTH_REPORT",
    DELETE_MONTH_REPORT_SUCCESS:"DELETE_MONTH_REPORT_SUCCESS",
    DELETE_MONTH_REPORT_FAILED:"DELETE_MONTH_REPORT_FAILED",


    RESET_MONTH_REPORT:"RESET_MONTH_REPORT",
    RESET_MONTH_REPORT_SUCCESS:"RESET_MONTH_REPORT_SUCCESS",




    DELETE_USER:"DELETE_USER",
    DELETE_USER_SUCCESS:"DELETE_USER_SUCCESS",
    DELETE_USER_FAILED:"DELETE_USER_FAILED",



};

export default types;
