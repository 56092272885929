import axios from "axios"
import { app } from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 700000,
});

export const getAllDaysreports = (criteria) => {
    return instance.get(`/daysreport/index.php?startDate=${criteria?.startDate}&endDate=${criteria?.endDate}`)
};

export const getAllDaysreportsbyclient = criteria => {
    return instance.get(`/daysreport/client/index.php?id=${criteria?.id}&startDate=${criteria?.startDate}&endDate=${criteria?.endDate}`)
};


export const createDaysreport = position => {
    return instance.post(`/daysreport/create/index.php`, position)
};


export const updateDaysreport = position => {
    return instance.put(`/daysreport/update/index.php`, position)
};


export const deleteDaysreport = id => {
    return instance.get(`/daysreport/delete/index.php?id=${id}`)
};
