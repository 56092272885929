import React, { useEffect } from 'react'

import { useSelector } from "react-redux";
import ClientCalendar from './client';
// import AgentCalendar from './agent';
import AdminDaysreport from './admin';



const DaysReportMain = props => {

    const { session, getAllEntreprises, getAllDaysReports, createDaysReport,getAllDaysReportsByClient,editDaysReport,resetDaysReport,deleteDaysReport } = props;

    if (session)
        if (session?.person_type === "client") {
            return (
                <ClientCalendar session={session}
                    getAllEntreprises={getAllEntreprises}
                    getAllDaysReportsByClient={getAllDaysReportsByClient}
                />
            )
        } else if (session?.position_name === "Superviseur" || session?.position_name === "Administrateur" && session?.entreprise_name === "Seedlee") {

            return (<AdminDaysreport session={session} 
                getAllEntreprises={getAllEntreprises}
                getAllDaysReports={getAllDaysReports}
                createDaysReport={createDaysReport}
                editDaysReport={editDaysReport}
                resetDaysReport={resetDaysReport}
                deleteDaysReport={deleteDaysReport}
            />)
        }
       else {
            return (<h1>eorrr</h1>)
        }
}



export default DaysReportMain;