/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getIntlMsg, intl } from "../../constants/utils";
import { injectIntl, createIntl, createIntlCache } from "react-intl"


toast.configure()


export const updateLink = link => ({
    type: types.UPDATE_LINK,
    payload: { isLoadingL: true, link: link }
});

export const updateLinkSuccess = link => {

    toast.success(getIntlMsg(intl('fr'), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_LINK_SUCCESS,
        payload: { isLoadingL: false, msLink: 'success', link: link }
    }
};


export const updateLinkFailed = msLink => {

    toast.error(getIntlMsg(intl('fr'), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_LINK_FAILED,
        payload: { isLoadingL: true, msLink: msLink }
    }
};


export const createLink = link => ({
    type: types.CREATE_LINK,
    payload: { isLoadingL: true, link: link }
});


export const createLinkSuccess = msLink => {

    toast.success(getIntlMsg(intl('fr'), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_LINK_SUCCESS,
        payload: { isLoadingL: false, msLink: 'success' }
    }
};


export const createLinkFailed = msLink => {

    toast.error(getIntlMsg(intl('fr'), msLink), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_LINK_FAILED,
        payload: { isLoadingL: true, msLink: msLink }
    }
};

export const getAllLinks = () => ({
    type: types.GET_ALL_LINKS,
    payload: { isLoadingL: true }
});

export const getAllLinksSuccess = lks => {
    return ({
        type: types.GET_ALL_LINKS_SUCCESS,
        payload: { isLoadingL: false, links: lks }
    })
};


export const getAllLinksFailed = () => ({
    type: types.GET_ALL_LINKS_FAILED,
    payload: { isLoadingL: false }
});




export const getLink = id => ({
    type: types.GET_LINK,
    payload: { isLoadingL: true, id: id }
});

export const getLinkSuccess = id => ({
    type: types.GET_LINK_SUCCESS,
    payload: { isLoadingL: false, id: id }
});


export const getLinkFailed = () => ({
    type: types.GET_LINK_FAILED,
    payload: { isLoadingL: false }
});


export const deleteLink = id => ({
    type: types.DELETE_LINK,
    payload: {
        isLoadingL: true, id: id
    }
});


export const deleteLinkSuccess = id => {

    toast.success(getIntlMsg(intl('fr'), `app.label.deleteSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_LINK_SUCCESS,
        payload: { isLoadingL: false, id: id }
    };
}

export const deleteLinkFailed = msLink => {

    toast.error(getIntlMsg(intl('fr'), msLink), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_LINK_FAILED,
        payload: { isLoadingL: true, msLink: msLink }
    };
}




export const getLinkByType = type => ({
    type: types.GET_LINK_BY_TYPE,
    payload: { isLoadingL: true, type: type }
});

export const getLinkByTypeSuccess = lksbytype => {
    return ({
        type: types.GET_LINK_BY_TYPE_SUCCESS,
        payload: { isLoadingL: false, linkByType: lksbytype }
    })
};


export const getLinkByTypeFailed = () => ({
    type: types.GET_LINK_BY_TYPE_FAILED,
    payload: { isLoadingL: false }
});



export const getLinkByEntreprise = (entreprise_id, type) => ({
    type: types.GET_LINK_BY_ENTREPRISE,
    payload: { isLoadingL: true, type: type, entreprise_id: entreprise_id }
});

export const getLinkByEntrepriseSuccess = lksbyEntr => {
    return ({
        type: types.GET_LINK_BY_ENTREPRISE_SUCCESS,
        payload: { isLoadingL: false, linkByEntreprise: lksbyEntr }
    })
};

export const getLinkByEntrepriseFailed = () => ({
    type: types.GET_LINK_BY_ENTREPRISE_FAILED,
    payload: { isLoadingL: false }
});


