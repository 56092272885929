import React, {useEffect} from 'react'
import ExperienceAdmin from "./admin";
import ExperienceClient from "./client";
import {useSelector} from "react-redux";


const Feedback = props => {

    const {session,getLinkByType,getLinkByEntreprise} = props;

    return (
        FeedbackByEntreprise(session,getLinkByType,getLinkByEntreprise)
    )
}

const FeedbackByEntreprise = (session,getLinkByType,getLinkByEntreprise) => {


    switch (session?.entreprise_name) {
        case 'Seedlee':
            return (<ExperienceAdmin  session={session} getLinkByType={getLinkByType} />)
        default:
            return (<ExperienceClient session={session} getLinkByType={getLinkByType} getLinkByEntreprise={getLinkByEntreprise}/>)
    }
}


export default Feedback;