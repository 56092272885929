/**
 * Created by louidorpc on 1/9/21.
 */
import React, { useEffect, useState } from 'react'
import SoftPhone from 'react-softphone'
import { WebSocketInterface } from 'jssip';
import EmForm from './emergencyForm';
import { Emergency } from '../../../constants/model';
import TableAssignCampaign from '../tableAssignedCampaign';
import axios from "axios"
import Pusher from "pusher-js";
import createHistory from "history/createBrowserHistory";
import TableAssignedCampaign from "../tableAssignedCampaign";
import CampaignModal from "../campaignModal";
import Softphone from "../softPhone";
import { FormattedMessage } from "react-intl";
import { intl } from "../../../constants/utils";


function EmbededCampaignForm({
    createEmergencyCall,
    language,
    creator,
    assCampaigns,
    isLoadingACA,
    session,
    mcac,
    isLoadingCA,
    resetEmergencyCall,
    assPhones,
    infouser,
    getAllPhoneCampaigns,
    numberList,
    getAllAssignedCampaignByAgent,
    getAllPhoneAssignedCampaignByAgent,
    getAllEntreprises,
    id
}) {


    useEffect(() => {
        getAllAssignedCampaignByAgent(infouser.person_id)
    
            getAllEntreprises();

    }, [])

    const submitSetting = values => {
        let entity = new Emergency(values);
        entity.language = language;
        entity.creator = creator;

        createEmergencyCall(entity)
    };

    const [state, setState] = useState({
        modalVisible: false,
        selectedData: false,
        show: '', display: 'none',
    })

   const  showModal = () => {
        setState({ show: 'show', display: 'block' })
    }

    const removeModal = () => {
        setState({ show: '', display: 'none' })
    }

    const { modalVisible,
        selectedData,
        show, display } = state;

    return (
        <React.Fragment>
            <section className="admin-content">

                <div className="container-fluid bg-dark m-b-30">
                    <div className="row  p-b-60 p-t-20">
                        <div className="col-md-6 text-white my-auto text-md-right p-b-30">

                            <div className="">
                                <h2 className="opacity-75"><a href="#!">
                                    Forme de note</a>
                                </h2>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container-fluid pull-up">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="card m-b-30">
                                <div className="card-header">
                                    <div className="card-title"></div>
                                </div>
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">

                                        <li className="nav-item">
                                            <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                href="#cplist"
                                                role="tab"
                                                aria-controls="contact" aria-selected="false"><i
                                                    className="mdi mdi-office-building"><FormattedMessage id="app.label.campaign_assign_list" defaultMessage='Liste des campagnes' /></i></a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                href="#formRef"
                                                role="tab"
                                                aria-controls="contact" aria-selected="false"><i
                                                    className="mdi mdi-office-building"> Forme de réference </i>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                href="#ficheT"
                                                role="tab"
                                                aria-controls="contact" aria-selected="false"><i
                                                    className="mdi mdi-office-building"> Fiche technique </i>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                href="#urgence"
                                                role="tab"
                                                aria-controls="contact" aria-selected="false"><i
                                                    className="mdi mdi-office-building"> Urgence </i>
                                            </a>
                                        </li>

                                    </ul>
                                    <div className="tab-content" id="myTabContent1">

                                        <div className="tab-pane fade show active" id="cplist" role="tabpanel"
                                            aria-labelledby="contact-tab">
                                            <TableAssignCampaign language={language ? language : 'en'}
                                                person_id={infouser.person_id} assCampaigns={assCampaigns ? assCampaigns : []}
                                                isLoadingACA={isLoadingACA}
                                                getAllPhoneCampaigns={getAllPhoneAssignedCampaignByAgent} />
                                        </div>

                                        <div className="tab-pane fade" id="formRef" role="tabpanel"
                                            aria-labelledby="contact-tab">

                                            <embed src="https://app.smartsheet.com/b/form/b8d641a8a6b249a8b7fc9bccbf20d7c4"
                                                style={{ height: 'calc(100vh - 80px)', width: '100%' }}></embed>
                                        </div>

                                        <div className="tab-pane fade" id="ficheT" role="tabpanel"
                                            aria-labelledby="contact-tab">
                                            <embed src="https://app.smartsheet.com/b/form/a0449cd257dd481e8ec31039fc21151c"
                                                style={{ height: 'calc(100vh - 80px)', width: '100%' }}></embed>
                                        </div>

                                        <div className="tab-pane fade" id="urgence" role="tabpanel"
                                            aria-labelledby="contact-tab">
                                            <EmForm onSubmit={submitSetting} mcac={mcac} resetEmergencyCall={resetEmergencyCall} intl={intl} language={language} />s
                                        </div>



                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <CampaignModal language={session ? session.language : null} isLoadingPC={isLoadingCA}
                numberList={assPhones ? assPhones : []} show={show} display={display}
                removeModal={removeModal} />
            {/* <Softphone /> */}
        </React.Fragment>
    );
}

export default EmbededCampaignForm;