import {put, call, takeLatest} from "redux-saga/effects";
import * as actions from "../actions/creator/service";
import types from "../actions/type";
import * as api from "../services/service";


export const handleGetAllServices = function* () {

    try {
        const getSv = yield call(api.getAllServices);
        if (getSv.data.length > 0) {
            yield put(actions.getAllServicesSuccess(getSv.data))
        } else {
            yield put(actions.getAllServicesFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllServicesFailled("error"))
    }


};

export const handleCreateService = function* (action) {
    const {service} = action.payload;
    try {
        const serv = yield call(api.createService, service);
        if (serv.data.message == "success") {
            yield put(actions.createServiceSuccess(service.language))
            yield put(actions.getAllServices())

        } else {
            yield put(actions.createServiceFailled({mssv: serv.data.message, locale: service.language}))
        }
    } catch (error) {
        yield put(actions.createServiceFailled({mssv: 'app.label.saveError', locale: service.language}))
    }
};

export const handleUpdateService = function* (action) {
    const {upsv} = action.payload;
    try {
        const serv = yield call(api.updateService, upsv);
        if (serv.data.message == "success") {
            yield put(actions.updateServiceSuccess(upsv.language))
            yield put(actions.getAllServices())

        } else {
            yield put(actions.updateServiceFailled({mssv: serv.data.message, locale: upsv.language}))
        }
    } catch (error) {
        yield put(actions.updateServiceFailled({mssv: 'app.label.saveError', locale: upsv.language}))
    }
};


export const handleDeleteService = function* (action) {
    const {id} = action.payload;
    try {
        const serv = yield call(api.deleteService, id);
        if (serv.data.message == "success") {
            yield put(actions.deleteServiceSuccess(serv.data.id))
        } else {
            yield put(actions.deleteServiceFailled({mssv: serv.data.message}))
        }
    } catch (error) {
        yield put(actions.deleteServiceFailled({mssv: 'app.label.saveError'}))
    }
};


export const serviceSaga = [
    takeLatest(types.GET_ALL_SERVICES, handleGetAllServices),
    takeLatest(types.CREATE_SERVICE, handleCreateService),
    takeLatest(types.UPDATE_SERVICE, handleUpdateService),
    takeLatest(types.DELETE_SERVICE, handleDeleteService)
];
