import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm, change} from "redux-form";
import {connect, useDispatch, useSelector} from 'react-redux'
import {validate, normalizeCin, normalizePhone, renderError, required} from "./validator";
import {renderField} from "../../../../common/FormComponents";
import {getIntlMsg} from "../../../../constants/utils";
import MultiSelect from "@khanacademy/react-multi-select";


const onClearForm = (reset, resetEntreprise, resetFile) => {
    reset('entreprise');
    resetFile();
    resetEntreprise();
}

const Form = props => {

    const {items} = useSelector(state => ({
        items: state.entrepriseReducer.entrepriseInfo ? state.entrepriseReducer.entrepriseInfo.campaign_type : [],


    }));

    const [selectedCampaign, setSelectedCampaign] = useState([]);
    const dispatch = useDispatch();

    const handleSelected = (selected) => {
        dispatch(change('entreprise', 'campaign_type', selected));
        setSelectedCampaign(selected)
    }


    const campains = [
        {label: "Appel de courtoisie et sondage", value: "Appel de courtoisie et sondage"},
        {label: "Appels de prospection", value: "Appels de prospection"},
        {label: "Appels de recouvrement", value: "Appels de recouvrement"},
        {label: "Service à la clientèle", value: "Service à la clientèle"},
        {label: "Relancement des Clients Dormants", value: "Relancement des Clients Dormants"},
        {label: "Suivis Des Demandes de Pro Forma", value: "Suivis Des Demandes de Pro Forma"},
        {label: "Mise à jour de la liste des Clients ", value: "Mise à jour de la liste des Clients"},
        {label: "Étude de marché", value: "Étude de marché"},
        {label: "Gestion de Résaux Siciaux", value: "Gestion de Résaux Siciaux"},
        {label: "Confirmation de Rendez Vous pour les clients existants", value: "Confirmation de Rendez Vous pour les clients existants"},
        {label: "Sondage Service après Vente", value: "Sondage Service après Vente"},
        {label: "Service d'annonces et de Promotions", value: "Service d'annonces et de Promotions"},
        {label: "Service Expérience Clients", value: "Service Expérience Clients"},
        {label: "Service Personnalisé", value: "Service Personnalisé"},
    ];


    // if (msEnt === "success") onClearForm(reset, resetEntreprise, resetFile)
    return (
        <React.Fragment>
            <div className="form-row m-2">

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="id"
                        component={renderField}
                        disabled={true}
                        type="text"
                        label="app.label.code"
                        placeholder="app.label.person_id"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="entr_name"
                        component={renderField}
                        type="text"
                        label="app.label.entr_name"
                        placeholder="app.label.entr_name"
                    />
                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="city"
                        component={renderField}
                        type="text"
                        label="app.label.ville"
                        placeholder="app.label.ville"
                    />
                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="postal_code"
                        component={renderField}
                        type="text"
                        label="app.label.postal_code"
                        placeholder="app.label.postal_code"
                    />
                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="email"
                        component={renderField}
                        type="text"
                        label="app.label.email"
                        placeholder="app.label.email"
                    />
                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="phone"
                        component={renderField}
                        normalize={normalizePhone}
                        type="text"
                        label="app.label.phone"
                        placeholder="app.label.phone"
                    />
                </div>


                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="address"
                        component={renderField}
                        type="text"
                        label="app.label.address"
                        placeholder="app.label.address"
                    />
                </div>


                <div className="form-group floating-label show-label  col-md-6">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="entr_nature"
                        component={renderField}
                        type="select"
                        label="app.label.entr_nature"
                        options={[{id: "Société anonyme", name: "app.label.anonym_society"}, {
                            id: "Société individuelle",
                            name: "app.label.individual_society"
                        }]}
                    />

                </div>


                <div className="form-group floating-label show-label  col-md-6">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="entr_type"
                        component={renderField}
                        type="select"
                        label="app.label.entr_type"
                        placeholder="app.label.entr_type"
                        options={[{id: "company", name: "app.label.company"}, {
                            id: "industry",
                            name: "app.label.industry"
                        }]}
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="entr_etablished"
                        component={renderField}
                        type="text"
                        label="app.label.entr_etablished"
                        placeholder="app.label.entr_etablished"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="entr_location"
                        component={renderField}
                        type="text"
                        label="app.label.location"
                        placeholder="app.label.location"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="entr_patent_number"
                        component={renderField}
                        type="text"
                        label="app.label.entr_patent_number"
                        placeholder="app.label.entr_patent_number"
                    />

                </div>


                <div className="col-md-12">


                    <div className="col-md-12 pb-4">
                        <h5>Type de campagne</h5>

                        <Field name="campaign_type"
                               type="select"
                               component={() => (<MultiSelect
                                   options={campains}
                                   selected={selectedCampaign.length > 0 ? selectedCampaign : items}
                                   onSelectedChanged={selected => handleSelected(selected)}
                                   overrideStrings={{
                                       selectSomeItems: "Sélectionnez certains éléments ...",
                                       allItemsAreSelected: "Tous les éléments sont sélectionnés",
                                       selectAll: "Tout sélectionner",
                                       search: "Rechercher",
                                   }}
                               />)}
                        />


                    </div>

                </div>

                {/*<div className="form-group floating-label show-label  col-md-6">*/}
                {/*<Field*/}
                {/*className="form-control"*/}
                {/*id="option-message"*/}
                {/*name="service_type"*/}
                {/*component={renderField}*/}
                {/*type="select"*/}
                {/*label="app.label.service_type"*/}
                {/*placeholder="app.label.service_type"*/}
                {/*options={[{id: "Appel de courtoisie et sondage", name: "Appel de courtoisie et sondage"},*/}
                {/*{*/}
                {/*id: "Appels de prospection",*/}
                {/*name: "Appels de prospection"*/}
                {/*}, {*/}
                {/*id: "Appels de recouvrement",*/}
                {/*name: "Appels de recouvrement"*/}
                {/*}, {*/}
                {/*id: "Service à la clientèle",*/}
                {/*name: "Service à la clientèle"*/}
                {/*},*/}
                {/*]}*/}
                {/*/>*/}

                {/*</div>*/}


            </div>
            {/*<div class="error-handling-messages">*/}
            {/*{msEnt != "success" ?*/}
            {/*<div id="error"> {msEnt}</div> : null}*/}
            {/*</div>*/}

        </React.Fragment>

    );
}
export default Form;


