import types from "../actions/type"

const defaultState = {
    isLoadingDr: false,
    ms: null,
    monthreport: null,
    upo: null,
    mpo: null,
    id: null,
    criteria: null,
    monthreports: [],
    clientMonthreports: [],
}

const monthreportReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.RESET_MONTH_REPORT:
            return {
                ...state,
                ms: '',
            };

        case types.RESET_MONTH_REPORT_SUCCESS:
            return {
                ...state,
                ms: '',
            };

        case types.GET_ALL_MONTH_REPORTS:
            return {
                ...state,
                isLoadingDr: action.payload.isLoadingDr,
                criteria: action.payload.criteria,
                monthreports:[]
            };

        case types.GET_ALL_MONTH_REPORTS_BY_CLIENT:
            return {
                ...state,
                criteria: action.payload.criteria,
                isLoadingDr: action.payload.isLoadingDr,
                clientMonthreports:[]
            };

        case types.GET_ALL_MONTH_REPORTS_SUCCESS:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingDr: action.payload.isLoadingDr,
                monthreports: action.payload.monthreports
            };
        case types.GET_ALL_MONTH_REPORTS_BY_CLIENT_SUCCESS:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingDr: action.payload.isLoadingDr,
                clientMonthreports: action.payload.clientMonthreports
            };

        case types.GET_ALL_MONTH_REPORTS_FAILED:
        case types.GET_ALL_MONTH_REPORTS_BY_CLIENT_FAILED:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingDr: action.payload.isLoadingDr
            };


        case types.CREATE_MONTH_REPORT:
        case types.EDIT_MONTH_REPORT:
            return {
                ...state,
                isLoadingDr: action.payload.isLoadingDr,
                monthreport: action.payload.monthreport
            };


        case types.DELETE_MONTH_REPORT:
            return {
                ...state,
                monthreport: action.payload.monthreport
            };

        case types.DELETE_MONTH_REPORT_SUCCESS:
            return {
                ...state,
                monthreport: null,
                monthreports: []
            };

        case types.DELETE_MONTH_REPORT_FAILED:
            return {
                ...state,
            };



        case types.CREATE_MONTH_REPORT_SUCCESS:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingDr: action.payload.isLoadingDr
            };

        case types.EDIT_MONTH_REPORT_SUCCESS:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingDr: action.payload.isLoadingDr
            };

        case types.CREATE_MONTH_REPORT_FAILED:
        case types.EDIT_MONTH_REPORT_FAILED:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingDr: action.payload.isLoadingDr
            };



        default:
            return state

    }
}


export default monthreportReducer