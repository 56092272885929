import types from "../actions/type"
import events from '../components/agent/calendar/Calendar/demoEvents';

const defaultState = {
    isLoadingACA: false,
    isLoadingAPC: false,
    message: null,
    assCampaigns: null,
    pid: null,
    pPid: null,
    assPhones: null,

}

const campaignReducer = (state = defaultState, action, getState) => {
    switch (action.type) {
        case types.GET_ASSIGNED_CAMPAIGN_BY_AGENT:
            return {
                ...state,
                pid: action.payload.pid,
                isLoadingACA: action.payload.isLoadingACA
            };

        case types.GET_ASSIGNED_CAMPAIGN_BY_AGENT_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                isLoadingACA: action.payload.isLoadingACA,
                assCampaigns: action.payload.assCampaigns
            };


        case types.GET_ASSIGNED_CAMPAIGN_BY_AGENT_FAILLED:
            return {
                ...state,
                message: action.payload.message,
                isLoadingACA: action.payload.isLoadingACA
            };

        case types.GET_ASSIGN_PHONE_BY_AGENT:
            return {
                ...state,
                pPid: action.payload.pPid,
                isLoadingAPC: action.payload.isLoadingAPC
            };
        case types.GET_ASSIGN_PHONE_BY_AGENT_SUCCESS:
            return {
                ...state,
                assPhones: action.payload.assPhones,
                isLoadingAPC: action.payload.isLoadingAPC
            };
        case types.GET_ASSIGN_PHONE_BY_AGENT_FAILLED:
            return {
                ...state,
                message: action.payload.message,
                isLoadingAPC: action.payload.isLoadingAPC
            };


        default:
            return state

    }
}

export default campaignReducer
