import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {validate, normalizeCin, normalizePhone} from "./validator";
import {renderField} from "../../../../common/FormComponents";

import {getIntlMsg} from "../../../../constants/utils";


const onClearForm = (reset, resetEntreprise, resetFile) => {
    reset('entreprise');
    resetFile();
    resetEntreprise();
}

const Form = props => {

    // if (msEnt === "success") onClearForm(reset, resetEntreprise, resetFile)
    return (
        <React.Fragment>
            <div className="form-row m-2">
                <h5> <h5><FormattedMessage id="app.label.person_contact" deafaultMessage=" PERSONNE DE CONTACT 🧙‍♂"/>️</h5>
                   </h5>


                <div className="form-row m-2">
                    <h6><FormattedMessage id="app.label.rh_reponsable" deafaultMessage="Responsable des Ressources Humaines :"/>️</h6>
                    <div className="form-group floating-label show-label col-md-12">

                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="rh_fulll_name"
                            component={renderField}
                            type="text"
                            label="app.label.fullname"
                            placeholder="app.label.fullname"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="rh_email"
                            component={renderField}
                            type="enmail"
                            label="app.label.email"
                            placeholder="app.label.email"
                        />
                    </div>
                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="rh_phone"
                            component={renderField}
                            normalize={normalizePhone}
                            type="text"
                            label="app.label.phone"
                            placeholder="app.label.phone"
                        />
                    </div>
                </div>


                <div className="form-row m-2">
                    <h6><FormattedMessage id="app.label.reponsable_marketing" deafaultMessage="Responsable de marketing et vente :"/></h6>
                    <div className="form-group floating-label show-label col-md-12">

                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="rm_fulll_name"
                            component={renderField}
                            type="text"
                            label="app.label.fullname"
                            placeholder="app.label.fullname"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="rm_email"
                            component={renderField}
                            type="enmail"
                            label="app.label.email"
                            placeholder="app.label.email"
                        />
                    </div>
                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="rm_phone"
                            component={renderField}
                            normalize={normalizePhone}
                            type="text"
                            label="app.label.phone"
                            placeholder="app.label.phone"
                        />
                    </div>
                </div>

                <div className="form-row m-2">
                    <h6><FormattedMessage id="app.label.reponsable_comptability" deafaultMessage="Responsable de la Comptabilité :"/></h6>
                    <div className="form-group floating-label show-label col-md-12">

                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="rc_fulll_name"
                            component={renderField}
                            type="text"
                            label="app.label.fullname"
                            placeholder="app.label.fullname"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="rc_email"
                            component={renderField}
                            type="enmail"
                            label="app.label.email"
                            placeholder="app.label.email"
                        />
                    </div>
                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="rc_phone"
                            component={renderField}
                            normalize={normalizePhone}
                            type="text"
                            label="app.label.phone"
                            placeholder="app.label.phone"
                        />
                    </div>
                </div>

                <div className="form-row m-2">
                    <h6><FormattedMessage id="app.label.dg" deafaultMessage="Directeur Général :"/></h6>
                    <div className="form-group floating-label show-label col-md-12">

                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="dg_fulll_name"
                            component={renderField}
                            type="text"
                            label="app.label.fullname"
                            placeholder="app.label.fullname"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="dg_email"
                            component={renderField}
                            type="enmail"
                            label="app.label.email"
                            placeholder="app.label.email"
                        />
                    </div>
                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="dg_phone"
                            component={renderField}
                            normalize={normalizePhone}
                            type="text"
                            label="app.label.phone"
                            placeholder="app.label.phone"
                        />
                    </div>
                </div>

            </div>
            {/*<div class="error-handling-messages">*/}
            {/*{msEnt != "success" ?*/}
            {/*<div id="error"> {msEnt}</div> : null}*/}
            {/*</div>*/}

        </React.Fragment>

    );
}
export default Form;


