import types from "../actions/type"
import events from '../components/agent/calendar/Calendar/demoEvents';

const defaultState = {
    event: events,
    view: 'month',
    tpid: null,
    tsData: null,
    isLoadingAS: false,
    pid: null,
    agentSessions: [],
    rcCall: [],
    agS: [],
    id: null,
    df: null,
    pQty: null,
    isLoadingCAC: false,
    cac: null,
    mcac: null,
    isLoadingRC: false,
    phones: [],
    isLoadingPH: false,
    emCalls: [],
    phone: null
}

const agentReducer = (state = defaultState, action, getState) => {
    switch (action.type) {

        case types.GET_SELECTED_DATE_FROM_CALENDAR:
            return { ...state, tsData: action.payload.tsData };

        case types.GET_SELECTED_DATE_FROM_CALENDAR_SUCCESS:
            return { ...state, tsData: action.payload.tsData };

        case types.CALENDAR_CHANGE_VIEW:
            return { ...state, view: action.payload.view };

        case types.CALENDAR_CHANGE_VIEW_SUCCESS:
            return { ...state, view: action.payload.view };

        case types.CALENDAR_EVENT:
            return { ...state, event: action.payload.event };

        case types.CALENDAR_EVENT_SUCCESS:
            return { ...state, event: action.payload.event };


        case types.GET_ALL_AGENT_SESSIONS:
            return {
                ...state,
                pid: action.payload.pid,
                isLoadingAS: action.payload.isLoadingAS
            };

        case types.GET_ALL_AGENT_SESSIONS_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                isLoadingAS: action.payload.isLoadingAS,
                agentSessions: action.payload.agentSessions
            };


        case types.GET_ALL_RECORDING_CALL:
            return {
                ...state,
                isLoadingRC: action.payload.isLoadingRC,
            };

        case types.GET_ALL_EMERGENCY_CALL:
            return {
                ...state,
                isLoadingRC: action.payload.isLoadingRC,
            };


        case types.GET_ALL_RECORDING_CALL_SUCCESS:
            return {
                ...state,
                isLoadingRC: action.payload.isLoadingRC,
                rcCall: action.payload.rcCall
            };

        case types.GET_ALL_EMERGENCY_CALL_SUCCESS:
            return {
                ...state,
                isLoadingRC: action.payload.isLoadingRC,
                emCalls: action.payload.emCalls
            };


        case types.GET_ALL_AGENT_SESSIONS_FAILLED:
        case types.GET_ALL_RECORDING_CALL_FAILLED:
            return {
                ...state,
                message: action.payload.message,
                isLoadingAS: action.payload.isLoadingAS,
                isLoadingRC: action.payload.isLoadingRC
            };

            case types.GET_ALL_EMERGENCY_CALL_FAILLED:
                return {
                    ...state,
                    message: action.payload.message,
                    isLoadingAS: action.payload.isLoadingAS,
                    isLoadingRC: action.payload.isLoadingRC,
                    emCalls: []
                };

           

        case types.GET_ALL_SESSIONS_BY_AGENT:
            return {
                ...state,
                id: action.payload.id,
                isLoadingAS: action.payload.isLoadingAS
            };

        case types.GET_ALL_SESSIONS_BY_AGENT_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                isLoadingAS: action.payload.isLoadingAS,
                agS: action.payload.agS,
            };


        case types.GET_ALL_PHONE_BY_PHONE:
            return {
                ...state,
                isLoadingPH: action.payload.isLoadingPH,
                phone: action.payload.phone,
            };


        case types.GET_ALL_PHONE_BY_PHONE_SUCCESS:
            return {
                ...state,
                isLoadingPH: action.payload.isLoadingPH,
                phones: action.payload.phones,
            };

        case types.GET_ALL_SESSIONS_BY_AGENT_FAILLED:
        case types.GET_ALL_PHONE_BY_PHONE_FAILLED:
            return {
                ...state,
                message: action.payload.message,
                isLoadingAS: action.payload.isLoadingAS
            };

        case types.CHANGE_PHONE_QTY:
            return {
                ...state,
                pQty: action.payload.pQty

            };


        case types.CHANGE_PHONE_QTY_SUCCESS:
            return {
                ...state,
                pQty: action.payload.pQty

            };


        case types.CREATE_ASSIGN_CAMPAIGN:
            return {
                ...state,
                cac: action.payload.cac,
                isLoadingCAC: action.payload.isLoadingCAC
            };

        case types.CREATE_ASSIGN_CAMPAIGN_SUCCESS:
            return {
                ...state,
                mcac: action.payload.mcac,
                isLoadingCAC: action.payload.isLoadingCAC,
            };


        case types.CREATE_ASSIGN_CAMPAIGN_FAILLED:
            return {
                ...state,
                isLoadingCAC: action.payload.isLoadingCAC,
                mcac: action.payload.mcac
            };


        case types.CREATE_EMERGENCY_CALL:
        case types.EDIT_EMERGENCY_CALL:
            return {
                ...state,
                cac: action.payload.cac,
                isLoadingCAC: action.payload.isLoadingCAC
            };

        case types.CREATE_EMERGENCY_CALL_SUCCESS:
            return {
                ...state,
                mcac: action.payload.mcac,
                isLoadingCAC: action.payload.isLoadingCAC,
            };

        case types.EDIT_EMERGENCY_CALL_SUCCESS:
            return {
                ...state,
                mcac: action.payload.mcac,
                isLoadingCAC: action.payload.isLoadingCAC,
            };


        case types.CREATE_EMERGENCY_CALL_FAILLED:
        case types.EDIT_EMERGENCY_CALL_FAILLED:
            return {
                ...state,
                isLoadingCAC: action.payload.isLoadingCAC,
                mcac: action.payload.mcac
            };

        case types.DELETE_EMERGENCY_CALL:
            return {
                ...state,
                cac: action.payload.cac,
                isLoadingCAC: action.payload.isLoadingCAC
            };

        case types.DELETE_EMERGENCY_CALL_SUCCESS:
            return {
                ...state,
                mcac: action.payload.mcac,
                isLoadingCAC: action.payload.isLoadingCAC,
            };

        case types.DELETE_EMERGENCY_CALL_FAILLED:
            return {
                ...state,
                isLoadingCAC: action.payload.isLoadingCAC,
                mcac: action.payload.mcac
            };


        case types.RESET_EMERGENCY_CALL:
            return {
                ...state,
                isLoadingCAC: false,
                mcac: action.payload.mcac
            };

        case types.RESET_EMERGENCY_CALL_SUCCESS:
            return {
                ...state,
                isLoadingCAC: false,
                mcac: action.payload.mcac
            };

        default:
            return state

    }
}


export default agentReducer
