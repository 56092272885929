import types from "../actions/type"

const defaultState = {
    isLoadingCfeed: false,
    isLoadingFeed: false,
    mfeed: null,
    feedback: null,
    feedbacks: [],
    id: null,
    feedId: null,
    upFeed: null,
    isLoadingUFS:false
}

const feedbackReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_FEEDBACK:
            return {
                ...state,
                isLoadingFeed: action.payload.isLoadingFeed,
                id: action.payload.id,
            };

        case types.RESET_FEEDBACK:
            return {
                ...state,
                mfeed:null
            };

        case types.RESET_FEEDBACK_SUCCESS:
            return {
                ...state,
                mfeed: null
            };

        case types.GET_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbacks: action.payload.feedbacks,
                isLoadingFeed: action.payload.isLoadingFeed
            };


        case types.GET_FEEDBACK_FAILED:
            return {
                ...state,
                feedbacks: action.payload.feedbacks,
                isLoadingFeed: action.payload.isLoadingFeed
            };
            

        case types.CREATE_FEEDBACK:
            return {
                ...state,
                feedback: action.payload.feedback,
                isLoadingCfeed: action.payload.isLoadingCfeed
            };
        case types.CREATE_FEEDBACK_SUCCESS:
            return {
                ...state,
                mfeed: action.payload.mfeed,
                isLoadingCfeed: action.payload.isLoadingCfeed
            };

        case types.CREATE_FEEDBACK_FAILED:
            return {
                ...state,
                mfeed: action.payload.mfeed,
                isLoadingCfeed: action.payload.isLoadingCfeed
            };


        case types.UPDATE_FEEDBACK:
            return {
                ...state,
                isLoadingUFS: action.payload.isLoadingUFS,
                feedId: action.payload.feedId,
            };

        case types.UPDATE_FEEDBACK_SUCCESS:
            return {
                ...state,
                isLoadingUFS: action.payload.isLoadingUFS,
                upFeed: action.payload.upFeed,
            };

        case types.UPDATE_FEEDBACK_FAILED:
            return {
                ...state,
                isLoadingUFS: action.payload.isLoadingUFS,
                upFeed: action.payload.upFeed,
            };



        default:
            return state

    }
}


export default feedbackReducer