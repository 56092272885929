import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MapperDistribution from './item'
import { getIntlMsg } from "../../../constants/utils";

const Dashboard = props => {
    const { getAllPbxReportClient, prefixIds, startDate, endDate, extension } = props

    const [prefix_num, setPrefix_num] = useState("");

    const handleChangeCampaignName = value => {
        setPrefix_num(value)
        getAllPbxReportClient(value)
    }


    const { statusItems, reportCalls, loading, stat, statByDays, outgoingByday, missedByday } = useSelector(state => ({
        reportCalls: state?.clientPbxReducer?.cReports?.reports || [],
        stat: state?.clientPbxReducer?.cReports?.stats || null,
        statByDays: state?.clientPbxReducer?.cReports?.incomingByday || [],
        outgoingByday: state?.clientPbxReducer?.cReports?.outgoingByday || [],
        missedByday: state?.clientPbxReducer?.cReports?.missedByday || [],
    }));

    useEffect(() => {
        // getAllPbxReportClient(prefixIds[0]?.prefix_number);
    }, [])

    return (
        <div className="row">
           
            <div className="col-md-12 pb-lg-2">
                <div className="row">
                   
                    <div className="col-md-12">
                                <button class="btn btn-sm btn-info font-weight-bolder float-right" onClick={() => getAllPbxReportClient({ startDate: startDate, endDate: endDate, extension: extension })}>
                                    <span class="svg-icon svg-icon-md">
                                        <span class="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true" title="Chercher">refresh</span>
                                    </span>Actualiser</button>
                           
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <MapperDistribution {...{ reportCalls, stat, getAllPbxReportClient, statByDays, outgoingByday, missedByday, prefixIds }} />
            </div>
        </div>

    )
}


const buildChartElement = (statusItems, intl) => {

    let labels = [];
    let values = [];
    if (statusItems)
        for (const [key, value] of Object.entries(statusItems)) {
            labels.push(getIntlMsg(intl, `app.label.${key.toLowerCase()}`))
            values.push(value)
        }
    return [labels, values]
}

export default Dashboard