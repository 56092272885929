import types from "../actions/type";

const defaultState = {
    locale: "en",
    localeM: null,
    session: null,
    sessionId: null,
    sessionM: null,
    isLoadingS: true,
    isLoadingFS: true,
    message: null,
    sno: null,
    msno: null,
    feedStats: [],
    statId:null

};

const settingReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.CHANGE_LOCALE_REQUEST:
            return {...state, locale: action.payload.locale};

        case types.CHANGE_LOCALE_REQUEST_SUCCESS:
            return {...state, locale: action.payload.locale};

        case types.CHANGE_LOCALE_REQUEST_FAILLED:
            return {...state, localeM: action.payload.localeM};

        case types.GET_USER_SESSION_INFO:
            return {...state, sessionId: action.payload.sessionId, isLoadingS: action.payload.isLoadingS};

        case types.GET_USER_SESSION_SUCCESS:
            return {...state, session: action.payload.session, isLoadingS: action.payload.isLoadingS};


        case types.GET_USER_SESSION_FAILLED:
            return {...state, sessionM: action.payload.sessionM, isLoadingS: action.payload.isLoadingS};

        case types.SIGNOUT_AGENT:
            return {
                ...state,
                sno: action.payload.sno
            };

        case types.SIGNOUT_AGENT_SUCCESS:
            return {
                ...state,
                sno: action.payload.sno,
                message: action.payload.message,
                msno: action.payload.msno,
            };
        case types.SIGNOUT_AGENT_FAILLED:
            return {
                ...state,
                sno: action.payload.sno,
                message: action.payload.message,
                msno: action.payload.msno,
            };


        case types.GET_FEEDBACK_STATISTIC:
            return {
                ...state,
                isLoadingFS: action.payload.isLoadingFS,
                statId: action.payload.statId,
            };
        case types.GET_FEEDBACK_STATISTIC_SUCCESS:
            return {
                ...state,
                isLoadingFS: action.payload.isLoadingFS,
                feedStats: action.payload.feedStats,
            };
        case types.GET_FEEDBACK_STATISTIC_FAILED:
            return {
                ...state,
                isLoadingFS: action.payload.isLoadingFS,
                feedStats: []
            };


        default:
            return state;
    }
};

export default settingReducer;
