/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'

const Loader = () => (
    <div class="spinner-grow align-center text-center" style={{width: "4rem", height: "4rem", margin: 'auto'}}
         role="status">
        <span class="sr-only align-center text-center">Loading...</span>
    </div>
)

export default Loader