import types from "../actions/type"

const defaultState = {
    isLoading: false,
    message: null,
    type: "signin",
    msn: null,
    msp: null,
    sn: null,
    sp: null,
    msna: null,

}

const loginReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.SWITCH_TO_SIGNIN:
            return {...state, type: action.payload.type, message: null};

        case types.SWITCH_TO_SIGNUP:
            return {...state, type: action.payload.type, message: null};

        case types.LOGIN_SWITCH:
            return {...state, type: action.payload.type};


        case types.RESET_STATE_SIGNIN:
        case types.RESET_STATE_SIGNUP:
        case types.RESET_STATE_RESET_PASSWORD:
            return {...state, message: action.payload.message};

        case types.RESET_STATE_SIGNIN_SUCCESS:
        case types.RESET_STATE_SIGNUP_SUCCESS:
        case types.RESET_STATE_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                isLoading: true,
                msn: action.payload.msn,
                msp: action.payload.msp
            };

        case types.SIGNUP:
        case types.SIGNIN:
        case types.SIGNIN_AGENT:
        case types.RESET_PASSWORD:
            return {
                ...state,
                sp: action.payload.sp,
                sn: action.payload.sn,
                sna: action.payload.sna,
                isLoading: action.payload.isLoading
            };

        case types.SIGNIN_SUCCESS:
        case types.SIGNUP_SUCCESS:
        case types.SIGNIN_AGENT_SUCCESS:
        case types.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                isLoading: false,
                msn: action.payload.msn,
                msna: action.payload.msna,
                msp: action.payload.msp,
                type: action.payload.type
            };


        case types.SIGNIN_FAILLED:
        case types.SIGNUP_FAILLED:
        case types.SIGNIN_AGENT_FAILLED:
        case types.RESET_PASSWORD_FAILLED:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                message: action.payload.message
            };

        default:
            return state

    }
}


export default loginReducer
