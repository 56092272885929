import React from "react";
import { KeyclockManager } from '../../../../../constants/model';
import { injectIntl, FormattedMessage } from "react-intl";



export const User = injectIntl(({ intl }) => {
 
  return (
        <>
            <div className="kt-user-card kt-margin-b-40 kt-margin-b-30-tablet-and-mobile" style={{ backgroundImage: "url(assets/media/misc/head_bg_sm.jpg)" }}>
                  <div className="kt-user-card__wrapper">
                        <div className="kt-user-card__pic">
                            <span className="kt-media kt-media--circle kt-media--primary bg-success ">
                                <span className="fa fa-user text-white"></span>
                            </span>
                        </div>
                        <div className="kt-user-card__details">
                              <div className="kt-user-card__name">pablo</div>
                              <div className="kt-user-card__position">Admin</div>
                        </div>
                  </div>
            </div>
            <ul className="kt-nav kt-margin-b-10">
                  <li className="kt-nav__item">
                        <a href="custom/profile/personal-information.html" className="kt-nav__link">
                              <span className="kt-nav__link-icon"><i className="flaticon2-calendar-3"></i></span>
                              <span className="kt-nav__link-text"><FormattedMessage id="app.label.profile" defaultMessage="Profile"/></span>
                        </a>
                  </li>
                  <li className="kt-nav__item">
                        <a href="custom/profile/overview-2.html" className="kt-nav__link">
                              <span className="kt-nav__link-icon"><i className="flaticon2-mail"></i></span>
                              <span className="kt-nav__link-text"><FormattedMessage id="app.label.messages" defaultMessage="Messages"/></span>
                        </a>
                  </li>
                  <li className="kt-nav__item">
                        <a href="custom/profile/account-settings.html" className="kt-nav__link">
                              <span className="kt-nav__link-icon"><i className="flaticon2-gear"></i></span>
                              <span className="kt-nav__link-text"><FormattedMessage id="app.label.settings" defaultMessage="settings"/></span>
                        </a>
                  </li>
                  <li className="kt-nav__separator kt-nav__separator--fit"></li>
                  <li className="kt-nav__custom kt-space-between">
                        <span   className="btn btn-label-brand btn-upper btn-sm btn-bold" style={{ cursor: 'pointer' }}>
                           <FormattedMessage id="app.label.signout" defaultMessage="Sign out"/>
                        </span>
                  </li>
            </ul>
      </>   
  )  
})
