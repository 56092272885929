import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CalendarStyleWrapper } from './calendar.style';
import DnDCalendar from './DnDCalendar';
import moment from 'moment';
import { getIntlMsg, intl, Localization } from "../../../../../constants/utils";


const getIndex = (events, selectedEvent) =>
    events.findIndex(event => event.id === selectedEvent.id);

class FullCalender extends Component {
    state = {
        view: this.props.view,
        modalVisible: false,
        selectedData: undefined
    };

    eventStyleGetter = (event, start, end, isSelected) => {
        console.log(event);
        var backgroundColor = "";

        if (event.created_at && !event.modified_at) {
            backgroundColor = "#33B6FF";
        }

        if (event.modified_at || event.published==0) {
            backgroundColor = "#7C33FF";
        }

        if (event.published==1) {
            backgroundColor = "#037B0F";
        }

        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block',
            fontSize: '12px',
        };
        return {
            style: style
        };
    }

    handleNavigate = (event) => {

        // this.props.resetStateTimesheet();
        // this.props.resetModal();
        // const date = moment(event).format('YYYY-MM');

        // const tpid = {
        //     person_id: this.props.person_id,
        //     complet_date: date
        // }

        this.props.getCalendarEventInfo(event);
        // console.log("***NAVIGATE**********",event)

    }

    onSelectSlot = selectedData => {
        //
        // this.props.resetModal();
        // this.props.showModal(selectedData, 2);
        // const date = moment(selectedData.start).format('YYYY-MM-DD');
        // this.props.getSelectedDataFromCalendar(selectedData)
        // console.log("****onSelectSlot*********",selectedData)
        this.props.handleShowModal(selectedData)
        this.props.getCalendarEventInfo(selectedData);
    };

    onView = view => {
        this.props.changeView(view);

    };

    onSelectEvent = selectedData => {

        // this.props.getAllSessionByAgent(selectedData.agentid);
        // this.props.resetModal();
        // this.props.showModal(selectedData, 2);
        // this.props.getSelectedDataFromCalendar(selectedData)

        // console.log("****onSelectEvent*********",selectedData)
        this.props.handleShowModal(selectedData)
        this.props.getCalendarEventInfo(selectedData);

    }


    render() {

        const { view, calendarInfos, locale } = this.props;


        let events = calendarInfos ? calendarInfos : [];
        console.log("__________________________", events)
        let vw = view;

        const messages = {
            next: getIntlMsg(intl(locale), `app.label.next`),
            today: getIntlMsg(intl(locale), `app.label.today`),
            previous: getIntlMsg(intl(locale), `app.label.back`),
            month: getIntlMsg(intl(locale), `app.label.month`),
            day: getIntlMsg(intl(locale), `app.label.day`),
            week: getIntlMsg(intl(locale), `app.label.week`),
            allDay: getIntlMsg(intl(locale), `app.label.allday`),
            time: getIntlMsg(intl(locale), `app.label.time`),
            event: getIntlMsg(intl(locale), `app.label.event`),
            showMore: function showMore(total) {
                return '+' + total + getIntlMsg(intl(locale), `app.label.more`);
            },

        }

        const components = {
            dateCellWrapper: {},
        }
        const calendarOptions = {
            events,
            vw,
            onSelectEvent: this.onSelectEvent,
            onSelectSlot: this.onSelectSlot,
            onView: this.onView,
            onNavigate: this.handleNavigate,
            messages: messages,
            eventPropGetter: this.eventStyleGetter,
        };

        return (
            <>
                <CalendarStyleWrapper className="isomorphicCalendarWrapper" events={events}>
                    <div className="divMain">
                        <DnDCalendar {...calendarOptions} intl={intl} locale={locale} />
                    </div>
                </CalendarStyleWrapper>

            </>
        );
    }
}

export default FullCalender;
