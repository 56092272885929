/**
 * Created by louidorpc on 11/5/20.
 */
import React from 'react'
import {FormattedMessage} from "react-intl";
import  {getIntlMsg, intl} from '../../../constants/utils';
import {AssignCampaign} from '../../../constants/model'

class CampaignAssign extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            phoneNumbers: [],
            nbrqty: props.numberList.length,
            check: false,
            checkS: false,
            agents: [],
            agent: {code: null, name: null},
            campaign: {code: null, name: null},
        }
    }

    handlerChangePhoneQty = e => {
        const val = e.target.value;
        let data = this.props.numberList;
        const {phoneNumbers}=this.state;
        let d = []

        if (data.length >= val) {

            for (let i = 0; i < val; i++) {
                d.push(data[i])
            }

            if (d.length > 0) {
                this.setState({check: true, phoneNumbers: d})
            } else {
                this.setState({check: false, phoneNumbers: []})
            }

        }

    }

    handlerSearchAgent = e => {
        const val = e.target.value;
        let data = this.props.persons;

        let d = []

        if (data.length > 0) {

            d = data.filter(p => p.firstname.toLowerCase().includes(val) || p.lastname.toLowerCase().includes(val) || p.username.toLowerCase().includes(val))

            if (d.length > 0) {
                this.setState({checkS: true, agents: d})
            } else {
                this.setState({checkS: false, agents: []})
            }
        }
    }

    handleSelectAgent = (code, name) => {

        this.setState({agent: {code: code, name: name}})

    }

    handleSelectCampaign = (code, name) => {
        this.props.getAllPhoneCampaigns(code)
        this.setState({campaign: {code: code, name: name}})

    }

    submitSetting = () => {

        const {phoneNumbers, agent, campaign}=this.state;

        const entity = {
            campaign_code: campaign.code,
            agentid: agent.code,
            phone_list: phoneNumbers,
            language: this.props.language,
            creator: this.props.person_id
        }
        const campaing = new AssignCampaign(entity);
        this.props.createAssignCampain(campaing);
    };


    render() {

        const {campaigns, getAllPhoneCampaigns, numberList, persons, isLoadingCAC}=this.props;
        const {phoneNumbers, nbrqty, check, checkS, agents, agent, campaign}=this.state

        let data = check ? phoneNumbers : numberList;
        let data1 = checkS ? agents : persons;


        return (
            <div className="row pt-4">
                <div className="col-md-3">
                    <div class="card card-box">
                        <div class="card-head">
                            <header className="ml-2 font-weight-bold">
                                <FormattedMessage id={`app.label.campaign_list`}
                                                  defaultMessage={`Campaign List`}/>
                            </header>

                        </div>
                        <div class="card-body">
                            <div style={{overflow: "auto", width: "100%", maxHeight: "400px"}}>
                                {campaigns && campaigns.length > 0 ? campaigns.map((v, i) => (
                                        <div class="card list-group-item" style={{cursor: 'pointer'}}
                                             onClick={() => this.handleSelectCampaign(v.campaing_code, v.campaign_name)}>
                                            <div class="card-body"><i
                                                class="mdi mdi-circle text-success"></i> {v.campaign_name}</div>
                                        </div>

                                    )) : null}

                            </div>


                        </div>


                    </div>

                </div>


                <div className="col-md-3">
                    <div class="card card-box">
                        <div class="card-head">

                            <div className="form-group floating-label show-label col-md-12">
                                <label> <FormattedMessage id={`app.label.number_qty`}
                                                          defaultMessage={`Campaign List`}/></label>
                                <input className="form-control" type="text" onChange={this.handlerChangePhoneQty}/>
                            </div>
                            <header className="ml-2 font-weight-bold">
                                <FormattedMessage id={`app.label.campaign_phone_list`}
                                                  defaultMessage={`List of phones`}/> {numberList.length > 0 ? data.length + '/' + numberList.length : null}
                            </header>

                        </div>
                        <div class="card-body">

                            <div style={{overflow: "auto", width: "100%", maxHeight: "400px"}}>
                                {data && data.length > 0 ? data.map((v, i) => (
                                        <div class="card list-group-item" style={{cursor: 'pointer'}}>
                                            <div class="card-body"><i
                                                class="mdi mdi-circle text-success"></i> {v.phone}</div>
                                        </div>

                                    )) : null}

                            </div>


                        </div>


                    </div>

                </div>

                <div className="col-md-3">
                    <div class="card card-box">
                        <div class="card-head">

                            <div className="form-group floating-label show-label col-md-12">
                                <label><FormattedMessage id={`app.label.search`}
                                                         defaultMessage={`List of phones`}/></label>
                                <input className="form-control" type="text" onChange={this.handlerSearchAgent}/>
                            </div>
                            <header className="ml-2 font-weight-bold">
                                <FormattedMessage id={`app.label.list_of_agent`}
                                                  defaultMessage={`List of phones`}/> {numberList.length > 0 ? data.length + '/' + numberList.length : null}
                            </header>

                        </div>
                        <div class="card-body">

                            <div style={{overflow: "auto", width: "100%", maxHeight: "400px"}}>
                                {data1 && data1.length > 0 ? data1.map((v, i) => {
                                        if (v.position_name === "Agent") {
                                            return (
                                                <div class="card list-group-item" style={{cursor: 'pointer'}}
                                                     onClick={() => this.handleSelectAgent(v.id, v.fullname)}>
                                                    <div class="card-body"><i
                                                        class="mdi mdi-circle text-success"></i> {v.fullname}</div>
                                                </div>
                                            )
                                        }

                                    }) : null}

                            </div>


                        </div>


                    </div>

                </div>
                <div className="col-md-3">
                    <div class="card card-box">
                        <div class="card-head">

                            <header className="ml-2 font-weight-bold">
                                <FormattedMessage id={`app.label.result`}
                                                  defaultMessage={`Result`}/>
                            </header>

                        </div>
                        {phoneNumbers.length > 0 && agent.name !== null && campaign.name !== null && isLoadingCAC == false ?
                            <div class="card-body">

                                <table className="table">
                                    <tr>
                                        <td class="text-left font-weight-bold" style={{fontSize: '16px'}}>
                                            Agent:
                                        </td>
                                        <td class="text-left" style={{fontSize: '16px', color: '#039be5'}}>
                                            {agent ? agent.name : null}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td class="text-left font-weight-bold" style={{fontSize: '16px'}}>
                                            <FormattedMessage id={`app.label.campaign`}
                                                              defaultMessage={`Cmapaign name`}/>:
                                        </td>
                                        <td class="text-letf" style={{fontSize: '16px', color: '#039be5'}}>
                                            {campaign ? campaign.name : null}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-left font-weight-bold" style={{fontSize: '16px'}}>
                                            <FormattedMessage id={`app.label.campaign_phone_list`}
                                                              defaultMessage={`Cmapaign name`}/>:
                                        </td>
                                        <td class="text-letf" style={{fontSize: '16px', color: '#039be5'}}>

                                            <div class="card-body">

                                                <div style={{overflow: "auto", width: "100%", maxHeight: "400px"}}>
                                                    {phoneNumbers && phoneNumbers.length > 0 ? phoneNumbers.map((v, i) => (
                                                            <div class="card list-group-item"
                                                                 style={{cursor: 'pointer'}}>
                                                                <div class="card-body">{v.phone}</div>
                                                            </div>

                                                        )) : null}

                                                </div>


                                            </div>

                                        </td>
                                    </tr>

                                </table>

                                <button type="button"
                                        class={`btn btn-lg m-b-15 ml-2 mr-2 btn-primary col-md-12 ${isLoadingCAC ? 'disabled' : ''}`}
                                        onClick={this.submitSetting}>
                                    <FormattedMessage id={`app.label.assign`} defaultMessage={`Assign`}/>
                                    {isLoadingCAC ? <span class="spinner-border spinner-border-sm ml-3" role="status"
                                                          aria-hidden="true"></span> : null}
                                </button>
                            </div> : null}


                    </div>

                </div>
            </div>
        )
    }
}
export default CampaignAssign