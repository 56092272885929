import { put, call, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/creator/monthreport";
import types from "../actions/type";
import * as api from "../services/monthreport";


export const handleGetAllMonthreports = function* (action) {
    const { criteria } = action.payload
    try {
        const getSv = yield call(api.getAllMonthreports, criteria);
        if (getSv.data.length > 0) {
            yield put(actions.getAllMonthReportsSuccess(getSv.data))
        } else {
            yield put(actions.getAllMonthReportsFailed(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllMonthReportsFailed("error"))
    }


};


export const handleGetAllMonthreportsByClient = function* (action) {
    const { criteria } = action.payload;
    try {
        const getSv = yield call(api.getAllMonthreportsbyclient, criteria);
        if (getSv.data.length > 0) {
            yield put(actions.getAllMonthReportsByClientSuccess(getSv.data))
        } else {
            yield put(actions.getAllMonthReportsByClientFailed(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllMonthReportsByClientFailed("error"))
    }


};

export const handleCreateMonthreport = function* (action) {
    const { monthreport } = action.payload;
    try {
        const pos = yield call(api.createMonthreport, monthreport);
        if (pos.data.message == "success") {
            yield put(actions.createMonthReportSuccess(pos.data.message))
            yield put(actions.getAllMonthReports())

        } else {
            yield put(actions.createMonthReportFailed('error'))
        }
    } catch (error) {
        yield put(actions.createMonthReportFailed('error'))
    }
};



export const handleEditMonthreport = function* (action) {
    const { monthreport } = action.payload;
    try {
        const pos = yield call(api.updateMonthreport, monthreport);
        if (pos.data.message == "success") {
            yield put(actions.editMonthReportSuccess(pos.data.message))
            yield put(actions.getAllMonthReports())

        } else {
            yield put(actions.editMonthReportFailed('error'))
        }
    } catch (error) {
        yield put(actions.editMonthReportFailed('error'))
    }
};


export const handleDeleteMonthreport = function* (action) {
    const { monthreport } = action.payload;
    try {
        const pos = yield call(api.deleteMonthreport, monthreport?.id);
        if (pos.data.message == "success") {
            yield put(actions.deleteMonthReportSuccess(pos.data.message))
            yield put(actions.getAllMonthReports())

        } else {
            yield put(actions.deleteMonthReportFailed('error'))
        }
    } catch (error) {
        yield put(actions.deleteMonthReportFailed('error'))
    }
};


export const handleResetMonthreport = function* () {
    yield put(actions.resetMonthReportSuccess())
};




export const monthreportSaga = [
    takeLatest(types.GET_ALL_MONTH_REPORTS, handleGetAllMonthreports),
    takeLatest(types.CREATE_MONTH_REPORT, handleCreateMonthreport),
    takeLatest(types.GET_ALL_MONTH_REPORTS_BY_CLIENT, handleGetAllMonthreportsByClient),
    takeLatest(types.EDIT_MONTH_REPORT, handleEditMonthreport),
    takeLatest(types.RESET_MONTH_REPORT, handleResetMonthreport),
    takeLatest(types.DELETE_MONTH_REPORT, handleDeleteMonthreport),
];
