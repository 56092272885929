import * as React from "react";
import {Bar} from "react-chartjs-2";
import {FormattedMessage} from "react-intl";
import MaterialTable from 'material-table';
import {getIntlMsg, Localization} from "../../../constants/utils";
// import Spinner from "../../../common/utils/Spinner";
import {useSelector} from "react-redux";


const ReportCallTable = props => {

    const {intl,getAllPbxReport} = props

    const {reportCalls} = useSelector(state => ({
        reportCalls: state?.pbxReducer?.reports?.reports || [],
    }));

    const columns = [
        {
            title: "Utilisateur",
            field: 'user_name', type: 'text',
        },
        {
            title: "Extension",
            field: 'extension', type: 'text',
        },
        {
            title: "# appels entrants",
            field: 'num_incoming_call', type: 'text',
            cellStyle:{ background: '#448AFF' ,color:"white",fontWeight:'bold',fontSize:'20px' },
            headerStyle:{ background: '#448AFF' ,color:"white",fontWeight:'bold',fontSize:'20px' },
        }, 
        {
            title: "# appels sortants",
            field: 'num_outgoing_call', type: 'text',
            cellStyle:{ background: ' #E040FB' ,color:"white",fontWeight:'bold',fontSize:'20px' },
            headerStyle:{ background: ' #E040FB' ,color:"white",fontWeight:'bold',fontSize:'20px' },
           
        },
         {
            title: "# appels entrants perdus",
            field: 'num_incoming_call_no_answer', type: 'text',
            cellStyle:{ background: 'rgb(255, 99, 132)',color:"white",fontWeight:'bold',fontSize:'20px' },
            headerStyle:{ background: 'rgb(255, 99, 132)',color:"white",fontWeight:'bold',fontSize:'20px' }
        }, {
            title: "Durée des appels entrants",
            field: 'duration_incoming_call',
            render:(v=>v.duration_incoming_call+" sec"),
            type: 'text',
        }, {
            title: "Durée des appels sortants",
            render:(v=>v.duration_outgoing_call+" sec"),
            field: 'duration_outgoing_call', type: 'text',
        },
    ]

    const  toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60
    
        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")
    }



    if (reportCalls.length > 0) {
        return (
            <>
                <div className="card-header pt-2">

                    <div className="card-toolbar  pb-5">
                        <ul className="nav nav-bold nav-pills float-right">
                            <a href="#" className="btn btn-sm btn-info font-weight-bolder"
                               onClick={()=>getAllPbxReport()} >
											<span className="svg-icon svg-icon-md">
                                                <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall"
                                                      aria-hidden="true" title="Chercher">refresh</span>
											</span>Actualiser</a>
                        </ul>
                    </div>
                </div>
                <MaterialTable
                    className="border-0"
                    title="Rapport des appels par agent"
                    columns={columns}
                    data={reportCalls ? reportCalls : []}
                    icon='unfold_less'
                    localization={Localization('fr')}
                    options={{
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },
                        actionsColumnIndex: -1,
                        exportButton: true,
                        exportAllData: true,
                        pageSize: reportCalls.length,
                        pageSizeOptions: [10, 20, 25, 50, 100]
                    }}

                />
            </>
        )
    } else {
        return (<hr/>)
    }
}

export default React.memo(ReportCallTable)

