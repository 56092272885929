/**
 * Created by louidorpc on 8/11/20.
 */
import React, { useEffect, useState } from 'react';
import { app } from '../../constants/config';
import Loader from '../../common/loader';
import Progress from '../../common/progress'
import { FormattedMessage } from "react-intl";
import { getIntlMsg, intl } from '../../constants/utils'
import Client from './client'
import Campaign from './campaign';
import Calendar from './calendar/Calendar/Calendar'
import moment from 'moment'
import CampaignModal from './campaignModal'
import createHistory from 'history/createBrowserHistory';
import ReportTable from './reports'
import RecorgingdCalls from './recordingCall'
import Dashboard from './dashboard'
import OutgoingCall from './outgoingRecordingCall'
import AllServices from '../pbx/services';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
const shortcutsItems = [
    {
        label: 'This Week',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today];
        },
    },
    {
        label: 'Current Month',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'Next Month',
        getValue: () => {
            const today = dayjs();
            const startOfNextMonth = today.endOf('month').add(1, 'day');
            return [startOfNextMonth, startOfNextMonth.endOf('month')];
        },
    },
    { label: 'Reset', getValue: () => [null, null] },
];


const IAPDashboard = ({ changeView,
    changeEvents,
    getSelectedDataFromCalendar,
    event,
    view,
    campaignsToCalendar,
    getAllCampaignToCalendar,
    reports,
    isLoadingR,
    recordingsCalls,
    session,
    isLoadingS,
    stats,
    isLoadingCA,
    getAllCampaigns,
    createCampaign,
    campaigns,
    resetStateClient,
    mc,
    getAllTatistic,
    getAllPhoneCampaigns,
    isLoadingPC,
    numberList,
    editCampaign,
    getCampaignItem,
    cpItem,
    getAllRecordingCallByEntrId,
    getAllCampaignsById,
    isLoadingBCA,
    campaignsId,
    deleteCampaignsById,
    getAllPbxReportClient,
    getAllIncomingCallCLient,
    getAllOutgoingCallClient, getAllPrefixById, getAllEntreprises, getAllReports }) => {



    const [tab, setTab] = useState('');
    const [show, setShow] = useState('');
    const [display, setDisplay] = useState('none');


    const [value, setValue] = React.useState([
        dayjs(dayjs().startOf('week')),
        dayjs(dayjs().endOf('week')),
    ]);


    const showModal = () => {
        setShow('show')
        setDisplay('block')

    }

    const removeModal = () => {
        setShow('')
        setDisplay('none')
    }


    useEffect(() => {

        const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);
        const history = createHistory();


        if (infouser.person_type === "client") {
            const ent = session?.entreprise_id ? session?.entreprise_id : 0;
            getAllPrefixById(session?.entreprise_id)

        } else {
            history.goBack()
        }

    }, [])

    const handleSwitchTable = tab => {
        if (tab === "client") {
            const date = moment(new Date()).format('YYYY-MM');

            const pid = {
                person_id: session?.id,
                complet_date: date,
                id: session?.entreprise_id,
            }
            getAllCampaignToCalendar(pid)
            setTab('client');
        } else if (tab === "campaign") {
            getAllCampaignsById(session?.entreprise_id);
            setTab('campaign')
        }
        else if (tab === "incoming") {
            setTab('incoming')
            getAllIncomingCallCLient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        } else if (tab === "outgoing") {
            setTab('outgoing')
            getAllOutgoingCallClient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        } else if (tab === 'servicereport') {
            setTab('servicereport')
        } else {
            setTab('dashbord')
            getAllPbxReportClient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        }
    }

    const handleCloseDatePicker = () => {

        if (tab === "incoming") {
            getAllIncomingCallCLient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        } else if (tab === "outgoing") {
            getAllOutgoingCallClient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        } else {
            getAllPbxReportClient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        }

    }

    const [extension, setExtension] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [openExtension, setOpenExtension] = React.useState(false);


    const { prefixIds } = useSelector(state => ({
        prefixIds: state?.prefixReducer?.prefixIds || [],
    }));


    useEffect(() => {

        if (prefixIds.length > 0) {
            setExtension(prefixIds[0]?.prefix_number)

            if (tab === "incoming") {
                getAllIncomingCallCLient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
            } else if (tab === "outgoing") {
                getAllOutgoingCallClient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
            }
            else {
                getAllPbxReportClient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
            }

        }

    }, [prefixIds])

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseExtension = () => {
        setOpenExtension(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleOpenExtension = () => {
        setOpenExtension(true);
    };

    const handleDatePickerChange = (e) => {
        setValue(e)
    }

    const handleChangeExtension = (event) => {
        setExtension(event.target.value);

        if (tab === "incoming") {
            getAllIncomingCallCLient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: event.target.value })
        } else if (tab === "outgoing") {
            getAllOutgoingCallClient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: event.target.value })
        }  else {
            getAllPbxReportClient({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: event.target.value })
        }


    };



    return (
        <>
            <section class="admin-content">

                <div class="container-fluid bg-dark m-b-30">
                    <div class="row  p-b-60 p-t-20">

                        <div class="col-md-6 text-white p-b-30">
                            <div class="media">

                                <div class="avatar mr-3  avatar-xl">

                                    {session && isLoadingS == false ?
                                        <img
                                            src={session ? session.photo ? app.BASE_IMAGE_URL + '/models/images/' + session.photo + '?' + new Date() : "/assets/img/avatar.png" : "/assets/img/avatar.png"}
                                            alt="..." class="avatar-img rounded-circle" /> :
                                        <Loader />}
                                </div>
                                <div class="media-body m-auto">
                                    <h5 class="mt-0">{session ? session.fullname : null}
                                    </h5>
                                    <div class="opacity-75">{session ? session.role : null}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 text-white my-auto text-md-right p-b-30">

                        </div>

                    </div>
                </div>

                <div class="container-fluid pull-up">
                    <div class="row ">
                        <div class="col-lg-12">
                            <div class="card m-b-30">
                                <div class="card-header">
                                    <div class="card-title"></div>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">

                                        <li class="nav-item">
                                            {prefixIds.length > 0 ?
                                                <a class="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#setting"
                                                    onClick={() => handleSwitchTable('dasboard')}
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-graph">
                                                        <FormattedMessage id={`app.label.dashboard`}
                                                            defaultMessage={`Label Name`} /></i></a> : null}
                                        </li>

                                        <li className="nav-item">
                                            {prefixIds.length > 0 ?
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#incoming"
                                                    role="tab"
                                                    onClick={() => handleSwitchTable('incoming')}
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-record-circle">
                                                        <FormattedMessage
                                                            id={`app.label.recording_incoming_call`}
                                                            defaultMessage={`Enregistrements des appels entrants`} />
                                                    </i>
                                                </a> : null}
                                        </li>
                                        <li className="nav-item">
                                            {prefixIds.length > 0 ?
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#outgoing"
                                                    role="tab"
                                                    onClick={() => handleSwitchTable('outgoing')}
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-record-circle">
                                                        <FormattedMessage
                                                            id={`app.label.recording_incoming_call`}
                                                            defaultMessage={`Enregistrements des appels sortants`} />
                                                    </i>
                                                </a> : null}
                                        </li>

                                        <li class="nav-item" onClick={() => handleSwitchTable('campaign')}>
                                            <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                href="#campaign"
                                                role="tab"
                                                aria-controls="contact" aria-selected="false"><i
                                                    className="mdi mdi-phone-alert">
                                                    <FormattedMessage id={`app.label.campaign_management`}
                                                        defaultMessage={`campaign`} />
                                                </i>
                                            </a>
                                        </li>

                                        <li class="nav-item" onClick={() => handleSwitchTable('client')}>
                                            <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                href="#client"
                                                role="tab"
                                                aria-controls="contact" aria-selected="false"><i
                                                    className="mdi mdi-calendar">
                                                    <FormattedMessage id={`app.label.campaign_calendar`}
                                                        defaultMessage={`Calendrier des campagnes`} />
                                                </i>
                                            </a>
                                        </li>

                                        {session?.entreprise_name === "Capital Bank S.A" ?
                                            <li class="nav-item" onClick={() => handleSwitchTable('servicereport')}>
                                                <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#servicereport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-file-chart">
                                                        <FormattedMessage id={`app.label.campaign_calendar`}
                                                            defaultMessage={` Rapport par services`} />
                                                    </i>
                                                </a>
                                            </li> : null}



                                    </ul>
                                    <div class="tab-content" id="myTabContent1">

                                        {tab !== 'client' && tab !== 'campaign' && tab !== 'servicereport' &&
                                            <div className="row pt-4 pb-4">
                                                <div className="col-lg-3">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer
                                                            components={[
                                                                'DatePicker',
                                                                'TimePicker',
                                                                'DateTimePicker',
                                                                'DateRangePicker',
                                                            ]}

                                                        >
                                                            <DemoItem

                                                                component="DateRangePicker"


                                                            >
                                                                <DateRangePicker
                                                                    localeText={{
                                                                        start: '',
                                                                        end: '',
                                                                    }}
                                                                    format='YYYY-MM-DD'
                                                                    slotProps={{
                                                                        shortcuts: {
                                                                            items: shortcutsItems,
                                                                        },
                                                                        actionBar: { actions: [] },
                                                                        textField: ({ position }) => ({
                                                                            label: position === 'start' ? 'Start date' : 'end date',
                                                                        }),
                                                                    }}
                                                                    onOpen={() => {
                                                                        setTimeout(() => {
                                                                            const firstDiv = document.querySelector('.MuiDateRangeCalendar-root');
                                                                            if (firstDiv) {
                                                                                const childDivToRemove = firstDiv.querySelector(':first-child');
                                                                                if (childDivToRemove) {
                                                                                    childDivToRemove.remove();
                                                                                }
                                                                            }
                                                                        }, 1000)
                                                                    }}
                                                                    onClose={() => {
                                                                        setTimeout(() => {
                                                                            const firstDiv = document.querySelector('.MuiDateRangeCalendar-root');
                                                                            if (firstDiv) {
                                                                                const childDivToRemove = firstDiv.querySelector(':first-child');
                                                                                if (childDivToRemove) {
                                                                                    childDivToRemove.remove();
                                                                                }
                                                                            }
                                                                        }, 1000)
                                                                        handleCloseDatePicker()

                                                                    }}
                                                                    value={value}
                                                                    onChange={(e) => handleDatePickerChange(e)}
                                                                />
                                                            </DemoItem>
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                                <div className='col-lg-9'>

                                                    <FormControl sx={{ m: 1, minWidth: 120 }} className='ext'>
                                                        <InputLabel id="demo-controlled-open-select-label">Extension</InputLabel>
                                                        <Select
                                                            labelId="demo-controlled-open-select-label"
                                                            id="demo-controlled-open-select"
                                                            open={openExtension}
                                                            onClose={handleCloseExtension}
                                                            onOpen={handleOpenExtension}
                                                            value={extension}
                                                            label="Extension"
                                                            onChange={handleChangeExtension}
                                                        >
                                                            {prefixIds?.map(v => <MenuItem value={v.prefix_number}>{v.campaign_name}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>}

                                        <div class="tab-pane fade show active" id="setting" role="tabpanel"
                                            aria-labelledby="contact-tab">
                                            {prefixIds.length > 0 ?
                                                <Dashboard getAllPbxReportClient={getAllPbxReportClient}
                                                    extension={extension} startDate={dayjs(value[0]).format('YYYY-MM-DD')} endDate={dayjs(value[1]).format('YYYY-MM-DD')}/> : null}

                                        </div>
                                        <div class="tab-pane fade" id="outgoing" role="tabpanel"
                                            aria-labelledby="contact-tab">
                                            {prefixIds.length > 0 ?
                                                <OutgoingCall language={session?.language}
                                                    getAllOutgoingCallClient={getAllOutgoingCallClient}
                                                    prefixIds={prefixIds} />
                                                : null}

                                        </div>
                                        <div class="tab-pane fade" id="incoming" role="tabpanel"
                                            aria-labelledby="contact-tab">
                                            {prefixIds.length > 0 ?
                                                <RecorgingdCalls language={session?.language}
                                                    getAllIncomingCallCLient={getAllIncomingCallCLient}
                                                    prefixIds={prefixIds} />
                                                : null}

                                        </div>
                                        <div class="tab-pane fade show" id="client" role="tabpanel"
                                            aria-labelledby="contact-tab">
                                            <Calendar
                                                changeView={changeView}
                                                changeEvents={changeEvents}
                                                resetModal={null}
                                                events={event}
                                                view={view}
                                                showModal={showModal}
                                                removeModal={removeModal}
                                                calendarInfos={campaignsToCalendar ? campaignsToCalendar : []}
                                                person_id={session?.id}
                                                getAllCampaignToCalendar={getAllCampaignToCalendar}
                                                locale={session ? session.language : 'en'}
                                                getSelectedDataFromCalendar={getSelectedDataFromCalendar}
                                                getAllSessionByAgent={[]}
                                                getAllPhoneCampaigns={getAllPhoneCampaigns}
                                                entreprise_id={session?.entreprise_id}
                                            />

                                        </div>

                                        <div class="tab-pane fade show" id="campaign" role="tabpanel"
                                            aria-labelledby="contact-tab">

                                            <Campaign
                                                entreprise_id={session?.entreprise_id}
                                                getAllCampaigns={getAllCampaigns}
                                                createCampaign={createCampaign}
                                                campaigns={campaigns}
                                                language={session ? session.language : null}
                                                isLoadingCA={isLoadingCA}
                                                person_id={session?.id}
                                                getAllPhoneCampaigns={getAllPhoneCampaigns}
                                                isLoadingPC={isLoadingPC}
                                                numberList={numberList}
                                                resetStateClient={resetStateClient}
                                                mc={mc}
                                                getCampaignItem={getCampaignItem}
                                                cpItem={cpItem}
                                                editCampaign={editCampaign}
                                                campaignsId={campaignsId}
                                                isLoadingBCA={isLoadingBCA}
                                                getAllCampaignsById={getAllCampaignsById}
                                                deleteCampaignsById={deleteCampaignsById}

                                            />

                                        </div>


                                        <div class="tab-pane fade" id="servicereport" role="tabpanel"
                                            aria-labelledby="contact-tab">
                                            <AllServices />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            <CampaignModal language={session ? session.language : null} isLoadingPC={isLoadingPC}
                numberList={numberList} show={show} display={display}
                removeModal={removeModal}
            />
        </>
    )
}


export default IAPDashboard