/**
 * Created by louidorpc on 8/4/20.
 */
import  React from 'react'
import Client from '../../components/client'
import {deleteCampaignsById} from "../../actions/creator/client";
import {
    getAllIncomingCallCLient,
    getAllOutgoingCallClient,
    getAllPbxReportClient
} from "../../actions/creator/clientPbx";


const Home = props => {

    const {
        sr:{session, isLoadingS}, actions:{
        changeView, changeEvents, getSelectedDataFromCalendar, getAllCampaignToCalendar, getAllReports, getAllRecordingCallByEntrId,
        getPersonById, getAllPhoneCampaigns, getCampaignItem, editCampaign, getAllTatistic, getAllServices, createService, updateService, getAllPositions, createPosition, updatePosition, resetEntreprise, getAllPersons, getPersonInfo, createCampaign, getAllCampaigns,
        getAllEntreprises, createEntreprise, updateEntreprise, getEntrepriseInfo, updatePersonById, resetStatePerson, changeUserStatus, createClient,
        resetStateClient, getAllClients, createCustomer, getAllCustomers, getAllPrefixById, getAllCampaignsById,deleteCampaignsById,
            getAllPbxReportClient,getAllIncomingCallCLient,getAllOutgoingCallClient
    }, person:{ps, isLoading, persons, personInfo, mups}, intl, stat:{stats, isLoadingSt},
        sv:{isLoadingSv, services}, positions, entreprises, isLoadingE, entrepriseInfo, msEnt, mc, clients, customers, campaigns, cpItem,
        isLoadingCA, isLoadingPC, numberList, event, view, campaignsToCalendar, reports, isLoadingR, recordingsCalls, prefixIds, campaignsId, isLoadingBCA
    }=props;

    if (session !== null)
        return (
            <Client

                changeView={changeView}
                changeEvents={changeEvents}
                getSelectedDataFromCalendar={getSelectedDataFromCalendar}
                recordingsCalls={recordingsCalls}

                session={session}
                getPersonById={getPersonById}
                getAllRecordingCallByEntrId={getAllRecordingCallByEntrId}
                ps={ps} isLoading={isLoading}
                intl={intl}
                isLoadingS={isLoadingS}
                getAllTatistic={getAllTatistic}
                stats={stats}
                isLoadingSt={isLoadingSt}
                isLoadingSv={isLoadingSv}
                getAllServices={getAllServices}
                createService={createService}
                updateService={updateService}
                services={services}
                positions={positions}

                updatePersonById={updatePersonById}

                getAllPositions={getAllPositions}
                createPosition={createPosition}
                updatePosition={updatePosition}

                getAllEntreprises={getAllEntreprises} createEntreprise={createEntreprise}
                updateEntreprise={updateEntreprise} getEntrepriseInfo={getEntrepriseInfo}
                entrepriseInfo={entrepriseInfo} entreprises={entreprises} isLoadingE={isLoadingE}
                msEnt={msEnt}
                resetEntreprise={resetEntreprise}
                persons={persons}
                getAllPersons={getAllPersons}
                getPersonInfo={getPersonInfo}
                personInfo={personInfo}
                mups={mups}
                resetStatePerson={resetStatePerson}

                changeUserStatus={changeUserStatus}
                createClient={createClient}
                resetStateClient={resetStateClient}
                mc={mc}
                clients={clients}
                getAllClients={getAllClients}
                createCustomer={createCustomer}
                getAllCustomers={getAllCustomers}
                customers={customers}
                createCampaign={createCampaign}
                getAllCampaigns={getAllCampaigns}
                campaigns={campaigns}
                isLoadingCA={isLoadingCA}
                isLoadingPC={isLoadingPC}
                numberList={numberList}
                getAllPhoneCampaigns={getAllPhoneCampaigns}
                getCampaignItem={getCampaignItem}
                cpItem={cpItem}
                editCampaign={editCampaign}
                event={event}
                view={view}
                getAllCampaignToCalendar={getAllCampaignToCalendar}
                campaignsToCalendar={campaignsToCalendar}
                getAllReports={getAllReports}
                reports={reports}
                isLoadingR={isLoadingR}
                getAllPrefixById={getAllPrefixById}
                prefixIds={prefixIds}
                campaignsId={campaignsId}
                isLoadingBCA={isLoadingBCA}
                getAllCampaignsById={getAllCampaignsById}
                deleteCampaignsById={deleteCampaignsById}
                getAllPbxReportClient={getAllPbxReportClient}
                getAllIncomingCallCLient={getAllIncomingCallCLient}
                getAllOutgoingCallClient={getAllOutgoingCallClient}

            />
        )
    else return <div></div>
}


export default Home;