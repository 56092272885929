import React from "react";
import {Link} from "react-router-dom";

export const Brand = () => {
    return (
        <div className="admin-sidebar-brand">
            <Link to="/">
                <img className="admin-brand-logo" src="assets/img/logowhite.png" width="40" alt="ZL Logo"/>
                <span className="admin-brand-content font-secondary">
                 SEEDLEE
               </span>
            </Link>
            <div className="ml-auto">

                <span className="admin-pin-sidebar btn-ghost btn btn-rounded-circle"></span>

                <span className="admin-close-sidebar"></span>
            </div>
        </div>
    )
};