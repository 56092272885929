import { put, call, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/creator/links";
import types from "../actions/type";
import * as api from "../services/links";


export const handleGetAllLinks = function* () {

    try {
        const getSv = yield call(api.getAllLinks);
        if (getSv?.data?.length > 0) {
            yield put(actions.getAllLinksSuccess(getSv.data))
        } else {
            yield put(actions.getAllLinksFailed(getSv?.data?.message))
        }

    } catch (error) {
        yield put(actions.getAllLinksFailed("error"))
    }

};


export const handleGetLinkByType = function* (action) {

    const { type } = action.payload;

    try {
        const getSv = yield call(api.getLinkByType, type);
        if (getSv?.data?.length > 0) {
            yield put(actions.getLinkByTypeSuccess(getSv.data))
        } else {
            yield put(actions.getLinkByTypeFailed(getSv?.data?.message))
        }

    } catch (error) {
        yield put(actions.getLinkByTypeFailed("error"))
    }

};

export const handleGetLinkByEntreprise = function* (action) {

    const { type, entreprise_id } = action.payload;

    try {
        const getSv = yield call(api.getLinkByEntreprise, type, entreprise_id);
        if (getSv?.data) {
            yield put(actions.getLinkByEntrepriseSuccess(getSv.data))
        } else {
            yield put(actions.getLinkByEntrepriseFailed(getSv?.data?.message))
        }

    } catch (error) {
        yield put(actions.getLinkByEntrepriseFailed("error"))
    }

};

export const handleCreateLink = function* (action) {
    const { link } = action.payload;
    try {
        const pos = yield call(api.createLink, link);
        if (pos.data.message == "success") {
            yield put(actions.createLinkSuccess(pos.data.message))
            yield put(actions.getAllLinks())

        } else {
            yield put(actions.createLinkFailed(pos.data.message))
        }
    } catch (error) {
        yield put(actions.createLinkFailed('error'))
    }
};

export const handleUpdateLink = function* (action) {
    const { link } = action.payload;
    try {
        const pos = yield call(api.updateLink, link);
        if (pos.data.message == "success") {
            yield put(actions.updateLinkSuccess(pos.data.message))
            yield put(actions.getAllLinks())

        } else {
            yield put(actions.updateLinkFailed(pos.data.message))
        }
    } catch (error) {
        yield put(actions.updateLinkFailed('error'))
    }
};



export const handleGetLink = function* (action) {
    const { id } = action.payload
    try {
        const getSv = yield call(api.getLink, id);
        if (getSv?.data?.id !== null) {
            yield put(actions.getLinkSuccess(getSv.data))
        } else {
            yield put(actions.getLinkFailed(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getLinkFailed("error"))
    }


};


export const handleDeleteLink = function* (action) {
    const { id } = action.payload;
    try {
        const serv = yield call(api.deleteLink, id);
        if (serv.data.message == "success") {
            yield put(actions.deleteLinkSuccess(serv.data.id))
        } else {
            yield put(actions.deleteLinkFailed({ mssv: serv.data.message }))
        }
    } catch (error) {
        yield put(actions.deleteLinkFailed('error'))
    }
};


export const linkSaga = [
    takeLatest(types.GET_ALL_LINKS, handleGetAllLinks),
    takeLatest(types.CREATE_LINK, handleCreateLink),
    takeLatest(types.UPDATE_LINK, handleUpdateLink),
    takeLatest(types.GET_LINK, handleGetLink),
    takeLatest(types.DELETE_LINK, handleDeleteLink),
    takeLatest(types.GET_LINK_BY_TYPE, handleGetLinkByType),
    takeLatest(types.GET_LINK_BY_ENTREPRISE, handleGetLinkByEntreprise),
];
