/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getIntlMsg, intl} from "../../constants/utils";
import  {injectIntl, createIntl, createIntlCache} from "react-intl"


toast.configure()

export const resetStatePerson = () => ({
    type: types.PERSON_RESET_STATE,
    payload: {message: null}
});


export const resetStatePersonSuccess = () => ({
    type: types.PERSON_RESET_STATE_SUCCES,
    payload: {message: null}
});


export const getPersonById = pid => ({
    type: types.GET_PERSON_BY_ID,
    payload: {isLoading: true, pid: pid}
});


export const getPersonByIdSuccess = ps => ({
    type: types.GET_PERSON_BY_ID_SUCCES,
    payload: {isLoading: false, ps: ps}
});

export const getPersonByIdFailled = psmer => ({
    type: types.GET_PERSON_BY_ID_FAILLED,
    payload: {isLoading: true, message: psmer}
});


export const updatePersonById = ups => ({
    type: types.UPDATE_PERSON_BY_ID,
    payload: {isLoading: true, ups: ups}
});


export const updatePersonByIdSuccess = ups => {

    toast.success(getIntlMsg(intl(ups), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_PERSON_BY_ID_SUCCESS,
        payload: {isLoading: false, ups: ups, mups: 'success'}
    }
};

export const updatePersonByIdFailled = mups => {

    toast.error(getIntlMsg(intl(mups), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_PERSON_BY_ID_FAILLED,
        payload: {isLoading: true, mups: mups}
    }
};


export const getAllPersons = () => ({
    type: types.GET_ALL_PERSONS,
    payload: {isLoading: true}
});

export const getAllPersonsSuccess = persons => ({
    type: types.GET_ALL_PERSONS_SUCCESS,
    payload: {isLoading: false, persons: persons}
});


export const getAllPersonsFailled = () => ({
    type: types.GET_ALL_PERSONS_FAILLED,
    payload: {isLoading: false}
});


export const getPersonInfo = personInfo => ({
    type: types.GET_PERSON_INFO,
    payload: {
        personInfo: personInfo
    }
});

export const getPersonInfoSuccess = personInfo => ({
    type: types.GET_PERSON_INFO_SUCCESS,
    payload: {
        personInfo: personInfo
    }
});


export const changeUserStatus = user => ({
    type: types.CHANGE_USER_STATUS,
    payload: {isLoading: true, user: user}
});


export const changeUserStatusSuccess = ms => {

    toast.success(getIntlMsg(intl(ms), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CHANGE_USER_STATUS_SUCCESS,
        payload: {isLoading: false}
    }
};

export const changeUserStatusFailled = ms => {

    toast.error(getIntlMsg(intl(ms.locale), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CHANGE_USER_STATUS_FAILLED,
        payload: {isLoading: true, msuser: ms.msuser}
    }
};


export const getUserById = idU => ({
    type: types.GET_USER_BY_ID,
    payload: {isLoadingUS: true, idU: idU}
});


export const getUserByIdSuccess = users => ({
    type: types.GET_USER_BY_ID_SUCCESS,
    payload: {isLoadingUS: false, users: users}
});

export const getUserByIdFailled = psmer => ({
    type: types.GET_USER_BY_ID_FAILLED,
    payload: {isLoadingUS: true, message: psmer}
});


export const updateUserById = user => ({
    type: types.UPDATE_USER_BY_ID,
    payload: {isLoading: true, user: user}
});


export const updateUserByIdSuccess = ups => {

    toast.success(getIntlMsg(intl(ups), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_USER_BY_ID_SUCCESS,
        payload: {isLoading: false, ups: ups, mups: 'success'}
    }
};

export const updateUserByIdFailled = mups => {

    toast.error(getIntlMsg(intl(mups), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_USER_BY_ID_FAILLED,
        payload: {isLoading: true, mups: mups}
    }
};


export const changePassword = pass => ({
    type: types.CHANGE_PASSWORD,
    payload: {isLoading: true, pass: pass}
});


export const changePasswordSuccess = ups => {

    toast.success(getIntlMsg(intl(ups), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CHANGE_PASSWORD_SUCCESS,
        payload: {isLoading: false, ups: ups, cms: 'success'}
    }
};

export const changePasswordFailled = mups => {

    toast.error(getIntlMsg(intl(mups), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CHANGE_PASSWORD_FAILLED,
        payload: {isLoading: true, mups: mups}
    }
};


