/**
 * Created by louidorpc on 2/2/21.
 */
import React from 'react'
import Form  from './changePasswordForm'
import {ChangePassword} from '../../constants/model'


class ChangePasswordContainer extends React.Component {


    handleSubmit = (values) => {
        let entity = new ChangePassword(values);
        entity.person_id = this.props.person_id;
        entity.language = this.props.language;
        this.props.changePassword(entity)


    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit} cms={this.props.cms}/>

        )
    }
}

export default ChangePasswordContainer;