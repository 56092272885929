/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getIntlMsg, intl} from "../../constants/utils";
import {injectIntl, createIntl, createIntlCache} from "react-intl"


toast.configure()

export const resetStateClient = () => ({
    type: types.CLIENT_RESET_STATE,
    payload: {mc: null}
});


export const resetStateClientSuccess = () => ({
    type: types.CLIENT_RESET_STATE_SUCCES,
    payload: {mc: null}
});


export const updateClientById = upc => ({
    type: types.UPDATE_CLIENT,
    payload: {isLoadingC: true, upc: upc}
});


export const updateClientByIdSuccess = upc => {

    toast.success(getIntlMsg(intl(upc), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_CLIENT_SUCCESS,
        payload: {isLoadingC: false, upc: upc, mupc: 'success'}
    }
};


export const updateClientByIdFailled = mupc => {

    toast.error(getIntlMsg(intl(mupc), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_CLIENT_FAILLED,
        payload: {isLoadingC: true, mupc: mupc}
    }
};


export const createClient = client => ({
    type: types.CREATE_CLIENT,
    payload: {isLoadingC: true, client: client}
});


export const createClientSuccess = mc => {

    toast.success(getIntlMsg(intl(mc), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_CLIENT_SUCCESS,
        payload: {isLoadingC: false, mc: 'success'}
    }
};


export const createClientFailled = ms => {

    toast.error(getIntlMsg(intl(ms.lg), ms.mc), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_CLIENT_FAILLED,
        payload: {isLoadingC: true, mc: ms.mc}
    }
};


export const getAllClients = () => ({
    type: types.GET_ALL_CLIENTS,
    payload: {isLoadingC: true}
});

export const getAllClientsSuccess = clients => ({
    type: types.GET_ALL_CLIENTS_SUCCESS,
    payload: {isLoadingC: false, clients: clients}
});


export const getAllClientsFailled = () => ({
    type: types.GET_ALL_CLIENTS_FAILLED,
    payload: {isLoadingC: false}
});


export const createCustomer = customer => ({
    type: types.CREATE_CUSTOMER,
    payload: {isLoadingCO: true, customer: customer}
});


export const createCustomerSuccess = mc => {

    toast.success(getIntlMsg(intl(mc), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_CUSTOMER_SUCCESS,
        payload: {isLoadingCO: false, mc: 'success'}
    }
};


export const createCustomerFailled = ms => {

    toast.error(getIntlMsg(intl(ms.lg), ms.mc), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_CUSTOMER_FAILLED,
        payload: {isLoadingCO: true, mc: ms.mc}
    }
};


export const getAllCustomers = () => ({
    type: types.GET_ALL_CUSTOMERS,
    payload: {isLoadingCO: true}
});

export const getAllCustomersSuccess = customers => ({
    type: types.GET_ALL_CUSTOMERS_SUCCESS,
    payload: {isLoadingCO: false, customers: customers}
});


export const getAllCustomersFailled = () => ({
    type: types.GET_ALL_CUSTOMERS_FAILLED,
    payload: {isLoadingCO: false}
});


export const getAllCampaigns = id => ({
    type: types.GET_ALL_CAMPAIGN,
    payload: {isLoadingCA: true, id: id}
});

export const getAllCampaignsSuccess = campaigns => ({
    type: types.GET_ALL_CAMPAIGN_SUCCESS,
    payload: {isLoadingCA: false, campaigns: campaigns}
});


export const getAllCampaignsFailled = () => ({
    type: types.GET_ALL_CAMPAIGN_FAILLED,
    payload: {isLoadingCA: false}
});


export const createCampaign = campaign => ({
    type: types.CREATE_CAMPAIGN,
    payload: {isLoadingCA: true, campaign: campaign}
});


export const createCampaignSuccess = mc => {

    toast.success(getIntlMsg(intl(mc), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_CAMPAIGN_SUCCESS,
        payload: {isLoadingCA: false, mc: 'success'}
    }
};


export const createCampaignFailled = ms => {

    toast.error(getIntlMsg(intl(ms.lg), ms.mc), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_CAMPAIGN_FAILLED,
        payload: {isLoadingCA: true, mc: ms.mc}
    }
};


export const getAllPhoneCampaigns = code => ({
    type: types.GET_ALL_PHONE_CAMPAIGN_BY_CODE,
    payload: {isLoadingPC: true, code: code}
});

export const getAllPhoneCampaignsSuccess = numberList => ({
    type: types.GET_ALL_PHONE_CAMPAIGN_BY_CODE_SUCCESS,
    payload: {isLoadingCA: false, numberList: numberList}
});


export const getAllPhoneCampaignsFailled = () => ({
    type: types.GET_ALL_PHONE_CAMPAIGN_BY_CODE_FAILLED,
    payload: {isLoadingPC: false}
});


export const getCampaignItem = cpItem => ({
    type: types.GET_CAMPAIGN_ITEM,
    payload: {cpItem: cpItem}
});


export const getCampaignItemSuccess = cpItem => ({
    type: types.GET_CAMPAIGN_ITEM_SUCCESS,
    payload: {cpItem: cpItem}
});


export const editCampaign = campaign => ({
    type: types.EDIT_CAMPAIGN,
    payload: {isLoadingCA: true, campaign: campaign}
});


export const editCampaignSuccess = mc => {

    toast.success(getIntlMsg(intl(mc), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.EDIT_CAMPAIGN_SUCCESS,
        payload: {isLoadingCA: false, mc: 'success'}
    }
};


export const editCampaignFailled = ms => {

    toast.error(getIntlMsg(intl(ms.lg), ms.mc), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.EDIT_CAMPAIGN_FAILLED,
        payload: {isLoadingCA: true, mc: ms.mc}
    }
};


export const getSelectedDataFromCalendar = tsData => ({
    type: types.GET_SELECTED_DATE_FROM_CALENDAR,
    payload: {tsData: tsData}
});


export const getSelectedDataFromCalendarSuccess = tsData => ({
    type: types.GET_SELECTED_DATE_FROM_CALENDAR_SUCCESS,
    payload: {tsData: tsData}
});
export const changeView = view => ({
    type: types.CALENDAR_CHANGE_VIEW,
    payload: {view: view}
})


export const changeViewSuccess = view => ({
    type: types.CALENDAR_CHANGE_VIEW_SUCCESS,
    payload: {view: view}
})

export const changeEvents = envent => ({
    type: types.CALENDAR_EVENT,
    payload: {envent: envent}
})

export const changeEventsSuccess = envent => ({
    type: types.CALENDAR_EVENT_SUCCESS,
    payload: {envent: envent}
})


export const getAllCampaignToCalendar = pid => ({
    type: types.GET_ALL_CAMPAIGNS_TO_CALENDAR,
    payload: {isLoadingAS: true, pid: pid}
});


export const getAllCampaignToCalendarSuccess = campaignsToCalendar => ({
    type: types.GET_ALL_CAMPAIGNS_TO_CALENDAR_SUCCESS,
    payload: {isLoadingAS: false, campaignsToCalendar: campaignsToCalendar}
});

export const getAllCampaignToCalendarFailled = message => ({
    type: types.GET_ALL_CAMPAIGNS_TO_CALENDAR_FAILLED,
    payload: {isLoadingAS: true, message: message}
});


export const getAllPhoneCampaignsAvailable = code => ({
    type: types.GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE,
    payload: {isLoadingPC: true, code: code}
});

export const getAllPhoneCampaignsAvailableSuccess = numberListA => ({
    type: types.GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE_SUCCESS,
    payload: {isLoadingCA: false, numberListA: numberListA}
});


export const getAllPhoneCampaignsAvailableFailled = () => ({
    type: types.GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE_FAILLED,
    payload: {isLoadingPC: false}
});


export const getAllReports = () => ({
    type: types.GET_ALL_REPORTS,
    payload: {isLoadingR: true}
});

export const getAllReportsSuccess = reports => ({
    type: types.GET_ALL_REPORTS_SUCCESS,
    payload: {isLoadingR: false, reports: reports}
});


export const getAllReportsFailled = () => ({
    type: types.GET_ALL_REPORT_FAILLED,
    payload: {isLoadingR: false}
});


export const getAllRecordingCallByEntrId = entr_id => ({
    type: types.GET_ALL_RECORDING_CALL_BY_ENTR_ID,
    payload: {isLoadingRC: true, entr_id: entr_id}
});

export const getAllRecordingCallByEntrIdSuccess = recordingsCalls => ({
    type: types.GET_ALL_RECORDING_CALL_BY_ENTR_ID_SUCCESS,
    payload: {isLoadingRC: false, recordingsCalls: recordingsCalls}
});


export const getAllRecordingCallByEntrIdFailled = ms => ({
    type: types.GET_ALL_RECORDING_CALL_BY_ENTR_ID_FAILLED,
    payload: {isLoadingRC: false, rcms: ms}
});


export const getAllCampaignsById = id => ({
    type: types.GET_CAMPAIGNS_BY_ID,
    payload: {isLoadingBCA: true, id: id}
});

export const getAllCampaignsByIdSuccess = campaignsId => ({
    type: types.GET_CAMPAIGNS_BY_ID_SUCCESS,
    payload: {isLoadingBCA: false, campaignsId: campaignsId}
});


export const getAllCampaignsByIdFailled = () => ({
    type: types.GET_CAMPAIGNS_BY_ID_FAILLED,
    payload: {isLoadingBCA: false}
});


export const deleteCampaignsById = id => ({
    type: types.DELETE_CAMPAIGN,
    payload: {isLoadingDCA: true, id: id}
});

export const deleteCampaignsByIdSuccess = campaign_code => {

    toast.success(getIntlMsg(intl('fr'), "app.label.delete_campaign_success"), {
        position: toast.POSITION.TOP_RIGHT,
        campaign_code: campaign_code,
    });

    return ({
        type: types.DELETE_CAMPAIGN_SUCCESS,
        payload: {isLoadingDCA: false, campaign_code: campaign_code}
    });
}


export const deleteCampaignsByIdFailled = () => {

    toast.error(getIntlMsg(intl('fr'), "app.label.delete_campaign_failled"), {
        position: toast.POSITION.TOP_RIGHT
    });


    return ({
        type: types.DELETE_CAMPAIGN_FAILLED,
        payload: {isLoadingDCA: false}
    });
}




