import moment from "moment";

moment.locale('fr', {
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm'
    },
    calendar: {
        sameDay: '[Aujourd’hui à] LT',
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? 'AM' : 'PM';
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // Used to determine first week of the year.
    }
});

const message = {


    //NOTE  Actions labels
    "app.label.detail": "Détails",
    "app.label.edit": "Editer",
    "app.label.delete": "Supp.",
    "app.label.cancel": "Annuler",
    "app.label.save": "Sauvegarder",
    "app.label.signup": "S'inscrire",
    "app.label.signin": "Connecter",

    "app.label.french": "Francais",
    "app.label.english": "Anglais",


    //NOTE  Form Fields labels

  
    "app.label.code": "Code",
    "app.label.username": "Nom d'utilisateur",
    "app.label.language": "Langue",
    "app.label.password": "Mot de passe",
    "app.label.repeatPassword": "Mot de passe a nouveau",
    "app.label.name": "Nom",
    "app.label.description": "Description",
    "app.label.phone": "Téléphone",
    "app.label.email": "Couriel",
    "app.label.address": "Adresse",
    "app.label.firstname": "Prénom",
    "app.label.fullname": "Nom complet",
    "app.label.lastname": "Nom",
    "app.label.function": "Fonction",
    "app.label.department": "Département",
    "app.label.position": "Position",
    "app.label.gender": "Sexe",
    "app.label.bloodType": "Groupe sanguin",
    "app.label.startDate": "Date d'entrée",
    "app.label.foodEligible": "Accès à la nourriture ?",
    "app.label.male": "Masculin",
    "app.label.female": "Féminin",
    "app.label.employee.list": "Liste des employés",


    //NOTE Form fields placeholders
    "app.placeholder.code": "Inserez code",
    "app.placeholder.validation_date": "Inserez date de validation",
    "app.placeholder.notification_date": "Inserez date de notification",
    "app.placeholder.name": "Inserez un nom",
    "app.placeholder.firstname": "Inserez prénom",
    "app.placeholder.lastname": "Inserez nom",
    "app.placeholder.function": "Choisissez une fonction",
    "app.placeholder.department": "Choisissez un département",
    "app.placeholder.description": "Inserez une description",
    "app.placeholder.cin": "Inserez un CIN",
    "app.placeholder.nif": "Inserez un NIF",
    "app.placeholder.address": "Inserez adresse",
    "app.placeholder.phone": "Téléphone",
    "app.placeholder.message": "Tapez votre Message ici...",

    "app.title.setting": "Données personnelles",
    "app.label.statistic": "Statistiques",
    "app.label.project": "Projet",
    "app.label.setting": "Parametre",
    "app.label.person_id": "ID",
    "app.label.site": "Site",
    "app.label.nif_cin": "CIN/NIF",
    "app.label.saveSuccess": "Sauvegarder ave succes",
    "app.label.saveError": "Échoué",
    "app.label.home": "Accueil",
    "app.label.editTimesheet": "Editer votre feuille de temps",
    "app.label.allReadyProjectAssign": "Vous êtes déjà affecté à ce projet",

    "app.label.project_name": "Nom du projet",
    "app.label.percentage": "Pourcentage",
    "app.label.project_list": "Liste des projets",
    "app.label.project_name_empty": "Le nom du projet ne peut pas être vide",
    "app.label.percentage_empty": "Le pourcentage ne doit pas être vide et ne doit pas inférieur ou egal a 0",
    "app.label.add": "Ajouter",
    "app.label.firstTooltip": "Première page",
    "app.label.lastTooltip": "Dernière page",
    "app.label.previousTooltip": "page précédente",
    "app.label.nextTooltip": "page suivante",
    "app.label.labelRowsSelect": "lignes",
    "app.label.labelDisplayedRows": "{from}-{to} à {count}",
    "app.label.searchTooltip": "Chercher",
    "app.label.searchPlaceholder": "Chercher",
    "app.label.event": "Un événement",

    "app.label.next": "Suivant",
    "app.label.today": "Aujourd'hui",
    "app.label.back": "Retour",
    "app.label.month": "Mois",
    "app.label.day": "Jour",
    "app.label.week": "Semaine",
    "app.label.time": "Temps",
    "app.label.allday": "toute la journée",
    "app.label.more": "plus",
    "app.label.timesheet": "Feuille de temps",
    "app.label.project_assign": "Projet attribué",
    "app.label.project_percentage": "Projet attribué",
    "app.label.hours_number": "Nombre d'heures",
    "app.label.update": "Modifier",
    "app.label.welcome": "Bienvenue à Seedlee",
    "app.label.dashboard": "Tableau de bord",
    "app.label.iap_list": "Indicateurs d'alerte precoce (IAP)",

    "app.label.art_withdrawal_in_time": "Retrait à temps des ARVS",
    "app.label.art_retention_on_twelve_months": "Rétention sous TAR à 12 mois",
    "app.label.viral_load_test_performance": "Couverture virale apres 12 mois",
    "app.label.out_of_pharmacy_stock": "Rupture de stock de pharmacie",
    "app.label.viral_load_suppression": "Suppression virale à 12 mois",
    "app.label.appropriate_transition_to_second_line": "Passage approprie au TAR de deuxieme intention",
    "app.label.lost_patients": "Patients perdus de vue",
    "app.label.iap": "Indicateur",
    "app.label.iap.numerator": "Numérateur",
    "app.label.iap.denominator": "Dénominateur",
    "app.label.administration": "Administration",
    "app.label.service_name": "Nom du service",
    "app.label.created_date": "Date de la creation",
    "app.label.service_list": "Liste des services",
    "app.label.creator": "Créer par",
    "app.label.position_name": "Nom de la position",
    "app.label.position_list": "Liste des positions",
    "app.label.entreprise_list": "Liste des clients",
    "app.label.emptyDataSourceMessage": "Aucun enregistrement à afficher",
    "app.label.entr_name": "Nom du client",
    "app.label.entr_type": "Type de l'entrprise",
    "app.label.company": "Compagnie",
    "app.label.industry": "Industrie",
    "app.title.entreprise": "Entreprise",
    "app.label.entreprise": "Entreprise",
    "app.label.service": "Service",
    "app.label.person_position": "Titre",
    "app.label.create_person": "Creer une personne",
    "app.label.birthdate": "Date de naissance",
    "app.label.person_form": "Formulaire d'enregistrement d'une personne",
    "app.label.person_list": "Liste des personnels",
    "app.label.access": "Vous n'avez pas acces a ce systeme... veuillez contacter le service Administration",
    "app.label.create_cient": "Créer un client",
    "app.label.client_form": "Formulaire d'enregistrement du client",
    "app.label.username_or_email_exists": "Le nom utilisateur ou e-mail  existe",
    "app.label.phone_or_email_exists": "Le numero de téléphone ou e-mail existe",
    "app.label.client_list": "Liste des clients",
    "app.label.year": " An(s)",
    "app.label.client": "Client(s)",
    "app.label.upload_csv_file": "Télécharger le fichier csv",
    "app.label.campaign": "Campagne",
    "app.label.campaign_name": "Nom de la Campagne",
    "app.label.campaign_slogan": "Slogan de la Campagne",
    "app.label.start_date": "Date début",
    "app.label.end_date": "Date fin",
    "app.label.start": "Début",
    "app.label.end": "Fin",
    "app.label.campaing_programmation": "Programmation du jour",
    "app.label.campaing_period": "Période du",
    "app.label.campaign_phone_list": "Liste des numéros pour cette campaigne",
    "app.label.assign_campaign_to_agent": "Attribuer une campagne à un agent",
    "app.label.extension_number": "Numero de l'extension",
    "app.label.extension_group": "Groupe",
    "app.label.extension_status": "Statut",
    "app.label.extension_list": "Liste des extensions",
    "app.label.available": "Disponible",
    "app.label.unvailable": "indisponible",
    "app.label.agent_session": "Session des agents",
    "app.label.session_list": "Liste des sessions de ",
    // "app.label.signin": "Entrée",
    "app.label.signout": "Sortie",
    "app.label.list_of_agent": "Liste des agents",
    "app.label.search": "Rechercher",
    "app.label.number_qty": "Quantité de numero de téléphone",
    "app.label.campaign_list": "liste des campagnes",
    "app.label.assign": "Assigner",
    "app.label.person": "Gestion utilisateur",
    "app.label.assigned_campaign": "Campagne attribuée",
    "app.label.campaign_assign_list": "Liste des Campagnes attribuée",
    "app.label.client_menagement": "Gestion des clients",
    "app.label.agent_allready_Assign": "l'agent est déjà assigné à cette campagne",
    "app.label.phone_exists": "L'une des numéros de téléphone est déjà attribué a un agent pour cette campagne",
    "app.label.campaign_management": "Gestion des campagnes",
    "app.label.campaign_creation": "Creation des campagnes",
    "app.label.new_client": "Nouveau",
    "app.label.status": "Statut",
    "app.label.logout":"Deconnexion",
    "app.label.profile":"Profil",

    "app.label.ville": "Ville",
    "app.label.postal_code": "Code postal",
    "app.label.anonym_society": "Société anonyme",
    "app.label.individual_society": "Société individuelle",
    "app.label.entr_nature": "Nature Juridique",
    "app.label.entr_etablished": "Etablie depuis",
    "app.label.location": "Lieu",
    "app.label.entr_patent_number": "Numéro de patente ",

    "app.label.flat_rate_type": "Type de forfait ",
    "app.label.bank_officier_name": "Officier bancaire",
    "app.label.bank_account_number": "Numéro du compte",
    "app.label.bank_account_name": "Nom du compte",
    "app.label.bank_name": "Nom de la banque",
    "app.label.previous": "Précédent",
    "app.label.step": "Etape",
    "app.label.entr_logo": "Logo",
    "app.label.entr_infomation": "Informations sur le client",
    "app.label.person_contact": "Personne de contact",
    "app.label.rh_reponsable": "Responsable des Ressources Humaines :",
    "app.label.reponsable_marketing": "Responsable de marketing et vente :",
    "app.label.reponsable_comptability": "Responsable de la Comptabilité :",
    "app.label.dg": "Directeur Général :",
    "app.label.signature": "Signature",
    "app.label.date": "Date",
    "app.label.sign_name": "E-signature(du responsable) autorisée",
    "app.label.sign_fonction": "Fonction",
    "app.label.comment": "Commentaire",
    "app.label.abonne": "Abonné",
    "app.label.agent": "Agent",
    "app.label.client_number": "Numéro Client",
    "app.label.feedback": "Courriel interne",
    "app.label.service_type": "Type de Campagne",
    "app.label.error": "Type de service",
    "app.label.campaign_exists": "Le nom de cette campaigne existe",
    "app.label.campaing_phone_exists": "Le numero de telephone existe",
    "app.label.note": "Note",
    "app.label.noMatchValue": "Les mots de passe doivent correspondre",
    "app.label.change": "Changer",
    "app.label.repeatpassword": "Retaper le mot de passe",
    "app.label.document_name": "Nom du document",
    "app.label.prefix_list": "Liste des Préfix",
    "app.label.prefix_number": "Numéro du préfix",
    "app.label.emergency": "Urgence",
    "app.label.newpassword": "Nouveau mot de passe",
    "app.label.delete_campaign_success": "La campagne a été supprimée",
    "app.label.delete_campaign_failled": "Échec de la suppression de la campagne",
    "app.label.deleteSuccess": "Suppression avec succes",
    "app.label.field_required": "Ce champs est obligatoire",
    "app.label.title": "Titre",
    "app.label.client_type_service": "Type de Service",
    "app.label.client_phone": "Telephone du client",
    "app.label.client_name": "Nom complet du client",
    "app.label.entreprise_name": "Nom de l'entreprise",
    "app.label.experience_admin_link": "Liste des liens pour l'administration",
    "app.label.link": "Lien",
    "app.label.report_type": "Type",
    "app.label.u.feedback": "Feedback",
    "app.label.french": "Francais",
    "app.label.english": "Anglais",

   


};


export default message;