import React, { useEffect } from 'react'
import { app } from "../../constants/config";
import Loader from "../../common/loader";
import { FormattedMessage } from "react-intl";
import Dashboard from './dashboard'
import ReportCallByAgent from './callReportByAgent'
import ReporIncomingCall from './recordingCall'
import OutgoingCall from './outgoingRecordingCall'
import ServiceReport from './services';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
const shortcutsItems = [
    {
        label: 'This Week',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today];
        },
    },
    {
        label: 'Current Month',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'Next Month',
        getValue: () => {
            const today = dayjs();
            const startOfNextMonth = today.endOf('month').add(1, 'day');
            return [startOfNextMonth, startOfNextMonth.endOf('month')];
        },
    },
    { label: 'Reset', getValue: () => [null, null] },
];



const PbxReport = ({ session, isLoadingS, getAllPbxReport, getAllIncomingCall, getAllOutgoingCall, updateIncomingCall, incomingCalls, getAllEntreprises, getAllPrefixById }) => {


    const [value, setValue] = React.useState([
        dayjs(dayjs().startOf('week')),
        dayjs(dayjs().endOf('week')),
    ]);

    const [tab, setTab] = React.useState('dashboard');

    useEffect(() => {
        getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        getAllEntreprises()
    }, [])

    const handleChangeTab = t => {

        if (t === "incoming") {
            getAllIncomingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
            setTab(t)
        } else if (t === "outgoing") {
            getAllOutgoingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
            setTab(t)
        } else if (t === "agent") {
            getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
            setTab(t)
        } else {
            getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
            setTab(t)
        }

    }

    const handleCloseDatePicker = () => {

        if (tab === "incoming") {
            getAllIncomingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        } else if (tab === "outgoing") {
            getAllOutgoingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        } else if (tab === "agent") {
            getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        } else {
            getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        }

    }


    const handleDatePickerChange = (e) => {
        setValue(e)
    }

    const { entreprises, prefixIds } = useSelector(state => ({
        entreprises: state?.entrepriseReducer?.entreprises || [],
        prefixIds: state?.prefixReducer?.prefixIds || [],
    }));


    const [entreprise, setEntreprise] = React.useState('');
    const [extension, setExtension] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [openExtension, setOpenExtension] = React.useState(false);

    useEffect(() => {

        if (prefixIds.length > 0) {
            setExtension(prefixIds[0]?.prefix_number)

            if (tab === "incoming") {
                getAllIncomingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
            } else if (tab === "outgoing") {
                getAllOutgoingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
            } else if (tab === "agent") {
                getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
            } else {
                getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
            }

        }

    }, [prefixIds])

    const handleChange = (event) => {
        setEntreprise(event.target.value);
        getAllPrefixById(event.target.value)
    };

    const handleChangeExtension = (event) => {
        setExtension(event.target.value);

        if (tab === "incoming") {
            getAllIncomingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: event.target.value })
        } else if (tab === "outgoing") {
            getAllOutgoingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: event.target.value })
        } else if (tab === "agent") {
            getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: event.target.value })
        } else {
            getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: event.target.value })
        }


    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseExtension = () => {
        setOpenExtension(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleOpenExtension = () => {
        setOpenExtension(true);
    };

    return (
        <section className="admin-content">

            <div className="container-fluid bg-dark m-b-20">
                <div className="row  p-b-60 p-t-20">

                    <div className="col-md-6 text-white p-b-20">
                        <div className="media">

                            <div className="avatar mr-3  avatar-xl">

                                {session && isLoadingS == false ?
                                    <img
                                        src={session ? session.photo ? app.BASE_IMAGE_URL + '/models/images/' + session.photo + '?' + new Date() : "/assets/img/avatar.png" : "/assets/img/avatar.png"}
                                        alt="..." className="avatar-img rounded-circle" /> :
                                    <Loader />}
                            </div>
                            <div className="media-body m-auto">
                                <h5 className="mt-0">{session ? session.fullname : null}
                                </h5>
                                <div className="opacity-75">{session ? session.role : null}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 text-white my-auto text-md-right p-b-5">

                        <div className="">
                            <h2 className="opacity-75"><a href="#!">
                                <FormattedMessage id={`app.label.pbx_report`}
                                    defaultMessage={`Rapport du CRM`} /></a>
                            </h2>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid pull-up">
                <div className="row ">
                    <div className="col-lg-12">
                        <div className="card m-b-30">
                            <div className="card-header">
                                <div className="card-title"></div>
                            </div>
                            <div className="card-body">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">

                                    <li className="nav-item">
                                        <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                            href="#setting"
                                            role="tab"
                                            onClick={() => handleChangeTab('dasboard')}
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-graph">
                                                <FormattedMessage id={`app.label.dashboard`}
                                                    defaultMessage={`Label Name`} /></i></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#reportCallByAgent"
                                            onClick={() => handleChangeTab('agent')}
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-call-made">
                                                <FormattedMessage id={`app.label.call_report_by_agent`}
                                                    defaultMessage={`Rapport des appels par Agent`} />
                                            </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#incoming"
                                            role="tab"
                                            onClick={() => handleChangeTab('incoming')}
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-record-circle">
                                                <FormattedMessage
                                                    id={`app.label.recording_incoming_call`}
                                                    defaultMessage={`Enregistrements des appels entrants`} />
                                            </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#outgoing"
                                            role="tab"
                                            onClick={() => handleChangeTab('outgoing')}
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-record-circle">
                                                <FormattedMessage
                                                    id={`app.label.recording_incoming_call`}
                                                    defaultMessage={`Enregistrements des appels sortants`} />
                                            </i>
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#servicereport"
                                            onClick={() => handleChangeTab('servicereport')}
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-book">
                                                <FormattedMessage
                                                    id={`app.label.recording_incoming_call`}
                                                    defaultMessage={` Rapport par services`} />
                                            </i>
                                        </a>
                                    </li>


                                </ul>

                                <div className="tab-content" id="myTabContent1">
                                    {tab !== 'servicereport' &&
                                        <div className="row pt-4 pb-4">
                                            <div className="col-lg-3">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer
                                                        components={[
                                                            'DatePicker',
                                                            'TimePicker',
                                                            'DateTimePicker',
                                                            'DateRangePicker',
                                                        ]}

                                                    >
                                                        <DemoItem

                                                            component="DateRangePicker"


                                                        >
                                                            <DateRangePicker
                                                                localeText={{
                                                                    start: '',
                                                                    end: '',
                                                                }}
                                                                format='YYYY-MM-DD'
                                                                slotProps={{
                                                                    shortcuts: {
                                                                        items: shortcutsItems,
                                                                    },
                                                                    actionBar: { actions: [] },
                                                                    textField: ({ position }) => ({
                                                                        label: position === 'start' ? 'Start date' : 'end date',
                                                                    }),
                                                                }}
                                                                onOpen={() => {
                                                                    setTimeout(() => {
                                                                        const firstDiv = document.querySelector('.MuiDateRangeCalendar-root');
                                                                        if (firstDiv) {
                                                                            const childDivToRemove = firstDiv.querySelector(':first-child');
                                                                            if (childDivToRemove) {
                                                                                childDivToRemove.remove();
                                                                            }
                                                                        }
                                                                    }, 1000)
                                                                }}
                                                                onClose={() => {
                                                                    setTimeout(() => {
                                                                        const firstDiv = document.querySelector('.MuiDateRangeCalendar-root');
                                                                        if (firstDiv) {
                                                                            const childDivToRemove = firstDiv.querySelector(':first-child');
                                                                            if (childDivToRemove) {
                                                                                childDivToRemove.remove();
                                                                            }
                                                                        }
                                                                    }, 1000)
                                                                    handleCloseDatePicker()

                                                                }}
                                                                value={value}
                                                                onChange={(e) => handleDatePickerChange(e)}
                                                            />
                                                        </DemoItem>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className='col-lg-9'>
                                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Entreprise</InputLabel>
                                                    <Select
                                                        labelId="demo-controlled-open-select-label"
                                                        id="demo-controlled-open-select"
                                                        open={open}
                                                        onClose={handleClose}
                                                        onOpen={handleOpen}
                                                        value={entreprise}
                                                        label="Entreprise"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Aucune</em>
                                                        </MenuItem>
                                                        {entreprises?.map(v => <MenuItem value={v.id}>{v.entr_name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ m: 1, minWidth: 120 }} className='ext'>
                                                    <InputLabel id="demo-controlled-open-select-label">Extension</InputLabel>
                                                    <Select
                                                        labelId="demo-controlled-open-select-label"
                                                        id="demo-controlled-open-select"
                                                        open={openExtension}
                                                        onClose={handleCloseExtension}
                                                        onOpen={handleOpenExtension}
                                                        value={extension}
                                                        label="Extension"
                                                        onChange={handleChangeExtension}
                                                    >
                                                        {prefixIds?.map(v => <MenuItem value={v.prefix_number}>{v.campaign_name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    }

                                    <div className="tab-pane fade show active" id="setting" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <Dashboard getAllPbxReport={getAllPbxReport} />
                                    </div>

                                    <div className="tab-pane fade" id="reportCallByAgent" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <ReportCallByAgent getAllPbxReport={getAllPbxReport} />
                                    </div>

                                    <div className="tab-pane fade" id="incoming" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <ReporIncomingCall getAllIncomingCall={getAllIncomingCall ? getAllIncomingCall : []} language={session ? session.language : 'en'} updateIncomingCall={updateIncomingCall} incomingCalls={incomingCalls} />
                                    </div>
                                    <div className="tab-pane fade" id="outgoing" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <OutgoingCall getAllOutgoingCall={getAllOutgoingCall ? getAllOutgoingCall : []} language={session ? session.language : 'en'} />
                                    </div>
                                    <div className="tab-pane fade" id="servicereport" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <ServiceReport />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PbxReport