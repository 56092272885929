import React from "react";
import { app } from "../../../constants/config";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getCookie } from "react-use-cookie";


const SideBar = ({ session, entreprises, handleShowMessage, getEntreprise }) => {

    const { feedStats } = useSelector(state => ({
        feedStats: state?.settingReducer?.feedStats || [],
    }));


    const getstats = (name) => {

        let vv = 0;
        if (feedStats.length > 0) {
            feedStats.map(v => {
                if (v.entreprise_name == name) {
                    vv = v.qty;
                }
            })
            return vv;
        }

    }


    const handleSwitchEntrprise = (v,session) => {


        switch (session.entreprise_name) {

            case 'Seedlee':
                if (v.entr_name !== 'Seedlee') {
                    return (<a href="#"
                        className={`${v.entr_name === getEntreprise ? 'active' : ''} chat-sidebar-item btn-ghost btn-Entr`}
                        onClick={() => handleShowMessage(v)} data-target=".chat-sidebar" data-toggleclass="d-none">

                        <div className="w-100 text-truncate">
                            {v.entr_name}
                            <span className="badge badge-info">{getstats(v.entr_name)}</span>
                        </div>
                    </a>)
                } break;
            case 'Support Technique':
                if (v.entr_name !== 'Support Technique') {
                    return (<a href="#"
                        className={`${v.entr_name === getEntreprise ? 'active' : ''} chat-sidebar-item btn-ghost btn-Entr`}
                        onClick={() => handleShowMessage(v)} data-target=".chat-sidebar" data-toggleclass="d-none">

                        <div className="w-100 text-truncate">
                            {v.entr_name}
                            <span className="badge badge-info">{getstats(v.entr_name)}</span>
                        </div>
                    </a>)
                } break;
            default:
                if (v.entr_name !== 'Support Technique' && v.entr_name !== 'Seedlee') {
                    return (<a href="#"
                        className={`${v.entr_name === getEntreprise ? 'active' : ''} chat-sidebar-item btn-ghost btn-Entr`}
                        onClick={() => handleShowMessage(v)} data-target=".chat-sidebar" data-toggleclass="d-none">

                        <div className="w-100 text-truncate">
                            {v.entr_name}
                            <span className="badge badge-info">{getstats(v.entr_name)}</span>
                        </div>
                    </a>)
                } break;


        }


    }


    return (
        <div className="col d-lg-block p-all-0 text-white   chat-sidebar">
            <div className="panel">
                <div className="panel-header p-all-15 chat-sidebar-header">
                    <div className="media">
                        <div className="d-inline-block m-r-10 align-middle">

                            <div className="avatar avatar avatar-online">
                                {session ?
                                    <img
                                        src={session ? session.photo ? app.BASE_IMAGE_URL + '/models/images/' + session.photo + '?' + new Date() : "/assets/img/avatar.png" : "/assets/img/avatar.png"}
                                        alt="..." class="avatar-img rounded-circle" /> :
                                    <span
                                        className="avatar-title rounded-circle  bg-white-translucent"> E </span>}
                            </div>
                        </div>
                        <div className="media-body my-auto">
                            <p className="font-secondary m-b-0">{session?.fullname} </p>
                            <p className="text-overline">
                                <code
                                    style={{ fontSize: '10px' }}>{session ? session.entreprise_name : null}</code><br /> {session ? session.position_name : null}
                            </p>
                        </div>

                        {getEntreprise !== null ? <button type="button" className="close d-inline-block d-lg-none light"
                            data-target=".chat-sidebar" data-toggleclass="d-none">
                            <span aria-hidden="true">×</span>
                        </button> : null}

                    </div>

                </div>
                <div className=".panel-body p-t-10  border-white">
                    <a href="#" className=" chat-sidebar-item btn-ghost">
                        <div className="w-100 text-truncate">
                            {/*<FormattedMessage id={'app.label.allFeedback'} defaultMessage={'All feedBack'}/>*/}
                        </div>
                    </a>

                    <div className="m-t-20 chat-sidebar-item clearfix border-bottom border-white">
                        <FormattedMessage id={'app.label.feedback'} defaultMessage={'Feedback'} /> <a href="#"
                            className="float-right">
                            <i className="mdi mdi-plus-circle-outline mdi-18px"></i>
                        </a>
                    </div>

                    {entreprises.map(v => handleSwitchEntrprise(v,session))}

                </div>


            </div>
        </div>
    )
}

export default SideBar;