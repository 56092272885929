import types from "../actions/type"

const defaultState = {
    isLoading: false,
    message: null,
    ps: null,
    pid: null,
    sessionId: null,
    ups: null,
    mups: null,
    pesrons: [],
    personInfo: null,
    cms: null,
    pass: null,
    user: null,
    isLoadingUS: false,
    users: []
}

const personReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_USER_SESSION_SUCCESS:
            return {...state, sessionId: action.payload.session};

        case types.PERSON_RESET_STATE:
            return {...state, message: action.payload.message, mups: null};

        case types.PERSON_RESET_STATE_SUCCES:
            return {
                ...state,
                message: action.payload.message,
                isLoading: true,
                personInfo: null
            };

        case types.GET_PERSON_BY_ID:
            return {
                ...state,
                pid: action.payload.pid,
                isLoading: action.payload.isLoading
            };

        case types.GET_PERSON_BY_ID_SUCCES:
            return {
                ...state,
                message: action.payload.message,
                isLoading: action.payload.isLoading,
                ps: action.payload.ps
            };


        case types.GET_PERSON_BY_ID_FAILLED:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                message: action.payload.message
            };


        case types.UPDATE_PERSON_BY_ID:
            return {
                ...state,
                ups: action.payload.ups,
                isLoading: action.payload.isLoading
            };

        case types.UPDATE_USER_BY_ID:
            return {
                ...state,
                user: action.payload.user,
                isLoading: action.payload.isLoading
            };

        case types.CHANGE_PASSWORD:
            return {
                ...state,
                pass: action.payload.pass,
                isLoading: action.payload.isLoading
            };

        case types.UPDATE_PERSON_BY_ID_SUCCESS:
        case types.UPDATE_USER_BY_ID_SUCCESS:
        case types.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                mups: action.payload.mups,
                cms: action.payload.cms,
                isLoading: action.payload.isLoading,
            };


        case types.UPDATE_PERSON_BY_ID_FAILLED:
        case types.CHANGE_PASSWORD_FAILLED:
        case types.UPDATE_USER_BY_ID_FAILLED:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                message: action.payload.message
            };

        case types.GET_ALL_PERSONS:
            return {
                ...state,
                isLoading: action.payload.isLoading
            };

        case types.GET_ALL_PERSONS_SUCCESS:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                persons: action.payload.persons
            };

        case types.GET_ALL_PERSONS_FAILLED:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            };

        case types.GET_PERSON_INFO:
            return {
                ...state,
                personInfo: action.payload.personInfo,
            };

        case types.GET_PERSON_INFO_SUCCESS:
            return {
                ...state,
                personInfo: action.payload.personInfo,
            };


        case types.CHANGE_USER_STATUS:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                user: action.payload.user,
            };

        case types.CHANGE_USER_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            };

        case types.CHANGE_USER_STATUS_FAILLED:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                msuser: action.payload.msuser,
            };


        case types.GET_USER_BY_ID:
            return {
                ...state,
                idU: action.payload.idU,
                isLoadingUS: action.payload.isLoadingUS
            };

        case types.GET_USER_BY_ID_SUCCESS:
            return {
                ...state,
                isLoadingUS: action.payload.isLoadingUS,
                users: action.payload.users
            };


        case types.GET_USER_BY_ID_FAILLED:
            return {
                ...state,
                isLoadingUS: action.payload.isLoadingUS,
            };


        default:
            return state

    }
}


export default personReducer
