import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import Form from "./form";

const CalendarModal = ({setShow, show, handleHideModal, selectedData, cInfo, createCalendarEvent, session,
     ms, calendarEventChangeStatus, getAllCalendarEvents,updateCalendarEvent }) => {


    const [time, setTime] = useState(null);

    console.log("---------------", session)



    const handleSubmitEvent = values => {

        if(selectedData.id){
            values.client_entreprise = values?.client_entreprise || "";
            values.editor = session.username;
            values.title = values.title_name;
            values.modified_at =moment(new Date()).format('YYYY-MM-DD');
            updateCalendarEvent(values)
        }else{
            values.client_entreprise = values?.client_entreprise || "";
            values.creator = session.id;
            values.title = values.title_name;
            values.created_at =moment(selectedData.start).format('YYYY-MM-DD');
            createCalendarEvent(values)
        }
      
    }

    const handleSubmitSuccess = () => {
        calendarEventChangeStatus();
        getAllCalendarEvents();
        setShow(false);
    }

    if (ms === "success") handleSubmitSuccess()
    return (
        <React.Fragment>
            <Modal
                size="lg"
                show={show}
                onHide={() => handleHideModal()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <span className="text-center ml-0"><i
                            className="mdi mdi-details">
                            {time}</i>  {selectedData?.created_at} - {selectedData?.title_name}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form selectedData={selectedData} onSubmit={handleSubmitEvent} cInfo={cInfo} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default CalendarModal;