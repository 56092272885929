import {put, take, call, takeLatest} from "redux-saga/effects";
import  * as actions from "../actions/creator/person";
import  * as sActions from "../actions/creator/setting";
import types from "../actions/type";
import * as api from "../services/person";


export const handleResetStatePerson = function*() {
    yield put(actions.resetStatePersonSuccess())
};


export const handleGetAllPersons = function*() {

    try {

        const getPs = yield call(api.getAllPersons);
        if (getPs.data.length > 0) {
            yield put(actions.getAllPersonsSuccess(getPs.data))
        } else {
            yield put(actions.getAllPersonsFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPersonsFailled("error"))
    }
};


export const handleGetPersonById = function*(action) {

    try {
        const {pid} = action.payload;
        const getPs = yield call(api.getPersonInfo, pid);
        if (getPs.data.message === "success") {
            yield put(actions.getPersonByIdSuccess(getPs.data))
        } else {
            yield put(actions.getPersonByIdFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getPersonByIdFailled("error"))
    }
};


export const handleUpdatePersonById = function*(action) {
    const {ups} = action.payload;
    try {
        const upPs = yield call(api.updatePersonInfo, ups);
        if (upPs.data.message === "success") {
            yield put(actions.updatePersonByIdSuccess(ups.language))
            // yield put(sActions.getUserSessionInfo(upPs.data.id))
            yield put(actions.getAllPersons());
        } else {
            yield put(actions.updatePersonByIdFailled(ups.language))
        }
    } catch (error) {
        yield put(actions.updatePersonByIdFailled(ups.language))
    }
};


export const handleChangeUserStatus = function*(action) {
    const {user} = action.payload;
    try {
        const upPs = yield call(api.changeUserStatus, user);
        if (upPs.data.message === "success") {
            yield put(actions.changeUserStatusSuccess(user.language))
            yield put(actions.getAllPersons());
        } else {
            yield put(actions.changeUserStatusFailled({locale: user.language, msuser: upPs.data.message}))
        }
    } catch (error) {
        yield put(actions.changeUserStatusFailled({locale: user.language, msuser: 'error'}))
    }
};


export const handlegetPersonInfo = function*(action) {
    const {personInfo} = action.payload;

    yield put(actions.getPersonInfoSuccess(personInfo))
};


export const handleUserById = function*(action) {

    try {
        const {idU} = action.payload;
        const getPs = yield call(api.getPersonInfo, idU);
        if (getPs.data.message === "success") {
            yield put(actions.getUserByIdSuccess(getPs.data))
        } else {
            yield put(actions.getUserByIdFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getUserByIdFailled("error"))
    }
};


export const handleUpdateUserById = function*(action) {
    const {user} = action.payload;
    try {
        const upPs = yield call(api.updatePersonInfo, user);
        if (upPs.data.message === "success") {
            yield put(actions.updateUserByIdSuccess(user.language))
            yield put(sActions.getUserSessionInfo(upPs.data.id))
        } else {
            yield put(actions.updateUserByIdFailled(user.language))
        }
    } catch (error) {
        yield put(actions.updateUserByIdFailled(user.language))
    }
};

export const handleChangePassword = function*(action) {
    const {pass} = action.payload;
    try {
        const upPs = yield call(api.changePassword, pass);
        if (upPs.data.message === "success") {
            yield put(actions.changePasswordSuccess(pass.language))
            // yield put(sActions.getUserSessionInfo(upPs.data.id))
        } else {
            yield put(actions.changePasswordFailled(pass.language))
        }
    } catch (error) {
        yield put(actions.changePasswordFailled(pass.language))
    }
};


export const personSaga = [
    takeLatest(types.GET_PERSON_BY_ID, handleGetPersonById),
    takeLatest(types.PERSON_RESET_STATE, handleResetStatePerson),
    takeLatest(types.UPDATE_PERSON_BY_ID, handleUpdatePersonById),
    takeLatest(types.GET_ALL_PERSONS, handleGetAllPersons),
    takeLatest(types.GET_PERSON_INFO, handlegetPersonInfo),
    takeLatest(types.CHANGE_USER_STATUS, handleChangeUserStatus),
    takeLatest(types.GET_USER_BY_ID, handleUserById),
    takeLatest(types.UPDATE_USER_BY_ID, handleUpdateUserById),
    takeLatest(types.CHANGE_PASSWORD, handleChangePassword),
];
