import axios from "axios"
import {app} from '../constants/config'
import feedback from "../reducers/feedback";


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 700000,
});

export const getFeedbacks = id => {
    return instance.get(`/feedback/read_single/index.php?sender_id=${id.sender_id}&person_id=${id.person_id}`)
};

export const createFeedback = feedback => {
    return instance.post(`/feedback/create/index.php`,feedback)
};


export const updateFeedback = feedId => {
    return instance.get(`/feedback/update/index.php?receive_id=${feedId?.receive_id}&sender_id=${feedId?.sender_id}`)
};


