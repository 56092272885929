/**
 * Created by louidorpc on 1/25/21.
 */
/**
 * Created by louidorpc on 1/9/21.
 */
/**
 * Created by louidorpc on 1/9/21.
 */
import React, { useEffect } from 'react'
import SoftPhone from 'react-softphone'
import { WebSocketInterface } from 'jssip';
import { useSelector } from 'react-redux';


const url = (ent_name) => {
    if (ent_name === "Chatelain Cargo Services S.A") {
        return "https://app.smartsheet.com/b/publish?EQBCT=e2901dcf9baf4d80a68723fd4ab08b07";
    } else if (ent_name === "Office Star S.A") {
        return ""
    } else if (ent_name === "Valerio Canez ") {
        return ""
    }
    else if (ent_name === "Caribe Mortors") {
        return ""
    }
    else if (ent_name === "Chatelain Tours and Travel Services") {
        return ""
    } else if (ent_name === "A&B HARDWARE ") {
        return "https://app.smartsheet.com/b/publish?EQBCT=e2901dcf9baf4d80a68723fd4ab08b07"
    } else if (ent_name === "Euroceram Plus") {
        return ""
    } else if (ent_name === "Euroceram Plus") {
        return ""
    } else if (ent_name === "Visa Lodge ") {
        return ""
    } else {
        return ""
    }

}

const FollowupCLient = props => {

    const { session } = props;
    console.log("____________________________________:::::::::::::::::::::", session ? session.entreprise_name : null);

    //<IFRAME WIDTH=1000 HEIGHT=700 FRAMEBORDER=0 SRC="https://app.smartsheet.com/b/publish?EQBCT=162d375f8aad4ddea8a06567869e015d"></IFRAME>
    //https://app.smartsheet.com/b/publish?EQBCT=162d375f8aad4ddea8\a06567869e015d

    const { getLinkByEntreprise } = props;


    useEffect(() => {
        getLinkByEntreprise(session?.entreprise_id, 'REPORT_FOLLOWUP_CLIENT')
    }, [])

    const { linkByEntreprise } = useSelector(state => ({
        linkByEntreprise: state?.linkReducer?.linkByEntreprise || null,
    }));


    return (
        linkByEntreprise?.link ? <div className='col-md-12 pt-3'><embed src={linkByEntreprise ? linkByEntreprise?.link : null}
            width="100%"
            style={{ height: 'calc(100vh - 70px)' }}></embed> </div> : <div className='col-md-12'>
            <h1 className="text-center"> Aucun rapport</h1>
        </div>
    );
}


export default FollowupCLient;