import { put, call, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/creator/clientPbx";
import types from "../actions/type";
import * as api from "../services/pbx";
import { getAllIncomingCall } from "../services/pbx";


export const handleGetAllPbxReportClient = function* (action) {
    const { criteria } = action.payload;
    try {
        const getSv = yield call(api.getAllPbxReportClient, criteria);
        if (getSv.data?.reports?.length > 0) {
            yield put(actions.getAllPbxReportClientSuccess(getSv.data))
        } else {
            yield put(actions.getAllPbxReportClientFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPbxReportClientFailled("error"))
    }
};


export const handleGetAllIncomingCallsClient = function* (action) {
    const { criteria } = action.payload;
    try {
        const getSv = yield call(api.getAllIncomingCallClient, criteria);
        if (getSv?.data?.length > 0) {
            yield put(actions.getAllIncomingCallCLientSuccess(getSv.data))
        } else {
            yield put(actions.getAllIncomingCallCLientFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllIncomingCallCLientFailled("error"))
    }
};

export const handleGetAllOutgoingCallsClient = function* (action) {
    const { criteria } = action.payload;
    try {
        const getSv = yield call(api.getAllOuntgoingCallClient, criteria);
        if (getSv?.data?.length > 0) {
            yield put(actions.getAllOutgoingCallClientSuccess(getSv.data))
        } else {
            yield put(actions.getAllOutgoingCallClientFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllOutgoingCallClientFailled("error"))
    }
};

export const clientPbxSaga = [
    takeLatest(types.CLIENT_GET_ALL_PBX_REPORTS, handleGetAllPbxReportClient),
    takeLatest(types.CLIENT_GET_ALL_INCOMING_CALL_REPORTS, handleGetAllIncomingCallsClient),
    takeLatest(types.CLIENT_GET_ALL_OUTGOING_CALL_REPORTS, handleGetAllOutgoingCallsClient),
];
