import React, {useEffect, useState} from 'react'
import MessageItem from "./messageItem";
import SideBar from "./sideBar";
import {useSelector} from "react-redux";
import Pusher from "pusher-js";
import useCookie, {getCookie, setCookie} from 'react-use-cookie';
import {app} from "../../../constants/config";
import $ from "jquery";


const FeedbackAdmin = props => {

    const {session, getAllEntreprises, getFeedback, createFeedback, resetFeedback,updateFeedback} = props;

    const [userToken, setUserToken] = useCookie('token', '');

    const {entreprises, mfeed} = useSelector(state => ({
        entreprises: state?.entrepriseReducer?.entreprises || [],
        mfeed: state.feedbackReducer.mfeed,
    }));


    const [showMessage, setShowMessage] = useState({
        show: false,
        item: null,
        getEntreprise:null
    })


    const handleShowMessage = item => {
        setShowMessage({show: true, item: item,getEntreprise:item.entr_name});
        updateFeedback({receive_id:session?.entreprise_id,sender_id:item?.id})
        setUserToken(JSON.stringify(item));
        // getFeedback(item?.id);
        getFeedback({sender_id:session?.entreprise_id , person_id: item?.id});
        setTimeout(() => scrollBottom(item), 1000)
        setCookie('notify', null)
        


    }

    const {show, item,getEntreprise} = showMessage;

    useEffect(() => {
        getAllEntreprises();
        // setCookie('token', null)
        // Notification()

    }, [])

    // const pusher = new Pusher(`a6ce790a9b4ff98dc66f`, {
    //     cluster: 'mt1',
    // });
    //
    // const pusher = new Pusher(`50f6113844234e51aeb0`, {
    //     cluster: 'mt1',
    // });

    const notify = getCookie('notify') ? JSON.parse(getCookie('notify')) : null;
    console.log("notify=====================",notify)
    if (notify) {
        handleShowMessage(notify)
    }

    const scrollBottom = (item) => {
        let height = 0;
        if(show) {
            updateFeedback({receive_id:session?.entreprise_id,sender_id:item?.id})
        }
        $('.chat-window-body div').each(function (i, value) {
            height += parseInt($(this).height());
        });

        height += '';

        $('.chat-window-body').animate({scrollTop: height});

    }


    return (
        <section className="admin-content">
            <div className="container-fluid ">
                <div className="row white">
               
                    <SideBar session={session} entreprises={entreprises} handleShowMessage={handleShowMessage} getEntreprise={getEntreprise}/>
                    <div className="col p-all-0">
                        {show ?

                            <MessageItem show={show} item={item} session={session} createFeedback={createFeedback}
                                         getFeedback={getFeedback} resetFeedback={resetFeedback}
                                         scrollBottom={scrollBottom}/>

                            : null}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeedbackAdmin;