import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const Signin = sn => {
    console.info(sn, 'new message');
    return instance.get(`/users/login/index.php?username=${sn.username}&password=${sn.password}`)
};


export const SigninAgent = sna => {
    console.info(sna, 'new message');
    return instance.get(`/users/loginAgent/index.php?username=${sna.username}&password=${sna.password}&extension_number=${sna.extension_number}`)
};

export const Signup = sp => {
    console.info(sp, 'new message');
    return instance.post(`/users/create/index.php`, sp)
};

export const ResetPassword = sna => {
    console.info(sna, 'new message');
    return instance.get(`/users/resetPassword/index.php?email=${sna.email}&password=${sna.password}`)
};
