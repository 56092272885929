import * as React from "react";
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux"
import { IntlProvider } from "react-intl"
import { bindActionCreators } from "redux"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { adminRoutes as routes } from "./constants/router";
import messages from "./constants/messages"
import { AsideBase } from "./components/layout/aside/AsideBase";
import { HeaderBase } from "./components/layout/header/HeaderBase";
import actions from './actions/creator';
import createHistory from 'history/createBrowserHistory';
import 'react-chat-widget/lib/styles.css';
import Pusher from 'pusher-js';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from "@material-ui/core";
const InactivityTimeout = 1 * 60 * 1000; // 7 minutes in milliseconds

export const GlobalContext = React.createContext({});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const firebaseConfig = {
    apiKey: "AIzaSyB7EOo2L-4m67o2fJZ0C5wrQxmexgCUgBM",
    authDomain: "seedlee-feedback.firebaseapp.com",
    projectId: "seedlee-feedback",
    storageBucket: "seedlee-feedback.appspot.com",
    messagingSenderId: "930747218951",
    appId: "1:930747218951:web:8f71d25ce346af7cb5fea2",
    measurementId: "G-YTRJ5EWJGZ"
};

const BaseLayout = props => {

    const { infouser } = props;


    const pusher = new Pusher(`50f6113844234e51aeb0`, {
        cluster: 'mt1',
    });

    const pusherEvent = new Pusher(`ea8191fd448d9cc72861`, {
        cluster: 'us2',
    });

    const [lastTransactionTime, setLastTransactionTime] = useState(null);

    // Function to reset the inactivity timer
    const resetInactivityTimer = () => {
        setLastTransactionTime(Date.now());
    };

    // Function to handle mouse click event
    const handleMouseClick = (e) => {
        handleTransaction()
        console.log("======***********=========", e)
    };

    // Function to handle key press event
    const handleKeyDown = (e) => {
        handleTransaction()
        console.log("===============", e)
    };

    // Function to handle mouse move event
    const handleMouseMove = (e) => {
        handleTransaction()
    };

    // Effect to monitor the inactivity timer
    // useEffect(() => {
    //     const checkInactivity = async () => {
    //         if (lastTransactionTime !== null) {
    //             const currentTime = Date.now();
    //             if (currentTime - lastTransactionTime > InactivityTimeout) {
    //                 // Automatically log out the user here due to inactivity
    //                 if (infouser) {
    //                     handleClickOpen()
    //                 }
    //             }
    //         }
    //     };

    //     const interval = setInterval(checkInactivity, 1000); // Check inactivity every second
    //     return () => clearInterval(interval); // Clean up the interval on component unmount

    // }, [lastTransactionTime, infouser]); // Re-run the effect whenever the lastTransactionTime changes

    // Function to handle a transaction and reset the inactivity timer
    const handleTransaction = () => {
        resetInactivityTimer(); // Reset the inactivity timer by updating the last transaction time
    };


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getUserSessionInfo(infouser.person_id))
        dispatch(actions.getFeedbackStatistic(infouser?.entreprise_id))

        const channel = pusher.subscribe('my-channel');
        const channelEvent = pusherEvent.subscribe('my-channel');
        let audio = new Audio("/assets/audio.mp3");

        channel.bind('my-event', data => {
            const receive_id = JSON.parse(JSON.stringify(data.message));
            if (receive_id?.receive_id === infouser?.entreprise_id) {
                dispatch(actions.getFeedbackStatistic(infouser?.entreprise_id))
                // audio.play()
            }
        })



        channelEvent.bind('my-event', data => {
            const event = JSON.parse(JSON.stringify(data.message));

            if (infouser.person_type === null && infouser.position_name === "Administrateur" && event.type === "create") {
                audio.play()
                dispatch(actions.getAllCalendarEvents())

            }
            if (infouser.person_type === null && infouser.position_name === "Superviseur" && event.type === "create") {
                audio.play()
                dispatch(actions.getAllCalendarEvents())

            }
            if (infouser.person_type === null && infouser.position_name === "Agent") {
                if (event.type === "publish" && event.data.creator === infouser.person_id) {
                    audio.play()
                    dispatch(actions.getAllCalendarEventsByAgent(infouser?.person_id))

                }
            }
            if (infouser?.position_name === "Agent" && event?.type === "modify") {

                if (event?.data?.user_id === infouser?.person_id) {
                    audio.play()
                    dispatch(actions.getAllCalendarEventsByAgent(infouser?.person_id))

                }
            }
            if (infouser?.person_type === "client" && event.type === "publish" && infouser?.entreprise_id === event?.data?.entreprise) {
                audio.play()
                dispatch(actions.getAllCalendarEventsByEntreprise(infouser?.entreprise_id))


            }
            if (infouser?.person_type === "client" && event.type === "modify" && infouser?.entreprise_id === event?.data?.entreprise) {
                audio.play()
                dispatch(actions.getAllCalendarEventsByEntreprise(infouser?.entreprise_id))


            }

            if (infouser?.person_type === null && infouser?.position_name === "Superviseur" && event?.data?.view_type === "supervisor") {
                // audio.play()
                dispatch(actions.getAllCalendarEvents())

            }
            if (infouser?.person_type === null && infouser?.position_name === "Administrateur" && event?.data?.view_type === "admin" && event?.type === "view") {
                // audio.play()
                // alert()
                dispatch(actions.getAllCalendarEvents())

            } if (infouser?.person_type === "client" && event?.data?.view_type === "client" && infouser?.entreprise_id === event?.data?.entreprise) {
                // audio.play()
                dispatch(actions.getAllCalendarEventsByEntreprise(infouser?.entreprise_id))


            } if (infouser?.position_name === "Agent" && event?.data?.view_type === "agent" && event?.data?.user_id === infouser?.person_id && event?.type === "view") {
                // audio.play()
                dispatch(actions.getAllCalendarEventsByAgent(infouser?.person_id))
            }
        })




    }, [])


    const { locale, sn, session, message } = props;

    const history = createHistory();
    let path = history.location.pathname;


    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);


    const [open, setOpen] = useState(false);

    const handleClickOpen = (st) => {

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };





    
    return (
        <GlobalContext.Provider value={{ pusher }}>
            <IntlProvider locale={session ? session.language : 'en'}
                messages={messages[session ? session.language : 'en']}>
                <Router>
                    <div
                        // onClick={handleMouseClick}
                        // onKeyDown={handleKeyDown}
                        // onMouseMove={handleMouseMove}
                        style={{ width: '100%', height: '100vh' }}
                        tabIndex={0}>
                        <AsideBase userinfo={infouser} path={path} />

                        <main class="admin-main">
                            <HeaderBase userinfo={infouser}
                                message={message} history={history} />
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route key={index} path={route.path} exact={route.exact}
                                        component={route.navbar} />
                                ))}
                            </Switch>

                            <Dialog
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Transition}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogContent>
                                    <div class="">
                                        <div class="text-center p-b-20 p-t-40 pull-up-sm">
                                            <div class="avatar avatar-lg avatar-offline">
                                                <img src="assets/img/users/user-3.jpg" alt="..." class="avatar-img rounded-circle" />
                                            </div>
                                        </div>
                                        <h3 class="text-center">Lock </h3>
                                        <p class="text-center opacity-75">Please Login to continue </p>
                                        <form action="modal.html">
                                            <div class="form-group">

                                                <div class="input-group input-group-flush mb-3">
                                                    <input type="password" class="form-control form-control-prepended" />
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <span class=" mdi mdi-key "></span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="form-group">
                                                <button data-dismiss="modal" class="btn text-uppercase btn-block  btn-primary">
                                                    Login
                                                </button>
                                            </div>
                                        </form>


                                    </div>

                                </DialogContent>


                            </Dialog>
                        </main>
                    </div>
                </Router>
            </IntlProvider>
        </GlobalContext.Provider>
    );
}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = ({ settingReducer, loginReducer }) => ({
    locale: settingReducer.locale,
    session: settingReducer.session,
    message: settingReducer.message,
    sn: loginReducer
})

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout)


