import * as validator from '../../../../constants/validator'
import types from '../../../../actions/type'


export const validate = values => {
    const errors = {}

    //NOTE when calling validateString the params are: (label, value, min, max, required=false)
    errors.code = validator.validateString('code', values.code, 3, 50, true)
    errors.entr_name = validator.validateString('entr_name', values.entr_name, 3, 30, true)
    errors.lastname = validator.validateString('lastname', values.lastname, 3, 30, true)
    errors.email = validator.validateEmail('email', values.email, true)

    return errors
}


export const asyncValidate = (values, dispatch, {status}) => {


    dispatch({type: types.IS_ENTITY_EXIST_REQUEST, payload: values.firstname});
    console.log(status, 'From state')


//   return sleep(1000).then(() => {
//     // simulate server latency
//     if (status) {
//       throw { firstname: "app.label.entity_type_exists" };
//     }
//   });
}


export const normalizeNif = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 3) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 6) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
        }
        if (onlyNums.length === 9) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6) + '-'
        }
    }

    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    if (onlyNums.length <= 9) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6)
    }

    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 9) + '-' + onlyNums.slice(9, 10)
}


export const normalizePhone = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 4) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 8) {
            return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8)
        }

    }

    if (onlyNums.length <= 4) {
        return onlyNums
    }
    if (onlyNums.length <= 8) {
        return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8)
    }

    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8);
}

export const normalizeCin = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 10) {
        return onlyNums.slice(0, 10)
    } else {


        if (!previousValue || value.length > previousValue.length) {
            // typing forward
            if (onlyNums.length === 2) {
                return onlyNums + '-'
            }
            if (onlyNums.length === 4) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2) + '-'
            }
            if (onlyNums.length === 6) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4) + '-'
            }
            if (onlyNums.length === 10) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-'
            }
            if (onlyNums.length === 12) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12) + '-'
            }
        }

        if (onlyNums.length <= 2) {
            return onlyNums
        }
        if (onlyNums.length <= 4) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2)
        }
        if (onlyNums.length <= 6) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4)
        }
        if (onlyNums.length <= 10) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10)
        }
        if (onlyNums.length <= 12) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12)
        }

        return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12) + '-' + onlyNums.slice(12, 17)
    }
}
