import types from "../actions/type"

const defaultState = {
    isLoadingDr: false,
    ms: null,
    daysreport: null,
    upo: null,
    mpo: null,
    id: null,
    criteria: null,
    daysreports: [],
    clientDaysreports: [],
}

const daysreportReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_ALL_DAYS_REPORTS:
            return {
                ...state,
                isLoadingDr: action.payload.isLoadingDr,
                criteria: action.payload.criteria,
                daysreports: []
            };

        case types.RESET_DAYS_REPORT:
            return {
                ...state,
                ms: '',
            };

        case types.RESET_DAYS_REPORT_SUCCESS:
            return {
                ...state,
                ms: '',
            };

        case types.GET_ALL_DAYS_REPORTS_BY_CLIENT:
            return {
                ...state,
                isLoadingDr: action.payload.isLoadingDr,
                criteria: action.payload.criteria,
                clientDaysreports:[]
            };

        case types.GET_ALL_DAYS_REPORTS_SUCCESS:
            return {
                ...state,
                isLoadingDr: action.payload.isLoadingDr,
                daysreports: action.payload.daysreports
            };
        case types.GET_ALL_DAYS_REPORTS_BY_CLIENT_SUCCESS:
            return {
                ...state,
                isLoadingDr: action.payload.isLoadingDr,
                clientDaysreports: action.payload.clientDaysreports
            };

        case types.GET_ALL_DAYS_REPORTS_FAILED:
        case types.GET_ALL_DAYS_REPORTS_BY_CLIENT_FAILED:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingDr: action.payload.isLoadingDr
            };


        case types.CREATE_DAYS_REPORT:
        case types.EDIT_DAYS_REPORT:
            return {
                ...state,
                isLoadingDr: action.payload.isLoadingDr,
                daysreport: action.payload.daysreport
            };

        case types.DELETE_DAYS_REPORT:
            return {
                ...state,
                daysreport: action.payload.daysreport
            };



        case types.CREATE_DAYS_REPORT_SUCCESS:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingDr: action.payload.isLoadingDr,
                daysreport: null,
                daysreports: [],

            };

        case types.DELETE_DAYS_REPORT_SUCCESS:
            return {
                ...state,
                daysreport: null
            };

        case types.DELETE_DAYS_REPORT_FAILED:
            return {
                ...state,
            };

        case types.EDIT_DAYS_REPORT_SUCCESS:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingDr: action.payload.isLoadingDr,
                daysreport: null
            };

        case types.CREATE_DAYS_REPORT_FAILED:
        case types.EDIT_DAYS_REPORT_FAILED:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingDr: action.payload.isLoadingDr
            };

        default:
            return state

    }
}


export default daysreportReducer