/**
 * Created by louidorpc on 11/4/20.
 */
/**
 * Created by louidorpc on 8/4/20.
 */
import  React from 'react'
import Campaign from '../../components/campaign'


const HomeCampaign = props => {

    const {
        sr, ag, cl, cp, actions:{getAllAssignedCampaignByAgent, getAllCampaigns, getAllPhoneCampaigns, getAllPersons, createAssignCampain},
        person:{persons}
    }=props;

    if (sr.session !== null)
        return (
            <Campaign {...sr} {...ag} {...cp} {...cl}

                      getAllCampaigns={getAllCampaigns}
                      getAllPhoneCampaigns={getAllPhoneCampaigns}
                      getAllPersons={getAllPersons}
                      persons={persons}
                      createAssignCampain={createAssignCampain}
                      getAllAssignedCampaignByAgent={getAllAssignedCampaignByAgent}
            />
        )
    else return <div></div>
}


export default HomeCampaign;