/**
 * Created by louidorpc on 9/12/20.
 */
/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization, ageYearDif} from "../../../constants/utils";
import {app} from '../../../constants/config';
import Player from './Player'
import Loader from '../../../common/loader';
import PhoneModal from './phoneModal';
import {useSelector} from "react-redux";
import {PrefixModel} from "../../../constants/model";


function format(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
        ret += "" + hrs + " h" + ":" + (mins < 10 ? "0 minute(s)" : " second(s)");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

const TableRecordingCall = props => {
    const {language,updateIncomingCall,incomingCalls,getAllIncomingCall} = props

    // const {incomingCalls} = useSelector(state => ({
    //     incomingCalls: state?.pbxReducer?.incomingCalls || [],
    // }));


    let rateBd = "";

    const columns = [
        {
            title: 'Date',
            field: 'calldate', type: 'text',
            editable: 'never',
        },
        {
            title: 'Source',
            field: 'src', type: 'text'
        },
        {
            title: 'Destination',
            field: 'dst', type: 'text',
            editable: 'never',
        },
        {
            title: 'Duration',
            field: 'duration', type: 'text',
            editable: 'never',
            render: rowData => <span>{format(rowData.duration)}</span>

        },
        {
            title: 'Statut',
            field: 'disposition', type: 'text',
            editable: 'never',
        },
        {
            title: 'Audio',
            type: 'text',
            height: "50px",
            editable: 'never',
            render: rowData => <>
                <audio src={`https://pbx.seedleemarketing.com//monitor/monitor/${rowData.recordingfile}`} controls/>
            </>
        }
    ]
    //<Player url={`http://192.168.11.42/connexus_api/monitor/${rowData.date}/${rowData.recordingfile}`}/>
    if (incomingCalls && incomingCalls.length > 0) {
        return (<>
                <div className="card-header pt-2">

                    <div className="card-toolbar  pb-5">
                        <ul className="nav nav-bold nav-pills float-right">
                            <a href="#" className="btn btn-sm btn-info font-weight-bolder"
                              onClick={()=>getAllIncomingCall()} >
											<span className="svg-icon svg-icon-md">
                                                <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall"
                                                      aria-hidden="true" title="Chercher">refresh</span>
											</span>Actualiser</a>
                        </ul>
                    </div>
                </div>
                <MaterialTable
                    title="Enregistrement des appels"
                    columns={columns}
                    data={incomingCalls ? incomingCalls : []}
                    icon='unfold_less'
                    localization={Localization(language)}
                    options={{
                        grouping: true,
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },
                        actionsColumnIndex:-1,
                        exportButton: true,
                        exportAllData: true
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {

                                    var entity = {
                                        id: newData.uniqueid,
                                        src: newData.src,
                                        language: language,
                                    }
                                    console.log("=-----------------------",entity)
                                    // updatePrefixById(entity);
                                    updateIncomingCall(entity)
                                    resolve();
                                }, 1000)
                            }),
                    }}
                    actions={[{
                        icon: row => (
                            <i className="mdi mdi-eye pr-2"
                               data-toggle="modal"
                               data-target="#slideRightModal"
                               title="view"></i>
                        ),
                        tooltip: 'View',
                        onClick: (e, row) => {
                            // getAllPhones(row.dst)
                        }
                    },
                        rowData => {

                        }
                    ]
                    }
                />

                {/*<PhoneModal phones={phones} language={language}/>*/}
            </>
        )
    } else {
        return <Loader/>
    }
}


export default TableRecordingCall