import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getAllExtension = () => {
    return instance.get(`/extension/index.php`)
};

export const getAllExtensionAvailable = () => {
    return instance.get(`/extension/extension_available.php`)
};

export const createExtension = ext => {
    return instance.post(`/extension/create/index.php`, ext)
};


export const updateExtension = ext => {
    return instance.put(`/extension/update/index.php`, ext)
};
