/**
 * Created by louidorpc on 8/4/20.
 */
import React from 'react'
import UserDashboard from '../../components/dashboards/userDashboard'
import AgentDashboard from '../../components/dashboards/agent'


const Profile = props => {

    const {
        sr: { session, isLoadingS }, actions: {
            getPersonById, updatePersonById, updateUserById,
            resetStatePerson, getSites, getProjectAssignByPersonId, getUserSessionInfo, changePassword,getAllTatistic,getAllPbxReportAgent
        }, person: { ps, isLoading, mups, cms }, intl, st: { sites },stat: {stats, isLoadingSt},
        reportCalls,cReports,reports } = props;

    if (session !== null)
        return (<UserDashboard session={session}
                getPersonById={getPersonById}
                ps={ps} isLoading={isLoading}
                intl={intl}
                updatePersonById={updateUserById}
                mups={mups}
                getSites={getSites}
                isLoadingS={isLoadingS}
                sites={sites}
                resetStatePerson={resetStatePerson}
                getProjectAssignByPersonId={getProjectAssignByPersonId}
                getUserSessionInfo={getUserSessionInfo}
                changePassword={changePassword}
                cms={cms}


            />
        )
    else return <div></div>
}


export default Profile;