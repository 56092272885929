/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization} from "../../../constants/utils";
import {app} from '../../../constants/config';
import Loader from '../../../common/loader'


const TablePosition = props => {

    const {language, entreprises, person_id, getEntrepriseInfo, getAllDocuments}=props;

    const columns = [

        {
            title: getIntlMsg(intl(language), `app.label.entr_name`),
            field: 'entr_name', type: 'text',
            render: rowData => (
                <span>
                                        <img

                                            src={rowData.photo ? app.BASE_IMAGE_URL + '/models/images/entreprises/' + rowData.photo : "/assets/img/avatar.png"}
                                            class="avatar avatar-sm avatar-img rounded-circle" width="30px"/>

                <span className="ml-2">  {rowData.entr_name}</span>
              </span>
            ),
        },
        {
            title: getIntlMsg(intl(language), `app.label.email`),
            field: 'email', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.phone`),
            field: 'phone', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.address`),
            field: 'address', type: 'text',
        },
    ]
    if (entreprises.length > 0)
        return (
            <MaterialTable
                title={getIntlMsg(intl(language), `app.label.entreprise_list`)}
                columns={columns}
                data={entreprises ? entreprises : []}
                icon='unfold_less'
                localization={Localization(language)}
                options={{
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportAllData: true,
                }}
                actions={[{
                    icon: row => (
                        <i className="mdi mdi-circle-edit-outline"></i>
                    ),
                    tooltip: 'Edit',
                    onClick: (e, row) => {
                        getEntrepriseInfo(row);
                    }
                }, {
                    icon: row => (
                        <i className="mdi mdi-eye"
                           data-toggle="modal"
                           data-target="#slideRightModal"></i>
                    ),
                    tooltip: 'View',
                    onClick: (e, row) => {
                        getEntrepriseInfo(row);
                    }
                }, {
                    icon: row => (
                        <i className="mdi mdi-file-document"
                           data-toggle="modal"
                           data-target="#slideRightModaldoc"></i>
                    ),
                    tooltip: 'Documents',
                    onClick: (e, row) => {
                        getAllDocuments(row.entr_name);
                    }
                }]
                }

            />
        )
    else return (<div style={{margin: '0px'}}><Loader/></div>)
}


export default TablePosition