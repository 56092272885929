/**
 * Created by louidorpc on 8/4/20.
 */
import React from 'react'
import DaysreportComponent from '../../components/admin/daysreport'
// import { getAllDaysReports, createDaysReport } from "../../actions/creator/daysreport";

const DaysreportContainer = props => {

    const {
        sr: { session }, actions: {
            getAllEntreprises,
            getAllDaysReports,
            createDaysReport,
            getAllDaysReportsByClient,
            editDaysReport,
            resetDaysReport,
            deleteDaysReport
        } } = props;

    if (session !== null)
        return (
            <DaysreportComponent session={session}
                getAllEntreprises={getAllEntreprises}
                getAllDaysReports={getAllDaysReports}
                createDaysReport={createDaysReport}
                getAllDaysReportsByClient={getAllDaysReportsByClient}
                editDaysReport={editDaysReport}
                resetDaysReport={resetDaysReport}
                deleteDaysReport={deleteDaysReport}

            />
        )
    else return <div></div>
}



export default DaysreportContainer;