import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CalendarStyleWrapper} from './calendar.style';
import DnDCalendar from './DnDCalendar';
import moment from 'moment';
import {getIntlMsg, intl, Localization} from "../../../../constants/utils";


const getIndex = (events, selectedEvent) =>
    events.findIndex(event => event.id === selectedEvent.id);

class FullCalender extends Component {
    state = {
        view: this.props.view,
        modalVisible: false,
        selectedData: undefined
    };

    getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    eventStyleGetter = (event, start, end, isSelected) => {
        console.log(event);
        var backgroundColor = "";
        if (event.campaign_name === "SASD") {
            backgroundColor = this.getRandomColor();
        } else {
            backgroundColor = this.getRandomColor();
        }

        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            border: '0px',
            display: 'block',
            fontSize: '12px',
        };
        return {
            style: style
        };
    }

    handleNavigate = (event) => {

        // this.props.resetStateTimesheet();
        this.props.removeModal();
        const date = moment(event).format('YYYY-MM');

        const tpid = {
            person_id: this.props.person_id,
            complet_date: date,
            id: this.props.entreprise_id
        }

        this.props.getAllCampaignToCalendar(tpid)

    }

    onSelectSlot = selectedData => {
        //
        // this.props.resetModal();
        this.props.showModal(selectedData, 2);
        const date = moment(selectedData.start).format('YYYY-MM-DD');
        this.props.getSelectedDataFromCalendar(selectedData)
    };

    onView = view => {
        this.props.changeView(view);

    };

    onSelectEvent = selectedData => {

        // this.props.getAllSessionByAgent(selectedData.agentid);
        // this.props.resetModal();
        this.props.getAllPhoneCampaigns(selectedData.campaing_code)
        this.props.showModal();
        // this.props.getSelectedDataFromCalendar(selectedData)

    }


    render() {

        const {view, calendarInfos, locale} = this.props;


        let events = calendarInfos ? calendarInfos : [];
        console.log("__________________________", events)
        let vw = view;

        const messages = {
            next: getIntlMsg(intl(locale), `app.label.next`),
            today: getIntlMsg(intl(locale), `app.label.today`),
            previous: getIntlMsg(intl(locale), `app.label.back`),
            month: getIntlMsg(intl(locale), `app.label.month`),
            day: getIntlMsg(intl(locale), `app.label.day`),
            week: getIntlMsg(intl(locale), `app.label.week`),
            allDay: getIntlMsg(intl(locale), `app.label.allday`),
            time: getIntlMsg(intl(locale), `app.label.time`),
            event: getIntlMsg(intl(locale), `app.label.event`),
            showMore: function showMore(total) {
                return '+' + total + getIntlMsg(intl(locale), `app.label.more`);
            },

        }

        const components = {
            dateCellWrapper: {},
        }
        const calendarOptions = {
            events,
            vw,
            onSelectEvent: this.onSelectEvent,
            onSelectSlot: this.onSelectSlot,
            onView: this.onView,
            onNavigate: this.handleNavigate,
            messages: messages,
            eventPropGetter: this.eventStyleGetter,
        };

        return (
            <>
            <CalendarStyleWrapper className="isomorphicCalendarWrapper" events={events}>
                <div className="divMain">
                    <DnDCalendar {...calendarOptions} intl={intl} locale={locale}/>
                </div>
            </CalendarStyleWrapper>

            </>
        );
    }
}

export default FullCalender;
