/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import {getIntlMsg, intl} from "../../constants/utils";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()


export const getAllPositions = () => ({
    type: types.GET_ALL_POSITIONS,
    payload: {isLoadingPo: true}
});


export const getAllPositionsSuccess = mpo => ({
    type: types.GET_ALL_POSITIONS_SUCCESS,
    payload: {isLoadingPo: false, positions: mpo}
});

export const getAllPositionsFailled = mpo => ({
    type: types.GET_ALL_POSITIONS_FAILLED,
    payload: {isLoadingPo: true, mpo: mpo}
});


export const createPosition = position => ({
    type: types.CREATE_POSITION,
    payload: {
        isLoadingPo: true, position: position
    }
});


export const createPositionSuccess = mpo => {

    toast.success(getIntlMsg(intl(mpo), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_POSITION_SUCCESS,
        payload: {isLoadingPo: false, mpo: mpo}
    };
}

export const createPositionFailled = ms => {

    toast.error(getIntlMsg(intl(ms.locale), ms.mpo), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_POSITION_FAILLED,
        payload: {isLoadingSv: true, mpo: ms.mpo}
    };
}


export const updatePosition = upo => ({
    type: types.UPDATE_POSITION,
    payload: {
        isLoadingPo: true, upo: upo
    }
});


export const updatePositionSuccess = mpo => {

    toast.success(getIntlMsg(intl(mpo), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_POSITION_SUCCESS,
        payload: {isLoadingPo: false, mpo: mpo}
    };
}

export const updatePositionFailled = ms => {

    toast.error(getIntlMsg(intl(ms.locale), ms.mpo), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_POSITION_FAILLED,
        payload: {isLoading: true, mpo: ms.mpo}
    };
}




export const deletePosition = id => ({
    type: types.DELETE_POSITION,
    payload: {
        isLoadingD: true, id: id
    }
});


export const deletePositionSuccess = id => {

    toast.success(getIntlMsg(intl('fr'), `app.label.deleteSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_POSITION_SUCCESS,
        payload: {isLoading: false, id: id}
    };
}

export const deletePositionFailled = mp => {

    toast.error(getIntlMsg(intl('fr'), mp.mssv), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_POSITION_FAILLED,
        payload: {isLoading: true, mssv: mp.mssv}
    };
}

