/**
 * Created by louidorpc on 8/5/20.
 */
import React from 'react'
import Signin from '../../components/signin';
import SigninAgent from '../../components/signinAgent';
import Signup from '../../components/signup';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import ModalComponent from "./modalComponent";
import ResetPassword from "../../components/resetPassword";

class Login extends React.Component {

    state = {
        show: false,
    }

    componentDidMount() {
        this.props.actions.getSites();
        this.props.actions.getAllExtensionAvailable();
    }

    hideModal = () => {
        this.setState({show: false})
    }

    showModal = () => {
        this.setState({show: true})
    }

    render() {
        const {
            login: {type, sn, sp, msn, msp, message, isLoading, msna},
            intl,
            actions: {
                SwitchToSignin,
                SwitchToSignup,
                signin,
                signup,
                resetStateSignup,
                resetStateSignin,
                signinAgent,
                getAllExtensionAvailable,
                resetPassword, resetStateResetPassword
            },
            st: {sites},
            extensionsA
        } = this.props;

        return (
            <React.Fragment>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-4  bg-white" style={{boxShadow: "2px 2px 4px #000000"}}>
                            <div class="row align-items-center m-h-100">
                                <div class="mx-auto col-md-8">
                                    <div class="p-b-20 text-center">
                                        <p>
                                            <img src="/assets/img/Seedlee_logo_New_page-0001.jpg" width="200" alt=""/>
                                        </p>
                                    </div>
                                    {type == "signin" ?
                                        <Signin SwitchToSignup={SwitchToSignup} signin={signin} isLoading={isLoading}
                                                sn={msn}
                                                message={message} resetStateSignin={resetStateSignin} intl={intl}
                                        /> :
                                        type == "signup" ?
                                            <Signup SwitchToSignin={SwitchToSignin} signup={signup}
                                                    isLoading={isLoading}
                                                    sn={msn}
                                                    message={message} resetStateSignup={resetStateSignup} intl={intl}
                                                    sites={sites}/> :
                                            type == "agent_login" ?
                                                <SigninAgent
                                                    SwitchToSignup={SwitchToSignup} signinAgent={signinAgent}
                                                    isLoading={isLoading}
                                                    msna={msna}
                                                    extensions={extensionsA}
                                                    message={message}
                                                    getAllExtensionAvailable={getAllExtensionAvailable}
                                                    resetStateSignin={resetStateSignin} intl={intl}/>
                                                : null
                                    }
                                    {message === "app.label.username_and_password" || message==="app.label.undefined" ?
                                        <div className="col-md-12 mb-3">
                                            <p className="text-right text-danger">
                                                <a href="#!" className="text-underline pt-4"
                                                   onClick={() => this.showModal()}>Forgot Password?</a>
                                            </p></div> : null}

                                </div>

                            </div>
                        </div>

                        <div class="col-lg-8 d-none d-md-block bg-cover pt-1">
                            <Splide
                                options={{
                                    type: 'loop',
                                    gap: '1rem',
                                    autoplay: true,
                                    pauseOnHover: false,
                                    resetProgress: false,
                                    arrows: 'slider',
                                }}
                                hasSliderWrapper
                                hasAutoplayProgress>
                                <SplideSlide>
                                    <img src="/assets/img/image1.jpeg" alt="Image 1"/>
                                    <marquee direction="down" behavior="alternate"><h1>Sondez votre marché!</h1>
                                    </marquee>
                                </SplideSlide>
                                <SplideSlide>
                                    <img src="/assets/img/image2.jpeg" alt="Image 2"/>
                                    <marquee direction="down" behavior="alternate"><h1>Connaissez vos clients!</h1>
                                    </marquee>
                                </SplideSlide>
                                <SplideSlide>
                                    <img src="/assets/img/image3.jpeg" alt="Image 2"/>
                                    <marquee direction="down" behavior="alternate"><h1>Développez votre entreprise!</h1>
                                    </marquee>
                                </SplideSlide>
                                <SplideSlide>
                                    <img src="/assets/img/image4.jpeg" alt="Image 2"/>
                                    <marquee direction="down" behavior="alternate"><h1>Restez connecter avec vos
                                        clients!</h1></marquee>
                                </SplideSlide>
                            </Splide>
                        </div>
                    </div>
                </div>
                <ModalComponent hideModal={this.hideModal} show={this.state.show}><ResetPassword
                    resetPassword={resetPassword} resetStateResetPassword={resetStateResetPassword} message={message} hideModal={this.hideModal}/></ModalComponent>
            </React.Fragment>
        )
    }

}

export default Login;