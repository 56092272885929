/**
 * Created by louidorpc on 1/25/21.
 */
/**
 * Created by louidorpc on 1/9/21.
 */
/**
 * Created by louidorpc on 1/9/21.
 */
import React, { useEffect } from 'react'
import SoftPhone from 'react-softphone'
import { WebSocketInterface } from 'jssip';
import { useSelector } from 'react-redux';


const url = (ent_name) => {
    if (ent_name === "Chatelain Cargo Services S.A") {
        return "https://app.smartsheet.com/b/publish?EQBCT=162d375f8aad4ddea8a06567869e015d";
    } else if (ent_name === "Office Star S.A") {
        return "https://app.smartsheet.com/b/publish?EQBCT=3c964a9fb9494cee8969ac5132ebc7ad"
    } else if (ent_name === "Valerio Canez ") {
        return "https://app.smartsheet.com/b/publish?EQBCT=968a5860f499466e84bc52e4cfdbbb63"
    }
    else if (ent_name === "Caribe Mortors") {
        return "https://app.smartsheet.com/b/publish?EQBCT=b25c792379f646c1815d425059e9c854"
    }
    else if (ent_name === "Chatelain Tours and Travel Services") {
        return "https://app.smartsheet.com/b/publish?EQBCT=e3912cb6b02e46af832769a5bba3e05d"
    } else if (ent_name === "A&B HARDWARE ") {
        return "https://app.smartsheet.com/b/publish?EQBCT=97fd31415fc34d7ba4c126f7fc67a3e3"
    } else if (ent_name === "Euroceram Plus") {
        return "https://app.smartsheet.com/b/publish?EQBCT=37bea20da8264db7b2e99e57be962f04"
    } else if (ent_name === "Euroceram Plus") {
        return "https://app.smartsheet.com/b/publish?EQBCT=0f04aeff0ea84c338a8d4b95a0528f57"
    } else if (ent_name === "Visa Lodge ") {
        return "https://app.smartsheet.com/b/publish?EQBCT=c9131e9cc15a462f91982c447c81516a"
    }
    else if (ent_name === "Turbo System") {
        return "https://app.smartsheet.com/b/publish?EQBCT=acbdd3dfd36a4c1397fdb9acceda0a39"
    }
    else if (ent_name === "Sunrise Airways") {
        return "https://app.smartsheet.com/b/publish?EQBCT=ced9c24a383e4196976c8b4a21a8d676"
    }
    else {
        return ""
    }

}

const EmbededPbx = props => {

    const { infouser, getLinkByEntreprise } = props;
    // console.log("____________________________________:::::::::::::::::::::", infouser ? infouser.entr_name : null);

    //<IFRAME WIDTH=1000 HEIGHT=700 FRAMEBORDER=0 SRC="https://app.smartsheet.com/b/publish?EQBCT=162d375f8aad4ddea8a06567869e015d"></IFRAME>
    //https://app.smartsheet.com/b/publish?EQBCT=162d375f8aad4ddea8\a06567869e015d


    useEffect(() => {
        getLinkByEntreprise(infouser?.entreprise_id, 'REPORT_CLIENT')
    }, [])

    const { linkByEntreprise } = useSelector(state => ({
        linkByEntreprise: state?.linkReducer?.linkByEntreprise || null,
    }));


    return (
        linkByEntreprise?.link ? <div className='col-md-12 pt-3'><embed src={linkByEntreprise?.link}
            width="100%"
            style={{ height: 'calc(100vh - 70px)' }}></embed></div> : <div className='col-md-12'>
            <h1 className="text-center"> Aucun rapport</h1>
        </div>
    );
}


export default EmbededPbx;