/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import EntrepriseForm from './entrepriseForm'
import EntrepriseTable from './entrepriseTable'
import {FormattedMessage} from "react-intl";
import {app} from '../../../constants/config';
import {Entreprise} from '../../../constants/model'
import EntrepriseModalInfos from './entrepriseModalInfos'
import DocumentModalInfos from './documentModal'


class EntrepriseComponent extends React.Component {


    constructor(props) {

        super(props);

        this.state = {
            photo: null,
            pictureType: null,
        }
    }

    cb = (result, file) => {
        let str = result;
        this.setState({photo: str, pictureType: file.type})
    }


    previewFile = (cb) => {

        var preview = document.getElementById('filePreviewEnt')
        var file = document.querySelector('input[type=file]').files[0];
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            preview.src = "";
            preview.src = reader.result;
            cb(reader.result, file)
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }

    }
    resetFile = () => {
        var preview = document.getElementById('filePreviewEnt')
        preview.src = "/assets/img/avatar.png";
        this.setState({photo: null, pictureType: null})
    }

    submitSetting = values => {

        console.log("----------------------------------------", values)

        const entrepriseEntity = new Entreprise(values);

        entrepriseEntity.pictureContent = this.state.photo;
        entrepriseEntity.pictureType = this.state.pictureType;
        entrepriseEntity.language = this.props.language;
        entrepriseEntity.creator = this.props.person_id;

        if (values.id)
            this.props.updateEntreprise(entrepriseEntity);
        else
            this.props.createEntreprise(entrepriseEntity);

    };


    componentDidMount() {
        // this.props.getAllEntreprises();
    }


    render() {

        const {ps, intl, onchangeFile, msEnt, person_id, resetStatePerson, sites, language, entreprises, getEntrepriseInfo, entrepriseInfo, resetEntreprise, getAllDocuments, documents}=this.props;

        return (
            <React.Fragment>
                <div class="row pt-4">

                    <div class="col-md-4 col-xs-12 m-b-30">
                        <div class="card">
                            <div class="card-body">

                                <div class="">
                                    <label class="avatar-input" for="logo">
                                    <span class="avatar avatar-xl">
                                        <img

                                            src={entrepriseInfo ? app.BASE_IMAGE_URL + '/models/images/entreprises/' + entrepriseInfo.photo : "/assets/img/avatar.png"}
                                            id="filePreviewEnt"
                                            class="avatar-img rounded-circle"/>
                                         <span class="avatar-input-icon rounded-circle">
                                        <i class="mdi mdi-upload mdi-24px"></i>
                                    </span>
                                    </span>
                                        <input type="file" name="file" class="avatar-file-picker"
                                               onChange={() => this.previewFile(this.cb)} id="logo"/>
                                        <p align="center"><FormattedMessage id={`app.label.entr_logo`}
                                                                            defaultMessage={`Label Name`}/></p>
                                    </label>

                                </div>

                                <EntrepriseForm ps={ps} intl={intl}
                                                onSubmit={this.submitSetting}
                                                msEnt={msEnt}
                                                resetEntreprise={resetEntreprise}
                                                person_id={person_id}
                                                entrepriseInfo={entrepriseInfo}
                                                resetFile={this.resetFile}
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8 col-xs-12 m-b-30">
                        <div class="card">
                            <div class="card-body">
                                <EntrepriseTable language={language}
                                                 entreprises={entreprises && entreprises.length > 0 ? entreprises : []}
                                                 entrepriseInfo={entrepriseInfo}
                                                 getAllDocuments={getAllDocuments}
                                                 getEntrepriseInfo={getEntrepriseInfo}/>
                            </div>
                        </div>
                    </div>
                </div>
                <EntrepriseModalInfos entrepriseInfo={entrepriseInfo}/>
                <DocumentModalInfos documents={documents} language={language}/>
            </React.Fragment>
        )

    }
}

export default EntrepriseComponent