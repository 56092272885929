import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {validate} from "./validator";
import {renderField} from "../../../common/FormComponents";


const onClearForm = (reset, resetStateResetPassword, sn, hideModal) => {
    reset('resetPassword');
    resetStateResetPassword();
    hideModal()

}

const Form = props => {
    const {
        handleSubmit,
        valid,
        reset,
        pristine,
        submitting,
        resetStateResetPassword,
        sn,
        intl,
        isLoading,
        history,
        message,
        form,
        SwitchToSignup,
        hideModal
    } = props;

    if (message === "reset password success") onClearForm(reset, resetStateResetPassword, sn, hideModal)
    return (
        <form onSubmit={handleSubmit} style={{margin: '20px'}}>
            <div className="form-row">
                <div className="form-group floating-label col-md-12">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="email"
                        component={renderField}
                        type="text"
                        label="app.label.email"
                        placeholder="app.label.email"
                    />

                </div>

                <div className="form-group floating-label col-md-12">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="rpassword"
                        component={renderField}
                        type="password"
                        label="app.label.newpassword"
                        placeholder="app.label.newpassword"
                    />
                </div>

                <div className="form-group floating-label col-md-12">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="repeatpassword"
                        component={renderField}
                        type="password"
                        label="app.label.repeatpassword"
                        placeholder="app.label.repeatpassword"
                    />
                </div>


                <div class="col-md-12 mb-3">
                    <button type="submit"
                            class="btn btn-primary btn-md btn-block waves-effect text-center float-right"
                            disabled={pristine || submitting}>
                        Reset password
                    </button>
                    {isLoading == true ?
                        <div id="loader">
                            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                        </div> : null}
                </div>


                <div class="error-handling-messages">
                    {message === "app.label.access" ?
                        <p className="text-c-red"><FormattedMessage id={`${message}`} defaultMessage={''}/>{message}
                        </p> : ''}
                </div>
            </div>
        </form>


    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: null,

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'resetPassword',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(Form));

