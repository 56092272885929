import { put, call, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/creator/agentPbx";
import types from "../actions/type";
import * as api from "../services/pbx";
import { getAllIncomingCall } from "../services/pbx";


export const handleGetAllPbxReportAgent = function* (action) {
    const { ent_id } = action.payload;
    try {
        const getSv = yield call(api.getAllPbxReportAgent, ent_id);
        if (getSv.data?.reports?.length > 0) {
            yield put(actions.getAllPbxReportAgentSuccess(getSv.data))
        } else {
            yield put(actions.getAllPbxReportAgentFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPbxReportAgentFailled("error"))
    }
};


export const handleGetAllIncomingCallsAgent = function* (action) {
    const { ent_id } = action.payload;
    try {
        const getSv = yield call(api.getAllIncomingCallAgent, ent_id);
        if (getSv?.data?.length > 0) {
            yield put(actions.getAllIncomingCallAgentSuccess(getSv.data))
        } else {
            yield put(actions.getAllIncomingCallAgentFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllIncomingCallAgentFailled("error"))
    }
};

export const handleGetAllOutgoingCallsAgent = function* (action) {
    const { ent_id } = action.payload;
    try {
        const getSv = yield call(api.getAllOuntgoingCallAgent, ent_id);
        if (getSv?.data?.length > 0) {
            yield put(actions.getAllOutgoingCallAgentSuccess(getSv.data))
        } else {
            yield put(actions.getAllOutgoingCallAgentFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllOutgoingCallAgentFailled("error"))
    }
};

export const agentPbxSaga = [
    takeLatest(types.AGENT_GET_ALL_PBX_REPORTS, handleGetAllPbxReportAgent),
    takeLatest(types.AGENT_GET_ALL_INCOMING_CALL_REPORTS, handleGetAllIncomingCallsAgent),
    takeLatest(types.AGENT_GET_ALL_OUTGOING_CALL_REPORTS, handleGetAllOutgoingCallsAgent

    ),
];
