import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getAllStatistic = criteria => {
    if(criteria?.entreprise_id){
        return instance.get(`/statistic/agent.php?entreprise_id=${criteria?.entreprise_id}`)
    }else{
        return instance.get(`/statistic/index.php?entreprise_id=&startDate=${criteria?.startDate}&endDate=${criteria?.endDate}&extension=${criteria?.extension || ""}`)
    }
  
};
