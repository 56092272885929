import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Loader from "../../../../common/loader";
import { app } from "../../../../constants/config";
import MaterialTable from "material-table";
import { Localization } from "../../../../constants/utils";
import DaysreportForm from "./DaysreportForm";
import { Card, CardHeader } from "@material-ui/core";
import sweetAlert from 'sweetalert'
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
const shortcutsItems = [
    {
        label: 'This Week',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today];
        },
    },
    {
        label: 'Current Month',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'Next Month',
        getValue: () => {
            const today = dayjs();
            const startOfNextMonth = today.endOf('month').add(1, 'day');
            return [startOfNextMonth, startOfNextMonth.endOf('month')];
        },
    },
    { label: 'Reset', getValue: () => [null, null] },
];


const AdminDaysreport = ({ session, getAllEntreprises, getAllDaysReports, createDaysReport, editDaysReport, resetDaysReport, deleteDaysReport }) => {



    const [daysReportInfo, setDaysReportInfo] = useState(null);

    useEffect(() => {
        getAllEntreprises()
        // getAllDaysReports()

    }, [])

    const { daysreports, ms } = useSelector(state => ({
        daysreports: state?.daysreportReducer?.daysreports || [],
        ms: state?.daysreportReducer?.ms || [],
    }));

    const columns = [
        {
            title: 'Date',
            field: 'start_date', type: 'text',
            width: '20'
        },
        {
            title: 'Abonné(s)',
            field: 'entr_name', type: 'text',
            width: '20'
        },
        {
            title: 'Description',
            field: 'description', type: 'text',
        },
        {
            title: 'Document',
            field: 'file_name', type: 'text',
            render: rowData => <span style={{ fontSize: '20px' }}>{rowData.file_name ?
                <i onClick={() => window.open(app.BASE_IMAGE_URL + "models/docs/" + rowData.file_name, "", "width=800,height=500", "target=_blanc")}
                    className="mdi mdi-file-document"
                    data-toggle="modal"
                    data-target="#slideRightModal"
                    title="Delete"></i> : <i>-</i>}</span>

        }
    ]

    const handleEdit = data => {
        setDaysReportInfo(data)
    }

    const handleSuccess = () => {
        resetDaysReport()
        setDaysReportInfo(null)
    }

    const handleDeleteDayReport = (value) => {

        sweetAlert({
            title: "Êtes-vous sûr de vouloir faire cela?",
            cancelButtonText: "Non",
            confirmButtonText: "Yes",
            reverseButtons: true,
            buttons: true,
            showCancelButton: true,
        }).then(resp => {
            if (resp) {
                handleDelete(value)
            }
        });
    }

    const handleDelete = values => {
        let entity = {
            id: values.id,

        };
        deleteDaysReport(entity)
    };

    const [value, setValue] = React.useState([
        dayjs(dayjs().startOf('week')),
        dayjs(dayjs().endOf('week')),
    ]);

    useEffect(() => {
        getAllDaysReports({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD') })
    }, [])


    const handleDatePickerChange = (e) => {
        setValue(e)
    }


    return (
        <React.Fragment>
            <section class="admin-content" id="daysreport" onAbort={() => this.resetModal(1)}>

                <div class="container-fluid bg-dark m-b-30">
                    <div class="row  p-b-60 p-t-20">


                        <div class="col-md-3 text-white p-b-30">

                            <div class="media">

                                <div class="avatar mr-3  avatar-xl">

                                    {session ?
                                        <img
                                            src={session ? session.photo ? app.BASE_IMAGE_URL + '/models/images/' + session.photo + '?' + new Date() : "/assets/img/avatar.png" : "/assets/img/avatar.png"}
                                            alt="..." class="avatar-img rounded-circle" /> :
                                        <Loader />}
                                </div>
                                <div class="media-body m-auto">
                                    <h5 class="mt-0">{session ? session.fullname : null}
                                    </h5>
                                    <div class="opacity-75">{session ? session.role : null}</div>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-12 text-white my-auto text-md-right p-b-30">

                            <div class="">
                                <h2 class="opacity-75"><a href="#!">
                                    <FormattedMessage id={`app.label.daysreport`}
                                        defaultMessage={`Rapport Journalier`} /></a>
                                </h2>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="container-fluid pull-up">
                    <div class="row ">
                        <div className="col-lg-6">
                            <div className="card">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer
                                        components={[
                                            'DatePicker',
                                            'TimePicker',
                                            'DateTimePicker',
                                            'DateRangePicker',
                                        ]}
                                        sx={{
                                            margin: '20px'
                                        }}
                                    >
                                        <DemoItem

                                            component="DateRangePicker"


                                        >
                                            <DateRangePicker
                                                localeText={{
                                                    start: '',
                                                    end: '',
                                                }}
                                                format='YYYY-MM-DD'
                                                slotProps={{
                                                    shortcuts: {
                                                        items: shortcutsItems,
                                                    },
                                                    actionBar: { actions: [] },
                                                    textField: ({ position }) => ({
                                                        label: position === 'start' ? 'Date debut' : 'Date fin',
                                                    }),
                                                }}
                                                onOpen={() => {
                                                    setTimeout(() => {
                                                        const firstDiv = document.querySelector('.MuiDateRangeCalendar-root');
                                                        if (firstDiv) {
                                                            const childDivToRemove = firstDiv.querySelector(':first-child');
                                                            if (childDivToRemove) {
                                                                childDivToRemove.remove();
                                                            }
                                                        }
                                                    }, 1000)
                                                }}
                                                onClose={() => {
                                                    setTimeout(() => {
                                                        const firstDiv = document.querySelector('.MuiDateRangeCalendar-root');
                                                        if (firstDiv) {
                                                            const childDivToRemove = firstDiv.querySelector(':first-child');
                                                            if (childDivToRemove) {
                                                                childDivToRemove.remove();
                                                            }
                                                        }
                                                    }, 1000)
                                                    getAllDaysReports({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD') })
                                                }}
                                                value={value}
                                                onChange={(e) => handleDatePickerChange(e)}
                                            />
                                        </DemoItem>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <MaterialTable
                                title="Liste des rapports journaliers"
                                columns={columns}
                                data={daysreports}
                                icon='unfold_less'
                                localization={Localization('fr')}
                                options={{
                                    headerStyle: {
                                        backgroundColor: '#01579b',
                                        color: '#FFF'
                                    },
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    exportAllData: true
                                }}
                                actions={[{
                                    icon: row => (
                                        <h4><i className="mdi mdi-pencil"></i></h4>
                                    ),
                                    tooltip: 'Edit',
                                    onClick: (e, row) => {
                                        handleEdit(row)
                                    }
                                },
                                rowData => {

                                },
                                {
                                    icon: row => (
                                        <i className="mdi mdi-delete pr-2"
                                            data-toggle="modal"
                                            data-target="#slideRightModal"
                                            title="Delete"></i>
                                    ),
                                    tooltip: 'Delete',
                                    onClick: (e, row) => {
                                        handleDeleteDayReport(row)
                                    }
                                },
                                rowData => {

                                }
                                ]
                                }
                            />
                        </div>
                        <div class="col-lg-4">
                            <Card>
                                <CardHeader>Form</CardHeader>
                                <DaysreportForm createDaysReport={createDaysReport} session={session} daysReportInfo={daysReportInfo} editDaysReport={editDaysReport} ms={ms} handleSuccess={handleSuccess} />
                            </Card>

                        </div>
                    </div>
                </div>
            </section>

            {/* <Modal
                size="xl"
                show={showModal}
                onHide={() => setShowModal(false)}
                aria-labelledby="example-modal-sizes-title-xxl"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-xxl">
                        <span className="text-center ml-0"><i
                            className="mdi mdi-calendar-edit">
                        </i> Rapport journalier</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DocViewer
                        documents={[{ uri: app.BASE_IMAGE_URL + "/models/docs/" + uri }]}
                        pluginRenderers={DocViewerRenderers}
                    />
                </Modal.Body>
            </Modal> */}

        </React.Fragment>
    )
}

export default AdminDaysreport;