/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization} from "../../../constants/utils";
import {Position} from '../../../constants/model'
import {deletePosition} from "../../../actions/creator/position";


const TablePosition = props => {

    const {language, createPosition, updatePosition, positions, person_id,deletePosition}=props;


    let rateBd = "";

    const columns = [
        {
            title: 'ID',
            field: 'id',
            type: 'text',
            editable: 'never',
        },
        {
            title: getIntlMsg(intl(language), `app.label.position_name`),
            field: 'position_name', type: 'text',

        },
        {
            title: getIntlMsg(intl(language), `app.label.creator`),
            field: 'creator', type: 'text',
            editable: 'never',
        },
        {
            title: getIntlMsg(intl(language), `app.label.created_date`),
            field: 'date_created', type: 'text',
            editable: 'never',
        }
    ]
    return (
        <MaterialTable
            title={getIntlMsg(intl(language), `app.label.position_list`)}
            columns={columns}
            data={positions ? positions : []}
            icon='unfold_less'
            localization={Localization(language)}
            options={{
                headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF'
                },
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true
            }}
            editable={{
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {

                            var positionEntity = new Position({
                                position_name: newData.position_name,
                                language: language,
                                creator: person_id
                            })
                            createPosition(positionEntity);
                            resolve();
                        }, 1000);
                    })
                ,
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {

                            var positionEntity = new Position({
                                id: newData.id,
                                position_name: newData.position_name,
                                language: language,
                                creator: person_id
                            })
                            updatePosition(positionEntity);
                            resolve();
                        }, 1000)
                    }),
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {

                            deletePosition(oldData.id)

                            resolve();
                        }, 1000);
                    })
            }}

        />
    )
}


export default TablePosition