import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {validate} from "./validator";
import {renderField} from "../../../common/FormComponents";


const onClearForm = (reset, resetStateSignin, sn) => {
    reset('signin');
    sessionStorage.setItem("userData", JSON.stringify(sn.user));
    document.location = "/";
    resetStateSignin();

}

const Form = props => {
    const {
        handleSubmit,
        valid,
        reset,
        pristine,
        submitting,
        resetStateSignin,
        sn,
        intl,
        isLoading,
        history,
        message,
        form,
        SwitchToSignup
    } = props;

    if (message === "success signin") onClearForm(reset, resetStateSignin, sn)
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group floating-label col-md-12">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="username"
                        component={renderField}
                        type="text"
                        label="app.label.username"
                        placeholder="app.label.username"
                    />

                </div>

                <div className="form-group floating-label col-md-12">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="password"
                        component={renderField}
                        type="password"
                        label="app.label.password"
                        placeholder="app.label.password"
                    />

                </div>


                <div class="col-md-12 mb-3">
                    <button type="submit"
                            class="btn btn-primary btn-md btn-block waves-effect text-center float-right"
                            disabled={pristine || submitting}>
                        <FormattedMessage id={'app.label.signin'}/>
                    </button>
                    {isLoading == true ?
                        <div id="loader">
                            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                        </div> : null}
                </div>

                <div class="col-md-12">
                    <p class="text-inverse">Vous n'avez pas encore de compte?
                        <a href="#" onClick={() => SwitchToSignup('signup')}>
                            <span className="ml-1 text-c-red"><b> S'incrivez-vous</b></span>
                        </a>
                    </p>
                </div>

                {/* <div class="col-md-12">
                    <h6 class="text-inverse text-center">
                        <a href="#" onClick={() => SwitchToSignup('agent_login')}
                           class="btn m-b-15 ml-2 mr-2 btn-outline-info">
                            <b>Login as Agent or Supervisor </b>
                        </a>
                    </h6>
                </div> */}

                <div class="error-handling-messages">
                    {message === "app.label.access" ?
                        <p className="text-c-red"><FormattedMessage id={`${message}`} defaultMessage={''}/>{message}
                        </p> : ''}
                </div>
            </div>
        </form>


    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: null,

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'signin',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(Form));

