/**
 * Created by louidorpc on 8/11/20.
 */
import React from 'react';
import Service from './service'
import Position from './position'
import Entreprise from './entreprise'
import { app } from '../../constants/config';
import Loader from '../../common/loader';
import Progress from '../../common/progress'
import { FormattedMessage } from "react-intl";
import { getIntlMsg, intl } from '../../constants/utils'
import Person from './person'
import Client from './client'
import Extension from './extension'
import Prefix from './prefix'
import createHistory from 'history/createBrowserHistory';
import { Line } from 'react-chartjs-2';
import { deletePosition } from "../../actions/creator/position";
import { Flipbook } from 'react-pdf-flipbook';



const state = {
    labels: ['January', 'February', 'March',
        'April', 'May'],
    datasets: [
        {
            label: 'Rainfall',
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: [65, 59, 80, 81, 56]
        }
    ]
}


class IAPDashboard extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            id: props.session.person_id,
            tab: null,
        }


    }


    componentDidMount() {

        const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);
        const history = createHistory();


        if (infouser.position_name === "Administrateur" && infouser.entr_name === "Seedlee") {
            this.props.getAllTatistic();
        } else {
            history.goBack()
        }

        // this.props.getAllServices();
        // this.props.getAllPositions();
    }

    handleSwitchTable = tab => {
        if (tab === "entreprise") {
            this.setState({ tab: 'entreprise' });
            this.props.getAllEntreprises();
        } else if (tab === "person") {
            this.props.getAllPersons();
            this.props.getAllServices();
            this.props.getAllPositions();
            this.props.getAllEntreprises();
            this.props. getAllExtensions();
            this.setState({ tab: 'person' });
        }
        else if (tab === "client") {
            this.props.getAllClients();
            this.props.getAllServices();
            this.props.getAllPositions();
            this.props.getAllEntreprises();
            this.setState({ tab: 'client' });

        } else if (tab === "extension") {
            this.props.getAllExtensions();
            this.setState({ tab: 'extension' });
        } else if (tab === "prefix") {
            this.props.getAllEntreprises();
            this.props.getAllPrefix();
            this.props.getAllPrefixAvailable();
            this.setState({ tab: 'prefix' });
        }
    }


    render() {

        const {
            session, isLoadingS, isLoadingSt, stats, updateService, createService, isLoadingSv, changeUserStatus, clients, getAllDocuments, documents,
            services, positions, updatePosition, createPosition, getAllEntreprises, createEntreprise, persons, mups, resetStatePerson, createClient, resetStateClient, mc,
            updateEntreprise, getEntrepriseInfo, entrepriseInfo, entreprises, isLoadingE, msEnt, resetEntreprise, getAllServices, getAllPositions, getPersonInfo, personInfo,
            updatePersonById, updatePrefixById, createPrefix, getAllPrefix, getAllPrefixAvailable, prefix, prefixA, deleteService, deletePosition, deletePrefix, deleteUser,
            getAllExtensions
        } = this.props;
        const { tab } = this.state

   

        return (
            <>

                <section class="admin-content">

                    <div class="container-fluid bg-dark m-b-30">
                        <div class="row  p-b-60 p-t-20">

                            <div class="col-md-6 text-white p-b-30">
                                <div class="media">

                                    <div class="avatar mr-3  avatar-xl">

                                        {session && isLoadingS == false ?
                                            <img
                                                src={session ? session.photo ? app.BASE_IMAGE_URL + '/models/images/' + session.photo + '?' + new Date() : "/assets/img/avatar.png" : "/assets/img/avatar.png"}
                                                alt="..." class="avatar-img rounded-circle" /> :
                                            <Loader />}
                                    </div>
                                    <div class="media-body m-auto">
                                        <h5 class="mt-0">{session ? session.fullname : null}
                                        </h5>
                                        <div class="opacity-75">{session ? session.role : null}</div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6 text-white my-auto text-md-right p-b-30">

                                <div class="">
                                    <h2 class="opacity-75"><a href="#!">
                                        <FormattedMessage id={`app.label.administration`}
                                            defaultMessage={`Label Name`} /></a>
                                    </h2>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="container-fluid pull-up">
                        <div class="row ">
                            <div class="col-lg-12">
                                <div class="card m-b-30">
                                    <div class="card-header">
                                        <div class="card-title"></div>
                                    </div>
                                    <div class="card-body">
                                        <ul class="nav nav-tabs" id="myTab" role="tablist">

                                            <li class="nav-item">
                                                <a class="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#setting"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-graph">
                                                        <FormattedMessage id={`app.label.dashboard`}
                                                            defaultMessage={`Label Name`} /></i></a>
                                            </li>
                                            <li class="nav-item" onClick={() => getAllServices()}>
                                                <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#service"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-room-service">
                                                        <FormattedMessage id={`app.label.service`}
                                                            defaultMessage={`Service`} />
                                                    </i>
                                                </a>
                                            </li>
                                            <li class="nav-item" onClick={() => getAllPositions()}>
                                                <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#position"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-hand-pointing-up">
                                                        <FormattedMessage id={`app.label.position`}
                                                            defaultMessage={`Position`} />
                                                    </i>
                                                </a>
                                            </li>
                                            <li class="nav-item" onClick={() => this.handleSwitchTable('entreprise')}>
                                                <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#entreprise"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-office-building">
                                                        <FormattedMessage id={`app.label.abonnee`}
                                                            defaultMessage={`Abonné(s)`} />
                                                    </i>
                                                </a>
                                            </li>

                                            <li class="nav-item" onClick={() => this.handleSwitchTable('person')}>
                                                <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#person"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-account-box-multiple">
                                                        <FormattedMessage id={`app.label.person`}
                                                            defaultMessage={`Person`} />
                                                    </i>
                                                </a>
                                            </li>


                                            <li class="nav-item" onClick={() => this.handleSwitchTable('client')}>
                                                <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#client"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-account-box-multiple">
                                                        <FormattedMessage id={`app.label.client_represent`}
                                                            defaultMessage={`Représentant client`} />
                                                    </i>
                                                </a>
                                            </li>

                                            <li class="nav-item" onClick={() => this.handleSwitchTable('extension')}>
                                                <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#extension"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-phone-voip">
                                                        <FormattedMessage id={`app.label.extension`}
                                                            defaultMessage={`Extension`} />
                                                    </i>
                                                </a>
                                            </li>

                                            <li class="nav-item" onClick={() => this.handleSwitchTable('prefix')}>
                                                <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#prefix"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-phone-voip">
                                                        <FormattedMessage id={`app.label.prefix`}
                                                            defaultMessage={`Prefix`} />
                                                    </i>
                                                </a>
                                            </li>

                                        </ul>
                                        <div class="tab-content" id="myTabContent1">

                                            <div class="tab-pane fade show active" id="setting" role="tabpanel"
                                                aria-labelledby="contact-tab">


                                                <div class="row mt-5">
                                                    {stats && stats.counts ? stats.counts.map(value => {
                                                        if (value.name !== "app.label.clientA")
                                                            return (
                                                                <div class="col-lg-3 col-md-3 m-b-30">
                                                                    <div class="card m-b-30"
                                                                        style={{
                                                                            background: `${value.color}`,
                                                                            color: '#FFF'
                                                                        }}>
                                                                        <div class="card-body">
                                                                            <div class="row p-t-20 p-b-20">
                                                                                <div class="my-auto col-md-7">

                                                                                    <h1 class="m-0"> {value.count}</h1>
                                                                                    <p class="m-0 text-white fw-600">  {getIntlMsg(intl(session.language), value.name)}</p>
                                                                                </div>
                                                                                <div
                                                                                    class="my-auto text-md-right col-md-5">
                                                                                    <a href="#"
                                                                                        class="btn btn-rounded-circle btn-lg btn-white text-danger">
                                                                                        <i class={`mdi ${value.icon}`}></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>)
                                                    }
                                                    ) : []}

                                                </div>
                                              
                                            </div>
                                            <div class="tab-pane fade show" id="service" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <Service
                                                    updateService={updateService} createService={createService}
                                                    services={services}
                                                    isLoadingSv={isLoadingSv}
                                                    person_id={this.state.id}
                                                    language={session ? session.language : null}
                                                    deleteService={deleteService}
                                                />

                                            </div>

                                            <div class="tab-pane fade show" id="position" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <Position
                                                    updatePosition={updatePosition}
                                                    createPosition={createPosition}
                                                    positions={positions}
                                                    person_id={this.state.id}
                                                    language={session ? session.language : null}
                                                    deletePosition={deletePosition}
                                                />

                                            </div>

                                            <div class="tab-pane fade show" id="entreprise" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                {tab === 'entreprise' ?
                                                    <Entreprise
                                                        person_id={this.state.id}
                                                        language={session ? session.language : null}
                                                        getAllEntreprises={getAllEntreprises}
                                                        createEntreprise={createEntreprise}
                                                        updateEntreprise={updateEntreprise}
                                                        getEntrepriseInfo={getEntrepriseInfo}
                                                        entrepriseInfo={entrepriseInfo}
                                                        entreprises={entreprises}
                                                        isLoadingE={isLoadingE}
                                                        msEnt={msEnt}
                                                        getAllDocuments={getAllDocuments}
                                                        documents={documents ? documents : []}
                                                        resetEntreprise={resetEntreprise}
                                                    /> : null}

                                            </div>
                                            <div class="tab-pane fade show" id="person" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                {tab === "person" ?
                                                    <Person
                                                        person_id={this.state.id}
                                                        language={session ? session.language : null}
                                                        services={services}
                                                        positions={positions}
                                                        persons={persons}
                                                        entreprises={entreprises}
                                                        getPersonInfo={getPersonInfo}
                                                        personInfo={personInfo}
                                                        updatePersonById={updatePersonById}
                                                        mups={mups}
                                                        resetStatePerson={resetStatePerson}
                                                        changeUserStatus={changeUserStatus}
                                                        deleteUser={deleteUser}
                                                        getAllExtensions={getAllExtensions}
                                                    /> : null}

                                            </div>

                                            <div class="tab-pane fade show" id="client" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                {tab === "client" ?
                                                    <Client
                                                        person_id={this.state.id}
                                                        language={session ? session.language : null}
                                                        services={services}
                                                        positions={positions}
                                                        clients={clients}
                                                        entreprises={entreprises}
                                                        getPersonInfo={getPersonInfo}
                                                        personInfo={personInfo}
                                                        createClient={createClient}
                                                        mc={mc}
                                                        resetStateClient={resetStateClient}

                                                    /> : null}

                                            </div>


                                            <div class="tab-pane fade show" id="extension" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                {tab === "extension" ?
                                                    <Extension
                                                        updateExtensionById={this.props.updateExtensionById}
                                                        createExtension={this.props.createExtension}
                                                        extensions={this.props.extensions}
                                                        person_id={this.state.id}
                                                        language={session ? session.language : null}
                                                    />
                                                    : null}

                                            </div>

                                            <div class="tab-pane fade show" id="prefix" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                {tab === "prefix" ?
                                                    <Prefix
                                                        updatePrefixById={updatePrefixById}
                                                        createPrefix={createPrefix}
                                                        prefix={prefix ? prefix : []}
                                                        prefixA={prefixA ? prefixA : []}
                                                        person_id={this.state.id}
                                                        language={session ? session.language : null}
                                                        entreprises={entreprises ? entreprises : []}
                                                        getAllPrefix={this.props.getAllPrefix}
                                                        deletePrefix={deletePrefix}
                                                    />
                                                    : null}

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


export default IAPDashboard