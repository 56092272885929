/**
 * Created by louidorpc on 9/9/20.
 */
import React from 'react'
import ExtensionTable from './extensionTable'

const Extension = ({language, createExtension, updateExtensionById, extensions, person_id}) => {

    return (

        <div class="row pt-4">

            <div class="col-md-12 col-xs-12 m-b-30">

                <ExtensionTable language={language}
                                updateExtensionById={updateExtensionById}
                                createExtension={createExtension}
                                extensions={extensions}
                                person_id={person_id}/>
            </div>

        </div>
    )
}

export default Extension;