/**
 * Created by louidorpc on 1/9/21.
 */
import React from 'react'


function AllServices() {
    return (
      
                <div className="row ">
                    <div className="col-lg-12">
                        <div className="card m-b-30">
                            <div className="card-header">
                                <div className="card-title"></div>
                            </div>
                            <div className="card-body">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">

                                    <li className="nav-item">
                                        <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                           href="#Carte"
                                           role="tab"
                                           aria-controls="contact" aria-selected="false"><i
                                            className="mdi mdi-card"> Carte</i></a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                           href="#Compte"
                                           role="tab"
                                           aria-controls="contact" aria-selected="false"><i
                                            className="mdi mdi-account">Compte </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                           href="#Paiements"
                                           role="tab"
                                           aria-controls="contact" aria-selected="false"><i
                                            className="mdi mdi-cash"> Paiements </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                           href="#Transactions"
                                           role="tab"
                                           aria-controls="contact" aria-selected="false"><i
                                            className="mdi mdi-credit-card"> Transactions </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                           href="#Voyage"
                                           role="tab"
                                           aria-controls="contact" aria-selected="false"><i
                                            className="mdi mdi-airplane"> Voyage </i>
                                        </a>
                                    </li>
                                    

                                </ul>
                                <div className="tab-content" id="myTabContent1">

                                    <div className="tab-pane fade show active" id="Carte" role="tabpanel"
                                         aria-labelledby="contact-tab">
                                        <embed src="https://app.smartsheet.com/b/publish?EQBCT=2e274623b5f84138b020725391cd7f37"
                                            width="100%" style={{height: 'calc(100vh - 70px)'}} className={'myIframe'}/>
                                    </div>

                                    <div className="tab-pane fade" id="Compte" role="tabpanel"
                                         aria-labelledby="contact-tab">

                                        <embed
                                            src="https://app.smartsheet.com/b/publish?EQBCT=9eb3ffffcb6b4112b5292bd28d73d3f0"
                                            width="100%" style={{height: 'calc(100vh - 70px)'}} className={'myIframe'}/>
                                    </div>

                                    <div className="tab-pane fade" id="Paiements" role="tabpanel"
                                         aria-labelledby="contact-tab">

                                        <embed
                                            src="https://app.smartsheet.com/b/publish?EQBCT=a37c2d710df048d883fe0b3fee0588ac"
                                            width="100%" style={{height: 'calc(100vh - 70px)'}} className={'myIframe'}></embed>
                                    </div>

                                    <div className="tab-pane fade" id="Transactions" role="tabpanel"
                                         aria-labelledby="contact-tab">

                                        <embed
                                            src="https://app.smartsheet.com/b/publish?EQBCT=d7affc375b9e401d983f4d6a7b6482c2"
                                            width="100%" style={{height: 'calc(100vh - 70px)'}} className={'myIframe'}></embed>
                                    </div>

                                    <div className="tab-pane fade" id="Voyage" role="tabpanel"
                                         aria-labelledby="contact-tab">

                                        <embed
                                            src="https://app.smartsheet.com/b/publish?EQBCT=2a834f0d0bf24261818b5d35ba49c63f"
                                            width="100%" style={{height: 'calc(100vh - 70px)'}} className={'myIframe'}></embed>
                                    </div>
                                   

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
      
    );
}

export default AllServices;