import React from "react";
import {FormattedMessage} from "react-intl";
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization, ageYearDif} from "../../constants/utils";
import Loader from "../../common/loader";

const TableAssingedCampaign = props => {


    const {assCampaigns, language, getAllPhoneCampaigns, person_id} = props;
    const columnss = [
        {
            title: getIntlMsg(intl(language), `app.label.campaign_name`),
            field: 'campaign_name', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.entreprise`),
            field: 'entreprise', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.number_qty`),
            type: 'text',
            field: 'phoneCount',
            cellStyle: {
                color: '#000',
                fontSize: '20px',
                fontweight: 'bold',
                textAlign: 'center'
            },

        }
    ]

        return (
            <MaterialTable
                title={getIntlMsg(intl(language), `app.label.campaign_assign_list`)}
                columns={columnss}
                data={assCampaigns ? assCampaigns : []}
                icon='unfold_less'
                localization={Localization(language)}
                options={{
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportAllData: true
                }}

                actions={[{
                    icon: row => (
                        <i className="mdi mdi-eye"
                           data-toggle="modal"
                           data-target="#slideRightModal"></i>
                    ),
                    tooltip: 'View',
                    onClick: (e, row) => {
                        getAllPhoneCampaigns({id: person_id, code: row.campaign_code})
                    }
                }]
                }

            />
        );

}


export default TableAssingedCampaign