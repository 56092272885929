/**
 * Created by louidorpc on 11/5/20.
 */
import React from 'react'
import {FormattedMessage} from "react-intl";
import {getIntlMsg, intl} from '../../../constants/utils';
import {AssignCampaign} from '../../../constants/model'

class CampaignAssign extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            phoneNumbers: [],
            nbrqty: props.numberList.length,
            check: false,
            checkS: false,
            agents: [],
            agent: {code: null, name: null},
            campaign: {code: null, name: null, entr_name: null},
        }
    }

    handlerChangePhoneQty = e => {
        const val = e.target.value;
        let data = this.props.numberList;
        const {phoneNumbers} = this.state;
        let d = []

        if (data.length >= val) {

            for (let i = 0; i < val; i++) {
                d.push(data[i])
            }

            if (d.length > 0) {
                this.setState({check: true, phoneNumbers: d})
                console.log("=============++++++++++++", val)
            } else {
                this.setState({check: false, phoneNumbers: []})
            }

        }

    }

    handlerSearchAgent = e => {
        const val = e.target.value;
        let data = this.props.persons;

        let d = []

        if (data.length > 0) {

            d = data.filter(p => p.firstname.toLowerCase().includes(val) || p.lastname.toLowerCase().includes(val) || p.username.toLowerCase().includes(val))

            if (d.length > 0) {
                this.setState({checkS: true, agents: d})
            } else {
                this.setState({checkS: false, agents: []})
            }
        }
    }

    handleSelectAgent = (code, name) => {

        this.setState({agent: {code: code, name: name}})

    }

    handleSelectCampaign = (code, name, entr_name) => {
        this.props.getAllPhoneCampaigns(code)
        this.setState({campaign: {code: code, name: name, entr_name: entr_name}})

    }

    submitSetting = () => {

        const {phoneNumbers, agent, campaign} = this.state;

        const entity = {
            campaign_code: campaign.code,
            agentid: agent.code,
            phone_list: phoneNumbers,
            language: this.props.language,
            creator: this.props.person_id
        }
        const campaing = new AssignCampaign(entity);
        this.props.createAssignCampain(campaing);
    };

    handleResetState = () => {
        this.props.resetEmergencyCall();
        this.props.resetStateClient();
        this.setState({
            phoneNumbers: [], agents: [], agent: {code: null, name: null},
            campaign: {code: null, name: null, entr_name: null},
            check: false,
            checkS: false,
        });
        document.getElementById('number_list').value=0;
        this.props.getAllPersons();
        this.props.getAllEntreprises();

    }


    render() {

        const {
            campaigns,
            getAllPhoneCampaigns,
            numberList,
            persons,
            isLoadingCAC,
            entreprises,
            getAllCampaigns,
            mcac
        } = this.props;
        const {phoneNumbers, nbrqty, check, checkS, agents, agent, campaign} = this.state

        let data = check === true && campaigns.lenght < 0 ? phoneNumbers : numberList;
        let data1 = checkS ? agents : persons;

        if (mcac === 'success') this.handleResetState()
        return (
            <React.Fragment>
                <div className="row pt-4">

                    <div className="col-md-4 m-0">
                        <div class="card card-box">
                            <div class="card-head">
                                <header className="font-weight-bold">
                                    <FormattedMessage id={`app.label.client_list`}
                                                      defaultMessage={`Liste des client`}/>
                                </header>

                            </div>
                            <div class="card-body m-0">
                                <div style={{overflow: "auto", width: "100%", maxHeight: "400px"}}>
                                    {entreprises && entreprises.length > 0 ? entreprises.map((v, i) => (
                                        <div class="card list-group-item" style={{cursor: 'pointer'}}
                                             onClick={() => this.props.getAllCampaigns(v.id)}>
                                            <div class="card-body"><i
                                                class="mdi mdi-circle text-success"></i> {v.entr_name}</div>
                                        </div>

                                    )) : null}

                                </div>


                            </div>


                        </div>
                        <div class="card card-box">
                            <div class="card-head">
                                <header className="ml-2 font-weight-bold">
                                    <FormattedMessage id={`app.label.campaign_list`}
                                                      defaultMessage={`Campaign List`}/>
                                </header>

                            </div>
                            <div class="card-body">
                                <div style={{overflow: "auto", width: "100%", maxHeight: "200px"}}>
                                    {campaigns && campaigns.length > 0 ? campaigns.map((v, i) => (
                                        <div class="card list-group-item" style={{cursor: 'pointer'}}
                                             onClick={() => this.handleSelectCampaign(v.campaing_code, v.campaign_name, v.entr_name)}>
                                            <div class="card-body"><i
                                                class="mdi mdi-circle text-success"></i> {v.campaign_name}</div>
                                        </div>

                                    )) : null}

                                </div>


                            </div>


                        </div>

                    </div>
                    <div className='col-md-4'>
                    <div class="card card-box">
                            <div class="card-head">

                                <div className="form-group floating-label show-label col-md-12">
                                    <label> <FormattedMessage id={`app.label.number_qty`}
                                                              defaultMessage={`Campaign List`}/></label>
                                    <input className="form-control" type="text" onChange={this.handlerChangePhoneQty}
                                           id="number_list"/>
                                </div>
                                <header className="ml-2 font-weight-bold">
                                    <FormattedMessage id={`app.label.campaign_phone_list`}
                                                      defaultMessage={`List of phones`}/> {numberList.length > 0 ? data.length + '/' + numberList.length : null}
                                </header>

                            </div>
                            <div class="card-body">

                                <div style={{overflow: "auto", width: "100%", maxHeight: "200px"}}>
                                    {data && data.length > 0 ? data.map((v, i) => (
                                        <div class="card list-group-item m-0" style={{cursor: 'pointer'}}>
                                            <div class="card-body">
                                                <div><i class="mdi mdi-phone text-success">  {v.phone} </i></div>
                                                <div><i class="mdi mdi-account-circle">  {v.firstname} {v.lastname} </i>
                                                </div>
                                            </div>
                                        </div>

                                    )) : null}

                                </div>


                            </div>


                        </div>
                    </div>
                    <div className="col-md-4">
                      
                    <div className="card card-box">
                            <div className="card-head">

                                <div className="form-group floating-label show-label col-md-12">
                                    <label><FormattedMessage id={`app.label.search`}
                                                             defaultMessage={`List of phones`}/></label>
                                    <input className="form-control" type="text" onChange={this.handlerSearchAgent}/>
                                </div>
                                <header className="ml-2 font-weight-bold">
                                    <FormattedMessage id={`app.label.list_of_agent`}
                                                      defaultMessage={`List of phones`}/>
                                </header>

                            </div>
                            <div className="card-body">

                                <div style={{overflow: "auto", width: "100%", maxHeight: "200px"}}>
                                    {data1 && data1.length > 0 ? data1.map((v, i) => {
                                        if (v.position_name === "Agent") {
                                            return (
                                                <div className="card list-group-item" style={{cursor: 'pointer'}}
                                                     onClick={() => this.handleSelectAgent(v.id, v.fullname)}>
                                                    <div className="card-body"><i
                                                        className="mdi mdi-circle text-success"></i> {v.fullname}</div>
                                                </div>
                                            )
                                        }

                                    }) : null}

                                </div>


                            </div>


                        </div>


                    </div>


        
                </div>
                <div className='row pt-4'>

                
                    <div className="col-md-12">


                        <div className="card card-box">
                            <div className="card-head">

                                <header className="ml-2 font-weight-bold">
                                    <FormattedMessage id={`app.label.result`}
                                                      defaultMessage={`Result`}/>
                                </header>

                            </div>
                            {phoneNumbers.length > 0 && agent.name !== null && campaign.name !== null ?
                                <div className="card-body">


                                    <table className="table">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <tr>
                                                    <td className="text-left font-weight-bold"
                                                        style={{fontSize: '16px'}}>
                                                        Agent:
                                                    </td>
                                                    <td className="text-left"
                                                        style={{fontSize: '16px', color: '#039be5'}}>
                                                        {agent ? agent.name : null}
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bold"
                                                        style={{fontSize: '16px'}}>
                                                        <FormattedMessage id={`app.label.entr_name`}
                                                                          defaultMessage={`Cmapaign name`}/>:
                                                    </td>
                                                    <td className="text-letf"
                                                        style={{fontSize: '16px', color: '#039be5'}}>
                                                        {campaign ? campaign.entr_name : null}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="text-left font-weight-bold"
                                                        style={{fontSize: '16px'}}>
                                                        <FormattedMessage id={`app.label.campaign_name`}
                                                                          defaultMessage={`Cmapaign name`}/>:
                                                    </td>
                                                    <td className="text-letf"
                                                        style={{fontSize: '16px', color: '#039be5'}}>
                                                        {campaign ? campaign.name : null}
                                                    </td>
                                                </tr>

                                                <button type="button"
                                                        className={`btn btn-lg m-b-15 ml-2 mr-2 btn-primary col-md-12 ${isLoadingCAC ? 'disabled' : ''}`}
                                                        onClick={this.submitSetting}>
                                                    <FormattedMessage id={`app.label.assign`}
                                                                      defaultMessage={`Assign`}/>
                                                    {isLoadingCAC ?
                                                        <span className="spinner-border spinner-border-sm ml-3"
                                                              role="status"
                                                              aria-hidden="true"></span> : null}
                                                </button>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="text-left font-weight-bold" style={{fontSize: '16px'}}>
                                                    <FormattedMessage id={`app.label.campaign_phone_list`}
                                                                      defaultMessage={`Cmapaign name`}/>:
                                                </div>
                                                <div className="text-letf"
                                                     style={{fontSize: '16px', color: '#039be5', width: '100%'}}>

                                                    <div className="card-body">

                                                        <div style={{
                                                            overflow: "auto",
                                                            width: "100%",
                                                            maxHeight: "300px"
                                                        }}>
                                                            {phoneNumbers && phoneNumbers.length > 0 ? phoneNumbers.map((v, i) => (
                                                                <div className="card list-group-item"
                                                                     style={{cursor: 'pointer'}}>
                                                                    <div className="card-body">
                                                                        <div><i
                                                                            className="mdi mdi-phone text-success">  {v.phone} </i>
                                                                        </div>
                                                                        <div><i
                                                                            className="mdi mdi-account-circle">  {v.firstname} {v.lastname} </i>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )) : null}

                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </table>


                                </div> : null}


                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CampaignAssign