import * as setting from "./setting";
import * as login from "./login";
import * as person from "./person";
import * as sites from "./sites";
import * as statistic from "./statistic";
import * as service from "./service";
import * as position from "./position";
import * as entreprise from "./entreprise";
import * as client from "./client";
import * as extension from "./extension";
import * as agent from "./agent";
import * as campaign from "./campaign";
import * as prefix from "./prefix";
import * as pbx from "./pbx";
import * as clientPbx from "./clientPbx";
import * as feedback from "./feedback";
import * as event from "./event";
import * as links from "./links";
import * as daysreport from "./daysreport";
import * as monthreport from "./monthreport";
import * as agentPbx from "./agentPbx";


export default {
    ...setting,
    ...login,
    ...person,
    ...sites,
    ...statistic,
    ...service,
    ...position,
    ...entreprise,
    ...client,
    ...extension,
    ...agent,
    ...campaign,
    ...prefix,
    ...pbx,
    ...clientPbx,
    ...feedback,
    ...event,
    ...links,
    ...daysreport,
    ...monthreport,
    ...agentPbx
};
