import React, { useEffect } from 'react'
import CalendarAdmin from "./admin";
import CalendarClient from "./client";
import { useSelector } from "react-redux";
import ClientCalendar from './client';
import AgentCalendar from './agent';


const CalendarTask = props => {

    const { session, getAllEntreprises, getAllCalendarEvents, changeEvents, changeView, getCalendarEventInfo,
        createCalendarEvent, calendarEventChangeStatus, updateCalendarEvent,publishCalendarEvent,
        getAllCalendarEventsByEntreprise,getAllCalendarEventsByAgent,setViewCalendarEvent } = props;

    if(session)
    if (session?.person_type === "client") {
        return (
            <ClientCalendar session={session} getAllEntreprises={getAllEntreprises}
                getAllCalendarEvents={getAllCalendarEvents}
                changeEvents={changeEvents}
                changeView={changeView}
                getCalendarEventInfo={getCalendarEventInfo}
                createCalendarEvent={createCalendarEvent}
                calendarEventChangeStatus={calendarEventChangeStatus}
                updateCalendarEvent={updateCalendarEvent}
                getAllCalendarEventsByEntreprise={getAllCalendarEventsByEntreprise}
                setViewCalendarEvent={setViewCalendarEvent}
            />
        )
    } else if (session?.position_name === "Superviseur" && session?.entreprise_name === "Seedlee") {
        
        return(<CalendarAdmin session={session} getAllEntreprises={getAllEntreprises}
            getAllCalendarEvents={getAllCalendarEvents}
            changeEvents={changeEvents}
            changeView={changeView}
            getCalendarEventInfo={getCalendarEventInfo}
            createCalendarEvent={createCalendarEvent}
            calendarEventChangeStatus={calendarEventChangeStatus}
            updateCalendarEvent={updateCalendarEvent}
            publishCalendarEvent={publishCalendarEvent}
            getAllCalendarEventsByEntreprise={getAllCalendarEventsByEntreprise}
            setViewCalendarEvent={setViewCalendarEvent}
        />)
    }
    else if (session?.position_name === "Administrateur" && session?.entreprise_name === "Seedlee") {
        
        return(<CalendarAdmin session={session} getAllEntreprises={getAllEntreprises}
            getAllCalendarEvents={getAllCalendarEvents}
            changeEvents={changeEvents}
            changeView={changeView}
            getCalendarEventInfo={getCalendarEventInfo}
            createCalendarEvent={createCalendarEvent}
            calendarEventChangeStatus={calendarEventChangeStatus}
            updateCalendarEvent={updateCalendarEvent}
            publishCalendarEvent={publishCalendarEvent}
            getAllCalendarEventsByEntreprise={getAllCalendarEventsByEntreprise}
            setViewCalendarEvent={setViewCalendarEvent}
        />)
    }else{
        return(<AgentCalendar session={session} getAllEntreprises={getAllEntreprises}
            getAllCalendarEvents={getAllCalendarEvents}
            changeEvents={changeEvents}
            changeView={changeView}
            getCalendarEventInfo={getCalendarEventInfo}
            createCalendarEvent={createCalendarEvent}
            calendarEventChangeStatus={calendarEventChangeStatus}
            updateCalendarEvent={updateCalendarEvent}
            getAllCalendarEventsByAgent={getAllCalendarEventsByAgent}
            setViewCalendarEvent={setViewCalendarEvent}
        />)
    }
}



export default CalendarTask;