import * as validator from '../../../../../../constants/validator'
import types from '../../../../../../actions/type'


export const validate = values => {
    const errors = {}

    //NOTE when calling validateString the params are: (label, value, min, max, required=false)
    errors.title_name = validator.validateString('title_name', values.title_name, 3, 300, true)
    errors.client_name = validator.validateString('client_name', values.client_name, 3, 200, true)
    errors.client_phone = validator.validateString('client_phone', values.client_phone, 1, 12, true)
    errors.service_type = validator.validateString('service_type', values.service_type, 1, 12, true)
    errors.entreprise = validator.validateString('entreprise', values.entreprise, 1, 12, true)
    errors.description = validator.validateString('description', values.description, 3, 1000000, true)
   
    return errors
}


