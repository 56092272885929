import React from "react";
import {useSelector} from "react-redux";
import MapperDistribution from './item'
import {getIntlMsg} from "../../../constants/utils";


const Dashboard = props => {
    const {getAllPbxReport} = props

    const {statusItems, reportCalls, loading, stat,statByDays,outgoingByday,missedByday} = useSelector(state => ({
        reportCalls: state?.pbxReducer?.reports?.reports || [],
        stat: state?.pbxReducer?.reports?.stats || null,
        statByDays: state?.pbxReducer?.reports?.incomingByday || [],
        outgoingByday: state?.pbxReducer?.reports?.outgoingByday || [],
        missedByday: state?.pbxReducer?.reports?.missedByday || [],
    }));

    return (
        <div className="row">
            <div className="col-sm-12 mt-2">
                <MapperDistribution {...{reportCalls, stat,getAllPbxReport,statByDays,outgoingByday,missedByday}}/>
            </div>
        </div>

    )
}


const buildChartElement = (statusItems, intl) => {

    let labels = [];
    let values = [];
    if (statusItems)
        for (const [key, value] of Object.entries(statusItems)) {
            labels.push(getIntlMsg(intl, `app.label.${key.toLowerCase()}`))
            values.push(value)
        }
    return [labels, values]
}

export default Dashboard