/**
 * Created by louidorpc on 12/16/20.
 */
import React from 'react';
import StepEnt from './step1';
import StepContact from './step2';
import StepForfait from './step3';
import StepFinal from './step4';
import {FormattedMessage} from "react-intl";
import {Field, reduxForm, change} from "redux-form";
import {connect, useDispatch, useSelector} from 'react-redux'
import {validate, normalizeCin, normalizePhone} from "./validator";
import {renderField} from "../../../../common/FormComponents";
import {getIntlMsg} from "../../../../constants/utils";


class MasterForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            email: '',
            username: '',
            password: '',
            title: ''
        }
    }


    handleSetServiceAb = list => {
        this.props.dispatch(change('entreprise', 'service_ab', list));
    }

    handleSetDocumentFile = list => {
        this.props.dispatch(change('entreprise', 'documentFile', list));
    }

    handleChange = event => {
        const {name, value} = event.target
        this.setState({
            [name]: value
        })
    }

    handleSubmit = event => {
        event.preventDefault()
        const {email, username, password} = this.state
        alert(`Your registration detail: \n 
           Email: ${email} \n 
           Username: ${username} \n
           Password: ${password}`)
    }

    _next = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep >= 3 ? 4 : currentStep + 1
        this.setState({
            currentStep: currentStep
        })
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    /*
     * the functions for our button
     */
    previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (
                <button
                    className="btn btn-secondary"
                    type="button" onClick={this._prev}>
                    <FormattedMessage id="app.label.previous" defaultMessage="Previous"/>

                </button>
            )
        }
        return null;
    }

    nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep < 4) {
            return (
                <button
                    className="btn btn-primary float-right"
                    type="button" onClick={this._next}>
                    <FormattedMessage id="app.label.next" defaultMessage="Next"/>
                </button>
            )
        }
        return null;
    }

    render() {
        const {handleSubmit, reset, submitting, intl, isLoadingE, form, msEnt, resetEntreprise, resetFile, entrepriseInfo} = this.props;
        return (
            <React.Fragment>
                <div class="alert alert-info" role="alert">
                    <b><FormattedMessage id="app.label.step" defaultMessage="Step"/> {this.state.currentStep}</b>
                </div>


                <form onSubmit={handleSubmit}>
                    {/*
                     render the form steps and pass required props in
                     */}
                    <Step1
                        currentStep={this.state.currentStep}
                        handleChange={this.onSubmit}
                        email={this.state.email}
                    />
                    <Step2
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                        username={this.state.username}
                    />
                    <Step3
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                        password={this.state.password}
                        handleSetServiceAb={this.handleSetServiceAb}

                    />
                    <Step4
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                        password={this.state.password}
                        isLoadingE={isLoadingE}
                        submitting={submitting}
                        entrepriseInfo={entrepriseInfo}
                        handleSetDocumentFile={this.handleSetDocumentFile}

                    />
                    {this.previousButton()}
                    {this.nextButton()}

                </form>
            </React.Fragment>
        );
    }
}

function Step1(props) {
    if (props.currentStep !== 1) {
        return null
    }
    return (
        <StepEnt/>
    );
}

function Step2(props) {
    if (props.currentStep !== 2) {
        return null
    }
    return (
        <StepContact/>
    );
}

function Step3(props) {
    if (props.currentStep !== 3) {
        return null
    }
    return (
        <StepForfait handleSetServiceAb={props.handleSetServiceAb}/>
    );
}

function Step4(props) {
    if (props.currentStep !== 4) {
        return null
    }
    return (
        <React.Fragment>

            <StepFinal entrepriseInfo={props.entrepriseInfo} handleSetDocumentFile={props.handleSetDocumentFile}/>

            <div class="col-md-12">
                <button type="submit"
                        class="btn btn-success btn-cta waves-effect text-center m-b-20 float-right"
                        disabled={props.submitting}>
                    <FormattedMessage id={`app.label.save`} defaultMessage={`Label Name`}/>
                </button>
                {props.isLoadingE === true ?
                    <div id="loader">
                        <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                    </div> : null}

            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state, props) => {
    return (
        {
            initialValues: props.entrepriseInfo ? props.entrepriseInfo : null,

        })
}

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'entreprise',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(MasterForm));
