/**
 * Created by louidorpc on 9/12/20.
 */
/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {Localization} from "../../../../constants/utils";
import Loader from '../../../../common/loader';
import {useSelector} from "react-redux";


function format(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
        ret += "" + hrs + " h" + ":" + (mins < 10 ? "0 minute(s)" : " second(s)");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

const TableRecordingCall = props => {
    const {language,getAllOutgoingCall} = props

    const {outgoingCalls} = useSelector(state => ({
        outgoingCalls: state?.pbxReducer?.outgoingCalls || [],
    }));

    const columns = [
        {
            title: 'Date',
            field: 'calldate', type: 'text'
        },
        {
            title: 'Source',
            field: 'src', type: 'text'
        },
        {
            title: 'Destination',
            field: 'dst', type: 'text'
        },
        {
            title: 'Duration',
            field: 'duration', type: 'text',
            render: rowData => <span>{format(rowData.duration)}</span>

        },
        {
            title: 'Status',
            field: 'disposition', type: 'text'
        },
        {
            title: 'Audio',
            type: 'text',
            height: "50px",
            render: rowData => <>
                <audio src={`https://pbx.seedleemarketing.com/monitor/monitor/${rowData.recordingfile}`} controls/>
            </>
        }
    ]

    if (outgoingCalls && outgoingCalls.length > 0) {
        return (<>
                <div className="card-header pt-2">

                    <div className="card-toolbar  pb-5">
                        <ul className="nav nav-bold nav-pills float-right">
                            <a href="#" className="btn btn-sm btn-info font-weight-bolder"
                               onClick={()=>getAllOutgoingCall()} >
											<span className="svg-icon svg-icon-md">
                                                <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall"
                                                      aria-hidden="true" title="Chercher">refresh</span>
											</span>Actualiser</a>
                        </ul>
                    </div>
                </div>
                <MaterialTable
                    title="Enregistrement des appels"
                    columns={columns}
                    data={outgoingCalls ? outgoingCalls : []}
                    icon='unfold_less'
                    localization={Localization('fr')}
                    options={{
                        grouping: true,
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },

                        exportButton: true,
                        exportAllData: true
                    }}
                    actions={[
                    ]
                    }
                />
            </>
        )
    } else {
        return <Loader/>
    }
}


export default TableRecordingCall