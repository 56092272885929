/**
 * Created by louidorpc on 8/5/20.
 */
import React from 'react'
import {SignupModel} from '../../constants/model';
import Form from './form'

const Signup = ({SwitchToSignin, isLoading, message, sp, signup, resetStateSignup, intl, sites}) => {


    const submit = values => {

        const spEntity = new SignupModel({...values});
        signup(spEntity);
    };


    return (
        <Form
            onSubmit={submit}
            message={message}
            intl={intl}
            resetStateSignup={resetStateSignup}
            isLoading={isLoading}
            SwitchToSignin={SwitchToSignin}
            sites={sites ? sites : []}
        />
    )
}
export default Signup;