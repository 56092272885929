import React from 'react';
import ReactDOM from 'react-dom';
import App from './AppLayout';
import LoginLayout from './LoginLayout';
import * as serviceWorker from './serviceWorker';
import configureStore from "./store";
import {Provider} from "react-redux";
import {render} from "react-dom";


const store = configureStore();

if (sessionStorage.getItem('userData') !== null) {

    const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);

    ReactDOM.render(
        <Provider store={store}>
            <App infouser={infouser}/>
        </Provider>,
        document.getElementById("root")
    );
} else {
    ReactDOM.render(
        <Provider store={store}>
            <LoginLayout />
        </Provider>,
        document.getElementById("root")
    );
}


serviceWorker.unregister();
