import {put, call, takeLatest} from "redux-saga/effects";
import  * as actions from "../actions/creator/extension";
import types from "../actions/type";
import * as api from "../services/extension";


export const handleGetAllExtension = function*() {

    try {
        const getSv = yield call(api.getAllExtension);
        if (getSv.data.length > 0) {
            yield put(actions.getAllExtensionsSuccess(getSv.data))
        } else {
            yield put(actions.getAllExtensionsFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllExtensionsFailled("error"))
    }


};

export const handleCreateExtension = function*(action) {
    const {ext} = action.payload;
    try {
        const pos = yield call(api.createExtension, ext);
        if (pos.data.message == "success") {
            yield put(actions.createExtensionSuccess(ext.language))
            yield put(actions.getAllExtensions())

        } else {
            yield put(actions.createExtensionFailled({mext: pos.data.message, locale: ext.language}))
        }
    } catch (error) {
        yield put(actions.createExtensionFailled({mext: 'app.label.saveError', locale: ext.language}))
    }
};

export const handleUpdateExtension = function*(action) {
    const {ext} = action.payload;
    try {
        const pos = yield call(api.updateExtension, ext);
        if (pos.data.message == "success") {
            yield put(actions.updateExtensionByIdSuccess(ext.language))
            yield put(actions.getAllExtensions())

        } else {
            yield put(actions.updateExtensionByIdFailled({mext: pos.data.message, locale: ext.language}))
        }
    } catch (error) {
        yield put(actions.updateExtensionByIdFailled({mext: 'app.label.saveError', locale: ext.language}))
    }
};


export const handleGetAllExtensionA = function*() {

    try {
        const getSv = yield call(api.getAllExtensionAvailable);
        if (getSv.data.length > 0) {
            yield put(actions.getAllExtensionAvailableSuccess(getSv.data))
        } else {
            yield put(actions.getAllExtensionAvailableFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllExtensionAvailableFailled("error"))
    }


};


export const extensionSaga = [
    takeLatest(types.GET_ALL_EXTENSIONS, handleGetAllExtension),
    takeLatest(types.CREATE_EXTENSION, handleCreateExtension),
    takeLatest(types.UPDATE_EXTENSION, handleUpdateExtension),
    takeLatest(types.GET_ALL_EXTENSIONS_AVAILABLE, handleGetAllExtensionA),
];
