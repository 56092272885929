import axios from "axios"
import { app } from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getAllLinks = () => {
    return instance.get(`/links/index.php`)
};


export const createLink = link => {
    return instance.post(`/links/create/index.php`, link)
};


export const updateLink = link => {
    return instance.put(`/links/update/index.php`, link)
};


export const getLink = id => {
    return instance.get(`/links/read_single/index.php?id=${id}`)
};

export const deleteLink = id => {
    return instance.get(`/links/delete/index.php?id=${id}`)
};

export const getLinkByType = type => {
    return instance.get(`/links/read_by_type/index.php?type=${type}`)
};

export const getLinkByEntreprise = (type, entreprise_id) => {
    return instance.get(`/links/read_by_entreprise/index.php?type=${type}&entreprise_id=${entreprise_id}`)
};