/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getIntlMsg, intl} from "../../constants/utils";
import {injectIntl, createIntl, createIntlCache} from "react-intl"


toast.configure()


export const getFeedback = id => ({
    type: types.GET_FEEDBACK,
    payload: {isLoadingFeed: true, id: id}
});

export const resetFeedback = () => ({
    type: types.RESET_FEEDBACK,
    payload: {mfeed: null}
});

export const resetFeedbackSuccess = () => ({
    type: types.RESET_FEEDBACK_SUCCESS,
    payload: {mfeed: null}
});



export const getFeedbackSuccess = feedbacks => ({
    type: types.GET_FEEDBACK_SUCCESS,
    payload: {isLoadingFeed: false, feedbacks: feedbacks}
});

export const getFeedbackFailed = () => ({
    type: types.GET_FEEDBACK_SUCCESS,
    payload: {isLoadingFeed: true, feedbacks: []}
});


export const createFeedback = feedback => ({
    type: types.CREATE_FEEDBACK,
    payload: {isLoadingCfeed: true, feedback: feedback}
});


export const createFeedbackSuccess = mfeed => {

    // toast.success(getIntlMsg(intl('fr'), `app.label.saveSuccess`), {
    //     position: toast.POSITION.TOP_RIGHT
    // });

    return {
        type: types.CREATE_FEEDBACK_SUCCESS,
        payload: {isLoadingCfeed: false, mfeed: 'success'}
    }
};


export const createFeedbackFailled = ms => {

    toast.error(getIntlMsg(intl('fr'), ms), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_FEEDBACK_FAILED,
        payload: {isLoadingEXT: true, mfeed: ms}
    }
};


export const updateFeedback = feedId => ({
    type: types.UPDATE_FEEDBACK,
    payload: {isLoadingUFS: true, feedId: feedId}
});


export const updateFeedbackSuccess = () => {
    return {
        type: types.UPDATE_FEEDBACK_SUCCESS,
        payload: {isLoadingUFS: false, upFeed: 'success'}
    }
}


export const updateFeedbackFailled = () => {
    return {
        type: types.UPDATE_FEEDBACK_FAILED,
        payload: {isLoadingUFS: false, upFeed: `ERROR`}
    };
}



