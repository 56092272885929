import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 20000,
    maxBodyLength: 20000,
    maxContentLength: 20000
});

export const getPersonInfo = pid => {
    console.info(pid, 'new message');
    return instance.get(`/users/read_single/index.php?person_id=${pid}`)
};


export const updatePersonInfo = ups => {

    return instance.put(`/users/update/index.php`, ups)
};

export const CreateClient = ups => {
    return instance.post(`/client/create/index.php`, ups)

};

export const getAllClients = () => {
    return instance.get(`/client/index.php`)
};

export const changeUserStatus = user => {
    return instance.put(`/users/changeStatus/index.php`, user)
};


export const createCustomer = ups => {
    return instance.post(`/customer/create/index.php`, ups)

};

export const getAllCustomers = () => {
    return instance.get(`/customer/index.php`)
};

export const getAllCampaigns = () => {
    return instance.get(`/campaign/index.php`)
};


export const getAllCampaignsById = id => {
    return instance.get(`/campaign/read_single/index.php?id=${id}`)
};

export const createCampaign = ups => {
    return instance.post(`/campaign/create/index.php`, ups)

};

export const deleteCampaign = id => {
    return instance.get(`/campaign/delete/index.php?campaign_code=${id}`)

};

// export const createCampaign = async (ups) => {
//     try {
//         const resp = await axios.post(`${app.BaseURL}/campaign/create/index.php`, ups);
//        return(resp.data);
//     } catch (err) {
//         // Handle Error Here
//         return(err);
//     }
// };

export const editCampaign = ups => {
    return instance.put(`/campaign/edit/index.php`, ups)

};


export const getAllPhoneCampaigns = code => {
    return instance.get(`/campaign/read_campaign_phone/index.php?campaing_code=${code}`)
};


export const getAllPhoneCampaignsAvailable = code => {
    return instance.get(`/campaign/available_campaign/index.php?campaing_code=${code}`)
};


export const getAllCampaignToCalendar = pid => {
    return instance.get(`/campaign/read_campaign_to_calendar/index.php?complet_date=${pid.complet_date}&id=${pid.id}`)
};


export const getAllReports = () => {
    return instance.get(`/smartsheet/index.php`)
};


export const getAllRecordingCalls = entr_id => {
    return instance.get(`/client/recordingCall/index.php?entreprise_id=${entr_id}`)
};






