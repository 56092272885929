/**
 * Created by louidorpc on 9/12/20.
 */
import React from 'react'
import  {app} from '../../../constants/config'
import  {getIntlMsg, intl} from '../../../constants/utils'
import {FormattedMessage} from "react-intl";
import ReactToPrint from "react-to-print";
import ComponentToPrintInfo from './ComponentToPrint'

const ids = ['2']

class EntrepriseModalInfos extends React.Component {

    render() {
        const {entrepriseInfo, language}=this.props;
        let str = entrepriseInfo && entrepriseInfo.docs ? entrepriseInfo.docs : null;
        const doc = str && str.split('-');

        return (<>
            <div class="modal fade modal-slide-right" id="slideRightModal" tabindex="-1" role="dialog"
                 aria-labelledby="slideRightModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-lg" role="document">
                    <div class="modal-content modal-lg" style={{height: 'auto'}}>
                        <div class="modal-header">
                            <h5 class="modal-title"
                                id="slideRightModalLabel">FORMULAIRE D’OUVERTURE DE COMPTE</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="row modal-body ml-5 mr-5">

                            <div class="" style={{margin: 'auto'}}>

                                <p style={{textAlign: 'center'}}><img
                                    src="/assets/img/Seedlee_logo_New_page-0001.jpg"
                                    width="150"/></p>
                                <p style={{textAlign: 'center'}} className="mt-0">
                                    FORMULAIRE D’OUVERTURE DE COMPTE
                                </p>


                                <div className="row pb-3">
                                    <div className="col-md-12"><p
                                        style={{border: '1px solid black', textAlign: 'center'}}>
                                        INFORMATIONS DE L’ENTREPRISE</p></div>
                                    <div className="col-md-12">
                                        <span style={{color: 'black'}}><b>Nom De L’entreprise : </b></span> <span
                                        style={{
                                            borderBottom: '1px dashed #000',
                                            width: '100%'
                                        }}>{entrepriseInfo && entrepriseInfo.entr_name}</span>
                                    </div>
                                    <div className="col-md-12">
                                        <span style={{color: 'black'}}><b>Adresse Physique : </b></span>
                                        <span style={{
                                            borderBottom: '1px dashed #000',
                                            width: '100%'
                                        }}>{entrepriseInfo && entrepriseInfo.address}</span>

                                    </div>
                                    <div className="col-md-6">
                                        <span style={{color: 'black'}}><b>Ville :</b></span> <span style={{
                                        borderBottom: '1px dashed #000',
                                        width: '100%'
                                    }}>{entrepriseInfo && entrepriseInfo.city}</span>

                                    </div>
                                    <div className="col-md-6"><span
                                        style={{color: 'black'}}><b>Code postal :</b></span>
                                        <span style={{
                                            borderBottom: '1px dashed #000',
                                            width: '100%'
                                        }}>{entrepriseInfo && entrepriseInfo.postal_code}</span>

                                    </div>
                                    <div className="col-md-6">
                                        <span style={{color: 'black'}}><b>Email :</b></span> <span style={{
                                        borderBottom: '1px dashed #000',
                                        width: '100%'
                                    }}>{entrepriseInfo && entrepriseInfo.email}</span>

                                    </div>
                                    <div className="col-md-6"><span style={{color: 'black'}}><b>Tél :</b></span>
                                        <span
                                            style={{
                                                borderBottom: '1px dashed #000',
                                                width: '100%'
                                            }}>{entrepriseInfo && entrepriseInfo.phone}</span>

                                    </div>
                                    <div className="col-md-12">
                                        <span style={{color: 'black'}}><b>Nature Juridique :</b></span> <span
                                        style={{
                                            borderBottom: '1px dashed #000',
                                            width: '100%'
                                        }}>{entrepriseInfo && entrepriseInfo.entr_nature}</span>

                                    </div>
                                    <div className="col-md-6">
                                        <span style={{color: 'black'}}><b>Etablie depuis :</b></span> <span style={{
                                        borderBottom: '1px dashed #000',
                                        width: '100%'
                                    }}>{entrepriseInfo && entrepriseInfo.entr_etablished}</span>

                                    </div>
                                    <div className="col-md-6"><span style={{color: 'black'}}><b>Lieu :</b></span>
                                        <span
                                            style={{
                                                borderBottom: '1px dashed #000',
                                                width: '100%'
                                            }}>{entrepriseInfo && entrepriseInfo.entr_location}</span>
                                    </div>
                                    <div className="col-md-12">
                                        <span style={{color: 'black'}}><b>Numéro de patente :</b></span> <span
                                        style={{
                                            borderBottom: '1px dashed #000',
                                            width: '100%'
                                        }}>{entrepriseInfo && entrepriseInfo.entr_patent_number}</span>

                                    </div>

                                </div>

                                <div className="row pb-3">
                                    <div className="col-md-12"><p
                                        style={{border: '1px solid black', textAlign: 'center'}}>
                                        PERSONNE DE CONTACT</p></div>
                                    <div className="col-md-12">
                                        <div className="row pb-2">
                                            <div className="col-md-12">
                                        <span
                                            style={{color: 'black'}}><b>Responsable des Ressources Humaines : </b></span>
                                                <span style={{
                                                    borderBottom: '1px dashed #000',
                                                    width: '100%'
                                                }}>{entrepriseInfo && entrepriseInfo.rh_fulll_name}</span>
                                            </div>
                                            <div className="col-md-6">
                                                <span style={{color: 'black'}}><b>Email : </b></span>
                                                <span style={{
                                                    borderBottom: '1px dashed #000',
                                                    width: '100%'
                                                }}>{entrepriseInfo && entrepriseInfo.rh_email}</span>

                                            </div>
                                            <div className="col-md-6">
                                                <span style={{color: 'black'}}><b>Tél :</b></span> <span style={{
                                                borderBottom: '1px dashed #000',
                                                width: '100%'
                                            }}>{entrepriseInfo && entrepriseInfo.rh_phone}</span>

                                            </div>

                                        </div>

                                        <div className="row pb-2">
                                            <div className="col-md-12">
                                        <span
                                            style={{color: 'black'}}><b>Responsable de marketing et vente : </b></span>
                                                <span style={{
                                                    borderBottom: '1px dashed #000',
                                                    width: '100%'
                                                }}>{entrepriseInfo && entrepriseInfo.rm_fulll_name}</span>
                                            </div>
                                            <div className="col-md-6">
                                                <span style={{color: 'black'}}><b>Email : </b></span>
                                                <span style={{
                                                    borderBottom: '1px dashed #000',
                                                    width: '100%'
                                                }}>{entrepriseInfo && entrepriseInfo.rm_email}</span>

                                            </div>
                                            <div className="col-md-6">
                                                <span style={{color: 'black'}}><b>Tél :</b></span> <span style={{
                                                borderBottom: '1px dashed #000',
                                                width: '100%'
                                            }}>{entrepriseInfo && entrepriseInfo.rm_phone}</span>

                                            </div>

                                        </div>

                                        <div className="row pb-2">
                                            <div className="col-md-12">
                                        <span
                                            style={{color: 'black'}}><b>Responsable de la Comptabilité: </b></span>
                                                <span style={{
                                                    borderBottom: '1px dashed #000',
                                                    width: '100%'
                                                }}>{entrepriseInfo && entrepriseInfo.rc_fulll_name}</span>
                                            </div>
                                            <div className="col-md-6">
                                                <span style={{color: 'black'}}><b>Email : </b></span>
                                                <span style={{
                                                    borderBottom: '1px dashed #000',
                                                    width: '100%'
                                                }}>{entrepriseInfo && entrepriseInfo.rc_email}</span>

                                            </div>
                                            <div className="col-md-6">
                                                <span style={{color: 'black'}}><b>Tél :</b></span> <span style={{
                                                borderBottom: '1px dashed #000',
                                                width: '100%'
                                            }}>{entrepriseInfo && entrepriseInfo.rc_phone}</span>

                                            </div>

                                        </div>

                                        <div className="row pb-2">
                                            <div className="col-md-12">
                                        <span
                                            style={{color: 'black'}}><b>Directeur Général : </b></span>
                                                <span style={{
                                                    borderBottom: '1px dashed #000',
                                                    width: '100%'
                                                }}>{entrepriseInfo && entrepriseInfo.dg_fulll_name}</span>
                                            </div>
                                            <div className="col-md-6">
                                                <span style={{color: 'black'}}><b>Email : </b></span>
                                                <span style={{
                                                    borderBottom: '1px dashed #000',
                                                    width: '100%'
                                                }}>{entrepriseInfo && entrepriseInfo.dg_email}</span>

                                            </div>
                                            <div className="col-md-6">
                                                <span style={{color: 'black'}}><b>Tél :</b></span> <span style={{
                                                borderBottom: '1px dashed #000',
                                                width: '100%'
                                            }}>{entrepriseInfo && entrepriseInfo.dg_phone}</span>

                                            </div>

                                        </div>
                                    </div>


                                </div>

                                <div className="row pb-3">
                                    <div className="col-md-12"><p
                                        style={{border: '1px solid black', textAlign: 'center'}}>
                                        INFORMATIONS BANCAIRES</p></div>
                                    <div className="col-md-12">
                                        <span style={{color: 'black'}}><b>Nom de la banque : </b></span> <span
                                        style={{
                                            borderBottom: '1px dashed #000',
                                            width: '100%'
                                        }}>{entrepriseInfo && entrepriseInfo.bank_name}</span>
                                    </div>
                                    <div className="col-md-12">
                                        <span style={{color: 'black'}}><b>Nom du compte : </b></span>
                                        <span style={{
                                            borderBottom: '1px dashed #000',
                                            width: '100%'
                                        }}>{entrepriseInfo && entrepriseInfo.bank_account_name}</span>

                                    </div>
                                    <div className="col-md-6">
                                        <span style={{color: 'black'}}><b>Numéro du compte :</b></span> <span
                                        style={{
                                            borderBottom: '1px dashed #000',
                                            width: '100%'
                                        }}>{entrepriseInfo && entrepriseInfo.bank_account_number}</span>

                                    </div>
                                    <div className="col-md-6"><span
                                        style={{color: 'black'}}><b>Officier bancaire :</b></span>
                                        <span style={{
                                            borderBottom: '1px dashed #000',
                                            width: '100%'
                                        }}>{entrepriseInfo && entrepriseInfo.bank_officier_name}</span>

                                    </div>
                                    <div className="col-md-6">
                                        <span style={{color: 'black'}}><b>Email :</b></span> <span style={{
                                        borderBottom: '1px dashed #000',
                                        width: '100%'
                                    }}>{entrepriseInfo && entrepriseInfo.bank_officier_email}</span>

                                    </div>
                                    <div className="col-md-6"><span style={{color: 'black'}}><b>Tél :</b></span>
                                        <span
                                            style={{
                                                borderBottom: '1px dashed #000',
                                                width: '100%'
                                            }}>{entrepriseInfo && entrepriseInfo.bank_officier_phone}</span>

                                    </div>
                                </div>

                                <div className="row pb-5" style={{border: '1px solid black', marginBottom: '10px'}}>
                                    <div className="col-md-12"><p
                                        style={{textAlign: 'center'}}>
                                        Type de campagne</p></div>

                                    {entrepriseInfo && entrepriseInfo.campaign_type.length > 0 ? entrepriseInfo.campaign_type.map(value => (
                                            <div className="col-md-12">
                                                <input type="checkbox" disabled="disabled"
                                                       checked={true}/><span
                                                className="ml-2">{value}</span></div>)) : null}


                                </div>

                                <div className="row pb-5" style={{border: '1px solid black', marginBottom: '10px'}}>
                                    <div className="col-md-12"><p
                                        style={{textAlign: 'center'}}>
                                        Services</p></div>

                                    {entrepriseInfo && entrepriseInfo.service_ab.length > 0 ? entrepriseInfo.service_ab.map(value => (
                                            <div className="col-md-12">
                                                <input type="checkbox" disabled="disabled"
                                                       checked={true}/><span
                                                className="ml-2">{value}</span></div>)) : null}


                                </div>


                                <div className="row pt-3" style={{border: '1px solid black'}}>
                                    <div className="col-md-12"><p style={{textAlign: 'center'}}>
                                        Documents annexés au présent formulaire :</p></div>
                                    <div className="col-md-12">
                                        <input type="checkbox" disabled="disabled"
                                               checked={doc && doc[0] == "Carte d’identité de la personne de contact" || doc && doc[0] == "Contrat de prestation service" || doc && doc[0] == "Patente et matricule fiscale" ? true : false}/><span
                                        className="ml-2">{doc && doc[0]}</span>
                                    </div>
                                    <div className="col-md-12">
                                        <input type="checkbox" disabled="disabled"
                                               checked={doc && doc[1] == "Carte d’identité de la personne de contact" || doc && doc[1] == "Contrat de prestation service" || doc && doc[1] == "Patente et matricule fiscale"}/><span
                                        className="ml-2">{doc && doc[1]}</span>
                                    </div>

                                    <div className="col-md-12">
                                        <input type="checkbox" disabled="disabled"
                                               checked={doc && doc[2] == "Carte d’identité de la personne de contact" || doc && doc[2] == "Contrat de prestation service" || doc && doc[2] == "Patente et matricule fiscale"}/><span
                                        className="ml-2">{doc && doc[2]}</span>
                                    </div>

                                </div>

                                <div className="row pb-3 pt-4">
                                    <div className="col-md-12">
                                        <p style={{textAlign: 'center', color: '#000'}}>
                                            Votre signature atteste votre responsabilité financière, ainsi que votre
                                            capacité et
                                            votre
                                            consentement à payer les sommes dues figurant sur le compte,
                                            conformément à nos
                                            conditions
                                            de prestations de services.</p></div>
                                    <div className="col-md-12">
                                        <span style={{color: 'black'}}><b>Signature : </b></span> <span style={{
                                        borderBottom: '1px dashed #000',
                                        width: '100%'
                                    }}>{entrepriseInfo && entrepriseInfo.signature}</span>
                                    </div>
                                    <div className="col-md-12">
                                        <span style={{color: 'black'}}><b>Date : </b></span> <span style={{
                                        borderBottom: '1px dashed #000',
                                        width: '100%'
                                    }}>{entrepriseInfo && entrepriseInfo.sign_date}</span>
                                    </div>
                                    <div className="col-md-12">
                                        <span style={{color: 'black'}}><b>Nom du signataire : </b></span> <span
                                        style={{
                                            borderBottom: '1px dashed #000',
                                            width: '100%'
                                        }}>{entrepriseInfo && entrepriseInfo.sign_name}</span>
                                    </div>
                                    <div className="col-md-12">
                                        <span style={{color: 'black'}}><b>Fonction : </b></span> <span style={{
                                        borderBottom: '1px dashed #000',
                                        width: '100%'
                                    }}>{entrepriseInfo && entrepriseInfo.sign_fonction}</span>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div class="modal-footer">

                            <div>
                                <ReactToPrint
                                    trigger={() => <button type="button" class="btn btn-secondary"><FormattedMessage
                                        id="app.label.print" defaultMessage="Print"/></button>}
                                    content={() => this.componentRef}
                                />

                            </div>

                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'none'}}>
                <ComponentToPrintInfo ref={el => (this.componentRef = el)} entrepriseInfo={entrepriseInfo}/>
            </div>
            </>
        )
    }
}


export default EntrepriseModalInfos;