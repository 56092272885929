import React from "react";
import {FormattedMessage} from "react-intl";
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization, ageYearDif} from "../../../../constants/utils";

const TableSession = props => {


    const {agS,language,selectedData} = props;
    const columnss = [
        {
            title: getIntlMsg(intl(language), `app.label.extension_number`),
            field: 'extension', type: 'text',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF'
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            title: getIntlMsg(intl(language), `app.label.signin`),
            field: 'signin', type: 'text',
            cellStyle: {
                backgroundColor: '#12a652',
                color: '#FFF'
            },
            headerStyle: {
                backgroundColor: '#12a652',
            }
        },
        {
            title: getIntlMsg(intl(language), `app.label.signout`),
            field: 'signout', type: 'text',
            cellStyle: {
                backgroundColor: '#ff000a',
                color: '#FFF'
            },
            headerStyle: {
                backgroundColor: '#ff000a',
            }
        },
        {
            title: 'IP',
            type: 'text',
            field: 'ipaddress',
        },
        {
            title: getIntlMsg(intl(language), `app.label.created_date`),
            type: 'text',
            field: 'date_in_out',
        }
    ]



    return (

        <MaterialTable
            title={getIntlMsg(intl(language), `app.label.session_list`)+" "+selectedData.username}
            columns={columnss}
            data={agS ? agS : []}
            icon='unfold_less'
            localization={Localization(language)}
            options={{
                grouping: true,
                headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF'
                },
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true
            }}

        />
    );
}


export default TableSession