/**
 * Created by louidorpc on 9/12/20.
 */
import React from 'react'
import  {app} from '../../../constants/config'
import {getIntlMsg, intl, Localization} from "../../../constants/utils";
import {FormattedMessage} from "react-intl";
import ReactToPrint from "react-to-print";
import ComponentToPrintInfo from './ComponentToPrint'
import MaterialTable from 'material-table';
import Loader from '../../../common/loader'
import FileDownload from 'js-file-download';

const ids = ['2']

const DocumentModalInfos = props => {

    const {language, documents, person_id, getEntrepriseInfo}=props;

    const columns = [

        {
            title: getIntlMsg(intl(language), `app.label.entr_name`),
            field: 'entr_name', type: 'text'
        },
        {
            title: getIntlMsg(intl(language), `app.label.document_name`),
            field: 'doc_name', type: 'text',
        },
    ]

    function downloadFile(filePath) {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();
    }

    return (<>
        <div class="modal fade modal-slide-right" id="slideRightModaldoc" tabindex="-1" role="dialog"
             aria-labelledby="slideRightModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-lg" role="document">
                <div class="modal-content modal-lg">
                    <div class="modal-header">
                        <h5 class="modal-title"
                            id="slideRightModalLabel">Liste des documents</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        {documents.length > 0 ? <MaterialTable
                                title="Document"
                                columns={columns}
                                data={documents ? documents : []}
                                icon='unfold_less'
                                localization={Localization(language)}
                                options={{
                                    headerStyle: {
                                        backgroundColor: '#01579b',
                                        color: '#FFF'
                                    },
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    exportAllData: true,
                                    pageSize: documents.length,
                                    pageSizeOptions: [10, 20, 25, 50, 100]
                                }
                                }
                                actions={[{
                                    icon: row => {
                                        return (<i className="mdi mdi-eye"></i>
                                        )
                                    },
                                    tooltip: 'View',
                                    onClick: (e, row) => {
                                        window.open(app.BASE_IMAGE_URL + "models/images/entreprises/" + row.doc_name, "", "width=800,height=500", "target=_blanc");

                                    }

                                }]
                                }

                            /> : <Loader/>}

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>

        </>
    )

}


export default DocumentModalInfos;