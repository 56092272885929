/**
 * Created by louidorpc on 11/4/20.
 */
 import axios from "axios"
 import {app} from '../constants/config'
 
 
 const instance = axios.create({
     baseURL: app.BaseURL,
     timeout: 100000,
 });
 
 export const getAllCalendarEvents = () => {
     return instance.get(`/event/index.php`)
 };

 export const getAllCalendarEventsByEntreprise = entreprise => {
    return instance.get(`/event/readByEntreprise/index.php?id=${entreprise}`)
};

export const getAllCalendarEventsByAgent = creator => {
    return instance.get(`/event/readByAgent/index.php?creator=${creator}`)
};

 export const createCalendarEvent = event => {
    return instance.post(`/event/create/index.php`,event)
};

export const updateCalendarEvent = event => {
    return instance.put(`/event/update/index.php`,event)
};

export const viewCalendarEvent = view => {
    return instance.put(`/event/view/index.php`,view)
};


export const publihsCalendarEvent = event => {
    return instance.put(`/event/publish/index.php`,event)
};
 
