import {put, call, takeLatest} from "redux-saga/effects";
import * as actions from "../actions/creator/feedback";
import types from "../actions/type";
import * as api from "../services/feedback";
import {getFeedbackStatistic} from "../actions/creator/setting";


export const handleResetStateFeedback = function* () {
    try {
        yield put(actions.resetFeedbackSuccess())
    } catch (error) {

    }
};


export const handleGetFeedback = function* (action) {

    const {id} = action.payload;


    try {
        const getF = yield call(api.getFeedbacks, id);
        if (getF.data.length > 0) {
            yield put(actions.getFeedbackSuccess(getF.data))
        } else {
            yield put(actions.getFeedbackFailed(getF.data.message))
        }

    } catch (error) {
        yield put(actions.getFeedbackFailed("error"))
    }


};

export const handleCreateFeedback = function* (action) {
    const {feedback} = action.payload;
    try {
        const pos = yield call(api.createFeedback, feedback);
        if (pos.data.response == "success") {
            yield put(actions.createFeedbackSuccess(feedback.language))

        } else {
            yield put(actions.createFeedbackFailled({mfeed: pos.data.message, locale: feedback.language}))
        }
    } catch (error) {
        yield put(actions.createFeedbackFailled({mfeed: 'app.label.saveError', locale: feedback.language}))
    }
};


export const handleUpdateFeedbackS = function* (action) {
    const {feedId} = action.payload
    try {
        const getPs = yield call(api.updateFeedback, feedId);
        if (getPs.data?.response === 'success') {
            yield put(actions.updateFeedbackSuccess(getPs.data))
            yield put(getFeedbackStatistic(feedId?.receive_id))
        } else {
            yield put(actions.updateFeedbackFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.updateFeedbackFailled("error"))
    }
}


export const feedbackSaga = [
    takeLatest(types.GET_FEEDBACK, handleGetFeedback),
    takeLatest(types.CREATE_FEEDBACK, handleCreateFeedback),
    takeLatest(types.RESET_FEEDBACK, handleResetStateFeedback),
    takeLatest(types.UPDATE_FEEDBACK, handleUpdateFeedbackS),
];
