/**
 * Created by louidorpc on 8/4/20.
 */
 import React from 'react'
 import Calendar from '../../components/admin/calendar'
 import {getFeedback, resetFeedback} from "../../actions/creator/feedback";
 
 
 const CalendarComponent = props => {
 
     const {
         sr: {session, isLoadingS}, actions: {
             getAllEntreprises, getFeedback, createFeedback,resetFeedback,getAllCalendarEvents,  changeView,
             changeEvents,getCalendarEventInfo,createCalendarEvent,calendarEventChangeStatus,updateCalendarEvent,
             publishCalendarEvent,getAllCalendarEventsByEntreprise,getAllCalendarEventsByAgent,setViewCalendarEvent
         }, person: {ps, isLoading, mups, cms}, intl, st: {sites},
     } = props;
 
     if (session !== null)
         return (
             <Calendar session={session}
             getAllEntreprises={getAllEntreprises}
             getAllCalendarEvents={getAllCalendarEvents}
             changeView={changeView}
             changeEvents={changeEvents}
             getCalendarEventInfo={getCalendarEventInfo}
             createCalendarEvent={createCalendarEvent}
             calendarEventChangeStatus={calendarEventChangeStatus}
             updateCalendarEvent={updateCalendarEvent}
             publishCalendarEvent={publishCalendarEvent}
             getAllCalendarEventsByEntreprise={getAllCalendarEventsByEntreprise}
             getAllCalendarEventsByAgent={getAllCalendarEventsByAgent}
             setViewCalendarEvent={setViewCalendarEvent}
             />
         )
     else return <div></div>
 }
 
 
 export default CalendarComponent;