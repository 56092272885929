import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import ExperienAdmin from './expAdmin'


const ConfigComponent = props => {

    const { getAllEntreprises, session, getAllLinks, createLink, updateLink, deleteLink, getLink } = props;

    useEffect(() => {
        getAllEntreprises()
    }, [])


    const { entreprises, links } = useSelector(state => ({
        entreprises: state?.entrepriseReducer?.entreprises || [],
        // links: state?.linksReducer?.links || [],
    }));


    return (
        <section className="admin-content">

            <div className="container-fluid bg-dark m-b-30">
                <div className="row  p-b-60 p-t-20">
                    <div className="col-md-6 text-white my-auto text-md-right p-b-30">

                        <div className="">
                            <h2 className="opacity-75"><a href="#!">
                                Configuration</a>
                            </h2>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid pull-up">
                <div className="row ">
                    <div className="col-lg-12">
                        <div className="card m-b-30">
                            <div className="card-header">
                                <div className="card-title"></div>
                            </div>
                            <div className="card-body">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">

                                    <li className="nav-item">
                                        <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                            href="#reportExpAdmin"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-link"> Liste des Liens</i></a>
                                    </li>

                                </ul>
                                <div className="tab-content" id="myTabContent1">

                                    <div className="tab-pane fade show active" id="reportExpAdmin" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <ExperienAdmin {...{ entreprises, person_id: session?.person_id, language: session?.language, getAllLinks, createLink, updateLink, deleteLink }} />
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}


export default ConfigComponent;