import React from "react"
import {Brand} from "./Brand"
import Menu from "./Menu"
import {Footer} from "./Footer"

export const AsideBase = ({userinfo,path}) => {
    return (
        <>
        <aside class="admin-sidebar">
            <Brand/>
            <Menu  userinfo={userinfo} path={path}/>
            <Footer/>
        </aside>
        </>

    )
}

