import React, {useContext, useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {app} from "../../../constants/config";
import {useSelector} from "react-redux";
import moment from 'moment'
import Pusher from 'pusher-js';
import DownloadLink from "react-download-link";
import {saveAs} from "file-saver";
import {getCookie} from "react-use-cookie";
import $ from 'jquery';
import {GlobalContext} from "../../../AppLayout";




const MessageItem = props => {
    const {item, session, createFeedback, getFeedback, resetFeedback, scrollBottom} = props;

    const {pusher} = useContext(GlobalContext)


    const documentType = (file, type) => {
        switch (type) {
            case 'pdf':
            case 'PDF':
                return (
                    "/assets/img/icon/pdf.svg"
                )
            case 'csv':
                return (
                    "/assets/img/icon/csv.svg"
                )
            case 'xlsx':
                return (
                    "/assets/img/icon/xls.svg"
                )
            case 'docx':
                return (
                    "/assets/img/icon/docx.svg"
                )
            case 'png':
            case 'JPG':
            case 'jpg':
            case 'jpeg':
            case 'JPEG':
            case 'PNG':

                return (
                    file
                )
            default:
                return (
                    "/assets/img/icon/doc.svg"
                )
        }
    }

    const {register, handleSubmit, reset} = useForm();
    const [fileName, setFileName] = useState(null);
    const onSubmit = (data) => {
        createFeedback({
            ...data,
            sender_id: session?.entreprise_id,
            person_id: session?.person_id,
            receive_id: item?.id,
            fileName
        })

    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handleOnFileChange = async (e) => {
        const targetFile = e.target.files[0]
        var reader = new FileReader();
        if (targetFile) {
            const val = await getBase64(targetFile)
            const fileName = targetFile.name;

            const current = {
                'size': targetFile.size,
                'fileName': fileName.substring(0, fileName.lastIndexOf('.')),
                'fileType': fileName.substring(fileName.lastIndexOf('.') + 1),
                'uploadedFile': val
            }

            setFileName(current)
        } else {
            setFileName(null)
        }
    }

    const {feedbacks, mfeed, isLoadingCfeed} = useSelector(state => ({
        feedbacks: state?.feedbackReducer?.feedbacks || [],
        mfeed: state.feedbackReducer.mfeed,
        isLoadingCfeed: state.feedbackReducer.isLoadingCfeed,
    }));

    const handleResetState = () => {
        resetFeedback()
        reset();
        setFileName(null)

    }

    useEffect(() => {
        const channel = pusher.subscribe('my-channel');
        channel.bind('my-event', data => {
            showMessages(JSON.stringify(data.message));
        })

    }, [])

    const showMessages = (mst) => {


        let entity = getCookie('token') ? JSON.parse(getCookie('token')) : null;
        const ms = JSON.parse(mst);
        
        

        if (entity !== null) {
            if (session?.entreprise_id == ms?.sender_id) {
              
                getFeedback({sender_id: ms?.sender_id, person_id: ms?.receive_id});
                setTimeout(() => scrollBottom(entity), 500)
            }
            if (session?.entreprise_id == ms?.receive_id) {
                getFeedback({sender_id:ms?.receive_id, person_id: ms?.sender_id});
                setTimeout(() => scrollBottom(entity), 500)
            }
        }

    }



    if (mfeed == "success") handleResetState()
    return (
        <div className="usable-height panel chat-window">
            <div className="row chat-window-header no-gutters">
                <div className="col-12 col-lg-12 ">
                    <div className="d-inline-block d-lg-none align-middle">
                        <a data-target=".chat-sidebar" data-toggleclass="d-none" href="#"
                           className="m-r-10">
                            <i className="mdi mdi-menu mdi-24px"></i>
                        </a>
                    </div>

                    <div className="d-inline-block align-middle">
                        <p className="font-secondary m-b-0">{item?.entr_name} </p>
                        <a href="#" className="text-muted m-r-5">Vu à {moment().format('LTS')}</a>
                        <span className="d-none d-lg-inline"><span className="text-muted m-r-5">| </span>
                                      </span>
                    </div>
                </div>
            </div>
            <div className=".panel-body chat-window-body">

                {feedbacks.map(v => <div className="chat-item border-bottom row">
                    <div className="col-12">
                        <p>

                            <div className="media" id={'medias'}>
                                <div className="d-inline-block m-r-10 align-middle">
                                    <div className="avatar avatar">
                                        {v.photo !== "" ? <img src={app.BASE_IMAGE_URL + '/models/images/' + v.photo}
                                                               className="avatar-img rounded-circle"/> :
                                            <span
                                                className="avatar-title rounded-circle  bg-dark"> {(v.fullName).substring(0, 1)} </span>}
                                    </div>
                                </div>
                                <div className="media-body my-auto">
                                    <p className="font-secondary m-b-0"> {v.fullName} <code
                                        className="text-muted font-primary m-l-10 bg-soft-primary p-1">{moment(v.created_date).format('LLL')}</code>
                                    </p>
                                    <div></div>
                                    <div className="text-muted">
                                        {v.message}
                                    </div>
                                    {v.fileName ? <div className="d-block">
                                        <div className="card w-25">
                                            <img className="card-img-top"
                                                 src={documentType(app.BASE_IMAGE_URL + 'models/images/' + v.fileName, v?.fileName?.split('&seed.')[1])}
                                                 alt="Card image cap"/>
                                            <div className="text-white card-controls" style={{background: 'black'}}>
                                                <a href="#" className="badge"
                                                   onClick={() => window.open(app.BASE_IMAGE_URL + '/models/images/' + v.fileName, "", "width=800,height=500", "target=_blanc")}>
                                                    <i className="mdi mdi-18px mdi-download"></i>
                                                </a>
                                      
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>
                            </div>
                        </p>

                    </div>
                </div>)}
            </div>

            <div className="panel-footer chat-window-footer">
                <div className="row full-height">
                    <div className="col-12 my-auto">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-group input-group-flush">
                                            <textarea {...register("message")} type="text"
                                                      className="form-control form-control-lg  form-control-appended form-control-prepended"
                                                      placeholder={`Message pour @ ${item?.entr_name}`}/>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <input {...register("fileName")} type="file" style={{display: 'none'}}
                                               id={'fileName'} onChange={handleOnFileChange}/>
                                        <label htmlFor={'fileName'}><i className="mdi mdi-attachment"
                                                                       style={{
                                                                           cursor: 'pointer',
                                                                           fontSize: '26px'
                                                                       }}></i>
                                            {fileName?.uploadedFile ? <img
                                                src={documentType(fileName.uploadedFile, fileName.fileType)}
                                                style={{width: '70px', height: '70px'}}
                                                width={'70px'}
                                                height={'70px'}
                                                alt="..." className="avatar-img rounded-circle"/> : null}
                                        </label>
                                    </div>
                                </div>
                                <div className="input-group-append">
                                    {isLoadingCfeed ? <div className="spinner-border" role="status"></div> :
                                        <div className="input-group-text">
                                            <button className="btn btn-dark btn-sm btnS" type="submit">Send</button>
                                        </div>}

                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MessageItem