/**
 * Created by louidorpc on 1/20/21.
 */
import React from "react"
import {FormattedMessage} from "react-intl";
import {getIntlMsg} from "../constants/utils";

export class FileInput extends React.Component {

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    onFileChange = async(e) => {
        const {input} = this.props
        const targetFile = e.target.files[0]
        var reader = new FileReader();
        if (targetFile) {
            const val = await this.getBase64(targetFile)
            const fileName = targetFile.name;
            const current = {
                'size': targetFile.size,
                'fileName': fileName.substring(0, fileName.lastIndexOf('.')),
                'fileType': fileName.substring(fileName.lastIndexOf('.') + 1),
                'uploadedFile': val
            }

            input.onChange(current)
        } else {
            input.onChange(null)
        }
    }

    render() {

        return (


            <input
                type="file"
                onChange={this.onFileChange}
            />
        )
    }
}