/**
 * Created by louidorpc on 8/11/20.
 */
import React, { useEffect } from 'react'
import { Person } from '../../../constants/model';
import { app } from '../../../constants/config';
import Loader from '../../../common/loader';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { FormattedMessage } from "react-intl";
import { Bar, Line } from 'react-chartjs-2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AgentIncomingCall from './lists/incomingCall';
import AgentOutgoingCall from './lists/outgoingCall';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const AgentDashboard = ({ session, getUserSessionInfo, getAllTatistic, getAllPbxReportAgent, cReports, stats, getAllIncomingCallAgent, cIncomingCalls, getAllOutgoingCallAgent, cOutgoingCalls }) => {

    useEffect(() => {
        getAllPbxReportAgent(session?.extension);
        getAllTatistic(session?.extension);
    }, [session])


    const data = {
        labels: cReports?.reports && cReports?.reports?.length > 0 ? cReports?.reports?.map(value =>  value.extension ) : [],
        datasets: [
            {
                label: '# appels entrants',
                data: cReports?.reports && cReports?.reports.length > 0 ? cReports?.reports?.map(value => value.num_incoming_call) : [],
                backgroundColor: '#3ecf8e',
            },
            {
                label: '# appels sortants',
                data: cReports?.reports && cReports?.reports?.length > 0 ? cReports?.reports?.map(value => value.num_outgoing_call) : [],
                backgroundColor: '#68d4f8',
            },
            {
                label: '# appels perdus',
                data: cReports?.reports && cReports?.reports?.length > 0 ? cReports?.reports?.map(value => value.num_incoming_call_no_answer) : [],
                backgroundColor: '#f2545b',
            },
        ],
    }

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    }

    const [open, setOpen] = React.useState(false);
    const [state, setSate] = React.useState(null);

    const handleClickOpen = (st) => {
        if (st == 'incoming') {
            getAllIncomingCallAgent(session?.extension)
        } else if (st == 'outgoing') {
            getAllOutgoingCallAgent(session?.extension)
        } else {

        }
        setSate(st)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <section class="admin-content">
                <div class="bg-dark ">
                    <div class="container-fluid m-b-30">
                        <div class="row">

                            <div class="col-md-12 p-b-60">
                                <div id="chart-09" class="chart-canvas invert-colors">
                                    <Line
                                        height={'100'}
                                        data={{
                                            labels: stats && stats.stat ? stats.stat.map(value => value.caldate) : [],
                                            datasets: [
                                                {
                                                    label: 'Nombre d\'appel',
                                                    fill: true,
                                                    fontSize: 3,
                                                    lineTension: 0.1,
                                                    borderColor: 'rgb(53, 162, 235)',
                                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                                    borderWidth: 2,
                                                    data: stats && stats.stat ? stats.stat.map(value => value.countCall) : []
                                                }
                                            ]
                                        }}
                                        options={{
                                            responsive: true,


                                            title: {

                                                display: true,
                                                text: 'Tableau de performance sur le nombre d\'appel par jours',
                                                fontSize: 16,
                                            },

                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row  pull-up d-lg-flex">
                        <div class="col m-b-30">
                            <div class="card ">

                                <div class="card-body" style={{ cursor: 'pointer' }} onClick={() => handleClickOpen('incoming')}>
                                    <div class="card-controls">
                                        <a href="#" class="badge badge-soft-success"> <i class="mdi mdi-phone-incoming"></i></a>
                                    </div>
                                    <div class="text-center p-t-30 p-b-20">
                                        <div class="text-overline text-muted opacity-75">Nombre total Appels entrants</div>
                                        <h1 class="text-success">{cReports?.stats && cReports?.stats?.num_incoming_call || 0}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col m-b-30">
                            <div class="card">

                                <div class="card-body" style={{ cursor: 'pointer' }} onClick={() => handleClickOpen('outgoing')}>
                                    <div class="card-controls">
                                        <a href="#" class="badge badge-soft-info"> <i class="mdi mdi-phone-outgoing"></i></a>
                                    </div>
                                    <div class="text-center p-t-30 p-b-20">
                                        <div class="text-overline text-muted opacity-75">Nombre total Appels sortants</div>
                                        <h1 class="text-info">{cReports?.stats && cReports?.stats?.num_outgoing_call || 0}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col m-b-30">
                            <div class="card ">
                                <div class="card-body" style={{ cursor: 'pointer' }} onClick={() => handleClickOpen('missed')}>
                                    <div class="card-controls">
                                        <a href="#" class="badge badge-soft-danger"> <i class="mdi mdi-call-missed"></i></a>
                                    </div>
                                    <div class="text-center p-t-30 p-b-20">
                                        <div class="text-overline text-muted opacity-75">
                                            Appels manquants
                                        </div>
                                        <h1 class="text-danger">{cReports?.stats && cReports?.stats?.num_incoming_call_no_answer || 0}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col m-b-30">
                            <div class="card ">

                                <div class="card-body" style={{ cursor: 'pointer' }} onClick={() => alert()}>
                                    <div class="card-controls">
                                        <a href="#" class="badge badge-soft-success"> <i class="mdi mdi-clock"></i> sec</a>
                                    </div>
                                    <div class="text-center p-t-30 p-b-20">
                                        <div class="text-overline text-muted opacity-75">
                                            Durée total des appels entrants
                                        </div>
                                        <h1 class="text-success">{cReports?.stats && cReports?.stats?.duration_incoming_call || 0}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col m-b-30">
                            <div class="card ">

                                <div class="card-body" style={{ cursor: 'pointer' }} onClick={() => alert()}>
                                    <div class="card-controls">
                                        <a href="#" class="badge badge-soft-info"> <i class="mdi mdi-clock"></i> sec</a>
                                    </div>
                                    <div class="text-center p-t-30 p-b-20">
                                        <div class="text-overline text-muted opacity-75">
                                            Durée totale  des appels sortants
                                        </div>
                                        <h1 class="text-info">{cReports?.stats && cReports?.stats?.duration_outgoing_call || 0}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div class="row">
                        <div class="col-lg-12 m-b-30">
                            <div class="row">


                                <div class="col-md-6">
                                    <div class="card m-b-30">
                                        <div class="">
                                            <Bar data={data} options={options} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="card m-b-30">
                                        <Line
                                            data={{
                                                labels: cReports?.incomingByday?.length > 0 ? cReports?.incomingByday?.map(value => value.caldate) : [],
                                                datasets: [
                                                    {
                                                        label: '# Appels',
                                                        fill: true,
                                                        lineTension: 0.5,
                                                        backgroundColor: '#3ecf8e',
                                                        borderColor: 'rgba(0,0,0,1)',
                                                        borderWidth: 2,
                                                        data: cReports?.incomingByday?.length > 0 ? cReports?.incomingByday?.map(value => value.countCall) : []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "Tableau de performance quotidienne sur le nombre d'appels entrant",
                                                    fontSize: 16
                                                },
                                                legend: {
                                                    display: false,
                                                    position: 'left'
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div class="card m-b-30">
                                        <Line
                                            data={{
                                                labels: cReports?.outgoingByday?.length > 0 ? cReports?.outgoingByday?.map(value => value.caldate) : [],
                                                datasets: [
                                                    {
                                                        label: '# Appels',
                                                        fill: true,
                                                        lineTension: 0.5,
                                                        backgroundColor: '#68d4f8',
                                                        borderColor: 'rgba(0,0,0,1)',
                                                        borderWidth: 2,
                                                        data: cReports?.outgoingByday?.length > 0 ? cReports?.outgoingByday?.map(value => value.countCall) : []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "Tableau de performance quotidienne sur le nombre d'appels sortants",
                                                    fontSize: 16
                                                },
                                                legend: {
                                                    display: false,
                                                    position: 'left'
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div class="card m-b-30">
                                        <Line
                                            data={{
                                                labels: cReports?.missedByday?.length > 0 ? cReports?.missedByday?.map(value => value.caldate) : [],
                                                datasets: [
                                                    {
                                                        label: '# Appels',
                                                        fill: true,
                                                        lineTension: 0.5,
                                                        backgroundColor: 'rgb(255, 99, 132)',
                                                        borderColor: 'rgba(0,0,0,1)',
                                                        borderWidth: 2,
                                                        data: cReports?.missedByday?.length > 0 ? cReports?.missedByday?.map(value => value.countCall) : []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "Tableau de performance quotidienne sur le nombre d'appels entrants manqués",
                                                    fontSize: 16
                                                },
                                                legend: {
                                                    display: false,
                                                    position: 'left'
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </section>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Liste des  Appels {state == 'incoming' ? 'entrants' : state == 'outgoing' ? 'sortants' : 'manques'}
                        </Typography>
                        <Button autoFocus color="inherit">
                            {state == 'incoming' ? cIncomingCalls?.length : state == 'outgoing' ? cOutgoingCalls.length : ''}
                        </Button>

                    </Toolbar>
                </AppBar>
                {state == 'incoming' ? <AgentIncomingCall {...{ cIncomingCalls, session }} /> : state == 'outgoing' ? <AgentOutgoingCall {...{ cOutgoingCalls, session }} /> : <AgentIncomingCall />}
            </Dialog>
        </>
    )
}

export default AgentDashboard