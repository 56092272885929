import {put, call, takeLatest} from "redux-saga/effects";
import  * as actions from "../actions/creator/entreprise";
import types from "../actions/type";
import * as api from "../services/entreprise";


export const handleResetEntreprise = function*() {
    yield put(actions.resetEntrepriseSuccess())
};

export const handleGetAllEntreprises = function*() {

    try {
        const getE = yield call(api.getAllEntreprises);
        if (getE.data.length > 0) {
            yield put(actions.getAllEntreprisesSuccess(getE.data))
        } else {
            yield put(actions.getAllEntreprisesFailled(getE.data.message))
        }

    } catch (error) {
        yield put(actions.getAllEntreprisesFailled("error"))
    }


};

export const handleCreateEntreprise = function*(action) {
    const {entreprise} = action.payload;
    try {
        const entr = yield call(api.createEntreprise, entreprise);
        if (entr.data.message == "success") {
            yield put(actions.createEntrepriseSuccess(entreprise.language))
            yield put(actions.getAllEntreprises())

        } else {
            yield put(actions.createEntrepriseFailled({msEnt: entr.data.message, locale: entreprise.language}))
        }
    } catch (error) {
        yield put(actions.createEntrepriseFailled({msEnt: 'app.label.saveError', locale: entreprise.language}))
    }
};

export const handleUpdateEntreprise = function*(action) {
    const {upent} = action.payload;
    try {
        const entr = yield call(api.updateEntreprise, upent);
        if (entr.data.message == "success") {
            yield put(actions.updateEntrepriseSuccess(upent.language))
            yield put(actions.getAllEntreprises())

        } else {
            yield put(actions.updateEntrepriseFailled({msEnt: entr.data.message, locale: upent.language}))
        }
    } catch (error) {
        yield put(actions.updateEntrepriseFailled({msEnt: 'app.label.saveError', locale: upent.language}))
    }
};

export const handlegetEntrepriseInfo = function*(action) {
    const {entrepriseInfo} = action.payload;

    yield put(actions.getEntrepriseInfoSuccess(entrepriseInfo))
};


export const handleGetDocuments = function*({payload}) {
    const {entr_name} =payload;


    try {
        const getE = yield call(api.getAllDocuments, entr_name);
        if (getE.data.length > 0) {
            yield put(actions.getAllDocumentSuccess(getE.data))
        } else {
            yield put(actions.getAllDocumentFailled(getE.data.message))
        }

    } catch (error) {
        yield put(actions.getAllDocumentFailled("error"))
    }


};

export const entrepriseSaga = [
    takeLatest(types.GET_ALL_ENTREPRISES, handleGetAllEntreprises),
    takeLatest(types.CREATE_ENTREPRISE, handleCreateEntreprise),
    takeLatest(types.UPDATE_ENTREPRISE, handleUpdateEntreprise),
    takeLatest(types.GET_ENTREPRISE_INFO, handlegetEntrepriseInfo),
    takeLatest(types.RESET_ENTREPRISE, handleResetEntreprise),
    takeLatest(types.GET_ALL_DOCUMENTS, handleGetDocuments),
];
