import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getAllPrefix = () => {
    return instance.get(`/prefix/index.php`)
};

export const getAllPrefixAvailable = () => {
    return instance.get(`/prefix/prefix_available.php`)
};

export const createPrefix = ext => {
    return instance.post(`/prefix/create/index.php`, ext)
};


export const updatePrefix = ext => {
    return instance.put(`/prefix/update/index.php`, ext)
};


export const getAllPrefixById = prefix_id => {
    return instance.get(`/prefix/read_single/index.php?entreprise_id=${prefix_id}`)
};

export const deletePrefix = id => {
    return instance.get(`/prefix/delete/index.php?id_prefix=${id}`)
};