/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const resetStateSite = () => ({
    type: types.RESET_SITES,
    payload: {message: null}
});


export const resetStateSiteSuccess = () => ({
    type: types.RESET_SITES_SUCCESS,
    payload: {message: null}
});


export const getSiteById = sid => ({
    type: types.GET_SITE_BY_ID,
    payload: {isLoading: true, sid: sid}
});


export const getSiteByIdSuccess = site => ({
    type: types.GET_SITE_BY_ID_SUCCESS,
    payload: {isLoading: false, site: site}
});

export const getSiteByIdFailled = msite => ({
    type: types.GET_PERSON_BY_ID_FAILLED,
    payload: {isLoading: true, message: msite}
});


export const updateSiteById = usid => ({
    type: types.UPDATE_SITE,
    payload: {isLoading: true, usid: usid}
});


export const updateSiteByIdSuccess = msite => {
    return {
        type: types.UPDATE_SITE_SUCCESS,
        payload: {isLoading: false, msite: msite}
    }
};

export const updateSiteByIdFailled = msite => ({
    type: types.UPDATE_SITE_FAILLED,
    payload: {isLoading: true, msite: msite}
});


export const getSites = () => ({
    type: types.GET_SITES,
    payload: {isLoading: true}
});


export const getSitesSuccess = sites => ({
    type: types.GET_SITES_SUCCESS,
    payload: {isLoading: false, sites: sites}
});

export const getSitesFailled = msite => ({
    type: types.GET_SITES_FAILLED,
    payload: {isLoading: true, message: msite}
});


export const createSite = site => ({
    type: types.CREATE_SITE,
    payload: {isLoading: true, site: site}
});


export const createSiteSuccess = msite => ({
    type: types.CREATE_SITE_SUCCESS,
    payload: {isLoading: false, msite: msite}
});

export const createSiteFailled = msite => ({
    type: types.CREATE_SITE_FAILLED,
    payload: {isLoading: true, message: msite}
});