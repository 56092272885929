/**
 * Created by louidorpc on 8/11/20.
 */
import React from 'react';
import {app} from '../../constants/config';
import Loader from '../../common/loader';
import Progress from '../../common/progress'
import {FormattedMessage} from "react-intl";
import  {getIntlMsg, intl} from '../../constants/utils'
import moment from 'moment';
import CampaignAssign from './campaign'
import TableAssignedCampaign from './tableAssignedCampaign';
import Softphone from './softPhone'
import createHistory from 'history/createBrowserHistory';
import CampaignModal from './campaignModal'
// import  client from 'smartsheet'

class AgentManager extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            id: props.session.person_id,
            tab: "session",
            modalVisible: false,
            selectedData: false,
            show: '', display: 'none',
            show1: '', display1: 'none',
            list: []
        }
    }


    showModal = () => {
        console.log("))))))))))))))))))))))))))")
        this.setState({show: 'show', display: 'block'})
    }

    removeModal = () => {
        console.log("_________________________________")
        this.setState({show: '', display: 'none'})
    }


    componentDidMount() {
        const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);
        const history = createHistory();


        if (infouser.position_name === "Agent" && infouser.entr_name === "Seedlee") {
            this.props.getAllAssignedCampaignByAgent(this.state.id)
        } else {
            history.goBack('/')
        }

    }

    handleSwitchTable = tab => {
        if (tab === "assigned_campaign") {

            this.props.getAllAssignedCampaignByAgent(this.state.id)

            this.setState({tab: 'assigned_campaign'})

        } else if (tab === "create_campaign") {
            this.setState({tab: 'create_campaign'})
        }

    }

    render() {

        const {
            session, isLoadingS,
            campaigns, isLoadingCA, isLoadingPC, numberList, getAllPhoneCampaigns, persons, createAssignCampain, isLoadingCAC, assCampaigns, isLoadingACA
        }=this.props;
        console.log("____________________", numberList)

        const {modalVisible, show, display, show1, display1, id, tab}=this.state

        return (
            <>
            <section class="admin-content">

                <div class="container-fluid bg-dark m-b-30">
                    <div class="row  p-b-60 p-t-20">

                        <div class="col-md-6 text-white p-b-30">
                            <div class="media">

                                <div class="avatar mr-3  avatar-xl">

                                    {session && isLoadingS == false ?
                                        <img
                                            src={session ? session.photo ? app.BASE_IMAGE_URL + '/models/images/' + session.photo + '?' + new Date() : "/assets/img/avatar.png" : "/assets/img/avatar.png"}
                                            alt="..." class="avatar-img rounded-circle"/> :
                                        <Loader/>}
                                </div>
                                <div class="media-body m-auto">
                                    <h5 class="mt-0">{session ? session.fullname : null}
                                    </h5>
                                    <div class="opacity-75">{session ? session.role : null}</div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6 text-white my-auto text-md-right p-b-30">

                            <div class="">
                                <h2 class="opacity-75"><a href="#!">
                                    <FormattedMessage id={`app.label.campaign`}
                                                      defaultMessage={`Agent`}/></a>
                                </h2>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="container-fluid pull-up">
                    <div class="row ">
                        <div class="col-lg-12">
                            <div class="card m-b-30">
                                <div class="card-header">
                                    <div class="card-title"></div>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">

                                        <li class="nav-item"
                                            onClick={() => this.handleSwitchTable('assigned_campaign')}>
                                            <a class="nav-link active" id="contact-tab-z" data-toggle="tab"
                                               href="#assigned_campaign"
                                               role="tab"
                                               aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-phone-log">
                                                <FormattedMessage id={`app.label.assigned_campaign`}
                                                                  defaultMessage={`Agent session`}/></i></a>
                                        </li>


                                        {/*<li class="nav-item"*/}
                                        {/*onClick={() => this.handleSwitchTable('assigned_campaign')}>*/}
                                        {/*<a class="nav-link" id="contact-tab-z" data-toggle="tab"*/}
                                        {/*href="#assigned_campaign"*/}
                                        {/*role="tab"*/}
                                        {/*aria-controls="contact" aria-selected="false"><i*/}
                                        {/*className="mdi mdi-phone-log">*/}
                                        {/*<FormattedMessage id={`app.label.assigned_campaign`}*/}
                                        {/*defaultMessage={`CLient`}/>*/}
                                        {/*</i>*/}
                                        {/*</a>*/}
                                        {/*</li>*/}

                                    </ul>
                                    <div class="tab-content" id="myTabContent1">


                                        <div class="tab-pane fade show active" id="agent_session" role="tabpanel"
                                             aria-labelledby="contact-tab">

                                            <TableAssignedCampaign language={session ? session.language : 'en'}
                                                                   person_id={id} assCampaigns={assCampaigns}
                                                                   isLoadingACA={isLoadingACA}
                                                                   getAllPhoneCampaigns={getAllPhoneCampaigns}/>
                                        </div>

                                        <div class="tab-pane fade show" id="assign_campaign" role="tabpanel"
                                             aria-labelledby="contact-tab" style={{height: 'calc(100vh - 65px)'}}>
                                            {tab === "assign_campaign" ?
                                                <CampaignAssign
                                                    campaigns={campaigns}
                                                    isLoadingCA={isLoadingCA}
                                                    isLoadingPC={isLoadingPC}
                                                    numberList={numberList}
                                                    getAllPhoneCampaigns={getAllPhoneCampaigns}
                                                    handlerGetNumberList={this.handlerGetNumberList}
                                                    persons={persons}
                                                    language={session ? session.language : 'en'}
                                                    person_id={id}
                                                    createAssignCampain={createAssignCampain}
                                                    isLoadingCAC={isLoadingCAC}

                                                />
                                                : null}
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <CampaignModal language={session ? session.language : null} isLoadingPC={isLoadingCA}
                           numberList={numberList ? numberList : []} show={this.state.show} display={this.state.display}
                           removeModal={this.removeModal}/>

                <Softphone/>
            </>
        )
    }
}

export default AgentManager