/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import { toast } from 'react-toastify';
import { getIntlMsg, intl } from "../../constants/utils";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()


export const getAllDaysReportsByClient = criteria => ({
    type: types.GET_ALL_DAYS_REPORTS_BY_CLIENT,
    payload: { isLoadingDr: true, criteria: criteria }
});

export const getAllDaysReports = criteria => ({
    type: types.GET_ALL_DAYS_REPORTS,
    payload: { isLoadingDr: true,criteria }
});


export const getAllDaysReportsSuccess = daysreports => ({
    type: types.GET_ALL_DAYS_REPORTS_SUCCESS,
    payload: { isLoadingDr: false, daysreports: daysreports }
});


export const getAllDaysReportsByClientSuccess = clientDaysreports => ({
    type: types.GET_ALL_DAYS_REPORTS_BY_CLIENT_SUCCESS,
    payload: { isLoadingDr: false, clientDaysreports: clientDaysreports }
});

export const getAllDaysReportsFailed = ms => ({
    type: types.GET_ALL_DAYS_REPORTS_FAILED,
    payload: { isLoadingDr: true, ms: ms }
});

export const getAllDaysReportsByClientFailed = ms => ({
    type: types.GET_ALL_DAYS_REPORTS_BY_CLIENT_FAILED,
    payload: { isLoadingDr: true, ms: ms }
});

export const resetDaysReport = () => ({
    type: types.RESET_DAYS_REPORT
});

export const resetDaysReportSuccess = () => ({
    type: types.RESET_DAYS_REPORT_SUCCESS,
    payload: {
         ms: null
    }
});


export const createDaysReport = daysreport => ({
    type: types.CREATE_DAYS_REPORT,
    payload: {
        isLoadingDr: true, daysreport: daysreport
    }
});

export const editDaysReport = daysreport => ({
    type: types.EDIT_DAYS_REPORT,
    payload: {
        isLoadingDr: true, daysreport: daysreport
    }
});

export const deleteDaysReport = daysreport => ({
    type: types.DELETE_DAYS_REPORT,
    payload: {
        isLoadingDr: true, daysreport: daysreport
    }
});


export const createDaysReportSuccess = ms => {

    toast.success(getIntlMsg(intl('fr'), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_DAYS_REPORT_SUCCESS,
        payload: { isLoadingDr: false, ms: 'success' }
    };
}

export const editDaysReportSuccess = ms => {

    toast.success(getIntlMsg(intl('fr'), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.EDIT_DAYS_REPORT_SUCCESS,
        payload: { isLoadingDr: false, ms: 'success' }
    };
}

export const deleteDaysReportSuccess = ms => {

    toast.success(getIntlMsg(intl('fr'), `app.label.deleteSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_DAYS_REPORT_SUCCESS
    };
}

export const createDaysReportFailed = ms => {

    toast.error(getIntlMsg(intl('fr'), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_DAYS_REPORT_FAILED,
        payload: { isLoadingDr: true, ms: ms }
    };
}

export const editDaysReportFailed = ms => {

    toast.error(getIntlMsg(intl('fr'), `app.label.editError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.EDIT_DAYS_REPORT_FAILED,
        payload: { isLoadingDr: true, ms: ms }
    };
}

export const deleteDaysReportFailed = ms => {

    toast.error(getIntlMsg(intl('fr'), `app.label.deleteError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_DAYS_REPORT_FAILED,
    };
}


