/**
 * Created by louidorpc on 1/9/21.
 */
import React from 'react';
import PbxComponent from '../../components/pbx';
import createHistory from 'history/createBrowserHistory';


const PbxManager = props => {
    const { sr: { session, isLoadingS }, pbx: { incomingCalls }, actions: { getAllPbxReport, getAllIncomingCall, getAllOutgoingCall, updateIncomingCall, getAllEntreprises,getAllPrefixById } } = props;

    const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);
    const history = createHistory();


    if (infouser.position_name === "Administrateur" || infouser.position_name === "Superviseur" && infouser.entr_name === "Seedlee") {

        return (
            <PbxComponent session={session} isLoadingS={isLoadingS} getAllPbxReport={getAllPbxReport} getAllIncomingCall={getAllIncomingCall} getAllOutgoingCall={getAllOutgoingCall} updateIncomingCall={updateIncomingCall} 
            incomingCalls={incomingCalls} getAllEntreprises={getAllEntreprises} getAllPrefixById={getAllPrefixById} />
        )
    } else {
        history.goBack('/')
    }


}

export default PbxManager;