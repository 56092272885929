import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm, change} from "redux-form";
import {connect, useDispatch, useSelector} from 'react-redux'
import {validate, normalizeCin, normalizePhone} from "./validator";
import {renderField} from "../../../../common/FormComponents";
import {getIntlMsg} from "../../../../constants/utils";
import {change as changeFieldValue} from 'redux-form'
import MultiSelect from "@khanacademy/react-multi-select";


const onClearForm = (reset, resetEntreprise, resetFile) => {
    reset('entreprise');
    resetFile();
    resetEntreprise();
}

const Form = props => {
    // if (msEnt === "success") onClearForm(reset, resetEntreprise, resetFile)
    const dispatch = useDispatch();

    const {items, flat_rate_types} = useSelector(state => ({
        items: state.entrepriseReducer.entrepriseInfo ? state.entrepriseReducer.entrepriseInfo.service_abs : [],
        flat_rate_types: state.entrepriseReducer.entrepriseInfo ? state.entrepriseReducer.entrepriseInfo.flat_rate_type : [],

    }));

    const [inputList, setInputList] = useState(items && items.length > 0 ? items : [""]);

    const removeItem = i => {

        const list = [...inputList];
        list.splice(i, 1);
        setInputList(list);
        props.handleSetServiceAb(list)
        // dispatch(change('entreprise', 'service_ab', list));
    }

    const onChange = function (event) {
        let options = event.target.options;
        let selectedOptions = [];
        if (options) {
            for (let x = 0; x < options.length; x++) {
                if (options[x].selected) {
                    selectedOptions.push(options[x].value);
                }
            }
            if (changeFieldValue)
                changeFieldValue('documents', selectedOptions);
        }
    }


    const handleInputChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...inputList];
        list[index] = value;
        setInputList(list);
    };

    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        //  setClientList(list)
        props.handleSetServiceAb(list)
        // dispatch(change('entreprise', 'service_ab', list));
        removeItem(index);
    };

    const handleAddClick = () => {
        setInputList([...inputList, ""]);
        // setClientList(inputList)
        props.handleSetServiceAb([...inputList, ""])
    };


    const campains = [
        {label: "SERVICE À LA CLIENTÈLE", value: "SERVICE À LA CLIENTÈLE"},
        {label: "SERVICE À LA CLIENTÈLE", value: "SERVICE À LA CLIENTÈLE"},
        {label: "RECOUVREMENT", value: "RECOUVREMENT"},
        {label: "PROSPECTION", value: "PROSPECTION"},
    ];


    const [selectedForfait, setSelectedForfait] = useState([]);


    const handleSelectedForfait = (selected) => {
        dispatch(change('entreprise', 'flat_rate_type', selected));
        setSelectedForfait(selected)
    }


    return (
        <React.Fragment>
            <div className="form-row m-2">
                <h6>INFORMATIONS BANCAIRES</h6>
                <div className="form-row m-2">
                    <h6>FACTURATION :</h6>
                    <div className="form-group floating-label show-label col-md-12">

                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="bank_name"
                            component={renderField}
                            type="text"
                            label="app.label.bank_name"
                            placeholder="app.label.bank_name"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="bank_account_name"
                            component={renderField}
                            type="enmail"
                            label="app.label.bank_account_name"
                            placeholder="app.label.bank_account_name"
                        />
                    </div>
                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="bank_account_number"
                            component={renderField}
                            type="text"
                            label="app.label.bank_account_number"
                            placeholder="app.label.bank_account_number"
                        />
                    </div>
                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="bank_officier_name"
                            component={renderField}
                            type="text"
                            label="app.label.bank_officier_name"
                            placeholder="app.label.bank_officier_name"
                        />
                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="bank_officier_email"
                            component={renderField}
                            type="email"
                            label="app.label.email"
                            placeholder="app.label.email"
                        />
                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="bank_officier_phone"
                            component={renderField}
                            normalize={normalizePhone}
                            type="text"
                            label="app.label.phone"
                            placeholder="app.label.phone"
                        />
                    </div>
                </div>


                <div className="col-md-12">

                    <h5><a href="#">Service de l’abonné</a></h5>
                    {inputList.map((x, i) => {
                        return (
                            <div className="row">
                                <div className="form-group floating-label show-label col-md-8">
                                    <input
                                        className="form-control"
                                        name="service"
                                        placeholder="Service"
                                        value={x}
                                        property={x ? 'required' : null}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>

                                <div className="form-group col-md-4">
                                    <div className="btn-box float-right">
                                        {inputList.length !== 1 && <button
                                            className="mr10 mr-2 pb-2 btn btn-danger"
                                            onClick={() => handleRemoveClick(i)}>Supprimer</button>}
                                        {inputList.length - 1 === i &&
                                        <button onClick={handleAddClick} className="btn btn-info pt-2">Ajouter</button>}
                                    </div>
                                </div>
                            </div>

                        );
                    })}
                    {/*<div style={{marginTop: 20}}>{JSON.stringify(inputList)}</div>*/}
                </div>

                {/*<div className="col-md-12">*/}
                {/*<h6>Forfait choisi :</h6>*/}

                {/*<Field name="flat_rate_type"*/}
                {/*type="select"*/}
                {/*component={() => (<MultiSelect*/}
                {/*options={campains}*/}
                {/*selected={selectedForfait.length > 0 ? selectedForfait : flat_rate_types}*/}
                {/*onSelectedChanged={selected => handleSelectedForfait(selected)}*/}
                {/*overrideStrings={{*/}
                {/*selectSomeItems: "Sélectionnez certains éléments ...",*/}
                {/*allItemsAreSelected: "Tous les éléments sont sélectionnés",*/}
                {/*selectAll: "Tout sélectionner",*/}
                {/*search: "Rechercher",*/}
                {/*}}*/}
                {/*/>)}*/}
                {/*/>*/}


                {/*</div>*/}

            </div>

        </React.Fragment>

    );
}
export default Form;



