import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getPersonInfo = pid => {
    console.info(pid, 'new message');
    return instance.get(`/users/read_single/index.php?person_id=${pid}`)
};


export const updatePersonInfo = ups => {

    return instance.put(`/users/update/index.php`, ups)
};

export const CreateClient = ups => {
    return instance.post(`/client/create/index.php`, ups)

};

export const getAllPersons = () => {
    return instance.get(`/users/index.php`)
};

export const changeUserStatus = user => {
    return instance.put(`/users/changeStatus/index.php`, user)
};


export const changePassword = pass => {

    return instance.put(`/users/changePassword/index.php`, pass)
};




