/**
 * Created by louidor on 12/7/19.
 */
import React from 'react';
import {getIntlMsg, intl} from '../../../../constants/utils'
import moment from 'moment'
import TableSession from './tableSession'


const AgentModal = ({resetModal, selectedData, language, show, display, agS}) => {

    return (
        <div class={`modal fade modal-slide-right ${show}`} id="scrollModal" tabIndex='-1' role="dialog"
             style={{display: display, width: '40%', left: '60%', overflow:'auto'}}
             aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content border-danger" style={{border: '3px solid #000', float: 'right'}}>
                    <div class="modal-header">

                        <h5 class="modal-title"
                        >
                            <span className="text-center ml-0"><i
                                className="mdi mdi-calendar-edit">
                                {selectedData ? moment(selectedData.start).format('YYYY-MM-DD H:mm:s') : null}</i></span>
                        </h5>

                        <button type="button" class="close" onClick={() => resetModal()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        {agS && agS.length > 0 ?
                            <TableSession agS={agS ? agS : []} language={language} selectedData={selectedData}/> : null}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={() => resetModal()}>
                            {getIntlMsg(intl(language), 'app.label.cancel')}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AgentModal;