import types from "../actions/type"

const defaultState = {
    isLoadingPo: false,
    message: null,
    position: null,
    upo: null,
    mpo: null,
    id: null,
    positions: [],
}

const positionReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_ALL_POSITIONS:
            return {
                ...state,
                isLoadingPo: action.payload.isLoadingPo
            };

        case types.GET_ALL_POSITIONS_SUCCESS:
            return {
                ...state,
                mpo: action.payload.mpo,
                isLoadingPo: action.payload.isLoadingPo,
                positions: action.payload.positions
            };

        case types.GET_ALL_POSITIONS_FAILLED:
            return {
                ...state,
                mpo: action.payload.mpo,
                isLoadingPo: action.payload.isLoadingPo
            };


        case types.CREATE_POSITION:
            return {
                ...state,
                isLoadingPo: action.payload.isLoadingPo,
                position: action.payload.position
            };

        case types.UPDATE_POSITION:
            return {
                ...state,
                isLoadingPo: action.payload.isLoadingPo,
                upo: action.payload.upo
            };

        case types.CREATE_POSITION_SUCCESS:
        case types.UPDATE_POSITION_SUCCESS:
            return {
                ...state,
                mpo: action.payload.mpo,
                isLoadingPo: action.payload.isLoadingPo
            };

        case types.CREATE_POSITION_FAILLED:
        case types.UPDATE_POSITION_FAILLED:
        case types.DELETE_POSITION_FAILLED:
            return {
                ...state,
                mpo: action.payload.mpo,
                isLoadingPo: action.payload.isLoadingPo
            };

        case types.DELETE_POSITION:
            return {
                ...state,
                id: action.payload.id,
            };

        case types.DELETE_POSITION_SUCCESS:
            return {
                ...state,
                positions: state.positions.filter(item => item.id !== action.payload?.id)
            };


        default:
            return state

    }
}


export default positionReducer