/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()


export const getAllTatistic = criteria => ({
    type: types.GET_ALL_STATISTIC,
    payload: {isLoadingSt: true, criteria: criteria}
});


export const getAllTatisticSuccess = stats => ({
    type: types.GET_ALL_STATISTIC_SUCCESS,
    payload: {isLoadingSt: false, stats: stats}
});

export const getAllTatisticFailled = mst => ({
    type: types.GET_ALL_STATISTIC_FAILLED,
    payload: {isLoadingSt: true, mst: mst}
});
