import {put, take, call, takeLatest} from "redux-saga/effects";
import * as actions from "../actions/creator/setting"
import types from "../actions/type"
import * as api from "../services/setting";
import {getFeedbackStatistic} from "../services/setting";

export const handleSwitchLocale = function* (action) {
    try {
        const {locale} = action.payload
        yield put(actions.changeLocaleSuccess(locale))
    } catch (error) {
        yield put(actions.changeLocaleFailled("error"))
    }
}


export const handleGetUserSessionInfo = function* (action) {


    try {
        const {sessionId} = action.payload;
        const getPs = yield call(api.getPersonInfo, sessionId);
        if (getPs.data.message === "success") {
            yield put(actions.getUserSessionInfoSuccess(getPs.data))
        } else {
            yield put(actions.getUserSessionInfoFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getUserSessionInfoFailled("error"))
    }

    // try {
    //     const {sessionId} = action.payload
    //     yield put(actions.getUserSessionInfoSuccess(session))
    // } catch (error) {
    //     yield put(actions.getUserSessionInfoFailled("error"))
    // }
}


export const handleSignoutAgent = function* (action) {

    try {
        const {sno} = action.payload;
        const savedSn = yield call(api.logoutAgent, sno);
        if (savedSn.data.message === "success") {
            yield put(actions.signoutAgentSuccess(savedSn.data))
        } else {
            yield put(actions.signoutAgentFailled(savedSn.data.message))
        }

    } catch (error) {
        yield put(actions.signoutAgentFailled("error"))
    }
};


export const handleGetFeedbackStatistic = function* (action) {
    const {statId} = action.payload
    try {
        const getPs = yield call(api.getFeedbackStatistic,statId);
        if (getPs.data?.length > 0) {
            yield put(actions.getFeedbackStatisticSuccess(getPs.data))
        } else {
            yield put(actions.getFeedbackStatisticFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getFeedbackStatisticFailled("error"))
    }
}


export const settingSaga = [
    takeLatest(types.CHANGE_LOCALE_REQUEST, handleSwitchLocale),
    takeLatest(types.GET_USER_SESSION_INFO, handleGetUserSessionInfo),
    takeLatest(types.SIGNOUT_AGENT, handleSignoutAgent),
    takeLatest(types.GET_FEEDBACK_STATISTIC, handleGetFeedbackStatistic),
]
