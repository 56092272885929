/**
 * Created by louidorpc on 8/11/20.
 */
import React, { useEffect } from 'react'
import { Person } from '../../../constants/model';
import { app } from '../../../constants/config';
import Loader from '../../../common/loader';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { FormattedMessage } from "react-intl";
import { Bar, Line } from 'react-chartjs-2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AgentIncomingCall from './lists/incomingCall';
import AgentOutgoingCall from './lists/outgoingCall';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { Card } from '@material-ui/core';
const shortcutsItems = [
    {
        label: 'This Week',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today];
        },
    },
    {
        label: 'Current Month',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'Next Month',
        getValue: () => {
            const today = dayjs();
            const startOfNextMonth = today.endOf('month').add(1, 'day');
            return [startOfNextMonth, startOfNextMonth.endOf('month')];
        },
    },
    { label: 'Reset', getValue: () => [null, null] },
];


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const AdminDashboard = ({ session, getUserSessionInfo,
    getAllTatistic, getAllPbxReportAgent, cReports, stats,
    getAllIncomingCallAgent,
    cIncomingCalls,
    getAllOutgoingCallAgent,
    cOutgoingCalls,
    getAllPbxReport,
    getAllIncomingCall,
    getAllOutgoingCall, getAllEntreprises, getAllPrefixById }) => {

    const [value, setValue] = React.useState([
        dayjs(dayjs().startOf('week')),
        dayjs(dayjs().endOf('week')),
    ]);

    const [entreprise, setEntreprise] = React.useState('');
    const [extension, setExtension] = React.useState("");
    const [extensionOpen, setExtensionOpen] = React.useState(false);
    const [openExtension, setOpenExtension] = React.useState(false);
    const [openEntreprise, setOpenEntreprise] = React.useState(false);

    useEffect(() => {
        getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        getAllTatistic({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension });
    }, [])

    const { statusItems, reportCalls, loading, stat, statByDays, outgoingByday, missedByday } = useSelector(state => ({
        reportCalls: state?.pbxReducer?.reports?.reports || [],
        stat: state?.pbxReducer?.reports?.stats || null,
        statByDays: state?.pbxReducer?.reports?.incomingByday || [],
        outgoingByday: state?.pbxReducer?.reports?.outgoingByday || [],
        missedByday: state?.pbxReducer?.reports?.missedByday || [],
    }));


    const data = {
        labels: reportCalls && reportCalls?.length > 0 ? reportCalls?.map(value => value.extension) : [],
        datasets: [
            {
                label: '# appels entrants',
                data: reportCalls && reportCalls.length > 0 ? reportCalls?.map(value => value.num_incoming_call) : [],
                backgroundColor: '#3ecf8e',
            },
            {
                label: '# appels sortants',
                data: reportCalls && reportCalls?.length > 0 ? reportCalls?.map(value => value.num_outgoing_call) : [],
                backgroundColor: '#68d4f8',
            },
            {
                label: '# appels perdus',
                data: reportCalls && reportCalls?.length > 0 ? reportCalls?.map(value => value.num_incoming_call_no_answer) : [],
                backgroundColor: '#f2545b',
            },
        ],
    }

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    }

    const [open, setOpen] = React.useState(false);
    const [state, setSate] = React.useState(null);

    const handleClickOpen = (st) => {
        if (st == 'incoming') {
            setTab(st)
            getAllIncomingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
           
        } else if (st == 'outgoing') {
            getAllOutgoingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
            setTab(st)
        } else {

        }
        setSate(st)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

 const handleCloseEntreprise = () => {
        setOpenEntreprise(false);
    };


    const [tab, setTab] = React.useState('dashboard');

    useEffect(() => {
        getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        getAllEntreprises()
    }, [])

    
    const handleCloseDatePicker = () => {

        if (tab === "incoming") {
            getAllIncomingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        } else if (tab === "outgoing") {
            getAllOutgoingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
        }else {
            getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension })
            getAllTatistic({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: extension });
        }

    }


    const handleDatePickerChange = (e) => {
        setValue(e)
    }

    const { entreprises, prefixIds } = useSelector(state => ({
        entreprises: state?.entrepriseReducer?.entreprises || [],
        prefixIds: state?.prefixReducer?.prefixIds || [],
    }));


    useEffect(() => {

        if (prefixIds.length > 0) {
            setExtension(prefixIds[0]?.prefix_number)

            if (tab === "incoming") {
                getAllIncomingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
            } else if (tab === "outgoing") {
                getAllOutgoingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
            } else if (tab === "agent") {
                getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
            } else {
                getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number })
                getAllTatistic({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: prefixIds[0]?.prefix_number  });
            }

        }

    }, [prefixIds])

    const handleChange = (event) => {
        setEntreprise(event.target.value);
        getAllPrefixById(event.target.value)
    };

    const handleChangeExtension = (event) => {
        setExtension(event.target.value);

        if (tab === "incoming") {
            getAllIncomingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: event.target.value })
        } else if (tab === "outgoing") {
            getAllOutgoingCall({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: event.target.value })
        }  else {
            getAllPbxReport({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension: event.target.value })
            getAllTatistic({ startDate: dayjs(value[0]).format('YYYY-MM-DD'), endDate: dayjs(value[1]).format('YYYY-MM-DD'), extension:  event.target.value  });
        }


    };

    const handleCloseExtension = () => {
        setOpenExtension(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleOpenEntreprise = () => {
        setOpenEntreprise(true);
    };

    const handleOpenExtension = () => {
        setOpenExtension(true);
    };

    return (
        <>
            <section class="admin-content">
                <div class="bg-dark ">
                    <div class="container-fluid m-b-30">
                        <div class="row">
                            <div class="col-md-12" style={{background:'white'}}>
                               
                                        <div className="row pt-4 pb-4">

                                            <div className="col-md-3">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer
                                                        components={[
                                                            'DatePicker',
                                                            'TimePicker',
                                                            'DateTimePicker',
                                                            'DateRangePicker',
                                                        ]}

                                                    >
                                                        <DemoItem

                                                            component="DateRangePicker"


                                                        >
                                                            <DateRangePicker
                                                                localeText={{
                                                                    start: '',
                                                                    end: '',
                                                                }}
                                                                format='YYYY-MM-DD'
                                                                slotProps={{
                                                                    shortcuts: {
                                                                        items: shortcutsItems,
                                                                    },
                                                                    actionBar: { actions: [] },
                                                                    textField: ({ position }) => ({
                                                                        label: position === 'start' ? 'Start date' : 'end date',
                                                                    }),
                                                                }}
                                                                onOpen={() => {
                                                                    setTimeout(() => {
                                                                        const firstDiv = document.querySelector('.MuiDateRangeCalendar-root');
                                                                        if (firstDiv) {
                                                                            const childDivToRemove = firstDiv.querySelector(':first-child');
                                                                            if (childDivToRemove) {
                                                                                childDivToRemove.remove();
                                                                            }
                                                                        }
                                                                    }, 1000)
                                                                }}
                                                                onClose={() => {
                                                                    setTimeout(() => {
                                                                        const firstDiv = document.querySelector('.MuiDateRangeCalendar-root');
                                                                        if (firstDiv) {
                                                                            const childDivToRemove = firstDiv.querySelector(':first-child');
                                                                            if (childDivToRemove) {
                                                                                childDivToRemove.remove();
                                                                            }
                                                                        }
                                                                    }, 1000)
                                                                    handleCloseDatePicker()

                                                                }}
                                                                value={value}
                                                                onChange={(e) => handleDatePickerChange(e)}
                                                            />
                                                        </DemoItem>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className='col-lg-9'>
                                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Entreprise</InputLabel>
                                                    <Select
                                                        labelId="demo-controlled-open-select-label"
                                                        id="demo-controlled-open-select"
                                                        open={openEntreprise}
                                                        onClose={handleCloseEntreprise}
                                                        onOpen={handleOpenEntreprise}
                                                        value={entreprise}
                                                        label="Entreprise"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Aucune</em>
                                                        </MenuItem>
                                                        {entreprises?.map(v => <MenuItem value={v.id}>{v.entr_name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ m: 1, minWidth: 120 }} className='ext'>
                                                    <InputLabel id="demo-controlled-open-select-label">Extension</InputLabel>
                                                    <Select
                                                        labelId="demo-controlled-open-select-label"
                                                        id="demo-controlled-open-select"
                                                        open={openExtension}
                                                        onClose={handleCloseExtension}
                                                        onOpen={handleOpenExtension}
                                                        value={extension}
                                                        label="Extension"
                                                        onChange={handleChangeExtension}
                                                    >
                                                        {prefixIds?.map(v => <MenuItem value={v.prefix_number}>{v.campaign_name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                  
                                </div>
                            </div>

                            <div class="col-md-12 p-b-60">
                                <div id="chart-09" class="chart-canvas invert-colors">
                                    <Line
                                        height={'100'}
                                        data={{
                                            labels: stats && stats.stat ? stats.stat.map(value => value.caldate) : [],
                                            datasets: [
                                                {
                                                    label: 'Nombre d\'appel',
                                                    fill: true,
                                                    fontSize: 3,
                                                    lineTension: 0.1,
                                                    borderColor: 'rgb(53, 162, 235)',
                                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                                    borderWidth: 2,
                                                    data: stats && stats.stat ? stats.stat.map(value => value.countCall) : []
                                                }
                                            ]
                                        }}
                                        options={{
                                            responsive: true,


                                            title: {

                                                display: true,
                                                text: 'Tableau de performance sur le nombre d\'appel par jours',
                                                fontSize: 16,
                                            },

                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row  pull-up d-lg-flex">
                        <div class="col m-b-30">
                            <div class="card ">

                                <div class="card-body" style={{ cursor: 'pointer' }} onClick={() => handleClickOpen('incoming')}>
                                    <div class="card-controls">
                                        <a href="#" class="badge badge-soft-success"> <i class="mdi mdi-phone-incoming"></i></a>
                                    </div>
                                    <div class="text-center p-t-30 p-b-20">
                                        <div class="text-overline text-muted opacity-75">Nombre total Appels entrants</div>
                                        <h1 class="text-success">{stat && stat?.num_incoming_call || 0}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col m-b-30">
                            <div class="card">

                                <div class="card-body" style={{ cursor: 'pointer' }} onClick={() => handleClickOpen('outgoing')}>
                                    <div class="card-controls">
                                        <a href="#" class="badge badge-soft-info"> <i class="mdi mdi-phone-outgoing"></i></a>
                                    </div>
                                    <div class="text-center p-t-30 p-b-20">
                                        <div class="text-overline text-muted opacity-75">Nombre total Appels sortants</div>
                                        <h1 class="text-info">{stat && stat?.num_outgoing_call || 0}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col m-b-30">
                            <div class="card ">
                                <div class="card-body" style={{ cursor: 'pointer' }} onClick={() => handleClickOpen('missed')}>
                                    <div class="card-controls">
                                        <a href="#" class="badge badge-soft-danger"> <i class="mdi mdi-call-missed"></i></a>
                                    </div>
                                    <div class="text-center p-t-30 p-b-20">
                                        <div class="text-overline text-muted opacity-75">
                                            Appels manquants
                                        </div>
                                        <h1 class="text-danger">{stat && stat?.num_incoming_call_no_answer || 0}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col m-b-30">
                            <div class="card ">

                                <div class="card-body" style={{ cursor: 'pointer' }} onClick={() => alert()}>
                                    <div class="card-controls">
                                        <a href="#" class="badge badge-soft-success"> <i class="mdi mdi-clock"></i> sec</a>
                                    </div>
                                    <div class="text-center p-t-30 p-b-20">
                                        <div class="text-overline text-muted opacity-75">
                                            Durée total des appels entrants
                                        </div>
                                        <h1 class="text-success">{stat && stat?.duration_incoming_call || 0}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col m-b-30">
                            <div class="card ">

                                <div class="card-body" style={{ cursor: 'pointer' }} onClick={() => alert()}>
                                    <div class="card-controls">
                                        <a href="#" class="badge badge-soft-info"> <i class="mdi mdi-clock"></i> sec</a>
                                    </div>
                                    <div class="text-center p-t-30 p-b-20">
                                        <div class="text-overline text-muted opacity-75">
                                            Durée totale  des appels sortants
                                        </div>
                                        <h1 class="text-info">{stat && stat?.duration_outgoing_call || 0}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div class="row">
                        <div class="col-lg-12 m-b-30">
                            <div class="row">


                                <div class="col-md-6">
                                    <div class="card m-b-30">
                                        <div class="">
                                            <Bar data={data} options={options} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="card m-b-30">
                                        <Line
                                            data={{
                                                labels: statByDays?.length > 0 ? statByDays?.map(value => value.caldate) : [],
                                                datasets: [
                                                    {
                                                        label: '# Appels',
                                                        fill: true,
                                                        lineTension: 0.5,
                                                        backgroundColor: '#3ecf8e',
                                                        borderColor: 'rgba(0,0,0,1)',
                                                        borderWidth: 2,
                                                        data: statByDays?.length > 0 ? statByDays?.map(value => value.countCall) : []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "Tableau de performance quotidienne sur le nombre d'appels entrant",
                                                    fontSize: 16
                                                },
                                                legend: {
                                                    display: false,
                                                    position: 'left'
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div class="card m-b-30">
                                        <Line
                                            data={{
                                                labels: outgoingByday?.length > 0 ? outgoingByday?.map(value => value.caldate) : [],
                                                datasets: [
                                                    {
                                                        label: '# Appels',
                                                        fill: true,
                                                        lineTension: 0.5,
                                                        backgroundColor: '#68d4f8',
                                                        borderColor: 'rgba(0,0,0,1)',
                                                        borderWidth: 2,
                                                        data: outgoingByday?.length > 0 ? outgoingByday?.map(value => value.countCall) : []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "Tableau de performance quotidienne sur le nombre d'appels sortants",
                                                    fontSize: 16
                                                },
                                                legend: {
                                                    display: false,
                                                    position: 'left'
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div class="card m-b-30">
                                        <Line
                                            data={{
                                                labels: missedByday?.length > 0 ? missedByday?.map(value => value.caldate) : [],
                                                datasets: [
                                                    {
                                                        label: '# Appels',
                                                        fill: true,
                                                        lineTension: 0.5,
                                                        backgroundColor: 'rgb(255, 99, 132)',
                                                        borderColor: 'rgba(0,0,0,1)',
                                                        borderWidth: 2,
                                                        data: missedByday?.length > 0 ? missedByday?.map(value => value.countCall) : []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "Tableau de performance quotidienne sur le nombre d'appels entrants manqués",
                                                    fontSize: 16
                                                },
                                                legend: {
                                                    display: false,
                                                    position: 'left'
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </section>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Liste des  Appels {state == 'incoming' ? 'entrants' : state == 'outgoing' ? 'sortants' : 'manques'}
                        </Typography>
                        <Button autoFocus color="inherit">
                            {state == 'incoming' ? cIncomingCalls?.length : state == 'outgoing' ? cOutgoingCalls.length : ''}
                        </Button>

                    </Toolbar>
                </AppBar>
                {state == 'incoming' ? <AgentIncomingCall {...{ cIncomingCalls, session }} /> : state == 'outgoing' ? <AgentOutgoingCall {...{ cOutgoingCalls, session }} /> : <AgentIncomingCall />}
            </Dialog>
        </>
    )
}

export default AdminDashboard