/**
 * Created by louidorpc on 9/9/20.
 */
import React from 'react'
import ClientTable from './clientTable'
import {Person, Client, Customer} from '../../../constants/model'
import ClientModal from './clientModalForm'
import {FormattedMessage} from "react-intl";

class ClientComponent extends React.Component {


    constructor(props) {

        super(props);

        this.state = {
            photo: null,
            pictureType: null,
        }
    }

    cb = (result, file) => {
        let str = result;
        this.setState({photo: str, pictureType: file.type})
    }


    previewFile = (cb) => {

        var preview = document.getElementById('filePreviewPers')
        var file = document.querySelector('input[name=files]').files[0];
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            preview.src = "";
            preview.src = reader.result;
            cb(reader.result, file)
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }

    }
    resetFile = () => {
        var preview = document.getElementById('filePreviewPers')
        preview.src = "/assets/img/avatar.png";
        this.setState({photo: null, pictureType: null})
    }

    submitSetting = values => {

        const customer = [
            {
                firstname: values.firstname,
                lastname: values.lastname,
                birthdate: values.birthdate,
                gender: values.gender,
                phone: values.phone,
                email: values.email,
                address: values.address,
                pictureContent: this.state.photo,
                pictureType: this.state.pictureType,
                nif: values.nif,
                gender: values.gender,
                entreprise_id: this.props.entreprise_id
            }
        ]

        const personEntity = new Customer({
            customer: customer,
            language: this.props.language,
            entreprise_id: this.props.entreprise_id
        });
        this.props.createCustomer(personEntity);

    };


    render() {

        const {language, createService, updateService, services, isLoadingSv, person_id, persons, positions, entreprises, getPersonInfo, customers, entreprise_id, personInfo, clients, createCustomer, resetStateClient, mc}=this.props;

        return (
            <>
            <div class="row pt-4">
                <div className="col-md-12 col-xs-12 m-b-30">
                    <button type="button" class="btn m-b-15 ml-2 mr-2 btn-primary float-right"
                            data-toggle="modal"
                            data-target="#slideRightModal"><FormattedMessage id={`app.label.create_cient`}
                                                                             defaultMessage={`Label Name`}/></button>
                </div>

                <div class="col-md-12 col-xs-12 m-b-30">
                    <ClientTable language={language} createService={createService} updateService={updateService}
                                 customers={customers} isLoadingSv={isLoadingSv} person_id={person_id}
                                 getPersonInfo={getPersonInfo}
                                 createCustomer={createCustomer}
                                 entreprise_id={entreprise_id}

                    />
                </div>

                <div class="col-md-4 col-md-4 m-b-30">

                </div>
            </div>
            <ClientModal services={services} language={language} positions={positions} entreprises={entreprises}
                         personInfo={personInfo} previewFile={() => this.previewFile(this.cb)}
                         submitSetting={this.submitSetting}
                         mc={mc}
                         resetStateClient={resetStateClient}/>
            </>
        )
    }
}

export default ClientComponent;