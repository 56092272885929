/**
 * Created by louidorpc on 9/9/20.
 */
import React from 'react'
import TablePerson from './personTable'
import { Person } from '../../../constants/model'
import PersonModal from './personModalForm'

class PersonComponent extends React.Component {


    constructor(props) {

        super(props);

        this.state = {
            photo: null,
            pictureType: null,
        }
    }

    cb = (result, file) => {
        let str = result;
        this.setState({ photo: str, pictureType: file.type })
    }


    previewFile = (cb) => {

        var preview = document.getElementById('filePreviewPers')
        var file = document.querySelector('input[type=file]').files[0];
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            preview.src = "";
            preview.src = reader.result;
            cb(reader.result, file)
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }

    }
    resetFile = () => {
        var preview = document.getElementById('filePreviewPers')
        preview.src = "/assets/img/avatar.png";
        this.setState({ photo: null, pictureType: null })
    }

    submitSetting = values => {


        const personEntity = new Person(values);

        personEntity.pictureContent = this.state.photo;
        personEntity.pictureType = this.state.pictureType;
        personEntity.language = this.props.language;
        personEntity.creator = this.props.person_id;

        this.props.updatePersonById(personEntity);

    };


    render() {

        const { language, createService, updateService, services, isLoadingSv, person_id, persons, positions, entreprises, getPersonInfo, personInfo, resetStatePerson, mups, changeUserStatus, getAllExtensions } = this.props;

        return (
            <>
                <div class="row pt-4">
                    <div class="col-md-12 col-xs-12 m-b-30">

                        <TablePerson language={language} createService={createService} updateService={updateService}
                            persons={persons} isLoadingSv={isLoadingSv} person_id={person_id}
                            getPersonInfo={getPersonInfo}
                            changeUserStatus={changeUserStatus}

                        />
                    </div>

                </div>
                <PersonModal services={services} language={language} positions={positions} entreprises={entreprises}
                    personInfo={personInfo} previewFile={() => this.previewFile(this.cb)}
                    submitSetting={this.submitSetting}
                    mups={mups}
                    getAllExtensions={getAllExtensions}
                    resetStatePerson={resetStatePerson} />
            </>
        )
    }
}

export default PersonComponent;