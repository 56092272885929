/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getIntlMsg, intl} from "../../constants/utils";
import {injectIntl, createIntl, createIntlCache} from "react-intl"


toast.configure()


export const updatePrefixById = ext => ({
    type: types.UPDATE_PREFIX,
    payload: {isLoadingPX: true, ext: ext}
});


export const updatePrefixByIdSuccess = mext => {

    toast.success(getIntlMsg(intl(mext), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_PERSON_BY_ID_SUCCESS,
        payload: {isLoadingEXT: false, mext: 'success'}
    }
};


export const updatePrefixByIdFailled = mext => {

    toast.error(getIntlMsg(intl(mext), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_PREFIX_FAILLED,
        payload: {isLoadingEXT: true, mext: mext}
    }
};


export const createPrefix = ext => ({
    type: types.CREATE_PREFIX,
    payload: {isLoadingEXT: true, ext: ext}
});


export const createPrefixSuccess = mext => {

    toast.success(getIntlMsg(intl(mext), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_PREFIX_SUCCESS,
        payload: {isLoadingEXT: false, mext: 'success'}
    }
};


export const createPrefixFailled = ms => {

    toast.error(getIntlMsg(intl(ms.lg), ms.mext), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_PREFIX_FAILLED,
        payload: {isLoadingEXT: true, mext: ms.mext}
    }
};


export const getAllPrefix = () => ({
    type: types.GET_ALL_PREFIX,
    payload: {isLoadingEXT: true}
});

export const getAllPrefixSuccess = prefix => ({
    type: types.GET_ALL_PREFIX_SUCCESS,
    payload: {isLoadingEXT: false, prefix: prefix}
});


export const getAllPrefixFailled = () => ({
    type: types.GET_ALL_PREFIX_FAILLED,
    payload: {isLoadingEXT: false}
});


export const getAllPrefixAvailable = () => ({
    type: types.GET_ALL_PREFIX_AVAILABLE,
    payload: {isLoadingEXT: true}
});

export const getAllPrefixAvailableSuccess = prefixA => ({
    type: types.GET_ALL_PREFIX_AVAILABLE_SUCCESS,
    payload: {isLoadingEXT: false, prefixA: prefixA}
});


export const getAllPrefixAvailableFailled = () => ({
    type: types.GET_ALL_PREFIX_AVAILABLE_FAILLED,
    payload: {isLoadingEXT: false}
});


export const getAllPrefixById = prefix_id => ({
    type: types.GET_ALL_PREFIX_BY_ID,
    payload: {isLoadingEXT: true, prefix_id: prefix_id}
});

export const getAllPrefixByIdSuccess = prefixIds => ({
    type: types.GET_ALL_PREFIX_BY_ID_SUCCESS,
    payload: {isLoadingEXT: false, prefixIds: prefixIds}
});


export const getAllPrefixByIdFailled = () => ({
    type: types.GET_ALL_PREFIX_BY_ID_FAILLED,
    payload: {isLoadingEXT: false}
});


export const deletePrefix = id => ({
    type: types.DELETE_PREFIX,
    payload: {
        isLoadingD: true, id: id
    }
});


export const deletePrefixSuccess = id => {

    toast.success(getIntlMsg(intl('fr'), `app.label.deleteSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_PREFIX_SUCCESS,
        payload: {isLoading: false, id: id}
    };
}

export const deletePrefixFailled = mp => {

    toast.error(getIntlMsg(intl('fr'), mp.mssv), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_PREFIX_FAILLED,
        payload: {isLoading: true, mssv: mp.mssv}
    };
}


