import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getAllEntreprises = () => {
    return instance.get(`/entreprise/index.php`)
};


export const createEntreprise = entreprise => {
    return instance.post(`/entreprise/create/index.php`, entreprise)
};


export const updateEntreprise = entreprise => {
    return instance.put(`/entreprise/update/index.php`, entreprise)
};


export const getAllDocuments = entr_name => {
    return instance.get(`/entreprise/read_document/index.php?entr_name=${entr_name}`)
};