/**
 * Created by louidorpc on 1/9/21.
 */
import React from 'react';
import EmbededCampaignRepport from '../../components/campaign/campaignManagementRepport';
import createHistory from 'history/createBrowserHistory';

const RepportNote = props => {

    const { actions: { getLinkByType } } = props;

    const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);
    const history = createHistory();


    if (infouser.position_name === "Administrateur" || infouser.position_name === "Superviseur" && infouser.entr_name === "Seedlee") {

        return (
            <EmbededCampaignRepport getLinkByType={getLinkByType}/>
        )
    } else {
        history.goBack('/')
    }


}

export default RepportNote;