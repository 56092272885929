import types from "../actions/type"

const defaultState = {
    isLoadingCPBX: false,
    isLoadingCIC: false,
    isLoadingCOC: false,
    message: null,
    cReports: [],
    cIncomingCalls: [],
    cOutgoingCalls: [],
    ent_id: null,
    criteria: null
}

const clientReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.CLIENT_GET_ALL_PBX_REPORTS:
            return {
                ...state,
                isLoadingCPBX: action.payload.isLoadingCPBX,
                criteria: action.payload.criteria
            };

        case types.CLIENT_GET_ALL_PBX_REPORTS_SUCCESS:
            return {
                ...state,
                cReports: action.payload.cReports,
                isLoadingCPBX: action.payload.isLoadingCPBX
            };


        case types.CLIENT_GET_ALL_PBX_REPORTS_FAILLED:
            return {
                ...state,
                cReports: [],
                isLoadingCPBX: action.payload.isLoadingPBX
            };


        case types.CLIENT_GET_ALL_INCOMING_CALL_REPORTS:
            return {
                ...state,
                isLoadingCIC: action.payload.isLoadingCIC,
                criteria: action.payload.criteria
            };

        case types.CLIENT_GET_ALL_INCOMING_CALL_REPORTS_SUCCESS:
            return {
                ...state,
                cIncomingCalls: action.payload.cIncomingCalls,
                isLoadingCIC: action.payload.isLoadingCIC
            };

        case types.CLIENT_GET_ALL_INCOMING_CALL_REPORTS_FAILLED:
            return {
                ...state,
                cIncomingCalls: [],
                isLoadingCIC: action.payload.isLoadingCIC
            };



        case types.CLIENT_GET_ALL_OUTGOING_CALL_REPORTS:
            return {
                ...state,
                isLoadingCOC: action.payload.isLoadingCOC,
                criteria: action.payload.criteria
            };

        case types.CLIENT_GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS:
            return {
                ...state,
                cOutgoingCalls: action.payload.cOutgoingCalls,
                isLoadingCOC: action.payload.isLoadingCOC
            };

        case types.CLIENT_GET_ALL_OUTGOING_CALL_REPORTS_FAILLED:
            return {
                ...state,
                cOutgoingCalls: [],
                isLoadingCOC: action.payload.isLoadingCOC
            };


        default:
            return state

    }
}


export default clientReducer