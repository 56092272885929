/**
 * Created by louidorpc on 8/4/20.
 */
import React from 'react'
import Experience from '../../components/experience'
import { getFeedback, resetFeedback } from "../../actions/creator/feedback";


const ExperienceComponent = props => {

    const {
        sr: { session, isLoadingS }, actions: { getLinkByType,getLinkByEntreprise
        }, person: { ps, isLoading, mups, cms }, intl, st: { sites },
    } = props;

    if (session !== null)
        return (
            <Experience session={session} getLinkByType={getLinkByType} getLinkByEntreprise={getLinkByEntreprise}
            />
        )
    else return <div></div>
}


export default ExperienceComponent;