/**
 * Created by louidorpc on 8/14/20.
 */

 import types from "../type"
 import {toast} from 'react-toastify';
 import {getIntlMsg, intl} from "../../constants/utils";
 import 'react-toastify/dist/ReactToastify.css';
 
 toast.configure()
 
 
 export const getAllCalendarEvents = () => ({
     type: types.GET_ALL_CALENDAR_EVENT,
     payload: {isLoadingCE: true}
 });

 export const getAllCalendarEventsByEntreprise = entr_id => ({
    type: types.GET_ALL_CALENDAR_EVENT_BY_ENTREPRISE,
    payload: {isLoadingCE: true,entr_id:entr_id}
});


export const getAllCalendarEventsByAgent = agent_id => ({
    type: types.GET_ALL_CALENDAR_EVENT_BY_AGENT,
    payload: {isLoadingCE: true,agent_id:agent_id}
});


 
 
 export const getAllCalendarEventsSuccess = events => ({
     type: types.GET_ALL_CALENDAR_EVENT_SUCCESS,
     payload: {isLoadingCE: false, events: events}
 });
 
 export const getAllCalendarEventsFailled = ms => ({
     type: types.GET_ALL_CALENDAR_EVENT_FAILLED,
     payload: {isLoadingCE: true, ms: ms}
 });
 
 
 export const createCalendarEvent = event => ({
     type: types.CREATE_CALENDAR_EVENT,
     payload: {
         isLoadingCRE: true, event: event
     }
 });
 
 
 export const createCalendarEventSuccess = () => {
 
     toast.success(getIntlMsg(intl('fr'), `app.label.createCalendarEventSuccess`), {
         position: toast.POSITION.TOP_RIGHT
     });
 
     return {
         type: types.CREATE_CALENDAR_EVENT_SUCCESS,
         payload: {isLoadingCRE: false, ms: 'success'}
     };
 }
 
 export const createCalendarEventFailled = () => {
 
     toast.error(getIntlMsg(intl('fr'), 'app.label.createCalendarEventFailed'), {
         position: toast.POSITION.TOP_RIGHT
     });
 
     return {
         type: types.CREATE_CALENDAR_EVENT_FAILED,
         payload: {isLoadingCRE: true, ms: 'failed'}
     };
 }
 
 
 export const updateCalendarEvent = event => ({
     type: types.UPDATE_CALENDAR_EVENT,
     payload: {
         isLoadingOCE: true, event: event
     }
 });


 export const publishCalendarEvent = event => ({
    type: types.PUBLISH_CALENDAR_EVENT,
    payload: {
        isLoadingOCE: true, event: event
    }
});

 
 
 export const updateCalendarEventSuccess = () => {
 
     toast.success(getIntlMsg(intl('fr'), `app.label.updateCalendarEventSuccess`), {
         position: toast.POSITION.TOP_RIGHT
     });
 
     return {
         type: types.UPDATE_CALENDAR_EVENT_SUCCESS,
         payload: {isLoadingOCE: false, ms: "success"}
     };
 }
 
 export const updateCalendarEventFailled = () => {
 
    toast.success(getIntlMsg(intl('fr'), `app.label.updateCalendarEventFailed`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_CALENDAR_EVENT_FAILED,
        payload: {isLoadingOCE: false, ms: "failed"}
    };
 }
 
 
 
 
 
 export const getCalendarEventInfo = cInfo => ({
     type: types.GET_CALENDAR_EVENT_INFO,
     payload: {
        cInfo: true, cInfo: cInfo
     }
 });

 export const getCalendarEventInfoSuccess = cInfo => ({
    type: types.GET_CALENDAR_EVENT_INFO_SUCCESS,
    payload: {
       cInfo: true, cInfo: cInfo
    }
});


export const calendarEventChangeStatus = () => ({
    type: types.CALENDAR_EVENT_CHANGE_STATUS,
    payload: {
       ms: null, cInfo:null
    }
});

export const calendarEventChangeStatusSuccess = cInfo => ({
   type: types.CALENDAR_EVENT_CHANGE_STATUS_SUCCESS,
   payload: {
    ms: null, cInfo:null
   }
});


export const setViewCalendarEvent = view => ({
    type: types.VIEW_CALENDAR_EVENT,
    payload: {view: view}
});

export const setViewCalendarEventFailled = () => ({
    type: types.VIEW_CALENDAR_EVENT_FAILLED,
    payload: {isLoadingCE: true, ms: null}
});

export const setViewCalendarEventSuccess = () => ({
    type: types.VIEW_CALENDAR_EVENT_SUCCESS,
    payload: {isLoadingCE: true, ms: 'view success'}
});

 

 
 
 