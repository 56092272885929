/**
 * Created by louidorpc on 9/9/20.
 */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import ExperienceAdminTable from './List'

const ExperienceAdmin = ({ language, prefix, prefixA, person_id, entreprises, createLink, updateLink, deleteLink, getAllLinks }) => {

    useEffect(() => {
        getAllLinks()

    }, [])


    const { links } = useSelector(state => ({
        links: state?.linkReducer?.links || [],
    }));


    return (

        <div class="row pt-4">

            <div class="col-md-12 col-xs-12 m-b-30">
                {entreprises && entreprises.length > 0 ?

                    <ExperienceAdminTable language={language}
                        createLink={createLink}
                        updateLink={updateLink}
                        deleteLink={deleteLink}
                        prefix={prefix}
                        prefixA={prefixA}
                        entreprises={entreprises}
                        links={links}
                        person_id={person_id} /> : null}

            </div>

        </div>
    )
}

export default ExperienceAdmin;