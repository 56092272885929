import types from "../actions/type"
import events from '../components/agent/calendar/Calendar/demoEvents';

const defaultState = {
    event: events,
    view: 'month',
    tpid: null,
    isLoadingC: false,
    isLoadingCO: false,
    isLoadingRC: false,
    mc: null,
    campaign: null,
    pc: null,
    upc: null,
    mupc: null,
    clients: [],
    customers: [],
    campaigns: [],
    campaignsId: [],
    isLoadingCA: null,
    isLoadingPC: false,
    isLoadingBCA: false,
    isLoadingR: false,
    numberList: [],
    allCampaigns: [],
    code: null, cpItem: null,
    campaignsToCalendar: [],
    numberListA: [],
    pid: null,
    id: null,
    entr_id: null,
    reports: [],
    recordingsCalls: [],
}

const clientReducer = (state = defaultState, action) => {
    switch (action.type) {

        case types.GET_SELECTED_DATE_FROM_CALENDAR:
            return {...state, tsData: action.payload.tsData};

        case types.GET_SELECTED_DATE_FROM_CALENDAR_SUCCESS:
            return {...state, tsData: action.payload.tsData};

        case types.CALENDAR_CHANGE_VIEW:
            return {...state, view: action.payload.view};

        case types.CALENDAR_CHANGE_VIEW_SUCCESS:
            return {...state, view: action.payload.view};

        case types.CALENDAR_EVENT:
            return {...state, event: action.payload.event};

        case types.CALENDAR_EVENT_SUCCESS:
            return {...state, event: action.payload.event};


        case types.CLIENT_RESET_STATE:
            return {...state, mc: action.payload.mc, mupc: null, cpItem: null, numberListA: [], campaigns: []};

        case types.CLIENT_RESET_STATE_SUCCES:
            return {
                ...state,
                mc: action.payload.mc,
                isLoadingC: true,
            };


        case types.UPDATE_CLIENT:
            return {
                ...state,
                upc: action.payload.upc,
                isLoadingC: action.payload.isLoadingC
            };

        case types.UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                mupc: action.payload.mupc,
                isLoadingC: action.payload.isLoadingC,
            };


        case types.UPDATE_CLIENT_FAILLED:
            return {
                ...state,
                isLoadingC: action.payload.isLoadingC,
                mc: action.payload.mc
            };


        case types.CREATE_CLIENT:
            return {
                ...state,
                client: action.payload.client,
                isLoadingC: action.payload.isLoadingC
            };

        case types.CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                mc: action.payload.mc,
                isLoadingC: action.payload.isLoadingC,
            };


        case types.CREATE_CLIENT_FAILLED:
            return {
                ...state,
                isLoadingC: action.payload.isLoadingC,
                mc: action.payload.mc
            };


        case types.GET_ALL_CLIENTS:
            return {
                ...state,
                isLoadingC: action.payload.isLoadingC
            };

        case types.GET_ALL_RECORDING_CALL_BY_ENTR_ID:
            return {
                ...state,
                isLoadingRC: action.payload.isLoadingRC,
                entr_id: action.payload.entr_id,
            };

        case types.GET_ALL_CLIENTS_SUCCESS:
        case types.GET_ALL_RECORDING_CALL_BY_ENTR_ID_SUCCESS:
            return {
                ...state,
                isLoadingC: action.payload.isLoadingC,
                clients: action.payload.clients,
                recordingsCalls: action.payload.recordingsCalls
            };

        case types.GET_ALL_CLIENTS_FAILLED:
        case types.GET_ALL_RECORDING_CALL_BY_ENTR_ID_FAILLED:
            return {
                ...state,
                isLoadingC: action.payload.isLoadingC,
                isLoadingRC: action.payload.isLoadingRC,
            };


        case types.CREATE_CUSTOMER:
            return {
                ...state,
                customer: action.payload.customer,
                isLoadingCO: action.payload.isLoadingCO
            };

        case types.CREATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                mc: action.payload.mc,
                isLoadingCO: action.payload.isLoadingCO,
            };


        case types.CREATE_CUSTOMER_FAILLED:
            return {
                ...state,
                isLoadingCO: action.payload.isLoadingCO,
                mc: action.payload.mc
            };


        case types.GET_ALL_CUSTOMERS:
            return {
                ...state,
                isLoadingCO: action.payload.isLoadingCO
            };

        case types.GET_ALL_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isLoadingCO: action.payload.isLoadingCO,
                customers: action.payload.customers
            };

        case types.GET_ALL_CUSTOMERS_FAILLED:
            return {
                ...state,
                isLoadingCO: action.payload.isLoadingCO,
            };


        case types.CREATE_CAMPAIGN:
        case types.EDIT_CAMPAIGN:
            return {
                ...state,
                campaign: action.payload.campaign,
                isLoadingCA: action.payload.isLoadingCA
            };

        case types.CREATE_CAMPAIGN_SUCCESS:
        case types.EDIT_CAMPAIGN_SUCCESS:
            return {
                ...state,
                mc: action.payload.mc,
                isLoadingCA: action.payload.isLoadingCA,
            };


        case types.CREATE_CAMPAIGN_FAILLED:
        case types.EDIT_CAMPAIGN_FAILLED:
            return {
                ...state,
                isLoadingCA: action.payload.isLoadingCA,
                mc: action.payload.mc
            };


        case types.GET_ALL_CAMPAIGN:
            return {
                ...state,
                isLoadingCA: action.payload.isLoadingCA,
                id: action.payload.id
            };

        case types.GET_CAMPAIGNS_BY_ID:
            return {
                ...state,
                isLoadingBCA: action.payload.isLoadingBCA,
                id: action.payload.id
            };

        case types.GET_ALL_REPORTS:
            return {
                ...state,
                isLoadingR: action.payload.isLoadingR,
            };

        case types.GET_ALL_REPORTS_SUCCESS:
            return {
                ...state,
                isLoadingR: action.payload.isLoadingR,
                reports: action.payload.reports
            };

        case types.GET_ALL_CAMPAIGN_SUCCESS:
            return {
                ...state,
                isLoadingCA: action.payload.isLoadingCA,
                campaigns: action.payload.campaigns
            };


        case types.GET_CAMPAIGNS_BY_ID_SUCCESS:
            return {
                ...state,
                isLoadingBCA: action.payload.isLoadingBCA,
                campaignsId: action.payload.campaignsId
            };

        case types.GET_ALL_CAMPAIGN_FAILLED:
            return {
                ...state,
                isLoadingCA: action.payload.isLoadingCA,
                campaigns: [],
                numberList: [],
            };

        case types.GET_CAMPAIGNS_BY_ID_FAILLED:
            return {
                ...state,
                isLoadingBCA: action.payload.isLoadingBCA,
                campaignsId: [],
                numberList: [],
            };


        case types.GET_ALL_REPORT_FAILLED:
            return {
                ...state,
                isLoadingR: action.payload.isLoadingR,
                reports: [],
            };


        case types.GET_ALL_PHONE_CAMPAIGN_BY_CODE:
        case types.GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE:
            return {
                ...state,
                isLoadingPC: action.payload.isLoadingPC,
                code: action.payload.code
            };

        case types.GET_ALL_PHONE_CAMPAIGN_BY_CODE_SUCCESS:
            return {
                ...state,
                isLoadingPC: action.payload.isLoadingPC,
                numberList: action.payload.numberList
            };

        case types.GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE_SUCCESS:
            return {
                ...state,
                isLoadingPC: action.payload.isLoadingPC,
                numberListA: action.payload.numberListA
            };

        case types.GET_ALL_PHONE_CAMPAIGN_BY_CODE_FAILLED:
        case types.GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE_FAILLED:
            return {
                ...state,
                isLoadingPC: action.payload.isLoadingPC
            };


        case types.GET_CAMPAIGNS:
            return {
                ...state,
                isLoadingC: action.payload.isLoadingC,
            };

        case types.GET_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                isLoadingPC: action.payload.isLoadingPC,
                allCampaigns: action.payload.allCampaigns
            };

        case types.GET_CAMPAIGNS_FAILLED:
            return {
                ...state,
                isLoadingC: action.payload.isLoadingC
            };


        case types.GET_CAMPAIGN_ITEM:
            return {
                ...state,
                cpItem: action.payload.cpItem
            };


        case types.GET_CAMPAIGN_ITEM_SUCCESS:
            return {
                ...state,
                cpItem: action.payload.cpItem
            };


        case types.GET_ALL_CAMPAIGNS_TO_CALENDAR:
            return {
                ...state,
                pid: action.payload.pid,
                isLoadingAS: action.payload.isLoadingAS
            };

        case types.GET_ALL_CAMPAIGNS_TO_CALENDAR_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                isLoadingAS: action.payload.isLoadingAS,
                campaignsToCalendar: action.payload.campaignsToCalendar
            };


        case types.GET_ALL_CAMPAIGNS_TO_CALENDAR_FAILLED:
            return {
                ...state,
                message: action.payload.message,
                isLoadingAS: action.payload.isLoadingAS
            };


        case types.DELETE_CAMPAIGN:
            return {
                ...state,
                id: action.payload.id,
                isLoadingDCA: action.payload.isLoadingDCA
            };

        case types.DELETE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                isLoadingDCA: action.payload.isLoadingDCA,
                campaignsId: state.campaignsId.filter(item => item?.campaing_code !== action.payload?.campaign_code)
            };


        case types.DELETE_CAMPAIGN_FAILLED:
            return {
                ...state,
                message: action.payload.message,
                isLoadingDCA: action.payload.isLoadingDCA
            };


        default:
            return state

    }
}


export default clientReducer
