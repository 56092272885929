import types from "../actions/type"

const defaultState = {
    isLoadingEXT: false,
    message: null,
    prefix: [],
    ext: null,
    id: null,
    mext: null,
    prefix_id: null,
    prefixA: [],
    prefixIds: [],
}

const prefixReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_ALL_PREFIX:
        case types.GET_ALL_PREFIX_AVAILABLE:
            return {
                ...state,
                isLoadingEXT: action.payload.isLoadingEXT
            };

        case types.GET_ALL_PREFIX_BY_ID:
            return {
                ...state,
                isLoadingEXT: action.payload.isLoadingEXT,
                prefix_id: action.payload.prefix_id
            };

        case types.GET_ALL_PREFIX_AVAILABLE_SUCCESS:
            return {
                ...state,
                prefixA: action.payload.prefixA,
                isLoadingEXT: action.payload.isLoadingEXT
            };


        case types.GET_ALL_PREFIX_BY_ID_SUCCESS:
            return {
                ...state,
                prefixIds: action.payload.prefixIds,
                isLoadingEXT: action.payload.isLoadingEXT
            };


        case types.GET_ALL_PREFIX_SUCCESS:
            return {
                ...state,
                prefix: action.payload.prefix,
                isLoadingEXT: action.payload.isLoadingEXT
            };

        case types.GET_ALL_PREFIX_FAILLED:
        case types.GET_ALL_PREFIX_AVAILABLE_FAILLED:
        case types.GET_ALL_PREFIX_BY_ID_FAILLED:
            return {
                ...state,
                mext: action.payload.mext,
                isLoadingEXT: action.payload.isLoadingEXT,
                prefixA: [],
                prefixIds: [],
                prefix: []
            };


        case types.CREATE_PREFIX:
            return {
                ...state,
                isLoadingEXT: action.payload.isLoadingEXT,
                ext: action.payload.ext
            };

        case types.UPDATE_PREFIX:
            return {
                ...state,
                isLoadingEXT: action.payload.isLoadingEXT,
                ext: action.payload.ext
            };

        case types.CREATE_PREFIX_SUCCESS:
        case types.UPDATE_PREFIX_SUCCESS:
            return {
                ...state,
                mext: action.payload.mext,
                isLoadingEXT: action.payload.isLoadingEXT
            };

        case types.CREATE_PREFIX_FAILLED:
        case types.UPDATE_PREFIX_FAILLED:
        case types.DELETE_PREFIX_FAILLED:
            return {
                ...state,
                mext: action.payload.mpo,
                isLoadingEXT: action.payload.isLoadingEXT
            };


        case types.DELETE_PREFIX:
            return {
                ...state,
                id: action.payload.id,
            };

        case types.DELETE_PREFIX_SUCCESS:
            return {
                ...state,
                prefix: state.prefix.filter(item => item.id !== action.payload?.id)
            };

        default:
            return state

    }
}


export default prefixReducer