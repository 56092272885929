import React from 'react'
import {SigninModel} from '../../constants/model';
import Form from './form';

const Signin = ({SwitchToSignup, isLoading, message, sn, signin, resetStateSignin, intl}) => {


    const submit = values => {

        const snEntity = new SigninModel({...values});
        signin(snEntity);
    };


    return (
        <Form
            onSubmit={submit}
            message={message}
            intl={intl}
            resetStateSignin={resetStateSignin}
            isLoading={isLoading}
            SwitchToSignup={SwitchToSignup}
            sn={sn}
        />
    )
}
export default Signin;