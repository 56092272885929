import moment from "moment";
import { put, take, call, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/creator/event";
import types from "../actions/type";
import * as api from "../services/event";


export const handleGetAllCalendarEvents = function* () {

    try {

        const getT = yield call(api.getAllCalendarEvents);

        let result = getT.data;

        if (result.length > 0) {
            result.map((event, index) => {
                result[index].title = `${event.entr_name}`;
                result[index].desc = `Desc of event ${event.title}`;
                result[index].start = new moment(event.created_at);
                result[index].end = new moment(event.created_at);

            });

            let ev = result;

            yield put(actions.getAllCalendarEventsSuccess(ev))

        } else {
            yield put(actions.getAllCalendarEventsFailled(getT.data.message))
        }

    } catch (error) {
        yield put(actions.getAllCalendarEventsFailled("error"))
    }
};


export const handleGetAllCalendarEventsByEntreprise = function* (action) {

    try {

        const { entr_id } = action.payload;

        const getT = yield call(api.getAllCalendarEventsByEntreprise, entr_id);

        let result = getT.data;

        if (result.length > 0) {
            result.map((event, index) => {
                result[index].title = `${event.title}`;
                result[index].desc = `Desc of event ${event.title}`;
                result[index].start = new moment(event.created_at);
                result[index].end = new moment(event.created_at);

            });

            let ev = result;

            yield put(actions.getAllCalendarEventsSuccess(ev))

        } else {
            yield put(actions.getAllCalendarEventsFailled(getT.data.message))
        }

    } catch (error) {
        yield put(actions.getAllCalendarEventsFailled("error"))
    }
};

export const handleCreateCalendarEvent = function* (action) {

    try {

        const { event } = action.payload;

        const getPs = yield call(api.createCalendarEvent, event);
        if (getPs.data.message === "success") {
            yield put(actions.createCalendarEventSuccess())
        } else {
            yield put(actions.createCalendarEventFailled())
        }

    } catch (error) {
        yield put(actions.createCalendarEventFailled())
    }
}

export const handleUpdateCalendarEvent = function* (action) {

    try {

        const { event } = action.payload;

        const getPs = yield call(api.updateCalendarEvent, event);
        if (getPs.data.message === "success") {
            yield put(actions.updateCalendarEventSuccess())
        } else {
            yield put(actions.updateCalendarEventFailled())
        }

    } catch (error) {
        yield put(actions.updateCalendarEventFailled())
    }
}

export const handleViewCalendarEvent = function* (action) {

    try {

        const { view } = action.payload;

        const getPs = yield call(api.viewCalendarEvent, view);
        if (getPs.data.message === "success") {
            yield put(actions.setViewCalendarEventSuccess())
        } else {
            yield put(actions.setViewCalendarEventFailled())
        }

    } catch (error) {
        yield put(actions.setViewCalendarEventFailled())
    }
}

export const handlePublishCalendarEvent = function* (action) {

    try {

        const { event } = action.payload;

        const getPs = yield call(api.publihsCalendarEvent, event);
        if (getPs.data.message === "success") {
            yield put(actions.updateCalendarEventSuccess())
        } else {
            yield put(actions.updateCalendarEventFailled())
        }

    } catch (error) {
        yield put(actions.updateCalendarEventFailled())
    }
}


export const handleResetStateEvent = function* () {
    yield put(actions.calendarEventChangeStatusSuccess())
};

export const handleGetCalendarEventInfo = function* (action) {

    try {
        const { cInfo } = action.payload;
        yield put(actions.getCalendarEventInfoSuccess(cInfo))
    } catch (error) { }

};


export const handleGetAllCalendarEventsByAgent = function* (action) {

    try {

        const { agent_id } = action.payload;

        const getT = yield call(api.getAllCalendarEventsByAgent, agent_id);

        let result = getT.data;

        if (result.length > 0) {
            result.map((event, index) => {
                result[index].title = `${event.entr_name}`;
                result[index].desc = `Desc of event ${event.title}`;
                result[index].start = new moment(event.created_at);
                result[index].end = new moment(event.created_at);

            });

            let ev = result;

            yield put(actions.getAllCalendarEventsSuccess(ev))

        } else {
            yield put(actions.getAllCalendarEventsFailled(getT.data.message))
        }

    } catch (error) {
        yield put(actions.getAllCalendarEventsFailled("error"))
    }
};



export const eventSaga = [
    takeLatest(types.GET_ALL_CALENDAR_EVENT, handleGetAllCalendarEvents),
    takeLatest(types.GET_ALL_CALENDAR_EVENT_BY_ENTREPRISE, handleGetAllCalendarEventsByEntreprise),
    takeLatest(types.GET_ALL_CALENDAR_EVENT_BY_AGENT, handleGetAllCalendarEventsByAgent),
    takeLatest(types.CALENDAR_EVENT_CHANGE_STATUS, handleResetStateEvent),
    takeLatest(types.GET_CALENDAR_EVENT_INFO, handleGetCalendarEventInfo),
    takeLatest(types.CREATE_CALENDAR_EVENT, handleCreateCalendarEvent),
    takeLatest(types.UPDATE_CALENDAR_EVENT, handleUpdateCalendarEvent),
    takeLatest(types.PUBLISH_CALENDAR_EVENT, handlePublishCalendarEvent),
    takeLatest(types.VIEW_CALENDAR_EVENT, handleViewCalendarEvent),

];
