/**
 * Created by louidorpc on 8/16/20.
 */
import React,{useState} from 'react'
import EntrepriseForm from './entrepriseForm'
import EntrepriseTable from './entrepriseTable'
import {FormattedMessage} from "react-intl";
import {app} from '../../../constants/config';
import {Entreprise, Campaign} from '../../../constants/model'
import PhonenubersModal from './phonenumberModal'


const    CampaignComponent =props=>{

    const {
        ps, intl, msEnt, person_id, mc, language, resetStateClient,entreprise_id,
        getAllPhoneCampaigns, isLoadingPC, numberList,createCampaign,editCampaign, getEntrepriseInfo,
        campaignsId, entrepriseInfo, getCampaignItem, cpItem,getAllCampaignsById,deleteCampaignsById
    }=props;
    // constructor(props) {
    //
    //     super(props);
    //
    //     this.state = {
    //         photo: null,
    //         pictureType: null,
    //         columns: [],
    //         data: [],
    //         listof: [],
    //         clientList: []
    //     }
    // }
    //
    const [listof, setlistof]=useState([])
    const [clientList, setClientList]=useState([])


   const  handleGetData = (l) => {
       setlistof(l)
    }

    const resetClientList = l => {
        setClientList(l)
        console.log("_______----____________________---", l)
    }

    const  removeItemClient = l => {

        setClientList(l)
    }

    const resetListClient = () => {
        const li = []
        setlistof(li)
    }


    const  resetList= l => {
        setlistof(l)
        setClientList(l)
    }

    const submitSetting = values => {
        const entity = {
            campaign_name: values.campaign_name,
            campaign_slogan: values.campaign_slogan,
            start_date: values.start_date,
            end_date: values.end_date,
            end_hour: values.end_hour,
            start_hour: values.start_hour,
            note: values.note,
            entreprise_id: entreprise_id,
            phone_list: [...clientList, ...listof],
            language: language,
            creator:person_id,
            campaing_code: values.campaing_code ? values.campaing_code : null,
            id: values.id ? values.id : null,
        }
        const campaing = new Campaign(entity);
        console.log(")))))))))))))))))))))")
        if (cpItem == null) {
            createCampaign(campaing);
        } else {
           editCampaign(campaing)
        }
    };
    const resetFile = () => {
        //this.setState({columns: [], data: [], listof: []})
        // this.state.listof = [];
        console.log("------------_______________________-----------")
    }


        return (
            <>
            <div class="row pt-4">

                <div class="col-md-4 col-xs-12 m-b-30">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="">
                                <FormattedMessage id={`app.label.campaign_creation`} defaultMessage={`Label Name`}/>
                            </h3>


                            <EntrepriseForm ps={ps} intl={intl}
                                            onSubmit={submitSetting}
                                            msEnt={msEnt}
                                            resetStateClient={resetStateClient}
                                            resetListClient={resetListClient}
                                            entrprise_id={entreprise_id}
                                            person_id={person_id}
                                            entrepriseInfo={entrepriseInfo}
                                            resetFile={resetFile}
                                            language={language}
                                            handleGetData={handleGetData}
                                            getAllCampaignsById={getAllCampaignsById}
                                            listof={[...clientList, ...listof]}
                                            mc={mc}
                                            setClientList={setClientList}
                                            clientList={clientList}
                                            removeItemClient={removeItemClient}
                                            cpItem={cpItem}
                                            resetList={resetList}
                                            setlistof={setlistof}
                            />
                        </div>
                    </div>
                </div>

                <div class="col-md-8 col-xs-12" style={{height:'100%'}}>
                    <div class="card">
                        <div class="card-body">
                            <EntrepriseTable language={language}
                                             campaignsId={campaignsId && campaignsId.length > 0 ? campaignsId : []}
                                             getAllPhoneCampaigns={getAllPhoneCampaigns}
                                             getEntrepriseInfo={getEntrepriseInfo}
                                             getCampaignItem={getCampaignItem}
                                             deleteCampaignsById={deleteCampaignsById}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <PhonenubersModal language={language} isLoadingPC={isLoadingPC}
                              numberList={numberList}/>
            </>
        )

    }


export default CampaignComponent