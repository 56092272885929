import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import actions from "../actions/creator"
import AgentNoteForm from "./ageNoteForm"
import {injectIntl} from 'react-intl';

const mapStateToProps = ({settingReducer, intl, history, agentReducer, clientReducer, personReducer,campaignReducer}) => ({
    intl: intl,
    history: history,
    sr: settingReducer,
    ag: agentReducer,
    cl: clientReducer,
    person: personReducer,
    cp: campaignReducer,

});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default (injectIntl(connect(mapStateToProps, mapDispatchToProps)(AgentNoteForm)))
