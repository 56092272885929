/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import {getIntlMsg, intl} from "../../constants/utils";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()


export const getAllServices = () => ({
    type: types.GET_ALL_SERVICES,
    payload: {isLoadingSv: true}
});


export const getAllServicesSuccess = mssv => ({
    type: types.GET_ALL_SERVICES_SUCCESS,
    payload: {isLoadingSv: false, services: mssv}
});

export const getAllServicesFailled = mssv => ({
    type: types.GET_ALL_SERVICES_FAILLED,
    payload: {isLoading: true, mssv: mssv}
});


export const createService = service => ({
    type: types.CREATE_SERVICE,
    payload: {
        isLoadingSv: true, service: service
    }
});


export const createServiceSuccess = mssv => {

    toast.success(getIntlMsg(intl(mssv), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_SERVICE_SUCCESS,
        payload: {isLoadingSv: false, mssv: mssv}
    };
}

export const createServiceFailled = mp => {

    toast.error(getIntlMsg(intl(mp.locale), mp.mssv), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_SERVICE_FAILLED,
        payload: {isLoadingSv: true, mssv: mp.mssv}
    };
}


export const updateService = upsv => ({
    type: types.UPDATE_SERVICE,
    payload: {
        isLoadingP: true, upsv: upsv
    }
});


export const updateServiceSuccess = mssv => {

    toast.success(getIntlMsg(intl(mssv), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_SERVICE_SUCCESS,
        payload: {isLoading: false, mssv: mssv}
    };
}

export const updateServiceFailled = mp => {

    toast.error(getIntlMsg(intl(mp.locale), mp.mssv), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_SERVICE_FAILLED,
        payload: {isLoading: true, mssv: mp.mssv}
    };
}





export const deleteService = id => ({
    type: types.DELETE_SERVICE,
    payload: {
        isLoadingD: true, id: id
    }
});


export const deleteServiceSuccess = id => {

    toast.success(getIntlMsg(intl('fr'), `app.label.deleteSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_SERVICE_SUCCESS,
        payload: {isLoading: false, id: id}
    };
}

export const deleteServiceFailled = mp => {

    toast.error(getIntlMsg(intl('fr'), mp.mssv), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_SERVICE_FAILLED,
        payload: {isLoading: true, mssv: mp.mssv}
    };
}


