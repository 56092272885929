import { put, call, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/creator/daysreport";
import types from "../actions/type";
import * as api from "../services/daysreport";


export const handleGetAllDaysreports = function* (action) {
    const { criteria } = action.payload
    try {
        const getSv = yield call(api.getAllDaysreports, criteria);
        if (getSv.data.length > 0) {
            yield put(actions.getAllDaysReportsSuccess(getSv.data))
        } else {
            yield put(actions.getAllDaysReportsFailed(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllDaysReportsFailed("error"))
    }


};


export const handleGetAllDaysreportsByClient = function* (action) {
    const { criteria } = action.payload;
    try {
        const getSv = yield call(api.getAllDaysreportsbyclient, criteria);
        if (getSv.data.length > 0) {
            yield put(actions.getAllDaysReportsByClientSuccess(getSv.data))
        } else {
            yield put(actions.getAllDaysReportsByClientFailed(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllDaysReportsByClientFailed("error"))
    }


};

export const handleCreateDaysreport = function* (action) {
    const { daysreport } = action.payload;
    try {
        const pos = yield call(api.createDaysreport, daysreport);
        if (pos.data.message == "success") {
            yield put(actions.createDaysReportSuccess(pos.data.message))
            yield put(actions.getAllDaysReports())

        } else {
            yield put(actions.createDaysReportFailed('error'))
        }
    } catch (error) {
        yield put(actions.createDaysReportFailed('error'))
    }
};

export const handleEditDaysreport = function* (action) {
    const { daysreport } = action.payload;
    try {
        const pos = yield call(api.updateDaysreport, daysreport);
        if (pos.data.message == "success") {
            yield put(actions.editDaysReportSuccess(pos.data.message))
            yield put(actions.getAllDaysReports())

        } else {
            yield put(actions.editDaysReportFailed('error'))
        }
    } catch (error) {
        yield put(actions.editDaysReportFailed('error'))
    }
};


export const handleDeleteDaysreport = function* (action) {
    const { daysreport } = action.payload;
    try {
        const pos = yield call(api.deleteDaysreport, daysreport?.id);
        if (pos.data.message == "success") {
            yield put(actions.deleteDaysReportSuccess(pos.data.message))
            yield put(actions.getAllDaysReports())

        } else {
            yield put(actions.deleteDaysReportFailed('error'))
        }
    } catch (error) {
        yield put(actions.deleteDaysReportFailed('error'))
    }
};


export const handleResetDaysreport = function* () {
    yield put(actions.resetDaysReportSuccess())
};



export const daysreportSaga = [
    takeLatest(types.GET_ALL_DAYS_REPORTS, handleGetAllDaysreports),
    takeLatest(types.CREATE_DAYS_REPORT, handleCreateDaysreport),
    takeLatest(types.GET_ALL_DAYS_REPORTS_BY_CLIENT, handleGetAllDaysreportsByClient),
    takeLatest(types.EDIT_DAYS_REPORT, handleEditDaysreport),
    takeLatest(types.RESET_DAYS_REPORT, handleResetDaysreport),
    takeLatest(types.DELETE_DAYS_REPORT, handleDeleteDaysreport),
];
