import React from "react";
import { injectIntl, FormattedDate, FormattedMessage } from "react-intl";

export const Title = injectIntl(({ intl }) => {
 const date = new Date();
  return (
      <div className="kt-subheader   kt-grid__item">
            <div className="kt-subheader__main">
                  <h3 className="kt-subheader__title">
                        <FormattedMessage id="app.name" defaultMessage="Dashboard"/>
                    </h3>
                  <span className="kt-subheader__separator kt-hidden"></span>
                  <div className="kt-subheader__breadcrumbs">
                        <span className="kt-subheader__breadcrumbs-home">
                              <i className="flaticon2-shelter"></i>
                        </span>
                        <span className="kt-subheader__breadcrumbs-separator"></span>
                        <span className="kt-subheader__breadcrumbs-link text-black">
                              <FormattedDate value={date} year="numeric"  month="short" day="2-digit"/>
                         </span>
                        <span className="kt-subheader__breadcrumbs-separator"></span>
                        <span className="kt-subheader__breadcrumbs-link">
                             <FormattedMessage id="app.label.adm_operation" defaultMessage="Dashboard"/>
                         </span>
                  </div>
            </div>
      </div>
  )
})