/**
 * Created by louidorpc on 8/5/20.
 */
import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getIntlMsg, intl} from "../../constants/utils";


toast.configure()

export const loginSwitch = type => ({
    type: types.LOGIN_SWITCH,
    payload: {type: type}
});

export const SwitchToSignin = type => ({
    type: types.SWITCH_TO_SIGNIN,
    payload: {type: type}
});


export const SwitchToSignup = type => ({
    type: types.SWITCH_TO_SIGNUP,
    payload: {type: type}
});

export const resetStateSignin = () => ({
    type: types.RESET_STATE_SIGNIN,
    payload: {message: null}
});


export const resetStateSigninSuccess = () => ({
    type: types.RESET_STATE_SIGNIN_SUCCESS,
    payload: {message: null}
});

export const resetStateSignup = () => ({
    type: types.RESET_STATE_SIGNUP,
    payload: {message: null}
});


export const resetStateSignupSuccess = () => ({
    type: types.RESET_STATE_SIGNUP_SUCCESS,
    payload: {message: null}
});

export const resetStateResetPassword = () => ({
    type: types.RESET_STATE_RESET_PASSWORD,
    payload: {message: null}
});
export const resetStateResetPasswordSuccess = () => ({
    type: types.RESET_STATE_RESET_PASSWORD_SUCCESS,
    payload: {message: null}
});


export const signin = sn => ({
    type: types.SIGNIN,
    payload: {isLoading: true, sn: sn}
});


export const signup = sp => ({
    type: types.SIGNUP,
    payload: {isLoading: true, sp: sp}
});


export const signinSuccess = msn => {
    toast.success(msn.message, {
        position: toast.POSITION.TOP_RIGHT
    });
    return {
        type: types.SIGNIN_SUCCESS,
        payload: {isLoading: false, msn: msn, message: `success signin`, msItem: null, type: "signin"}
    }

}


export const signupSuccess = msp => {
    toast.success(msp.response, {
        position: toast.POSITION.TOP_RIGHT
    });
    return {
        type: types.SIGNUP_SUCCESS,
        payload: {isLoading: false, msp: msp, message: `success signup`, msItem: null, type: "signin"}
    }

}


export const signinFailled = msn => {
    toast.error(getIntlMsg(intl('en'), `app.label.${msn}`), {
        position: toast.POSITION.TOP_RIGHT
    });
    return {
        type: types.SIGNIN_FAILLED,
        payload: {isLoading: false, message: `app.label.${msn}`}
    };
}

export const signupFailled = msn => {
    toast.error(msn, {
        position: toast.POSITION.TOP_RIGHT
    });
    return {
        type: types.SIGNUP_FAILLED,
        payload: {isLoading: false, message: `error`}
    };
}


export const signinAgent = sna => ({
    type: types.SIGNIN_AGENT,
    payload: {isLoading: true, sna: sna}
});


export const signinAgentSuccess = msna => {
    toast.success(msna.message, {
        position: toast.POSITION.TOP_RIGHT
    });
    return {
        type: types.SIGNIN_AGENT_SUCCESS,
        payload: {isLoading: false, msna: msna, message: "success", msItem: null, type: "agent_login"}
    }

}


export const signinAgentFailled = msna => {
    toast.error(getIntlMsg(intl('en'), `app.label.${msna}`), {
        position: toast.POSITION.TOP_RIGHT
    });
    return {
        type: types.SIGNIN_AGENT_FAILLED,
        payload: {isLoading: false, message: `app.label.${msna}`}
    };
}


export const resetPassword = sna => ({
    type: types.RESET_PASSWORD,
    payload: {isLoading: true, sna: sna}
});


export const resetPasswordSuccess = msna => {
    toast.success(msna.message, {
        position: toast.POSITION.TOP_RIGHT
    });
    return {
        type: types.RESET_PASSWORD_SUCCESS,
        payload: {isLoading: false, msna: msna, message: "reset password success", msItem: null,type: "signin"}
    }

}


export const resetPasswordFailled = msna => {
    toast.error(getIntlMsg(intl('en'), `app.label.${msna}`), {
        position: toast.POSITION.TOP_RIGHT
    });
    return {
        type: types.RESET_PASSWORD_FAILLED,
        payload: {isLoading: false, message: `app.label.${msna}`}
    };
}






