import  {injectIntl, createIntl, createIntlCache} from "react-intl"
import Messages from "../constants/messages"
/**
 NOTE  get internationalized text by a specific key
 *     @param intl
 *     @param key
 *     @return string
 */
export const getIntlMsg = (intl, key) => {
    return intl.formatMessage({id: key});
};

const cache = createIntlCache()

export const intl = loc => {

    return createIntl({
        locale: loc,
        messages: Messages[loc]
    }, cache)
}


/**
 NOTE  Uppercase the first letter of a string
 *     @param  s : string
 *     @return string
 */
export const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};


/**
 NOTE  Replace a specific item in a list
 *     @param  items : array
 *     @param  item: Item
 *     @return array
 */
export const replaceItem = (items, item) => {
    let newItems = items;
    newItems.forEach(function (element, index, array) {
        if (element.id == item.id) {
            newItems[index] = item;
        }
    });

    console.log("************************new ITEM", newItems)

    return newItems;
};


/**
 NOTE  Remove a specific item by its ID, in a list
 *     @param  items : array
 *     @param  id: integer
 *     @return array
 */
export const removeItem = (items, id) => {
    let newItems = items;
    items.forEach(function (element, index, array) {
        if (element.id === id) {
            newItems.splice(index, 1);
        }
    });
    return newItems;
};


/**
 NOTE  Set a specific finger in a Map of fingers if it not exists,
 replace it, if it exists
 *     @param  fingers : Map
 *     @param  finger: Finger
 *     @return Map
 */
export const setFinger = (fingers, finger) => {
    let newFingers = fingers;

    if (fingers.size !== 0) {
        fingers.forEach((value, key, map) => {
            key === finger.fingerSide
                ? newFingers.set(key, finger)
                : newFingers.set(finger.fingerSide, finger);
        });
    } else {
        newFingers.set(finger.fingerSide, finger);
    }

    return newFingers;
};

export function ageYearDif(birthdate, lang) {
    const age = birthdate == null ? '--' : new Date((new Date()) - new Date(birthdate)).getUTCFullYear() - 1970;
    return `${age !=='--' ? age +getIntlMsg(intl(lang), 'app.label.year'):'---'}`;
}
/**
 NOTE  Set a specific finger in a Map of fingers if it not exists,
 replace it, if it exists
 *     @param  fingers : Map
 *     @param  finger: Finger
 *     @return Map
 */
export const getFingers = fingers => {
    let newFingers = fingers;

    if (fingers.size !== 0) {
        fingers.forEach((finger, key, map) => {
            if (key === "left_index") {
                newFingers.leftIndex = finger.minutiae;
                newFingers.leftIndexImage = finger.image;
                newFingers.leftIndexQuality = finger.quality;
            }
            if (key === "right_index") {
                newFingers.rightIndex = finger.minutiae;
                newFingers.rightIndexImage = finger.image;
                newFingers.rightIndexQuality = finger.quality;
            }
        });
    }

    return newFingers;
};


export const bloodTypes = [
    {
        key: 'a+',
        value: "A+",
        isDefault: false
    },
    {
        key: 'b+',
        value: "B+",
        isDefault: true
    },
    {
        key: 'ab+',
        value: "AB+",
        isDefault: true
    },
    {
        key: 'o+',
        value: "O+",
        isDefault: true
    },
    {
        key: 'a-',
        value: "A-",
        isDefault: false
    },
    {
        key: 'b-',
        value: "B-",
        isDefault: true
    },
    {
        key: 'ab-',
        value: "AB-",
        isDefault: true
    },
    {
        key: 'o-',
        value: "O-",
        isDefault: true
    },
];

export const Localization = (language) => ({
    grouping: {
        groupedBy: "Grouped By:",
        placeholder: "Drag headers here to group by"
    },
    pagination: {
        labelDisplayedRows: getIntlMsg(intl(language), `app.label.labelDisplayedRows`),
        labelRowsPerPage: "Lignes par page:",
        labelRowsSelect: getIntlMsg(intl(language), `app.label.labelRowsSelect`),
        firstTooltip: getIntlMsg(intl(language), `app.label.firstTooltip`),
        lastTooltip: getIntlMsg(intl(language), `app.label.lastTooltip`),
        previousTooltip: getIntlMsg(intl(language), `app.label.previousTooltip`),
        nextTooltip: getIntlMsg(intl(language), `app.label.nextTooltip`),
    },
    body: {
        filterRow: {searchText: "tetetetet"},
        emptyDataSourceMessage: getIntlMsg(intl(language), `app.label.emptyDataSourceMessage`),
        editRow: {
            saveTooltip: getIntlMsg(intl(language), `app.label.save`),
            cancelTooltip: getIntlMsg(intl(language), `app.label.cancel`),
        },
        addTooltip: getIntlMsg(intl(language), `app.label.add`),
        deleteTooltip: getIntlMsg(intl(language), `app.label.delete`),
        editTooltip: getIntlMsg(intl(language), `app.label.edit`),
    },
    toolbar: {
        searchTooltip: getIntlMsg(intl(language), `app.label.searchTooltip`),
        searchPlaceholder: getIntlMsg(intl(language), `app.label.searchPlaceholder`),
    }
})
