/**
 * Created by louidorpc on 11/4/20.
 */
/**
 * Created by louidorpc on 8/4/20.
 */
import React from 'react'
import Agent from '../../components/agent'


const HomeAgent = props => {

    const {
        sr,
        ag,
        cl,
        entreprises,
        actions: {
            getAllAgentSession,
            getAllEmergencyCall,
            getAllPhones,
            getAllPhoneCampaignsAvailable,
            getAllRecordingCall,
            getAllEntreprises,
            changeView,
            changeEvents,
            getSelectedDataFromCalendar,
            getAllSessionByAgent,
            getAllCampaigns,
            getAllPhoneCampaigns,
            getAllPersons,
            createAssignCampain,
            resetEmergencyCall,
            resetStateClient
        },
        person: {persons}
    } = props;

    if (sr.session !== null)
        return (
            <Agent {...sr} {...ag} getAllAgentSession={getAllAgentSession} changeView={changeView} {...cl}
                   getAllSessionByAgent={getAllSessionByAgent}
                   changeEvents={changeEvents}
                   getSelectedDataFromCalendar={getSelectedDataFromCalendar}
                   getAllCampaigns={getAllCampaigns}
                   getAllPhoneCampaigns={getAllPhoneCampaigns}
                   getAllPersons={getAllPersons}
                   persons={persons}
                   createAssignCampain={createAssignCampain}
                   getAllRecordingCall={getAllRecordingCall}
                   getAllEmergencyCall={getAllEmergencyCall}
                   entreprises={entreprises}
                   getAllEntreprises={getAllEntreprises}
                   getAllPhones={getAllPhones}
                   getAllPhoneCampaignsAvailable={getAllPhoneCampaignsAvailable}
                   resetEmergencyCall={resetEmergencyCall}
                   resetStateClient={resetStateClient}
            />
        )
    else return <div></div>
}


export default HomeAgent;