/**
 * Created by louidorpc on 12/9/20.
 */
import React, {useState} from "react";

const ClientForm = ({setClientList, removeItem}) => {

    const [inputList, setInputList] = useState([{Telephone: "", Nom: "", Prenom: "", note: ""}]);
    const handleInputChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        //  setClientList(list)
        removeItem(index);
    };

    const handleAddClick = () => {
        setInputList([...inputList, {Telephone: "", Nom: "", Prenom: "", note: ""}]);
        setClientList(inputList)
    };


    return (
        <div className="App">
            <h5><a href="#">Ajouter nouveau client.</a></h5>
            {inputList.map((x, i) => {
                return (
                    <div className="row">
                        <div className="form-group floating-label show-label col-md-6">
                            <input
                                className="form-control"
                                name="Nom"
                                placeholder="Nom"
                                value={x.Nom}
                                property={x.Nom ? 'required' : null}
                                onChange={e => handleInputChange(e, i)}
                            />
                        </div>

                        <div className="form-group floating-label show-label col-md-6">
                            <input
                                className="form-control"
                                name="Prenom"
                                placeholder="Prénom"
                                value={x.Prenom}
                                onChange={e => handleInputChange(e, i)}
                                property={x.Prenom ? 'required' : null}
                            />
                        </div>

                        <div className="form-group floating-label show-label col-md-6">
                            <input
                                className="form-control"
                                name="Téléphone"
                                placeholder="Telephone"
                                value={x.Telephone}
                                onChange={e => handleInputChange(e, i)}
                                property={x.Telephone ? 'required=""' : null}
                            />
                        </div>

                        <div className="form-group floating-label show-label col-md-12 col-12">
                            <textarea
                                className="form-control"
                                id="datetimepicker1"
                                name="note"
                                type="textarea"
                                value={x.note}
                                onChange={e => handleInputChange(e, i)}
                                property={x.note ? 'required=""' : null}
                                placeholder="Note"
                            />

                        </div>
                        <div className="form-group col-md-6 float-right">
                            <div className="btn-box">
                                {inputList.length !== 1 && <button
                                    className="mr10 mr-2 btn btn-danger"
                                    onClick={() => handleRemoveClick(i)}>Supprimer</button>}
                                {inputList.length - 1 === i &&
                                <button onClick={handleAddClick} className="btn btn-info">Ajouter</button>}
                            </div>
                        </div>
                    </div>

                );
            })}
            {/*<div style={{marginTop: 20}}>{JSON.stringify(inputList)}</div>*/}
        </div>
    )
}
export default ClientForm;