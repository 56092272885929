/**
 * Created by louidorpc on 9/9/20.
 */
import React from 'react'
import PrefixTable from './prefixTable'

const Extension = ({language, prefix, prefixA, person_id, entreprises, updatePrefixById, createPrefix,deletePrefix}) => {

    return (

        <div class="row pt-4">

            <div class="col-md-12 col-xs-12 m-b-30">

                {prefixA && prefixA.length > 0 ?

                    <PrefixTable language={language}
                                 updatePrefixById={updatePrefixById}
                                 createPrefix={createPrefix}
                                 prefix={prefix}
                                 prefixA={prefixA}
                                 entreprises={entreprises}
                                 deletePrefix={deletePrefix}
                                 person_id={person_id}/> : null}
            </div>

        </div>
    )
}

export default Extension;