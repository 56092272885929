/**
 * Validate String size
 * @Param label
 * @Param value
 * @Param min
 * @Param max
 * @Param require
 */
export const validateString = (label, value = '', min = 0, max = 0, required = false) => {


    return (value.length === 0 && !required) ? ''
        : (required && value.trim().length === 0) ? 'app.label.field_required'
            : (value.length < min) ? 'app.label.must_have_at_least_' + min
                : (value.length > max) ? 'app.label.must_have_at_last_' + max
                    : ''

}


/**
 * Validate phone
 * @Param label
 * @Param value
 * @Param require
 */
export const validateHtPhone = (label, value = '', required = false) => {
    let phoneReg = /\d{4}-\d{4}/g

    return (value.length === 0 && !required) ? ''
        : (value.length === 0 && required) ? 'app.label.' + label
            : (!phoneReg.test(value)) ? 'app.label.phone_invalid'
                : ''
}


/**
 * Validate email
 * @Param label
 * @Param value
 * @Param require
 */
export const validateEmail = (label, value = '', required = false) => {

    let emailReg = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
    return (value.length === 0 && !required) ? ''
        : (value.length === 0 && required) ? 'app.label.required.' + label
            : (!emailReg.test(value)) ? 'app.label.email_invalid'
                : ''
}

export const passwordsMatch = (value, allValues) =>
    value !== allValues.password ? 'Passwords don\'t match' : undefined;