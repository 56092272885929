import types from "../actions/type"

const defaultState = {
    isLoadingL: false,
    msLink: null,
    links: [],
    link: null,
    id: null,
    type: null,
    entreprise_id: null,
    linkByType: [],
    linkByEntreprise: null,
}

const linkReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_ALL_LINKS:
            return {
                ...state,
                isLoadingL: action.payload.isLoadingL
            };

        case types.GET_LINK:
            return {
                ...state,
                isLoadingL: action.payload.isLoadingL,
                id: action.payload.id
            };

        case types.GET_LINK_SUCCESS:
            return {
                ...state,
                link: action.payload.link,
                isLoadingL: action.payload.isLoadingL
            };


        case types.GET_ALL_LINKS_SUCCESS:
            return {
                ...state,
                links: action.payload.links,
                isLoadingL: action.payload.isLoadingL
            };

        case types.GET_ALL_LINKS_FAILED:
            return {
                ...state,
                msLink: action.payload.msLink,
                isLoadingL: action.payload.isLoadingL,
                links: [],
            };

        case types.GET_LINK_FAILED:
            return {
                ...state,
                msLink: action.payload.msLink,
                isLoadingL: action.payload.isLoadingL,
                link: null,
            };


        case types.CREATE_LINK:
            return {
                ...state,
                isLoadingL: action.payload.isLoadingL,
                link: action.payload.link
            };

        case types.UPDATE_LINK:
            return {
                ...state,
                isLoadingL: action.payload.isLoadingL,
                link: action.payload.link
            };

        case types.CREATE_LINK_SUCCESS:
            return {
                ...state,
                isLoadingL: action.payload.isLoadingL,
                msLink: action.payload.msLink,
            };

        case types.UPDATE_LINK_SUCCESS:
            return {
                ...state,
                isLoadingL: action.payload.isLoadingL,
                msLink: action.payload.msLink,
            };

        case types.CREATE_LINK_FAILED:
        case types.UPDATE_LINK_FAILED:
        case types.DELETE_LINK_FAILED:
            return {
                ...state,
                mext: action.payload.mpo,
                msLink: action.payload.msLink
            };


        case types.DELETE_LINK:
            return {
                ...state,
                id: action.payload.id,
            };

        case types.DELETE_LINK_SUCCESS:
            return {
                ...state,
                links: state.links.filter(item => item.id !== action.payload?.id)
            };


        case types.GET_LINK_BY_TYPE:
            return {
                ...state,
                isLoadingL: action.payload.isLoadingL,
                type: action.payload.type
            };

        case types.GET_LINK_BY_TYPE_SUCCESS:
            return {
                ...state,
                linkByType: action.payload.linkByType,
                isLoadingL: action.payload.isLoadingL
            };

        case types.GET_LINK_BY_TYPE_FAILED:
            return {
                ...state,
                linkByType: [],
                isLoadingL: action.payload.isLoadingL
            };


            case types.GET_LINK_BY_ENTREPRISE:
                return {
                    ...state,
                    isLoadingL: action.payload.isLoadingL,
                    type: action.payload.type,
                    entreprise_id: action.payload.entreprise_id,
                };
    
            case types.GET_LINK_BY_ENTREPRISE_SUCCESS:
                return {
                    ...state,
                    linkByEntreprise: action.payload.linkByEntreprise,
                    isLoadingL: action.payload.isLoadingL
                };
    
            case types.GET_LINK_BY_ENTREPRISE_FAILED:
                return {
                    ...state,
                    linkByEntreprise: null,
                    isLoadingL: action.payload.isLoadingL
                };

        default:
            return state

    }
}


export default linkReducer