import {put, call, takeLatest} from "redux-saga/effects";
import * as actions from "../actions/creator/position";
import types from "../actions/type";
import * as api from "../services/position";


export const handleGetAllPositions = function* () {

    try {
        const getSv = yield call(api.getAllPositions);
        if (getSv.data.length > 0) {
            yield put(actions.getAllPositionsSuccess(getSv.data))
        } else {
            yield put(actions.getAllPositionsFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPositionsFailled("error"))
    }


};

export const handleCreatePosition = function* (action) {
    const {position} = action.payload;
    try {
        const pos = yield call(api.createPosition, position);
        if (pos.data.message == "success") {
            yield put(actions.createPositionSuccess(position.language))
            yield put(actions.getAllPositions())

        } else {
            yield put(actions.createPositionFailled({mpo: pos.data.message, locale: position.language}))
        }
    } catch (error) {
        yield put(actions.createPositionFailled({mpo: 'app.label.saveError', locale: position.language}))
    }
};

export const handleUpdatePosition = function* (action) {
    const {upo} = action.payload;
    try {
        const pos = yield call(api.updatePosition, upo);
        if (pos.data.message == "success") {
            yield put(actions.updatePositionSuccess(upo.language))
            yield put(actions.getAllPositions())

        } else {
            yield put(actions.updatePositionFailled({mpo: pos.data.message, locale: upo.language}))
        }
    } catch (error) {
        yield put(actions.updatePositionFailled({mpo: 'app.label.saveError', locale: upo.language}))
    }
};


export const handleDeletePosition = function* (action) {
    const {id} = action.payload;
    try {
        const serv = yield call(api.deletePosition, id);
        if (serv.data.message == "success") {
            yield put(actions.deletePositionSuccess(serv.data.id))
        } else {
            yield put(actions.deletePositionFailled({mssv: serv.data.message}))
        }
    } catch (error) {
        yield put(actions.deletePositionFailled({mssv: 'app.label.saveError'}))
    }
};


export const positionSaga = [
    takeLatest(types.GET_ALL_POSITIONS, handleGetAllPositions),
    takeLatest(types.CREATE_POSITION, handleCreatePosition),
    takeLatest(types.UPDATE_POSITION, handleUpdatePosition),
    takeLatest(types.DELETE_POSITION, handleDeletePosition),
];
