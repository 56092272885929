/**
 * Created by louidorpc on 8/5/20.
 */
import React from 'react'
import Signin from '../../components/signin';
import Signup from '../../components/signup';

const Login = props => {
    const {login:{type, sn, sp, msn, msp, message, isLoading}, intl, actions:{SwitchToSignin, SwitchToSignup, signin, signup, resetStateSignup, resetStateSignin}}=props;

    return (


        <div class="card-block">
            <div class="row m-b-20">
                <div class="col-md-12">
                    <h3 class="text-center txt-primary">Signup</h3>
                </div>
            </div>
            <p class="text-muted text-center p-b-5">Sign in with your regular account</p>


                <Signup SwitchToSignin={SwitchToSignin} signup={signup} isLoading={isLoading}
                        sn={msn}
                        message={message} resetStateSignup={resetStateSignup} intl={intl}/>
        </div>
    )


}

export default Login;