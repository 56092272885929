import React from "react";
import {Title} from "./Title";
import {TopBar} from "./TopBar";
import {Modal} from "./topbar/headerModal";
import  '@mdi/font/css/materialdesignicons.css';


const handleLogout = () => {
    sessionStorage.clear();
    document.location = "/";
}


export const HeaderBase = ({signoutAgent, userinfo, message,history}) => {
    if (message === "success")handleLogout()
        return (<>
            <header className="admin-header">
                <a href="#" className="sidebar-toggle" data-toggleclass="sidebar-open" data-target="body"> </a>
                <TopBar signoutAgent={signoutAgent} userinfo={userinfo} history={history}/>

            </header>
            <Modal/>
            </>
        )
}
