/**
 * Created by louidorpc on 11/4/20.
 */
import types from "../type"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getIntlMsg, intl } from "../../constants/utils";
import { injectIntl, createIntl, createIntlCache } from "react-intl"

toast.configure()

export const getSelectedDataFromCalendar = tsData => ({
    type: types.GET_SELECTED_DATE_FROM_CALENDAR,
    payload: { tsData: tsData }
});


export const getSelectedDataFromCalendarSuccess = tsData => ({
    type: types.GET_SELECTED_DATE_FROM_CALENDAR_SUCCESS,
    payload: { tsData: tsData }
});
export const changeView = view => ({
    type: types.CALENDAR_CHANGE_VIEW,
    payload: { view: view }
})


export const changeViewSuccess = view => ({
    type: types.CALENDAR_CHANGE_VIEW_SUCCESS,
    payload: { view: view }
})

export const changeEvents = envent => ({
    type: types.CALENDAR_EVENT,
    payload: { envent: envent }
})

export const changeEventsSuccess = envent => ({
    type: types.CALENDAR_EVENT_SUCCESS,
    payload: { envent: envent }
})


export const getAllAgentSession = pid => ({
    type: types.GET_ALL_AGENT_SESSIONS,
    payload: { isLoadingAS: true, pid: pid }
});


export const getAllAgentSessionSuccess = agentSessions => ({
    type: types.GET_ALL_AGENT_SESSIONS_SUCCESS,
    payload: { isLoadingAS: false, agentSessions: agentSessions }
});

export const getAllAgentSessionFailled = message => ({
    type: types.GET_ALL_AGENT_SESSIONS_FAILLED,
    payload: { isLoadingAS: true, message: message }
});


export const getAllSessionByAgent = id => ({
    type: types.GET_ALL_SESSIONS_BY_AGENT,
    payload: { isLoadingAS: true, id: id }
});


export const getAllSessionByAgentSuccess = agS => ({
    type: types.GET_ALL_SESSIONS_BY_AGENT_SUCCESS,
    payload: { isLoadingAS: false, agS: agS }
});

export const getAllSessionByAgentFailled = message => ({
    type: types.GET_ALL_SESSIONS_BY_AGENT_FAILLED,
    payload: { isLoadingAS: true, message: message }
});


export const changePhoneQty = pQty => ({
    type: types.CHANGE_PHONE_QTY,
    payload: { pQty: pQty }
});

export const changePhoneQtySuccess = pQty => ({
    type: types.CHANGE_PHONE_QTY_SUCCESS,
    payload: { pQty: pQty }
});


export const createAssignCampain = cac => ({
    type: types.CREATE_ASSIGN_CAMPAIGN,
    payload: { isLoadingCAC: true, cac: cac }
});


export const createAssignCampainSuccess = mcac => {

    toast.success(getIntlMsg(intl(mcac), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_ASSIGN_CAMPAIGN_SUCCESS,
        payload: { isLoadingCAC: false, mcac: 'success' }
    }
};


export const createAssignCampainFailled = ms => {

    toast.error(getIntlMsg(intl(ms.lg), ms.mcac), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_ASSIGN_CAMPAIGN_FAILLED,
        payload: { isLoadingCAC: true, mcac: ms.mcac }
    }
};


export const getAllRecordingCall = () => ({
    type: types.GET_ALL_RECORDING_CALL,
    payload: { isLoadingRC: true }
});


export const getAllRecordingCallSuccess = rcCall => ({
    type: types.GET_ALL_RECORDING_CALL_SUCCESS,
    payload: { isLoadingRC: false, rcCall: rcCall }
});

export const getAllRecordingCallFailled = message => ({
    type: types.GET_ALL_RECORDING_CALL_FAILLED,
    payload: { isLoadingRC: true, message: message }
});


export const createEmergencyCall = cac => ({
    type: types.CREATE_EMERGENCY_CALL,
    payload: { isLoadingCAC: true, cac: cac }
});


export const createEmergencyCallSuccess = mcac => {

    toast.success(getIntlMsg(intl(mcac), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_EMERGENCY_CALL_SUCCESS,
        payload: { isLoadingCAC: false, mcac: 'success' }
    }

};


export const createEmergencyCallFailled = ms => {

    toast.error(getIntlMsg(intl(ms.lg), ms.mcac), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.CREATE_EMERGENCY_CALL_FAILLED,
        payload: { isLoadingCAC: true, mcac: ms.mcac }
    }
};


export const editEmergencyCall = cac => ({
    type: types.EDIT_EMERGENCY_CALL,
    payload: { isLoadingCAC: true, cac: cac }
});


export const editEmergencyCallSuccess = mcac => {

    toast.success(getIntlMsg(intl(mcac), `app.label.editSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.EDIT_EMERGENCY_CALL_SUCCESS,
        payload: { isLoadingCAC: false, mcac: 'success' }
    }

};


export const editEmergencyCallFailled = ms => {

    toast.error(getIntlMsg(intl(ms.lg), ms.mcac), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.EDIT_EMERGENCY_CALL_FAILLED,
        payload: { isLoadingCAC: true, mcac: ms.mcac }
    }
};




export const deleteEmergencyCall = cac => ({
    type: types.DELETE_EMERGENCY_CALL,
    payload: { isLoadingCAC: true, cac: cac }
});


export const deleteEmergencyCallSuccess = mcac => {

    toast.success(getIntlMsg(intl(mcac), `app.label.deleteSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_EMERGENCY_CALL_SUCCESS,
        payload: { isLoadingCAC: false, mcac: 'success' }
    }

};


export const deleteEmergencyCallFailled = ms => {

    toast.error(getIntlMsg(intl(ms.lg), ms.mcac), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.DELETE_EMERGENCY_CALL_FAILLED,
        payload: { isLoadingCAC: true, mcac: ms.mcac }
    }
};

export const resetEmergencyCall = () => ({
    type: types.RESET_EMERGENCY_CALL,
    payload: { isLoadingCAC: true, mcac: "" }
});


export const resetEmergencyCallSuccess = () => ({
    type: types.RESET_EMERGENCY_CALL_SUCCESS,
    payload: { isLoadingCAC: true, mcac: "" }
});


export const getAllEmergencyCall = () => ({
    type: types.GET_ALL_EMERGENCY_CALL,
    payload: { isLoadingRC: true }
});


export const getAllEmergencyCallSuccess = emCalls => ({
    type: types.GET_ALL_EMERGENCY_CALL_SUCCESS,
    payload: { isLoadingRC: false, emCalls: emCalls }
});

export const getAllEmergencyCallFailled = message => ({
    type: types.GET_ALL_EMERGENCY_CALL_FAILLED,
    payload: { isLoadingRC: true, message: message }
});


export const getAllPhones = phone => ({
    type: types.GET_ALL_PHONE_BY_PHONE,
    payload: { isLoadingPH: true, phone: phone }
});


export const getAllPhonesSuccess = phones => ({
    type: types.GET_ALL_PHONE_BY_PHONE_SUCCESS,
    payload: { isLoadingPH: false, phones: phones }
});

export const getAllPhonesFailled = message => ({
    type: types.GET_ALL_PHONE_BY_PHONE_FAILLED,
    payload: { isLoadingPH: true, message: message }
});



