import React from 'react'
import ClientCalendar from './client';
import AdminMonthreport from './admin';



const MonthReportMain = props => {

    const { session, getAllEntreprises, getAllMonthReports,
        createMonthReport,
        getAllMonthReportsByClient,editMonthReport,resetMonthReport,deleteMonthReport } = props;

    if (session)
        if (session?.person_type === "client") {
            return (
                <ClientCalendar session={session}
                    getAllEntreprises={getAllEntreprises}
                    getAllDaysReportsByClient={getAllMonthReportsByClient}
                />
            )
        } else if (session?.position_name === "Superviseur" || session?.position_name === "Administrateur" && session?.entreprise_name === "Seedlee") {

            return (<AdminMonthreport session={session}
                getAllEntreprises={getAllEntreprises}
                getAllDaysReports={getAllMonthReports}
                createDaysReport={createMonthReport}
                editMonthReport={editMonthReport}
                resetMonthReport={resetMonthReport}
                deleteMonthReport={deleteMonthReport}
            />)
        }
        else {
            return (<h1>eorrr</h1>)
        }
}



export default MonthReportMain;