export class ContactModel {


    constructor({fullname, email, phone, message}) {
        this.fullname = fullname;
        this.email = email;
        this.phone = phone;
        this.message = message;
    }


}


export class SignupModel {

    constructor({fullname, username, email, password, language}) {
        this.fullname = fullname;
        this.email = email;
        this.username = username;
        this.password = password;
        this.language = language;

    }


}


export class Client {

    constructor({
                    fullname,
                    username,
                    email,
                    firstname,
                    lastname,
                    gender,
                    phone,
                    address,
                    nif_cin,
                    photo,
                    position_id,
                    person_id,
                    entreprise_id,
                    service_id,
                    language,
                    birth_date
                }) {
        this.fullname = firstname + ',' + lastname;
        this.email = email;
        this.username = username;
        this.firstname = firstname;
        this.lastname = lastname;
        this.gender = gender;
        this.phone = phone;
        this.address = address;
        this.nif_cin = nif_cin;
        this.photo = photo;
        this.position_id = position_id ? position_id : 1;
        this.entreprise_id = entreprise_id ? entreprise_id : 2;
        this.service_id = service_id ? service_id : 1;
        this.language = language;
        this.birth_date = birth_date;
        this.person_id = parseInt(person_id);
        this.person_type = 'client'

    }
}


export class Customer {

    constructor({customer, language, entreprise_id}) {
        this.customer = customer;
        this.language = language;
        this.entreprise_id = parseInt(entreprise_id);
    }
}


export class Campaign {

    constructor({
                    campaign_name,
                    campaign_slogan,
                    start_date,
                    end_date,
                    entreprise_id,
                    end_hour,
                    start_hour,
                    phone_list,
                    creator,
                    campaing_code,
                    id,
                    note
                }) {
        this.campaign_name = campaign_name;
        this.campaign_slogan = campaign_slogan ? campaign_slogan : "";
        this.start_date = start_date ? start_date : "";
        this.end_date = end_date ? end_date : "";
        this.end_hour = end_hour ? end_hour : "";
        this.note = note ? note : "";
        this.start_hour = start_hour ? start_hour : "";
        this.phone_list = phone_list ? phone_list.filter(v => v.Nom !== "Liste des clients AEROPOST") : [];
        this.creator = parseInt(creator);
        this.entreprise_id = parseInt(entreprise_id);
        this.id = id ? parseInt(id) : null;
        this.campaing_code = campaing_code ? campaing_code : null;
    }
}

export class AssignCampaign {

    constructor({campaign_code, agentid, phone_list, creator, language}) {

        this.campaign_code = campaign_code;
        this.agentid = agentid;
        this.phone_list = phone_list;
        this.language = language;
        this.creator = parseInt(creator);
    }
}


export class Person {

    constructor({
                    fullname,
                    username,
                    email,
                    firstname,
                    lastname,
                    gender,
                    phone,
                    address,
                    nif_cin,
                    photo,
                    position_id,
                    person_id,
                    entreprise_id,
                    service_id,
                    language,
                    birth_date,
                    extension
                }) {
        this.fullname = fullname;
        this.email = email;
        this.username = username;
        this.firstname = firstname;
        this.lastname = lastname;
        this.fullname = `${firstname.toUpperCase()},${lastname}`;
        this.gender = gender;
        this.phone = phone;
        this.address = address;
        this.nif_cin = nif_cin;
        this.photo = photo;
        this.position_id = position_id ? position_id : 1;
        this.entreprise_id = entreprise_id ? entreprise_id : 1;
        this.service_id = service_id ? service_id : 1;
        this.language = language;
        this.person_id = parseInt(person_id);
        this.birth_date = birth_date;
        this.extension = extension;

    }
}


export class Entreprise {

    constructor({
                    entr_name,
                    email,
                    address,
                    phone,
                    entr_type,
                    id,
                    logo,
                    language,
                    entr_patent_number,
                    entr_location,
                    entr_etablished,
                    city,
                    postal_code,
                    entr_nature,
                    rh_fulll_name,
                    rh_email,
                    rh_phone,
                    rm_fulll_name,
                    rm_email,
                    rm_phone,
                    rc_fulll_name,
                    rc_email,
                    rc_phone,
                    dg_fulll_name,
                    dg_email,
                    dg_phone,
                    bank_name,
                    bank_account_name,
                    bank_account_number,
                    bank_officier_name,
                    bank_officier_email,
                    bank_officier_phone,
                    flat_rate_type,
                    documents,
                    signature,
                    sign_date,
                    sign_name,
                    sign_fonction,
                    service_type,
                    campaign_type,
                    service_ab,
                    documentFile
                }) {
        this.entr_name = entr_name ? entr_name : null;
        this.email = email ? email : null;
        this.phone = phone ? phone : null;
        this.address = address ? address : null;
        this.entr_type = entr_type ? entr_type : null;
        this.logo = logo ? logo : null;
        this.id = parseInt(id);
        this.language = language ? language : null;
        this.entr_patent_number = entr_patent_number ? entr_patent_number : null;
        this.entr_location = entr_location ? entr_location : null;
        this.entr_etablished = entr_etablished ? entr_etablished : null;
        this.entr_type = entr_type ? entr_type : null;
        this.entr_nature = entr_nature ? entr_nature : null;
        this.city = city ? city : null;
        this.postal_code = postal_code;
        this.rh_fulll_name = rh_fulll_name ? rh_fulll_name : null;
        this.rh_email = rh_email ? rh_email : null;
        this.rh_phone = rh_phone ? rh_phone : null;
        this.rm_fulll_name = rm_fulll_name ? rm_fulll_name : null;
        this.rm_email = rm_email ? rm_email : null;
        this.rm_phone = rm_phone ? rm_phone : null;
        this.rc_fulll_name = rc_fulll_name ? rc_fulll_name : null;
        this.rc_email = rc_email ? rc_email : null;
        this.rc_phone = rc_phone ? rc_phone : null;
        this.dg_fulll_name = dg_fulll_name ? dg_fulll_name : null;
        this.dg_email = dg_email ? dg_email : null;
        this.dg_phone = dg_phone ? dg_phone : null;
        this.bank_name = bank_name ? bank_name : null;
        this.bank_account_name = bank_account_name ? bank_account_name : null;
        this.bank_account_number = bank_account_number ? bank_account_number : null;
        this.bank_officier_name = bank_officier_name ? bank_officier_name : null;
        this.bank_officier_email = bank_officier_email ? bank_officier_email : null;
        this.bank_officier_phone = bank_officier_phone ? bank_officier_phone : null;
        this.flat_rate_type = flat_rate_type ? flat_rate_type.filter(val => val !== "") : [];
        this.documents = documents ? documents.filter(val => val !== "") : [];
        this.signature = signature ? signature : null;
        this.sign_date = sign_date ? sign_date : null;
        this.sign_name = sign_name ? sign_name : null;
        this.sign_fonction = sign_fonction ? sign_fonction : null;
        this.campaign_type = campaign_type ? campaign_type.filter(val => val !== "") : [];
        this.service_ab = service_ab ? service_ab.filter(val => val !== "") : [];
        this.documentFile = documentFile ? documentFile : [];

    }
}


export class Service {

    constructor({id, service_name, language, creator}) {
        this.service_name = service_name;
        this.id = id;
        this.language = language;
        this.creator = creator;
    }
}


export class Position {

    constructor({id, position_name, language, creator}) {
        this.position_name = position_name;
        this.id = id;
        this.language = language;
        this.creator = creator;
    }
}

export class Extension {

    constructor({id, extension_number, extension_group, language, creator}) {
        this.extension_number = extension_number;
        this.extension_group = extension_group;
        this.id = id;
        this.language = language;
        this.creator = creator;
    }
}

export class PrefixModel {

    constructor({id, prefix_number, campaign_name, entreprise_id, language, creator}) {
        this.prefix_number = prefix_number;
        this.campaign_name = campaign_name;
        this.id = id;
        this.entreprise_id = entreprise_id;
        this.language = language;
        this.creator = creator;
    }
}


export class Site {

    constructor({name, short_name, id}) {
        this.name = name;
        this.short_name = short_name;
        this.id = id;
    }
}

export class SigninModel {

    constructor({username, password}) {
        this.username = username;
        this.password = password;

    }
}

export class ResetPasswordModel {

    constructor({email, rpassword}) {
        this.email = email ? email : null;
        this.password = rpassword ? rpassword : null;

    }
}

export class SigninAgentModel {

    constructor({username, password, extension_number}) {
        this.username = username;
        this.password = password;
        // this.extension_number = extension_number;

    }
}


export class TimesheetModel {

    constructor({person_id, project_assign_id, qt_hours, w_year, w_month, w_day, complet_date, id, language}) {
        this.person_id = person_id;
        this.project_assign_id = project_assign_id;
        this.qt_hours = parseInt(qt_hours);
        this.w_year = w_year;
        this.w_month = w_month;
        this.w_day = w_day;
        this.complet_date = complet_date;
        this.id = id;
        this.language = language;

    }
}


export class Emergency {

    constructor({cl_name, cl_phone, cl_comment, creator, language, cl_client, cl_client_number, cl_feedback,uploadFile}) {

        this.cl_name = cl_name;
        this.cl_phone = cl_phone;
        this.cl_comment = cl_comment;
        this.language = language;
        this.cl_client = cl_client;
        this.cl_feedback = cl_feedback;
        this.cl_client_number = cl_client_number;
        this.uploadFile = uploadFile;
        this.creator = parseInt(creator);
    }
}


export class ChangePassword {

    constructor({password, person_id, creator, language}) {

        this.password = password;
        this.person_id = person_id;
        this.language = language;
        this.creator = parseInt(creator);
    }
}



