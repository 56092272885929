/**
 * Created by louidorpc on 9/12/20.
 */
/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization, ageYearDif} from "../../../constants/utils";
import {app} from '../../../constants/config';
import Player from './Player'
import Loader from '../../../common/loader';
import PhoneModal from './phoneModal';


function format(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
        ret += "" + hrs + " h" + ":" + (mins < 10 ? "0 minute(s)" : " second(s)");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}
const TableRecordingCall = props => {

    const {language, isLoadingSv, createService, updateService, persons, person_id, rcCall, getAllPhones, phones}=props;
    console.log("____________________________________________-----------------", rcCall)


    let rateBd = "";

    const columns = [
        {
            title: 'Date',
            field: 'calldate', type: 'text'
        },
        {
            title: 'Source',
            field: 'src', type: 'text'
        },
        {
            title: 'Destination',
            field: 'dst', type: 'text'
        },
        {
            title: 'Duration',
            field: 'duration', type: 'text',
            render: rowData => <span>{format(rowData.duration)}</span>

        },
        {
            title: 'Statut',
            field: 'disposition', type: 'text'
        },
        {
            title: 'Audio',
            type: 'text',
            height: "50px",
            render: rowData => <>
            <audio src={`https://pbx.seedleemarketing.com/audios/monitor/${rowData.date}/${rowData.recordingfile}`} controls/>
            </>
        }
    ]
    //<Player url={`http://192.168.11.42/connexus_api/monitor/${rowData.date}/${rowData.recordingfile}`}/>
    if (rcCall && rcCall.length > 0) {
        return (<>
            <MaterialTable
                title="Enregistrement des appels"
                columns={columns}
                data={rcCall ? rcCall : []}
                icon='unfold_less'
                localization={Localization(language)}
                options={{
                    grouping: true,
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    },

                    exportButton: true,
                    exportAllData: true
                }}
                actions={[{
                    icon: row => (
                        <i className="mdi mdi-eye pr-2"
                           data-toggle="modal"
                           data-target="#slideRightModal"
                           title="view"></i>
                    ),
                    tooltip: 'View',
                    onClick: (e, row) => {
                        getAllPhones(row.dst)
                    }
                },
                    rowData => {

                    }
                ]
                }
            />

            <PhoneModal phones={phones} language={language}/>
            </>
        )
    } else {
        return <Loader/>
    }
}


export default TableRecordingCall