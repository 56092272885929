import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import Form from "./form";

const CalendarModal = ({ setShow, show, handleHideModal, selectedData, cInfo, createCalendarEvent, session,
    ms, calendarEventChangeStatus, getAllCalendarEvents, updateCalendarEvent, publishCalendarEvent }) => {


    const [time, setTime] = useState(null);

    console.log("-----------zzzzzzzzzzzzzzzzzzzzzzz----", selectedData)



    const handleSubmitEvent = values => {

        if (selectedData.id) {
            values.client_entreprise = values?.client_entreprise || "";
            values.editor = session.username;
            values.title = values.title_name;
            values.modified_at = moment(new Date()).format('YYYY-MM-DD');
            updateCalendarEvent(values)
        }
        // }else{
        //     values.client_entreprise = values?.client_entreprise || "";
        //     values.creator = session.id;
        //     values.title = values.title_name;
        //     values.created_at =moment(selectedData.start).format('YYYY-MM-DD');
        //     createCalendarEvent(values)
        // }

    }

    const handlePublishEvent = () => {

        if (selectedData.id) {

            if(selectedData.published==1){

            const entity = {
                id: selectedData.id,
                creator: selectedData.user_id,
                entreprise: selectedData.entreprise,
                published: 0,
                published_at: moment(new Date()).format('YYYY-MM-DD')
            }

            publishCalendarEvent(entity)
        }else{
           
                const entity = {
                    id: selectedData.id,
                    creator: selectedData.user_id,
                    entreprise: selectedData.entreprise,
                    published: 1,
                    published_at: moment(new Date()).format('YYYY-MM-DD')
                }
    
                publishCalendarEvent(entity)
        
        }
        
    }

    }

    const handleSubmitSuccess = () => {
        calendarEventChangeStatus();
        getAllCalendarEvents();
        setShow(false);
    }

    if (ms === "success") handleSubmitSuccess()
    return (
        <React.Fragment>
            <Modal
                size="lg"
                show={show}
                onHide={() => handleHideModal()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <span className="text-center ml-0"><i
                            className="mdi mdi-calendar-edit">
                            {time}</i> Forme  de modification et de publication d'événement</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form selectedData={selectedData} onSubmit={handleSubmitEvent} cInfo={cInfo} handlePublishEvent={handlePublishEvent} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default CalendarModal;