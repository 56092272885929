/**
 * Created by louidorpc on 8/16/20.
 */
import React, {useState, useCallback, useMemo, useRef, useEffect} from 'react';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import SettingForm from './settingForm'
import {FormattedMessage} from "react-intl";
import {app} from '../../../constants/config'
// import socketIOClient from "socket.io-client";
// import {w3cwebsocket as W3CWebSocket} from "websocket";
// const client = new W3CWebSocket('ws://localhost:2000');
// import openSocket from 'socket.io-client';
// import {w3cwebsocket as W3CWebSocket} from "websocket";


// const  socket = openSocket('http:s//localhost:2000');

// const ENDPOINT = "ws://127.0.0.1:2000";
const Setting = ({ps, intl, onchangeFile, submitSetting, mups, getPersonById, person_id, resetStatePerson, sites}) => {

    const submit = values => {
        submitSetting(values);
    };
    //
    // const [socketUrl, setSocketUrl] = useState('ws://127.0.0.1:2000');
    // const messageHistory = useRef([]);
    //
    // const {
    //     sendMessage,
    //     sendJsonMessage,
    //     lastMessage,
    //     getWebSocket,
    //     readyState,
    // } = useWebSocket(socketUrl);
    //
    // messageHistory.current = useMemo(() =>
    //     messageHistory.current.concat(lastMessage), [lastMessage]);
    //
    // const handleClickChangeSocketUrl = useCallback(() =>
    //     setSocketUrl('ws://127.0.0.1:2000'), []);
    //
    // const handleClickSendMessage = useCallback(() =>
    //     getWebSocket().send("chat message","hello"),[]);
    //
    // const connectionStatus = {
    //     [ReadyState.CONNECTING]: 'Connecting',
    //     [ReadyState.OPEN]: 'Open',
    //     [ReadyState.CLOSING]: 'Closing',
    //     [ReadyState.CLOSED]: 'Closed',
    //     [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    // }[readyState];

    // const [response, setResponse] = useState("");
    useEffect(() => {
        // const client = new W3CWebSocket('ws://localhost:2000');
        // const socket = socketIOClient(ENDPOINT);
        // client.onmessage = (message) => {
        //     const ms = JSON.parse(message.data)
        //     console.log('___________+++++++++++++++++++++===================================WebSocket Client Connected', ms);
        //     // alert(data);
        // };


        {/*client.onopen = () => {*/
        }
        //     console.log('___________+++++++++++++++++++++===================================WebSocket Client Connected');
        // };
        //
        // // client.on('add user', "LOUIDORO");
        // client.on('new message',message => {
        //     // const ms = JSON.parse(message.data)
        //     // alert(ms ? ms.msg : null)
        //     // alert("-----------------------------", message);
        //     console.log("_________________________-",message);
        // });

    }, []);

    return (
        <div class="card-body">

            {/*<div>*/}
                {/*<button*/}
                    {/*onClick={handleClickChangeSocketUrl}*/}
                {/*>*/}
                    {/*Click Me to change Socket Url*/}
                {/*</button>*/}
                {/*<button*/}
                    {/*onClick={handleClickSendMessage}*/}
                    {/*disabled={readyState !== ReadyState.OPEN}*/}
                {/*>*/}
                    {/*Click Me to send 'Hello'*/}
                {/*</button>*/}
                {/*<span>The WebSocket is currently {connectionStatus}</span>*/}
                {/*{lastMessage ? <span>Last message: {lastMessage ?.data}</span> : null}*/}
                {/*<ul>*/}
                    {/*{messageHistory.current*/}
                        {/*.map((message, idx) => <span key={idx}>{message ?.data}</span>)}*/}
                {/*</ul>*/}
            {/*</div>*/}
            <h3 class="">
                <FormattedMessage id={`app.title.setting`} defaultMessage={`Label Name`}/>
            </h3>
            <div class="">
                <label class="avatar-input">
                                    <span class="avatar avatar-xl">
                                        <img

                                            src={ps ? app.BASE_IMAGE_URL + '/models/images/' + ps.photo : "/assets/img/avatar.png"}
                                            id="filePreview"
                                            class="avatar-img rounded-circle"/>
                                         <span class="avatar-input-icon rounded-circle">
                                        <i class="mdi mdi-upload mdi-24px"></i>
                                    </span>
                                    </span>
                    <input type="file" name="file" class="avatar-file-picker" onChange={onchangeFile}/>

                </label>

            </div>

            <SettingForm ps={ps} intl={intl}
                         onSubmit={submit}
                         mups={mups}
                         getPersonById={getPersonById}
                         resetStatePerson={resetStatePerson}
                         person_id={person_id}
                         sites={sites}
            />
        </div>
    )
}

export default Setting