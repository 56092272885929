/**
 * Created by louidorpc on 8/11/20.
 */
import React from 'react'
import Setting from './user/setting';
import {Person} from '../../constants/model';
import {app} from '../../constants/config';
import Loader from '../../common/loader';
import {w3cwebsocket as W3CWebSocket} from "websocket";
import {FormattedMessage} from "react-intl";
import ChangePassword from './ChangePassword'

//const client = new W3CWebSocket('ws://localhost:8282');

class UserDashboard extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            id: props.session.person_id,
            photo: null,
            pictureType: null,
        }
    }


    // componentWillMount() {
    //
    //     client.onopen = () => {
    //         alert('ettaa')
    //         console.log('WebSocket Client Connected');
    //     };
    //     client.onmessage = (message) => {
    //         const ms = JSON.parse(message.data)
    //         alert(ms ? ms.msg : null)
    //         console.log(ms.msg);
    //     };
    // }

    cb = (result, file) => {
        let str = result;
        this.setState({photo: str, pictureType: file.type})
    }


    previewFile = (cb) => {

        var preview = document.getElementById('filePreview')
        var file = document.querySelector('input[type=file]').files[0];
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            preview.src = "";
            preview.src = reader.result;
            cb(reader.result, file)
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }

    }

    submitSetting = (values) => {

        const person = new Person(values);

        person.pictureContent = this.state.photo;
        person.pictureType = this.state.pictureType;
        this.props.updatePersonById(person);

    };


    componentDidMount() {
        this.props.getUserSessionInfo(this.state.id)
    }


    render() {
        const {
            ps, getPersonById, session, intl, mups, resetStatePerson, sites, isLoadingS, changePassword,cms
        }=this.props;

        const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);

        return (
            <>
            <section class="admin-content">

                <div class="bg-dark m-b-30">
                    <div class="container">
                        <div class="row p-b-60 p-t-60">

                            <div class="col-md-6 text-white p-b-30">
                                <div class="media">
                                    <div class="avatar mr-3  avatar-xl">

                                        {session && isLoadingS == false ?
                                            <img
                                                src={session ? session.photo ? app.BASE_IMAGE_URL + '/models/images/' + session.photo + '?' + new Date() : "/assets/img/avatar.png" : "/assets/img/avatar.png"}
                                                alt="..." class="avatar-img rounded-circle"/> :
                                            <Loader/>}
                                    </div>
                                    <div class="media-body m-auto">
                                        <h5 class="mt-0">{session ? session.fullname : null}
                                        </h5>
                                        <div
                                            class="opacity-75">{session ? session.position_name : null}
                                            / {infouser ? infouser.extension : null}</div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6 text-white my-auto text-md-right p-b-30">


                            </div>
                        </div>
                    </div>
                </div>
                <div class="container pull-up">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="card m-b-30">
                                <div class="card-header">
                                    <div class="card-title"></div>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">

                                        <li class="nav-item">
                                            <a class="nav-link active" id="contact-tab-z" data-toggle="tab"
                                               href="#setting"
                                               role="tab"
                                               aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-settings">
                                                <FormattedMessage id={`app.label.setting`}
                                                                  defaultMessage={`Label Name`}/></i></a>
                                        </li>


                                        <li class="nav-item">
                                            <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                               href="#password"
                                               role="tab"
                                               aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-key-change">
                                                <FormattedMessage id={`app.label.change_password`}
                                                                  defaultMessage={`Changer votre mot de passe`}/></i></a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent1">

                                        <div class="tab-pane fade show active" id="setting" role="tabpanel"
                                             aria-labelledby="contact-tab">
                                            {session && isLoadingS == false ?
                                                <Setting ps={ps ? ps : session} intl={intl}
                                                         onchangeFile={() => this.previewFile(this.cb)}
                                                         submitSetting={this.submitSetting} mups={mups}
                                                         getPersonById={getPersonById} person_id={this.state.id}
                                                         resetStatePerson={resetStatePerson}
                                                         sites={sites}
                                                /> : <Loader/>}
                                        </div>


                                        <div class="tab-pane fade pt-5" id="password" role="tabpanel"
                                             aria-labelledby="contact-tab">
                                            <ChangePassword intl={intl} person_id={this.state.id}
                                                            changePassword={changePassword}
                                                            language={this.props.session.language}
                                                            cms={cms}

                                            />
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-4">

                            <div class="card m-b-30 p-2">
                                <div class="card-header">
                                    <div class="avatar mr-2 avatar-xs">
                                        <div class="avatar-title bg-dark rounded-circle">
                                            <i class="••mdi mdi-information mdi-18px"></i>
                                        </div>
                                    </div>
                                    <FormattedMessage id={`app.title.setting`} defaultMessage={`Label Name`}/>
                                </div>
                                <div class="list-group list  list-group-flush ml-2">

                                    {session && isLoadingS === false ? <>
                                        <div class="list-group-item tooltip-link pb-2 pt-2">
                                            <a href="#!" data-toggle="tooltip" data-placement="right"
                                               data-trigger="hover"
                                               title="" data-original-title="Site d'affectation">
                                                <i class="mdi mdi-briefcase"></i>
                                                {session && session.entreprise_name ? session.entreprise_name :
                                                    <label class="badge badge-danger">A completer..</label>}
                                            </a>
                                        </div>
                                        <div class="list-group-item tooltip-link pb-2 pt-2">

                                            <a href="#!" data-toggle="tooltip" data-placement="right"
                                               data-trigger="hover"
                                               title="" data-original-title="Votre Adresse">
                                                <i class="mdi mdi-map-marker"></i>
                                                {session && session.address ? session.address :
                                                    <label class="badge badge-danger">A completer..</label>}
                                            </a>
                                        </div>
                                        <div class="list-group-item tooltip-link pb-2 pt-2">
                                            <a href="#!" data-toggle="tooltip" data-placement="right"
                                               data-trigger="hover"
                                               title="" data-original-title="Votre numero de telephone">
                                                <i class="mdi mdi-phone"></i>
                                                {session && session.phone ? session.phone :
                                                    <label class="badge badge-danger">A completer..</label>}
                                            </a>
                                        </div>
                                        <div class="list-group-item tooltip-link pb-2 pt-2">
                                            <a href="#!" data-toggle="tooltip" data-placement="right"
                                               data-trigger="hover"
                                               title="" data-original-title="Votre e-mail">
                                                <i class="mdi mdi-email"></i>
                                                {session && session.email ? session.email :
                                                    <label class="badge badge-danger">A completer..</label>}
                                            </a>
                                        </div>
                                        </>
                                        :
                                        <Loader/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}

export default UserDashboard