import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {validate, normalizeCin, normalizePhone} from "./validator";
import {renderField} from "../../../../common/FormComponents";
import {getIntlMsg} from "../../../../constants/utils"


const onClearForm = (reset, resetStateClient) => {
    reset('cleint');
    resetStateClient();
}

const Form = props => {
    const {handleSubmit, reset, pristine, submitting, intl, isLoading, history, sn, message, form, mups, services, person_id, resetStateClient, positions, mc, entreprises} = props;
    if (mc === "success") onClearForm(reset, resetStateClient)
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-row">

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="id"
                        component={renderField}
                        disabled={true}
                        type="text"
                        label="app.label.code"
                        placeholder="app.label.person_id"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="firstname"
                        component={renderField}
                        type="text"
                        label="app.label.firstname"
                        placeholder="app.label.firstname"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="lastname"
                        component={renderField}
                        type="text"
                        label="app.label.lastname"
                        placeholder="app.label.lastname"
                    />

                </div>

                <div className="form-group floating-label show-label  col-md-6">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="gender"
                        component={renderField}
                        type="select"
                        label="app.label.gender"
                        placeholder="app.label.gender"
                        options={[{id: "m", name: "app.label.female"}, {id: "f", name: "app.label.male"}]}
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="birthdate"
                        component={renderField}
                        type="date"
                        label="app.label.birthdate"
                        placeholder="app.label.birthdate"
                    />

                </div>


                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="summernote"
                        name="phone"
                        component={renderField}
                        normalize={normalizePhone}
                        type="text"
                        value="polo"
                        label="app.label.phone"
                        placeholder="app.label.phone"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="address"
                        component={renderField}
                        type="text"
                        label="app.label.address"
                        placeholder="app.label.address"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="email"
                        component={renderField}
                        type="email"
                        label="app.label.email"
                        placeholder="app.label.email"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="nif"
                        component={renderField}
                        normalize={normalizeCin}
                        type="text"
                        label="app.label.nif_cin"
                        placeholder="app.label.nif_cin"
                    />

                </div>


                <div class="col-md-12">
                    <button type="submit"
                            class="btn btn-success btn-cta waves-effect text-center m-b-20 float-right"
                            disabled={pristine || submitting}>
                        <FormattedMessage id={`app.label.save`} defaultMessage={`Label Name`}/>
                    </button>
                    {isLoading == true ?
                        <div id="loader">
                            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                        </div> : null}

                </div>
            </div>
            <div class="error-handling-messages">
                {message != "success" ?
                    <div id="error"> {message}</div> : null}
            </div>

        </form>


    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: props.personInfo ? props.personInfo : null

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'person',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(Form));

