import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getPersonInfo = pid => {
    console.info(pid, 'new message');
    return instance.get(`/users/read_single/index.php?person_id=${pid}`)
};


export const updatePersonInfo = ups => {
    console.info(ups, 'new message------------------------');
    return instance.put(`/users/update/index.php`, ups)
};

export const logoutAgent = sno => {
    console.info(sno, 'new message');
    return instance.get(`/users/logoutAgent/index.php?person_id=${sno.person_id}`)
};


export const getFeedbackStatistic = statId => {
    return instance.get(`/feedback/read_statistic/index.php?receive_id=${statId}`)
};


