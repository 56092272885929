import {put, take, call, takeLatest} from "redux-saga/effects";
import  * as actions from "../actions/creator/statistic";
import types from "../actions/type";
import * as api from "../services/statistic";


const stats = [
    {name: "Patient perdu de vue", num: 500, deno: 600},
    {name: "Retension sur TAR", num: 900, deno: 1000}
]

export const handleGetAllStatistic = function*(action) {
    const {criteria}=action.payload;
    try {
        const getT = yield call(api.getAllStatistic,criteria);

        if (getT.data.counts.length > 0) {

            yield put(actions.getAllTatisticSuccess(getT.data))

        } else {
            yield put(actions.getAllTatisticFailled(getT.data.message))
        }

    } catch (error) {
        yield put(actions.getAllTatisticFailled("error"))
    }
};


export const statisticSaga = [
    takeLatest(types.GET_ALL_STATISTIC, handleGetAllStatistic)
];
