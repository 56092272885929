/**
 * Created by louidorpc on 11/4/20.
 */
import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getIntlMsg, intl} from "../../constants/utils";
import  {injectIntl, createIntl, createIntlCache} from "react-intl"

toast.configure()


export const getAllAssignedCampaignByAgent = pid => ({
    type: types.GET_ASSIGNED_CAMPAIGN_BY_AGENT,
    payload: {isLoadingACA: true, pid: pid}
});


export const getAllAssignedCampaignByAgentSuccess = assCampaigns => ({
    type: types.GET_ASSIGNED_CAMPAIGN_BY_AGENT_SUCCESS,
    payload: {isLoadingACA: false, assCampaigns: assCampaigns}
});

export const getAllAssignedCampaignByAgentFailled = message => ({
    type: types.GET_ASSIGNED_CAMPAIGN_BY_AGENT_FAILLED,
    payload: {isLoadingACA: true, message: message}
});

export const getAllPhoneAssignedCampaignByAgent = pPid => ({
    type: types.GET_ASSIGN_PHONE_BY_AGENT,
    payload: {isLoadingAPC: true, pPid: pPid}
});


export const getAllPhoneAssignedCampaignByAgentSuccess = assPhones => ({
    type: types.GET_ASSIGN_PHONE_BY_AGENT_SUCCESS,
    payload: {isLoadingAPC: false, assPhones: assPhones}
});

export const getAllPhoneAssignedCampaignByAgentFailled = message => ({
    type: types.GET_ASSIGN_PHONE_BY_AGENT_FAILLED,
    payload: {isLoadingAPC: true, message: message}
});

