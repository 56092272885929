import React, {useEffect} from 'react'
import FollowupAdmin from "./admin";
import FollowupClient from "./client";
import {useSelector} from "react-redux";


const FollowupComponent = props => {

    const {session,getLinkByType,getLinkByEntreprise} = props;

    return (
        FeedbackByEntreprise(session,getLinkByType,getLinkByEntreprise)
    )
}

const FeedbackByEntreprise = (session,getLinkByType,getLinkByEntreprise) => {


    switch (session?.entreprise_name) {
        case 'Seedlee':
            return (<FollowupAdmin  session={session} getLinkByType={getLinkByType} />)
        default:
            return (<FollowupClient session={session} getLinkByType={getLinkByType} getLinkByEntreprise={getLinkByEntreprise}/>)
    }
}


export default FollowupComponent;