import types from "../actions/type"

const defaultState = {
    isLoadingCE: false,
    isLoadingCRE: false,
    isLoadingOCE: false,
    ms: null,
    id: null,
    entr_id: null,
    agent_id: null,
    events: [],
    event: null,
    cInfo: null,
    view: null,
}

const eventReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_ALL_CALENDAR_EVENT:
            return {
                ...state,
                isLoadingCE: action.payload.isLoadingCE
            };

        case types.GET_ALL_CALENDAR_EVENT_BY_AGENT:
            return {
                ...state,
                isLoadingCE: action.payload.isLoadingCE,
                agent_id: action.payload.agent_id
            };
        case types.GET_ALL_CALENDAR_EVENT_BY_ENTREPRISE:
            return {
                ...state,
                isLoadingCE: action.payload.isLoadingCE,
                entr_id: action.payload.entr_id
            };

        case types.GET_ALL_CALENDAR_EVENT_SUCCESS:
            return {
                ...state,
                isLoadingCE: action.payload.isLoadingCE,
                events: action.payload.events
            };

        case types.GET_ALL_CALENDAR_EVENT_FAILLED:
            return {
                ...state,
                ms: action.payload.ms,
                events: [],
                isLoadingCE: action.payload.isLoadingCE
            };


        case types.CREATE_CALENDAR_EVENT:
            return {
                ...state,
                event: action.payload.event,
                isLoadingCRE: action.payload.isLoadingCRE
            };

        case types.UPDATE_CALENDAR_EVENT:
        case types.PUBLISH_CALENDAR_EVENT:
            return {
                ...state,
                event: action.payload.event,
                isLoadingOCE: action.payload.isLoadingOCE
            };

        case types.CREATE_CALENDAR_EVENT_SUCCESS:
        case types.UPDATE_CALENDAR_EVENT_SUCCESS:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingOCE: action.payload.isLoadingOCE,
                isLoadingCRE: action.payload.isLoadingCRE
            };

        case types.CREATE_CALENDAR_EVENT_FAILED:
        case types.UPDATE_CALENDAR_EVENT_FAILED:
        case types.VIEW_CALENDAR_EVENT_FAILLED:
            return {
                ...state,
                ms: action.payload.ms,
                isLoadingOCE: action.payload.isLoadingOCE,
                isLoadingCRE: action.payload.isLoadingCRE
            };

        case types.GET_CALENDAR_EVENT_INFO:
        case types.GET_CALENDAR_EVENT_INFO_SUCCESS:
            return {
                ...state,
                cInfo: action.payload.cInfo,
            };

        case types.CALENDAR_EVENT_CHANGE_STATUS:
        case types.CALENDAR_EVENT_CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                cInfo: action.payload.cInfo,
                ms: action.payload.ms,
            };
            case types.VIEW_CALENDAR_EVENT_SUCCESS:
                return {
                    ...state,
                    ms: action.payload.ms,
                };

        case types.VIEW_CALENDAR_EVENT:
            return {
                ...state,
                view: action.payload.view,
            };


        default:
            return state

    }
}


export default eventReducer