import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import actions from "../actions/creator"
import Pbx from "./pbx"
import {injectIntl} from 'react-intl';

const mapStateToProps = ({settingReducer, intl, history, agentReducer, clientReducer, personReducer,pbxReducer}) => ({
    intl: intl,
    history: history,
    sr: settingReducer,
    ag: agentReducer,
    cl: clientReducer,
    person: personReducer,
    pbx:pbxReducer,

});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});


export default (injectIntl(connect(mapStateToProps, mapDispatchToProps)(Pbx)))
