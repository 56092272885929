/**
 * Created by louidorpc on 1/9/21.
 */
import React from 'react'


function EmbededCampaignReport() {
    return (


        <section className="admin-content">

            <div className="container-fluid bg-dark m-b-30">
                <div className="row  p-b-60 p-t-20">
                    <div className="col-md-6 text-white my-auto text-md-right p-b-30">

                        <div className="">
                            <h2 className="opacity-75"><a href="#!">
                                Listes,Expériences,Rapports,Formulaires des Abonnés</a>
                            </h2>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid pull-up">
                <div className="row ">
                    <div className="col-lg-12">
                        <div className="card m-b-30">
                            <div className="card-header">
                                <div className="card-title"></div>
                            </div>
                            <div className="card-body">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">

                                    <li className="nav-item">
                                        <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                            href="#abc"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> A & B Hardware</i></a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#Behrmann"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> Behrmann Motors </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#Caribe"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> Caribe Motors </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#Chatelain"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> Chatelain Cargo </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#Chatelain_Tours"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> Chatelain Tours </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#Euroceram"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> Euroceram </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#Lorraine"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> La Lorraine </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#Metro"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> Metro Security </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#Office"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> Office Star </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#Valerio"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> Valerio Canez </i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                            href="#Visa"
                                            role="tab"
                                            aria-controls="contact" aria-selected="false"><i
                                                className="mdi mdi-office-building"> Visa Lodge </i>
                                        </a>
                                    </li>

                                </ul>
                                <div className="tab-content" id="myTabContent1">

                                    <div className="tab-pane fade show active" id="abc" role="tabpanel"
                                        aria-labelledby="contact-tab">

                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#abclist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#abcExp"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Experience client</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#abcRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#abcForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="abclist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=97fd31415fc34d7ba4c126f7fc67a3e3"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="abcExp" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=1cefb37e11604ce68af41ae7b2c3cf32"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="abcRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=ad45ca53386e4b8dbc0311757587b8a5"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="abcForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/form/41e9321f6a2b4c1393f897246243a276"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="Behrmann" role="tabpanel"
                                        aria-labelledby="contact-tab">

                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#Behrmannlist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste des clients</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#BehrmannRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#BehrmannForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="Behrmannlist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=bd4d8be8f5c7445189353fb97ad7b78c"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="BehrmannRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=9ab509ae4cd945d5bed80e2eabf70cd5"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="BehrmannForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/form/4b6d620db9e1400ba7b7550e862896bd"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="Caribe" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#Caribelist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste des clients</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#CaribeRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#CaribeForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="Caribelist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=b25c792379f646c1815d425059e9c854"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="CaribeRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=cff21fcd5a024fa088d77c2587ea4bb3"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="CaribeForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/form/af807d3385654037a85f61604492b7ca"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="Chatelain" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#Chatelainlist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste des clients</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#ChatelainRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#ChatelainForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="Chatelainlist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/folders/GQp6H64V95GjvgWQcJRXPP37p2x4fg2m7hGXFwJ1"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="ChatelainRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=a9b4cedea9f6400da2d38cc826562dc6"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="ChatelainForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/form/cf9bf17611a646dfbcd96047a7cf8701"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="Chatelain_Tours" role="tabpanel"
                                        aria-labelledby="contact-tab">

                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#Chatelain_Tourslist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste des clients</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#Chatelain_ToursRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#Chatelain_ToursForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="Chatelain_Tourslist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/folders/px8jHP92jqrHF3rvJ23cR4GFMxv4W3gXXMc9MW211"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="Chatelain_ToursRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=1911a3ebf2b34b09b264b356bfc6a3bc"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="Chatelain_ToursForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/form/6fa6beb2129543acb59f39d364aac1f0"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Euroceram" role="tabpanel"
                                        aria-labelledby="contact-tab">

                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#Euroceramlist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste des clients</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#EuroceramRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#EuroceramForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="Euroceramlist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/folders/QmqfgRH29p3RqM5QpR74J7mHHCGC73JhGrvwqf61"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="EuroceramRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=9c53f4172d594282a48549af888dd12e"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="EuroceramForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/form/5c9b9ccb13c446aa926cd66f5340cbe1"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="Lorraine" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#Lorrainelist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste des clients</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#LorraineRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#LorraineForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="Lorrainelist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=82ac454f41ee49d381be38b898494083"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="LorraineRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=9da1a80b131b4479bfa830c37245db96"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="LorraineForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/form/ecebead814134a33a4691b15be1848ae"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>


                                        </div>
                                    </div>


                                    <div className="tab-pane fade" id="Metro" role="tabpanel"
                                        aria-labelledby="contact-tab">

                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#Metrolist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste des clients</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#MetroRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#MetroForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="Metrolist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/folders/4vpmfxWjqXwgmmfcvGMpqPwjm899qpV3C5Wwhv41"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="MetroRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=4f7d6f6a630f407db49a689fc2cf6f4a"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="MetroForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/form/3470136d6c23414584fb8df42b68d916"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="Office" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#Officelist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste des clients</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#OfficeRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#OfficeForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="Officelist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/folders/fjQ425x6WvVx7Qv6chGmwqr7H3CgqxgmGJXXrjr1"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="OfficeRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=43690370d3d14fe8829a41232cb5cc49"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="OfficeForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/form/eec1940788a84a819d3109a7aaa326b6"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="Valerio" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#Valeriolist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste des clients</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#ValerioRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#ValerioForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="Valeriolist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=968a5860f499466e84bc52e4cfdbbb63"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="ValerioRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=14f101e42c8246bfb0bb05d4422a1164"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="ValerioForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="hhttps://app.smartsheet.com/b/form/53e6353f06c048e8a4f8856acb7903cc"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="Visa" role="tabpanel"
                                        aria-labelledby="contact-tab">

                                        <ul className="nav nav-tabs pt-4" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                <a className="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                    href="#Visalist"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Liste des clients</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#VisaRepport"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Rapport</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab-z" data-toggle="tab"
                                                    href="#VisaForm"
                                                    role="tab"
                                                    aria-controls="contact" aria-selected="false">Formulaire</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent1">

                                            <div className="tab-pane fade show active" id="Visalist" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=92cea8d47c2f4207b92b09c6fe1b5cfa"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="VisaRepport" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/publish?EQBCT=94b05b4d9e6743e3acfd4f1cff97833b"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>
                                            <div className="tab-pane fade show" id="VisaForm" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <embed
                                                    src="https://app.smartsheet.com/b/form/6e8dac8289f242eb8adbc932f526e001"
                                                    width="100%" style={{ height: 'calc(100vh - 70px)' }} id={'myIframe'}></embed>
                                            </div>


                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default EmbededCampaignReport;