import React from "react";
import { FormattedMessage } from "react-intl";
import { change, Field, reduxForm } from "redux-form";
import { connect, useDispatch, useSelector } from 'react-redux'
import { validate, normalizeCin, normalizePhone } from "./validator";
import { renderField } from "../../../../common/FormComponents";
import { FileInput } from '../../../../common/FileInput'


const onClearForm = (reset, resetEmergencyCall,setEmergencyInfo) => {
    reset('emergency');
    resetEmergencyCall();
    setEmergencyInfo(null)
}

const Form = props => {
    const {
        handleSubmit,
        reset,
        pristine,
        submitting,
        intl,
        isLoading,
        history,
        language,
        sn,
        message,
        form,
        resetEmergencyCall,
        mcac,
        emergencyInfo,setEmergencyInfo
    } = props;

    const { entreprises } = useSelector(state => ({
        entreprises: state?.entrepriseReducer?.entreprises || [],
    }));

    const dispatch = useDispatch();


    const handleChangeEntreprise = value => {

        let phone = null;

        const t = entreprises?.forEach(v => {
            if (v.entr_name === value) {
                phone = v.phone;
            }
        })


        dispatch(change('emergency', 'cl_phone', phone));

    }


    if (mcac === "success") onClearForm(reset, resetEmergencyCall,setEmergencyInfo)
    return (


        <div className="modal-body m-4">
            <form onSubmit={handleSubmit}>
                <div className="form-row pt-5 mr-4">
                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="entreprise"
                            name="cl_name"
                            component={renderField}
                            type="select"
                            label="app.label.abonne"
                            placeholder="app.label.abonne"
                            onChange={(e) => handleChangeEntreprise(e.target.value)}
                            options={entreprises ? entreprises.map(value => ({ id: value.entr_name, name: value.entr_name })) : []}
                        />

                    </div>


                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="summernote"
                            name="cl_phone"
                            component={renderField}
                            normalize={normalizePhone}
                            type="text"
                            value="polo"
                            label="app.label.phone"
                            placeholder="app.label.phone"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="cl_client"
                            component={renderField}
                            type="text"
                            label="app.label.client"
                            placeholder="app.label.client"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="summernote"
                            name="cl_client_number"
                            component={renderField}
                            normalize={normalizePhone}
                            type="text"
                            value="polo"
                            label="app.label.client_number"
                            placeholder="app.label.client_number"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="cl_feedback"
                            component={renderField}
                            type="textarea"
                            label="app.label.u.feedback"
                            placeholder="app.label.u.feedback"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="cl_comment"
                            component={renderField}
                            type="textarea"
                            label="app.label.comment"
                            placeholder="app.label.comment"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <h5><a href="#">Téleverser le document</a></h5>

                        <Field name="uploadFile" component={FileInput} type="file" id="uploadFile"
                        />{' '} <label
                            htmlFor="byMail"></label>
                    </div>


                    <div class="col-md-12">
                        <button type="submit"
                            class="btn btn-danger btn-cta waves-effect text-center m-b-20 float-right"
                            disabled={pristine || submitting}>
                            {emergencyInfo?.id ?
                                <FormattedMessage id={`app.label.edit`} defaultMessage={`Modifier`} /> :
                                <FormattedMessage id={`app.label.send`} defaultMessage={`Envoyer`} />
                            }
                        </button>
                        {isLoading == true ?
                            <div id="loader">
                                <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                            </div> : null}

                    </div>
                </div>
                <div class="error-handling-messages">
                    {message != "success" ?
                        <div id="error"> {message}</div> : null}
                </div>

            </form>
        </div>


    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: props?.emergencyInfo || null

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'emergency',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(Form));

