/**
 * Created by louidorpc on 1/9/21.
 */
import React from 'react';
import EmbededCampaignForm from '../../components/campaign/campaignManagementForm';
import createHistory from 'history/createBrowserHistory';
import {intl} from "../../constants/utils";
import Softphone from "../../components/campaign/softPhone";

const  FormNote=({ag,sr, actions,cp,cl,intl})=> {


    const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);
    const history = createHistory();
    console.log("....................", infouser)

    if (infouser.position_name === "Agent" && infouser.entr_name === "Seedlee") {

        return (
            <EmbededCampaignForm {...ag} {...cp} {...sr} {...cl}
                                 createEmergencyCall={actions.createEmergencyCall} creator={infouser.fullname}
                                 language={infouser.language} resetEmergencyCall={actions.resetEmergencyCall}
                                 infouser={infouser}
                                 intl={intl}
                                 getAllPhoneAssignedCampaignByAgent={actions.getAllPhoneAssignedCampaignByAgent}
                                 getAllPhoneCampaigns={actions.getAllPhoneCampaigns}
                                 getAllAssignedCampaignByAgent={actions.getAllAssignedCampaignByAgent}
                                 getAllEntreprises={actions.getAllEntreprises}
                                 />

        )
    } else {
        history.goBack('/')
    }


}

export default FormNote;