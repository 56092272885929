/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getIntlMsg, intl } from "../../constants/utils";
import { injectIntl, createIntl, createIntlCache } from "react-intl"


toast.configure()


export const getAllPbxReport = criteria => ({
    type: types.GET_ALL_PBX_REPORTS,
    payload: { isLoadingPBX: true, criteria: criteria }
});

export const getAllPbxReportSuccess = reports => ({
    type: types.GET_ALL_PBX_REPORTS_SUCCESS,
    payload: { isLoadingPBX: false, reports: reports }
});


export const getAllPbxReportFailled = () => ({
    type: types.GET_ALL_PBX_REPORTS_FAILLED,
    payload: { isLoadingIC: false }
});



export const getAllIncomingCall = criteria => ({
    type: types.GET_ALL_INCOMING_CALL_REPORTS,
    payload: { isLoadingIC: true, criteria: criteria }
});

export const getAllIncomingCallSuccess = incomingCalls => ({
    type: types.GET_ALL_INCOMING_CALL_REPORTS_SUCCESS,
    payload: { isLoadingIC: false, incomingCalls: incomingCalls }
});


export const getAllIncomingCallFailled = () => ({
    type: types.GET_ALL_INCOMING_CALL_REPORTS_FAILLED,
    payload: { isLoadingIC: false }
});


export const getAllOutgoingCall = criteria => ({
    type: types.GET_ALL_OUTGOING_CALL_REPORTS,
    payload: { isLoadingOC: true, criteria: criteria }
});

export const getAllOutgoingCallSuccess = outgoingCalls => ({
    type: types.GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS,
    payload: { isLoadingOC: false, outgoingCalls: outgoingCalls }
});


export const getAllOutgoingCallFailled = () => ({
    type: types.GET_ALL_OUTGOING_CALL_REPORTS_FAILLED,
    payload: { isLoadingOC: false }
});



export const updateIncomingCall = phone => ({
    type: types.UPDATE_INCOMING_CALL,
    payload: { isLoadingUIC: true, phone: phone }
});


export const updateIncomingCallSuccess = (ms, pos) => {

    toast.success(getIntlMsg(intl(ms), `app.label.saveSuccess`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_INCOMING_CALL_SUCCESS,
        payload: { isLoadingUIC: false, msuic: 'success', data: pos }
    }
};


export const updateIncomingCallFailled = ms => {

    toast.error(getIntlMsg(intl(ms), `app.label.saveError`), {
        position: toast.POSITION.TOP_RIGHT
    });

    return {
        type: types.UPDATE_INCOMING_CALL_FAILED,
        payload: { isLoadingEXT: true, msuic: 'failed' }
    }
};





