import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getSites = () => {
    return instance.get(`/sites/index.php`)
};


export const updateSite = usid => {
    console.info(usid, 'new message------------------------');
    return instance.put(`/sites/update/index.php`, usid)
};


export const getSiteById = sid => {
    console.info(sid, 'new message------------------------');
    return instance.put(`/sites/read_single/index.php`, sid)
};


export const createSite = site => {
    console.info(site, 'new message------------------------');
    return instance.put(`/sites/create/index.php`, site)
};