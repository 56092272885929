/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import { getIntlMsg, intl, Localization } from "../../../constants/utils";
import { PrefixModel } from '../../../constants/model'


const List = props => {

    const { language, prefix, prefixA, person_id, entreprises, createLink, updateLink, deleteLink, links } = props;

    let rateBd = "";

    const clientOptions = {};
    const prefixOptions = {};

    entreprises.map(v => {
        const { id, entr_name } = v;
        clientOptions[id] = entr_name
    })



    const columns = [
        {
            title: 'ID',
            field: 'id',
            type: 'text',
            editable: 'never',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF'
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            title: getIntlMsg(intl(language), `app.label.entr_name`),
            field: 'entreprise_id', type: 'text',
            lookup: clientOptions ? clientOptions : {}

        },
        {
            title: getIntlMsg(intl(language), `app.label.report_type`),
            field: 'type_link', type: 'text',
            lookup: {
                "EXPERIENCE_ADMIN": "Experience pour Admin",
                "EXPERIENCE_CLIENT": "Experience pour Client",
                "REPORT_ADMIN": "Rapport pour Admin",
                "REPORT_CLIENT": "Rapport pour Client",
                "REPORT_FOLLOWUP_ADMIN": "Rapport de suivi pour Admin",
                "REPORT_FOLLOWUP_CLIENT": "Rapport de suivi pour Client"
            }
        },
        {
            title: getIntlMsg(intl(language), `app.label.link`),
            field: 'link', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.creator`),
            field: 'username', type: 'text',
            editable: 'never',
        },
        {
            title: getIntlMsg(intl(language), `app.label.created_date`),
            field: 'created_date', type: 'text',
            editable: 'never',
        }
    ]
    return (
        <MaterialTable
            title={getIntlMsg(intl(language), `app.label.experience_admin_link`)}
            columns={columns}
            data={links}
            icon='unfold_less'
            localization={Localization(language)}
            options={{
                grouping: true,
                headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF'
                },
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true
            }}
            editable={{
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {

                            var entity = {
                                entreprise_id: newData.entreprise_id,
                                type_link: newData.type_link,
                                link: newData.link,
                                creator: person_id
                            }
                            createLink(entity);
                            resolve();
                        }, 1000);
                    })
                ,
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {

                            var entity = {
                                id: newData.id,
                                entreprise_id: newData.entreprise_id,
                                type_link: newData.type_link,
                                link: newData.link,
                                creator: person_id
                            }
                            updateLink(entity);
                            resolve();
                        }, 1000)
                    }),
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            deleteLink(oldData.id)

                            resolve();
                        }, 1000);
                    })
            }}

        />
    )
}


export default List