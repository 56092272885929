import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Pusher from 'pusher-js';
import actions from '../../../actions/creator';
import { useDispatch, useSelector } from "react-redux";
import useCookie from "react-use-cookie";
import { FormattedMessage } from "react-intl";

export const TopBar = ({ userinfo }) => {

    const [response, setResponse] = useState([]);

    const { feedStats, events } = useSelector(state => ({
        feedStats: state?.settingReducer?.feedStats || [],
        events: state?.eventReducer?.events || [],
    }));
    const dispatch = useDispatch();

    let som = 0;
    let somEv = 0;
    const eventList = []

    feedStats.forEach(v => {
        som = parseInt(som) + parseInt(v?.qty);
    })


    events.forEach(v => {

        if (!v.client_view && userinfo?.person_type === "client") {
            eventList.push(v);
        }
        else if (!v.agent_view && v.user_id === userinfo?.person_id && userinfo?.position_name === "Agent" && v.modified_at !== null) {
            eventList.push(v);
        }

        else if (!v.admin_view && userinfo?.person_type === null && userinfo?.position_name === "Administrateur") {
            eventList.push(v);
        }
        else if (!v.supervisor_view && userinfo?.person_type === null && userinfo?.position_name === "Superviseur") {
            eventList.push(v);
        } else { }
    })


    const [userToken, setUserToken] = useCookie('notify', '');
    const [eventToken, setEventToken] = useCookie('event', '');

    const handleClickNotification = item => {
        setUserToken(JSON.stringify({ ...item, entr_name: item?.entreprise_name }));
        document.location = '/feedback'
    }

    const handleClickNotificationEvent = item => {
        setEventToken(JSON.stringify(item));
        document.location = '/calendar'
    }

    return (<>
        <nav class=" ml-auto">
            <ul class="nav align-items-center">
                <li>
                    <i className="mdi mdi-24px mdi-bell-ring mdi-spin mr-1 onesignal-customlink-container"></i>

                </li>
                <li class="nav-item">
                    <div class="dropdown">
                        <a href="#" class="nav-link" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i class="mdi mdi-24px mdi-bell-outline mr-1"></i>
                            <span className="notification-counter ml-3 p-0" style={{ background: 'transparent' }}>{som !== 0 ? parseInt(som) + parseInt(eventList.length) : eventList.length}</span>
                        </a>

                        <div class="dropdown-menu notification-container dropdown-menu-right"
                            x-placement="bottom-end"
                            style={{
                                position: "absolute",
                                willChange: "transform",
                                top: "0px",
                                left: "0px",
                                transform: "translate3d(56px, 52px, 0px)"
                            }}>
                            <div class="d-flex p-all-15 bg-white justify-content-between border-bottom ">
                                <a href="#!" class="mdi mdi-18px mdi-settings text-muted"></a>
                                <span class="h5 m-0">Notifications</span>
                                <a href="#!" class="mdi mdi-18px mdi-notification-clear-all text-muted"></a>
                            </div>
                            <div class="notification-events bg-gray-300">
                                <div class="text-overline m-b-5">Couriel Interne</div>
                                {feedStats.map(v => (
                                    <a href="#" class="d-block m-b-10"
                                        onClick={() => handleClickNotification(v)}>
                                        <div class="card">
                                            <div class="card-body"><i class="mdi mdi-circle text-success"></i>
                                                {v.entreprise_name} <span
                                                    className="text-primary float-right mr-1">{v.qty}</span>
                                            </div>
                                        </div>
                                    </a>))}


                            </div>
                            <div class="notification-events bg-gray-300">
                                <div class="text-overline m-b-5">Evenements</div>
                                {eventList.map(v => (
                                    <a href="#" class="d-block m-b-10"
                                        onClick={() => handleClickNotificationEvent(v)}>
                                        <div class="card">
                                            <div class="card-body"><i class="mdi mdi-clock text-info"> {v.title_name}</i>  <span
                                                className="text-primary float-right mr-1">{v.created_at}</span>
                                            </div>
                                        </div>
                                    </a>))}

                            </div>

                        </div>
                    </div>
                </li>

                <li class="nav-item dropdown ">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <div class="avatar avatar-sm avatar-online">
                            <span
                                class="avatar-title rounded-circle bg-dark">
                                {(userinfo ? (userinfo.fullname).substr(0, 1) : null) + "" + (userinfo ? (userinfo.fullname).substr(0, 1) : null)}</span>
                        </div>
                    </a>
                    <div class="dropdown-menu  dropdown-menu-right">
                        <a class="dropdown-item" href="/profile"> <FormattedMessage id={'app.label.profile'} /></a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                            onClick={() => dispatch(actions.signoutAgent({ person_id: userinfo ? userinfo.person_id : null }))}> <FormattedMessage id={'app.label.logout'} /></a>
                    </div>
                </li>

            </ul>

        </nav>
    </>
    );
};
