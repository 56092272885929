/**
 * Created by louidorpc on 9/12/20.
 */
/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization, ageYearDif} from "../../../constants/utils";
import {app} from '../../../constants/config';


const TablePerson = props => {

    const {language, isLoadingSv, createService, updateService, clients, person_id, getPersonInfo}=props;


    let rateBd = "";

    const columns = [
        {
            title: getIntlMsg(intl(language), `app.label.fullname`),
            field: 'fullname', type: 'text',
            render: rowData => (
                <>
                <span class="avatar avatar-sm">
                                        <img

                                            src={rowData.photo ? app.BASE_IMAGE_URL + '/models/images/' + rowData.photo : "/assets/img/avatar.png"}
                                            class="avatar-img rounded-circle"/>


                </span>
                <span>  {rowData.firstname},{rowData.lastname}</span>
                </>
            ),

        },
        {
            title: getIntlMsg(intl(language), `app.label.phone`),
            field: 'phone', type: 'text'
        },
        {
            title: getIntlMsg(intl(language), `app.label.email`),
            field: 'email', type: 'text'
        },
        {
            title: getIntlMsg(intl(language), `app.label.address`),
            field: 'address', type: 'text'
        },
        {
            title: getIntlMsg(intl(language), `app.label.person_position`),
            field: 'position_name', type: 'text'
        },
        {
            title: getIntlMsg(intl(language), `app.label.service`),
            field: 'service_name', type: 'text'
        },
        {
            title: getIntlMsg(intl(language), `app.label.company`),
            field: 'entreprise_name', type: 'text'
        },
        {
            title: getIntlMsg(intl(language), `app.label.birthdate`),
            field: 'birth_date', type: 'text',
            editable: 'never',
        },
        {
            title:'Age',
            type: 'text',
            render: rowData => <span>{ageYearDif(rowData ? rowData.birth_date : 0, language)}</span>
        }
    ]
    return (
        <MaterialTable
            title={getIntlMsg(intl(language), `app.label.client_list`)}
            columns={columns}
            data={clients ? clients : []}
            icon='unfold_less'
            localization={Localization(language)}
            options={{
                headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF'
                },
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true
            }}
            actions={[{
                icon: row => (
                    <i className="mdi mdi-eye pr-2"
                       data-toggle="modal"
                       data-target="#slideRightModal"
                       title="view"></i>
                ),
                tooltip: 'View',
                onClick: (e, row) => {
                    getPersonInfo(row);
                }
            }]
            }
        />
    )
}


export default TablePerson