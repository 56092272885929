import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {validate} from "./validator";
import {renderField} from "../../../common/FormComponents";
import swal from 'sweetalert'


const onClearForm = (reset, resetStateSignin, msna) => {
    reset('signinAgent');

    swal({
        title: `Good job! ${msna.user ? msna.user.fullname : null}`,
        text: "",
        icon: "success",
        button: "Next",
    }).then((value) => {
        sessionStorage.setItem("userData", JSON.stringify(msna.user));
        document.location = "/";
    });

    // resetStateSignin();

}

const FormAgent = props => {
    const {handleSubmit, valid, reset, pristine, submitting, resetStateSignin, msna, intl, isLoading, history, message, form, SwitchToSignup, extensions, getAllExtensionAvailable} = props;
    if (message === "success") onClearForm(reset, resetStateSignin, msna)

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group floating-label show-label col-md-12">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="username"
                        component={renderField}
                        type="text"
                        label="app.label.username"
                        placeholder="app.label.username"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-12">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="password"
                        component={renderField}
                        type="password"
                        label="app.label.password"
                        placeholder="app.label.password"
                    />

                </div>

                {/* <div className="form-group floating-label show-label  col-md-12">
                    <Field
                        className="form-control"
                        id="option-message"
                        name="extension_number"
                        onFocus={getAllExtensionAvailable}
                        component={renderField}
                        type="select"
                        label="app.label.extension_number"
                        placeholder="app.label.extension_number"
                        options={extensions ? extensions.map(value => ({
                                id: value.extension_number,
                                name: `${value.extension_number}  (${value.extension_group})`
                            })) : []}
                    />
                </div> */}


                <div class="col-md-12 mb-3">
                    <button type="submit"
                            class="btn btn-primary btn-md btn-block waves-effect text-center float-right"
                            disabled={pristine || submitting}>
                        Signin
                    </button>
                    {isLoading == true ?
                        <div id="loader">
                            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                        </div> : null}

                </div>
                <div class="col-md-12">
                    <p class="text-inverse text-center">Don't have an account?
                        <a href="#" onClick={() => SwitchToSignup('signup')}>
                            <b>Register here </b>
                        </a>
                    </p>
                </div>

                <div class="col-md-12">
                    <h6 class="text-inverse text-center">
                        <a href="#" onClick={() => SwitchToSignup('signin')}
                           class="btn m-b-15 ml-2 mr-2 btn-outline-info">
                            <b>Login as Administrator</b>
                        </a>
                    </h6>
                </div>

                <div class="error-handling-messages">
                    {message === "app.label.access" ?
                        <p className="text-c-red"><FormattedMessage id={`${message}`} defaultMessage={''}/></p> : ''}
                </div>
            </div>
        </form>


    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: null,

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'signinAgent',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(FormAgent));

