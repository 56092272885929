import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import Form from "./form";

const DaysreportForm = ({ createDaysReport, session, editMonthReport, monthReportInfo, ms, handleSuccess }) => {


    const [time, setTime] = useState(null);

    const handleSubmit = values => {

        if (values) {
            const entity = {
                ...values,
                creator: session?.person_id
            }
            if (monthReportInfo?.id)
                editMonthReport(entity)
            else
                createDaysReport(entity)
        }

    }

    return (
        <React.Fragment>
            <Form onSubmit={handleSubmit} monthReportInfo={monthReportInfo} ms={ms} handleSuccess={handleSuccess} />

        </React.Fragment>
    );
}

export default DaysreportForm;