import React, {useEffect} from 'react'
import FeedbackAdmin from "./admin";
import FeedbackClient from "./client";
import FeedbackAgent from "./agent";
import {useSelector} from "react-redux";


const Feedback = props => {

    const {session, getAllEntreprises, getFeedback, createFeedback, resetFeedback, updateFeedback} = props;

    return (
        FeedbackByEntreprise(session, getAllEntreprises, getFeedback, createFeedback, resetFeedback, updateFeedback)
    )
}

const FeedbackByEntreprise = (session, getAllEntreprises, getFeedback, createFeedback, resetFeedback, updateFeedback) => {

    // if (session?.position_name === 'Agent') {
    //     return (<FeedbackAgent updateFeedback={updateFeedback} getAllEntreprises={getAllEntreprises}
    //                            session={session} getFeedback={getFeedback} createFeedback={createFeedback}
    //                            resetFeedback={resetFeedback}/>)
    // } else {
        switch (session?.entreprise_name) {
            case 'Seedlee':
            case 'Support Technique':
                return (<FeedbackAdmin updateFeedback={updateFeedback} getAllEntreprises={getAllEntreprises}
                                       session={session} getFeedback={getFeedback} createFeedback={createFeedback}
                                       resetFeedback={resetFeedback}/>)
            default:
                return (<FeedbackClient updateFeedback={updateFeedback} getAllEntreprises={getAllEntreprises}
                                        session={session} getFeedback={getFeedback} createFeedback={createFeedback}
                                        resetFeedback={resetFeedback}/>)
        }

}


export default Feedback;