/**
 * Created by louidorpc on 8/4/20.
 */
import React from 'react'
import Feedback from '../../components/feedback_hold'

import {getFeedback, resetFeedback} from "../../actions/creator/feedback";


const FeedbackComponent = props => {

    const {
        sr: {session, isLoadingS}, actions: {
            getAllEntreprises, getFeedback, createFeedback,resetFeedback,updateFeedback
        }, person: {ps, isLoading, mups, cms}, intl, st: {sites},
    } = props;

    if (session !== null)
        return (
            <Feedback session={session}
                      ps={ps} isLoading={isLoading}
                      intl={intl}
                      mups={mups}
                      isLoadingS={isLoadingS}
                      sites={sites}
                      cms={cms}
                      getAllEntreprises={getAllEntreprises}
                      getFeedback={getFeedback}
                      createFeedback={createFeedback}
                      resetFeedback={resetFeedback}
                      updateFeedback={updateFeedback}
            />
        )
    else return <div></div>
    
}


export default FeedbackComponent;