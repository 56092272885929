/**
 * Created by louidorpc on 1/9/21.
 */
import React from 'react';
import EmbededCampaignForm from '../../components/admin/urgence';
import createHistory from 'history/createBrowserHistory';
import {intl} from "../../constants/utils";
import Softphone from "../../components/campaign/softPhone";

const  UrgenceContainer=({ag,sr, actions,cp,cl,intl})=> {


    const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);
    const history = createHistory();
    console.log("....................", infouser)

    if (infouser.position_name === "Administrateur" && infouser.entr_name === "Seedlee") {

        return (
            <EmbededCampaignForm {...ag} {...cp} {...sr} {...cl}
                                 createEmergencyCall={actions.createEmergencyCall} 
                                 editEmergencyCall={actions.editEmergencyCall} 
                                 creator={infouser.fullname}
                                 language={infouser.language} 
                                 resetEmergencyCall={actions.resetEmergencyCall}
                                 infouser={infouser}
                                 intl={intl}
                                 getAllEntreprises={actions.getAllEntreprises}
                                 getAllEmergencyCall={actions.getAllEmergencyCall}
                                 deleteEmergencyCall={actions.deleteEmergencyCall}
                                 />

        )
    } else {
        history.goBack('/')
    }


}

export default UrgenceContainer;