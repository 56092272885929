import React from "react"
import { injectIntl, FormattedMessage } from "react-intl"
import { getIntlMsg } from "../constants/utils"


export const renderField = injectIntl(
    ({ disabled, intl, input, label, placeholder, options, itemSize, labelSize, inputSize, type, required = false, meta: { asyncValidating, touched, error, warning, onChange } }) => (
        <div className={itemSize}>
            <label> <FormattedMessage id={label} defaultMessage={`Label Name`} /></label>
            {type === "select" ? (
                <>
                    <select {...input} className="form-control" id={label} style={{ width: "100%" }}>
                        <option selected=""></option>
                        {options.map((opt, key) => (
                            <option value={opt.id} key={key}>
                                {getIntlMsg(intl, opt.name)}
                            </option>
                        ))}
                    </select>
                </>
            ) : type === "textarea" ? (
                <div>
                    <textarea {...input} placeholder={getIntlMsg(intl, placeholder)} rows="60" cols="40"
                        className="form-control" style={{ height: "100px" }} />
                </div>
            ) 
                : type === "password" ? (
                    <input
                        {...input}
                        className="form-control"
                        id={label}
                        type={type}
                        placeholder={getIntlMsg(intl, placeholder)}
                    />
                ) : type === "multipeSelect" ? (<select {...input} onChange={onChange} multiple>
                    {(options) && options.map(current => (
                        <option key={current.id} value={current.id}>{current.name}</option>))
                    }
                </select>) : (
                    <input
                        {...input}
                        className="form-control"
                        id={label}
                        disabled={disabled}
                        type={type}
                        placeholder={getIntlMsg(intl, placeholder)}
                    />

                )}

            {touched && error && (
                <div className="">
                    <small className="text-c-red" style={{ fontSize: "12px", color: 'red' }}>
                        <FormattedMessage id={error} defaultMessage={`Field error`} />
                    </small>
                </div>
            )}
        </div>
    )
)

//NOTE  Custom common field
export const FileField = injectIntl(
    ({ intl, input, label, placeholder, options, itemSize, labelSize, inputSize, type, required = false, meta: { asyncValidating, touched, error, warning } }) => (
        <input  {...input} className="form-control" type={type} />
    )
)
