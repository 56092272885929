import React, {useContext} from "react";
import Modal from "react-bootstrap/Modal";


const ModalComponent = props => {
    const {children, title, show, hideModal} = props

    return (
        <Modal show={show}
               onHide={() => hideModal()} size="lg"
               className="modal-100w"
               aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Reset Password
                </Modal.Title>
            </Modal.Header>
            <Modal.Body id="example-custom-modal-styling-title">
                <div className="m-10">{children}</div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalComponent