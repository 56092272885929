/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization} from "../../../constants/utils";
import {PrefixModel} from '../../../constants/model'


const PrefixTable = props => {

    const {language, prefix,prefixA, person_id, entreprises,updatePrefixById,createPrefix,deletePrefix}=props;

    let rateBd = "";

    const clientOptions = {};
    const prefixOptions = {};

   entreprises.map(v => {
        const { id,entr_name } = v;
        clientOptions[ id ] = entr_name
    })

    prefixA.map(v => {
        const { id,prefix_number } = v;
        prefixOptions[ id ] = prefix_number
    })

    const columns = [
        {
            title: 'ID',
            field: 'id',
            type: 'text',
            editable: 'never',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF'
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            title: getIntlMsg(intl(language), `app.label.entr_name`),
            field: 'entreprise_id', type: 'text',
            lookup:clientOptions?clientOptions:{}

        },
        {
            title: getIntlMsg(intl(language), `app.label.campaign_name`),
            field: 'campaign_name', type: 'text',
            lookup:  {"Appel de courtoisie et sondage":"Appel de courtoisie et sondage",
                "Appels de prospection":"Appels de prospection","Appels de recouvrement":"Appels de recouvrement",
                "Service à la clientèle":"Service à la clientèle", "Relancement des Clients Dormants":"Relancement des Clients Dormants",
                "Suivis Des Demandes de Pro Forma": "Suivis Des Demandes de Pro Forma",
                "Mise à jour de la liste des Clients": "Mise à jour de la liste des Clients", "Étude de marché": "Étude de marché",  "Gestion de Résaux Siciaux":"Gestion de Résaux Siciaux"},

        },
        {
            title: getIntlMsg(intl(language), `app.label.prefix_number`),
            field: 'prefix_id', type: 'text',
            lookup:prefixOptions?prefixOptions:{}
        },
        {
            title: getIntlMsg(intl(language), `app.label.creator`),
            field: 'username', type: 'text',
            editable: 'never',
        },
        {
            title: getIntlMsg(intl(language), `app.label.created_date`),
            field: 'created_date', type: 'text',
            editable: 'never',
        }
    ]
    return (
        <MaterialTable
            title={getIntlMsg(intl(language), `app.label.prefix_list`)}
            columns={columns}
            data={prefix ? prefix : []}
            icon='unfold_less'
            localization={Localization(language)}
            options={{
                headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF'
                },
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true
            }}
            editable={{
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {

                            var entity = new PrefixModel({
                                prefix_number: newData.prefix_id,
                                campaign_name: newData.campaign_name,
                                entreprise_id: newData.entreprise_id,
                                language: language,
                                creator: person_id
                            })
                            createPrefix(entity);
                            resolve();
                        }, 1000);
                    })
                ,
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {

                            var entity = new PrefixModel({
                                id: newData.id,
                                prefix_number: newData.prefix_id,
                                campaign_name: newData.campaign_name,
                                entreprise_id: newData.entreprise_id,
                                language: language,
                                creator: person_id
                            })
                            updatePrefixById(entity);
                            resolve();
                        }, 1000)
                    }),
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            // const dataDelete = [...data];
                            // const index = oldData.tableData.id;
                            // dataDelete.splice(index, 1);
                            // setData([...dataDelete]);
                            console.log('---------------',oldData)
                            deletePrefix(oldData.id)

                            resolve();
                        }, 1000);
                    })
            }}

        />
    )
}


export default PrefixTable