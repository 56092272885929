/**
 * Created by louidorpc on 9/12/20.
 */
/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import { getIntlMsg, intl, Localization, ageYearDif } from "../../../../constants/utils";
import { app } from '../../../../constants/config';
import sweetAlert from 'sweetalert'
import { Emergency } from '../../../../constants/model';


const EmergencyList = props => {

    const { language, emCalls, setEmergencyInfo, deleteEmergencyCall } = props;


    let rateBd = "";

    const columns = [
        {
            title: 'Date',
            field: 'created_date', type: 'text'
        },
        {
            title: 'Abonné',
            field: 'cl_name', type: 'text'
        },
        {
            title: 'Rapporteur',
            field: 'creator', type: 'text'
        },
        {
            title: 'Client',
            field: 'cl_client', type: 'text',

        },
        {
            title: 'Numéro Client',
            field: 'cl_client_number', type: 'text',
        },
        {
            title: 'Feedback',
            field: 'cl_feedback', type: 'text'
        },
        {
            title: 'Document',
            field: 'file_name', type: 'text',
            render: rowData => <span style={{ fontSize: '18px' }}>{rowData.file_name ?
                <i onClick={() => window.open(app.BASE_IMAGE_URL + "models/docs/" + rowData.file_name, "", "width=800,height=500", "target=_blanc")}
                    className="mdi mdi-file-document"
                    data-toggle="modal"
                    data-target="#slideRightModal"
                    title="Delete"></i> : <i>-</i>}</span>

        }
    ]

    const handleDeleteEmergency = (value) => {

        sweetAlert({
            title: "Êtes-vous sûr de vouloir faire cela?",
            cancelButtonText: "Non",
            confirmButtonText: "Yes",
            reverseButtons: true,
            buttons: true,
            showCancelButton: true,
        }).then(resp => {
            if (resp) {
                handleDelete(value)
            }
        });
    }

    const handleDelete = values => {
        let entity = {
            id: values.id,
            language: language

        };
        deleteEmergencyCall(entity)
    };

    return (
        <MaterialTable
            title="Urgence"
            columns={columns}
            data={emCalls ? emCalls : []}
            icon='unfold_less'
            localization={Localization(language)}
            options={{
                headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF'
                },
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true
            }}
            actions={[{
                icon: row => (
                    <i className="mdi mdi-pencil pr-2"
                        data-toggle="modal"
                        data-target="#slideRightModal"
                        title="view"></i>
                ),
                tooltip: 'View',
                onClick: (e, row) => {
                    console.log("-____", row)
                    setEmergencyInfo(row)
                }
            },
            {
                icon: row => (
                    <i className="mdi mdi-delete pr-2"
                        data-toggle="modal"
                        data-target="#slideRightModal"
                        title="Delete"></i>
                ),
                tooltip: 'Delete',
                onClick: (e, row) => {
                    console.log("-____", row)
                    handleDeleteEmergency(row)
                }
            },
            rowData => {

            }
            ]
            }
        />
    )
}


export default EmergencyList