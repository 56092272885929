/**
 * Created by louidorpc on 8/4/20.
 */
import React from 'react'
import UserDashboard from '../../components/dashboards/userDashboard'
import AgentDashboard from '../../components/dashboards/agent'
import AdminDashboard from '../../components/dashboards/admin'


const Home = props => {

    const {
        sr: { session, isLoadingS }, actions: {
            getPersonById, updatePersonById, updateUserById,
            resetStatePerson, getSites, getProjectAssignByPersonId, getUserSessionInfo, changePassword, getAllTatistic,
            getAllPbxReportAgent, getAllIncomingCallAgent, getAllOutgoingCallAgent, getAllPbxReport,
            getAllIncomingCall, getAllOutgoingCall, getAllEntreprises, getAllPrefixById
        }, person: { ps, isLoading, mups, cms }, intl, st: { sites }, stat: { stats, isLoadingSt },
        reportCalls, cReports, reports, cIncomingCalls, cOutgoingCalls } = props;
    if (session !== null)

        return (<>
            {session?.extension ?
                <AgentDashboard session={session}
                    getPersonById={getPersonById}
                    ps={ps} isLoading={isLoading}
                    intl={intl}
                    updatePersonById={updateUserById}
                    mups={mups}
                    getSites={getSites}
                    isLoadingS={isLoadingS}
                    sites={sites}
                    resetStatePerson={resetStatePerson}
                    getProjectAssignByPersonId={getProjectAssignByPersonId}
                    getUserSessionInfo={getUserSessionInfo}
                    changePassword={changePassword}
                    getAllTatistic={getAllTatistic}
                    isLoadingSt={isLoadingSt}
                    stats={stats}
                    cms={cms}
                    getAllPbxReportAgent={getAllPbxReportAgent}
                    reportCalls={reportCalls}
                    cReports={cReports}
                    reports={reports}
                    getAllIncomingCallAgent={getAllIncomingCallAgent}
                    cIncomingCalls={cIncomingCalls}
                    getAllOutgoingCallAgent={getAllOutgoingCallAgent}
                    cOutgoingCalls={cOutgoingCalls}




                /> : session?.position_name == "Administrateur" && session?.entreprise_name == "Seedlee" ?
                    <AdminDashboard session={session}
                        getPersonById={getPersonById}
                        ps={ps} isLoading={isLoading}
                        intl={intl}
                        updatePersonById={updateUserById}
                        mups={mups}
                        getSites={getSites}
                        isLoadingS={isLoadingS}
                        sites={sites}
                        resetStatePerson={resetStatePerson}
                        getProjectAssignByPersonId={getProjectAssignByPersonId}
                        getUserSessionInfo={getUserSessionInfo}
                        changePassword={changePassword}
                        getAllTatistic={getAllTatistic}
                        isLoadingSt={isLoadingSt}
                        stats={stats}
                        cms={cms}
                        getAllPbxReportAgent={getAllPbxReportAgent}
                        reportCalls={reportCalls}
                        cReports={cReports}
                        reports={reports}
                        getAllIncomingCallAgent={getAllIncomingCallAgent}
                        cIncomingCalls={cIncomingCalls}
                        getAllOutgoingCallAgent={getAllOutgoingCallAgent}
                        cOutgoingCalls={cOutgoingCalls}
                        getAllPbxReport={getAllPbxReport}
                        getAllIncomingCall={getAllIncomingCall}
                        getAllOutgoingCall={getAllOutgoingCall}
                        getAllEntreprises={getAllEntreprises}
                        getAllPrefixById={getAllPrefixById}



                    /> : <UserDashboard session={session}
                        getPersonById={getPersonById}
                        ps={ps} isLoading={isLoading}
                        intl={intl}
                        updatePersonById={updateUserById}
                        mups={mups}
                        getSites={getSites}
                        isLoadingS={isLoadingS}
                        sites={sites}
                        resetStatePerson={resetStatePerson}
                        getProjectAssignByPersonId={getProjectAssignByPersonId}
                        getUserSessionInfo={getUserSessionInfo}
                        changePassword={changePassword}
                        cms={cms}


                    />
            }
        </>
        )
    else return <div></div>
}


export default Home;