import React, {useState} from "react";
import {app} from "../../../constants/config";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";


const SideBar = ({session, entreprises,handleShowMessage,getEntreprise}) => {


    const {feedStats} = useSelector(state => ({
        feedStats: state?.settingReducer?.feedStats || [],
    }));

    const getstats = (name) => {

        let vv = 0;
        if (feedStats.length > 0) {
            feedStats.map(v => {
                if (name === v.entreprise_name) {
                    vv = v.qty;
                } else {
                    vv = 0;
                }
            })
            return vv;
        }

    }

    return (
        <div className="col d-lg-block p-all-0 text-white  chat-sidebar">
            <div className="usable-height panel">
                <div className="panel-header p-all-15 chat-sidebar-header">
                    <div className="media">
                        <div className="d-inline-block m-r-10 align-middle">

                            <div className="avatar avatar avatar-online">
                                {session ?
                                    <img
                                        src={session ? session.photo ? app.BASE_IMAGE_URL + '/models/images/' + session.photo + '?' + new Date() : "/assets/img/avatar.png" : "/assets/img/avatar.png"}
                                        alt="..." class="avatar-img rounded-circle"/> :
                                    <span
                                        className="avatar-title rounded-circle  bg-white-translucent"> E </span>}
                            </div>
                        </div>
                        <div className="media-body my-auto">
                            <p className="font-secondary m-b-0">{session?.fullname} </p>
                            <p className="text-overline    m-b-0">
                                <code style={{
                                    fontSize: '10px',
                                    textOverflow: 'hidden',
                                    width: '100%'
                                }}>{session ? session.entreprise_name : null}</code><br/> {session ? session.position_name : null}
                            </p>
                        </div>

                        {getEntreprise!==null ?<button type="button" className="close d-inline-block d-lg-none light"
                                                       data-target=".chat-sidebar" data-toggleclass="d-none">
                            <span aria-hidden="true">×</span>
                        </button>:null}
                    </div>

                </div>
                <div className=".panel-body p-t-10  border-white">


                    <div className="m-t-20 chat-sidebar-item clearfix border-bottom border-white">
                        <FormattedMessage id={'app.label.feedback'} defaultMessage={'Feedback'}/>
                    </div>

                    {entreprises.map(v => v.entr_name === 'Seedlee' ?
                        <a href="#" className={`${v.entr_name === getEntreprise ? 'active' : ''}  chat-sidebar-item btn-ghost`}
                           onClick={()=>handleShowMessage(v)} data-target=".chat-sidebar" data-toggleclass="d-none">

                            <div className="w-100 text-truncate">
                                {v.entr_name} <span className="badge badge-info">{getstats(v.entr_name)}</span>
                            </div>
                        </a> : v.entr_name === 'Support Technique' ?
                        <a href="#" className={`${v.entr_name === getEntreprise ? 'active' : ''}  chat-sidebar-item btn-ghost`}
                           onClick={()=>handleShowMessage(v)} data-target=".chat-sidebar" data-toggleclass="d-none">

                            <div className="w-100 text-truncate">
                                {v.entr_name} <span className="badge badge-info">{getstats(v.entr_name)}</span>
                            </div>
                        </a>: null)}

                </div>


            </div>
        </div>
    )
}

export default SideBar;