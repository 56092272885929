import {put, call, takeLatest} from "redux-saga/effects";
import  * as actions from "../actions/creator/prefix";
import types from "../actions/type";
import * as api from "../services/prefix";


export const handleGetAllPrefix = function*() {

    try {
        const getSv = yield call(api.getAllPrefix);
        if (getSv.data.length > 0) {
            yield put(actions.getAllPrefixSuccess(getSv.data))
        } else {
            yield put(actions.getAllPrefixFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPrefixFailled("error"))
    }


};

export const handleCreatePrefix = function*(action) {
    const {ext} = action.payload;
    try {
        const pos = yield call(api.createPrefix, ext);
        if (pos.data.message == "success") {
            yield put(actions.createPrefixSuccess(ext.language))
            yield put(actions.getAllPrefix())

        } else {
            yield put(actions.createPrefixFailled({mext: pos.data.message, locale: ext.language}))
        }
    } catch (error) {
        yield put(actions.createPrefixFailled({mext: 'app.label.saveError', locale: ext.language}))
    }
};

export const handleUpdatePrefix = function*(action) {
    const {ext} = action.payload;
    try {
        const pos = yield call(api.updatePrefix, ext);
        if (pos.data.message == "success") {
            yield put(actions.updatePrefixByIdSuccess(ext.language))
            yield put(actions.getAllPrefix())

        } else {
            yield put(actions.updatePrefixByIdFailled({mext: pos.data.message, locale: ext.language}))
        }
    } catch (error) {
        yield put(actions.updatePrefixByIdFailled({mext: 'app.label.saveError', locale: ext.language}))
    }
};


export const handleGetAllPrefixA = function*() {

    try {
        const getSv = yield call(api.getAllPrefixAvailable);
        if (getSv.data.length > 0) {
            yield put(actions.getAllPrefixAvailableSuccess(getSv.data))
        } else {
            yield put(actions.getAllPrefixAvailableFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPrefixAvailableFailled("error"))
    }


};

export const handleGetAllPrefixById = function*(action) {
    const {prefix_id}=action.payload
    try {
        const getSv = yield call(api.getAllPrefixById, prefix_id);
        if (getSv.data.length > 0) {
            yield put(actions.getAllPrefixByIdSuccess(getSv.data))
        } else {
            yield put(actions.getAllPrefixByIdFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPrefixByIdFailled("error"))
    }


};


export const handleDeletePrefix = function* (action) {
    const {id} = action.payload;
    try {
        const serv = yield call(api.deletePrefix, id);
        if (serv.data.message == "success") {
            yield put(actions.deletePrefixSuccess(serv.data.id))
        } else {
            yield put(actions.deletePrefixFailled({mssv: serv.data.message}))
        }
    } catch (error) {
        yield put(actions.deletePrefixFailled({mssv: 'app.label.saveError'}))
    }
};


export const prefixSaga = [
    takeLatest(types.GET_ALL_PREFIX, handleGetAllPrefix),
    takeLatest(types.CREATE_PREFIX, handleCreatePrefix),
    takeLatest(types.UPDATE_PREFIX, handleUpdatePrefix),
    takeLatest(types.GET_ALL_PREFIX_AVAILABLE, handleGetAllPrefixA),
    takeLatest(types.GET_ALL_PREFIX_BY_ID, handleGetAllPrefixById),
    takeLatest(types.DELETE_PREFIX, handleDeletePrefix),
];
