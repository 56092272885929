import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm, formValueSelector} from "redux-form";
import {connect} from 'react-redux'
import {validate, normalizeCin, normalizePhone, passwordsMustMatch, required} from "./validator";
import {renderField} from "../../../common/FormComponents";
import {getIntlMsg} from "../../../constants/utils"


const onClearForm = (reset, resetStatePerson) => {
    reset('changePassword');
    // resetStatePerson();
}


const Form = props => {
    const {handleSubmit, valid, reset, pristine, submitting, intl, isLoading, history, sn, message, form, ps, mups, getPersonById, person_id, resetStatePerson, sites,cms} = props;
    if (cms === "success") onClearForm(reset, resetStatePerson)
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-row">


                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="password"
                        component={renderField}
                        disabled={true}
                        type="password"
                        label="app.label.password"
                        placeholder="app.label.password"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="entreprise_id"
                        component={renderField}
                        disabled={true}
                        type="password"
                        label="app.label.repeatpassword"
                        placeholder="app.label.repeatpassword"
                        validate={[required, passwordsMustMatch]}
                    />

                </div>


                <div class="col-md-12">
                    <button type="submit"
                            class="btn btn-success btn-cta waves-effect text-center m-b-20 float-right"
                            disabled={submitting}>
                        <FormattedMessage id={`app.label.change`} defaultMessage={`Label Name`}/>
                    </button>
                    {isLoading == true ?
                        <div id="loader">
                            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                        </div> : null}

                </div>
            </div>
            <div class="error-handling-messages">
                {message != "success" ?
                    <div id="error"> {message}</div> : null}
            </div>

        </form>


    );
}


const mapStateToProps = (state, props) => (
    {
        // initialValues: props.ps ? props.ps : null,

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'changePassword',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(Form));

