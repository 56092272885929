import React from "react"
import Setting from "../containers/UserHomeContainer"
import LoginContainer from "../containers/LoginContainer"
import SignupContainer from "../containers/SignupContainer"
import AdminContainer from "../containers/AdminContainer"
import ClientContainer from "../containers/ClientContainer"
import AgentContainer from "../containers/AgentContainer"
import CampaignContainer from "../containers/CampaignContainer"
import AgentNoteFormContainer from "../containers/AgentNOteFormContainer"
import AgentNoteRepportContainer from "../containers/AgentNoteRepportContainer"
import PbxContainer from "../containers/PbxContainer"
import CLientReportContainer from "../containers/ClientReportContainer"
import FeedbackContainer from "../containers/FeedbackController"
import ExperienceContainer from "../containers/ExperienceController"
import CalendarContainer from "../containers/CalendarContainer"
import EntreprisesRepportContainer from "../containers/EntreprisesRepportContainer"
import ConfigContainer from "../containers/ConfigContainer"
import FollowupContainer from "../containers/FollowupController"
import DaysReportContainer from "../containers/DaysReportController"
import MonthsContainer from "../containers/MonthsReportController"
import UrgenceContainer from "../containers/UrgenceContainer"
import ProfileContainer from "../containers/ProfileController"



export const adminRoutes = [
    {
        path: "/",
        exact: true,
        navbar: () => <Setting />
    },
    {
        path: "/admin",
        exact: true,
        navbar: () => <AdminContainer />
    },
    {
        path: "/crm",
        exact: true,
        navbar: () => <ClientContainer />
    },
    {
        path: "/agent",
        exact: true,
        navbar: () => <AgentContainer />
    },
    {
        path: "/campaign",
        exact: true,
        navbar: () => <CampaignContainer />
    }, {
        path: "/note",
        exact: true,
        navbar: () => <AgentNoteFormContainer />
    }, {
        path: "/call_report",
        exact: true,
        navbar: () => <AgentNoteRepportContainer />
    }, {
        path: "/pbx",
        exact: true,
        navbar: () => <PbxContainer />
    }, {
        path: "/reports",
        exact: true,
        navbar: () => <CLientReportContainer />
    }, {
        path: "/feedback",
        exact: true,
        navbar: () => <FeedbackContainer />
    }, {
        path: "/experience",
        exact: true,
        navbar: () => <ExperienceContainer />
    }
    , {
        path: "/calendar",
        exact: true,
        navbar: () => <CalendarContainer />
    }
    , {
        path: "/entreprises",
        exact: true,
        navbar: () => <EntreprisesRepportContainer />
    }
    , {
        path: "/config",
        exact: true,
        navbar: () => <ConfigContainer />
    }
    , {
        path: "/followup",
        exact: true,
        navbar: () => <FollowupContainer />
    }, {
        path: "/daysreport",
        exact: true,
        navbar: () => <DaysReportContainer />
    },
     {
        path: "/monthreport",
        exact: true,
        navbar: () => <MonthsContainer />
    }, 
    {
        path: "/urgence",
        exact: true,
        navbar: () => <UrgenceContainer />
    },
    {
        path: "/profile",
        exact: true,
        navbar: () => <ProfileContainer />
    }
]


export const loginRoutes = [
    {
        path: "/",
        exact: true,
        navbar: () => <LoginContainer />
    },
    {
        path: "/signup",
        exact: true,
        navbar: () => <SignupContainer />
    }
]

