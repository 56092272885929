/**
 * Created by louidorpc on 8/11/20.
 */
import React from 'react';
import {app} from '../../constants/config';
import Loader from '../../common/loader';
import Progress from '../../common/progress'
import {FormattedMessage} from "react-intl";
import {getIntlMsg, intl} from '../../constants/utils'
import Calendar from './calendar/Calendar/Calendar'
import moment from 'moment';
import AgentModal from './calendar/Calendar/agentModal';
import CampaignAssign from './campaign';
import createHistory from 'history/createBrowserHistory';
import RecordingCall from './recordingCall';
import EmergencyCall from './emergencyCall';

class AgentManager extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            id: props.session.person_id,
            tab: "session",
            modalVisible: false,
            selectedData: false,
            show: '', display: 'none',
            show1: '', display1: 'none',
            list: []
        }
    }


    componentDidMount() {

        const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);
        const history = createHistory();


        if (infouser.position_name === "Administrateur" || infouser.position_name === "Superviseur" && infouser.entr_name === "Seedlee") {
            const date = moment(new Date()).format('YYYY-MM');

            const pid = {
                person_id: this.state.id,
                complet_date: date
            }


            this.props.getAllAgentSession(pid)
        } else {
            history.goBack('/')
        }


    }

    handleSwitchTable = tab => {
        if (tab === "session") {
            const date = moment(new Date()).format('YYYY-MM');

            const pid = {
                person_id: this.state.id,
                complet_date: date
            }
            this.props.getAllAgentSession(pid)

            this.setState({tab: 'session'})


        } else if (tab === "assign_campaign") {
            this.props.getAllPersons()
            this.props.getAllEntreprises()
            this.setState({tab: 'assign_campaign'})
        } else if (tab === "recording_call") {
            this.props.getAllRecordingCall()
            this.setState({tab: 'recording_call'})
        } else if (tab === "emergency_call") {
            this.props.getAllEmergencyCall()
            this.setState({tab: 'emergency_call'})
        }

    }

    showModal = (data, type) => {
        if (type === 1)
            this.setState({show1: 'show', display1: 'block'});
        else this.setState({
            selectedData: data,
            show: 'show',
            display: 'block',
            show1: '',
            display1: 'none',
            modalVisible: true
        });

    }

    resetModal = (type) => {
        if (type === 1) {
            this.setState({show1: '', display1: 'none'});
        } else {
            this.setState({
                show: '',
                display: 'none',
                show1: 'show',
                display1: 'block',
                selectedData: [],
                modalVisible: false
            });
        }
    }


    handlerGetNumberList = (code) => {
        this.props.getAllPhoneCampaigns(code)

    }

    callbackPhone = (list) => {
        this.setState({list: list})

    }


    componentDidUpdate(prevProps, prevState, nextState) {
        console.log("______++++++++++_________", prevProps)
    }

    render() {

        const {
            session,
            isLoadingS,
            agentSessions,
            view,
            event,
            tsData,
            isLoadingAS,
            isLoadingRC,
            rcCall,
            emCalls,
            changeView,
            changeEvents,
            getAllAgentSession,
            getSelectedDataFromCalendar,
            intl,
            agS,
            getAllSessionByAgent,
            getAllPhoneCampaignsAvailable,
            campaigns,
            isLoadingCA,
            isLoadingPC,
            numberList,
            numberListA,
            getAllPhoneCampaigns,
            persons,
            createAssignCampain,
            isLoadingCAC,
            entreprises,
            getAllCampaigns,
            getAllPhones,
            phones,
            mcac,
            getAllPersons,
            getAllEntreprises,
            resetEmergencyCall,
            resetStateClient
        } = this.props;


        const {modalVisible, show, display, show1, display1, id, tab} = this.state

        return (
            <>
                <section class="admin-content" onAbort={() => this.resetModal(1)}>

                    <div class="container-fluid bg-dark m-b-30">
                        <div class="row  p-b-60 p-t-20">

                            <div class="col-md-6 text-white p-b-30">
                                <div class="media">

                                    <div class="avatar mr-3  avatar-xl">

                                        {session && isLoadingS == false ?
                                            <img
                                                src={session ? session.photo ? app.BASE_IMAGE_URL + '/models/images/' + session.photo + '?' + new Date() : "/assets/img/avatar.png" : "/assets/img/avatar.png"}
                                                alt="..." class="avatar-img rounded-circle"/> :
                                            <Loader/>}
                                    </div>
                                    <div class="media-body m-auto">
                                        <h5 class="mt-0">{session ? session.fullname : null}
                                        </h5>
                                        <div class="opacity-75">{session ? session.role : null}</div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6 text-white my-auto text-md-right p-b-30">

                                <div class="">
                                    <h2 class="opacity-75"><a href="#!">
                                        <FormattedMessage id={`app.label.agent`}
                                                          defaultMessage={`Agent`}/></a>
                                    </h2>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="container-fluid pull-up">
                        <div class="row ">
                            <div class="col-lg-12">
                                <div class="card m-b-30">
                                    <div class="card-header">
                                        <div class="card-title"></div>
                                    </div>
                                    <div class="card-body">
                                        <ul class="nav nav-tabs" id="myTab" role="tablist">

                                            <li class="nav-item" onClick={() => this.handleSwitchTable('session')}>
                                                <a class="nav-link active" id="contact-tab-z" data-toggle="tab"
                                                   href="#agent_session"
                                                   role="tab"
                                                   aria-controls="contact" aria-selected="false"><i
                                                    className="mdi mdi-login">
                                                    <FormattedMessage id={`app.label.agent_sessions`}
                                                                      defaultMessage={`Login Time`}/></i></a>
                                            </li>
                                            {session && session.position_name === "Superviseur" ? null :
                                                <li class="nav-item"
                                                    onClick={() => this.handleSwitchTable('assign_campaign')}>
                                                    <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                       href="#assign_campaign"
                                                       role="tab"
                                                       aria-controls="contact" aria-selected="false"><i
                                                        className="mdi mdi-phone">
                                                        <FormattedMessage id={`app.label.assign_campaign_to_agents`}
                                                                          defaultMessage={`Liste des appels`}/>
                                                    </i>
                                                    </a>
                                                </li>}

                                            {/*<li class="nav-item"*/}
                                            {/*    onClick={() => this.handleSwitchTable('recording_call')}>*/}
                                            {/*    <a class="nav-link" id="contact-tab-z" data-toggle="tab"*/}
                                            {/*       href="#recording_call"*/}
                                            {/*       role="tab"*/}
                                            {/*       aria-controls="contact" aria-selected="false"><i*/}
                                            {/*        className="mdi mdi-call-made"> <FormattedMessage*/}
                                            {/*        id={`app.label.recording_call`}*/}
                                            {/*        defaultMessage={`Enregistrements d'appels `}/>*/}
                                            {/*    </i>*/}
                                            {/*    </a>*/}
                                            {/*</li>*/}
                                            <li class="nav-item"
                                                onClick={() => this.handleSwitchTable('emergency_call')}>
                                                <a class="nav-link" id="contact-tab-z" data-toggle="tab"
                                                   href="#emergency_call"
                                                   role="tab"
                                                   aria-controls="contact" aria-selected="false"><i
                                                    className="mdi mdi-alert"> <FormattedMessage
                                                    id={`app.label.emergency_calls`} defaultMessage={`Liste des suivis `}/>
                                                </i>
                                                </a>
                                            </li>

                                        </ul>
                                        <div class="tab-content" id="myTabContent1">

                                            <div class="tab-pane fade show active" id="agent_session" role="tabpanel"
                                                 aria-labelledby="contact-tab">
                                                <Calendar
                                                    changeView={changeView}
                                                    changeEvents={changeEvents}
                                                    resetModal={this.resetModal}
                                                    events={event}
                                                    view={view}
                                                    showModal={this.showModal}
                                                    calendarInfos={agentSessions && agentSessions.length > 0 ? agentSessions : []}
                                                    person_id={id}
                                                    getAllAgentSession={getAllAgentSession}
                                                    locale={session ? session.language : 'en'}
                                                    getSelectedDataFromCalendar={getSelectedDataFromCalendar}
                                                    getAllSessionByAgent={getAllSessionByAgent}

                                                />

                                            </div>
                                            <div class="tab-pane fade show" id="assign_campaign" role="tabpanel"
                                                 aria-labelledby="contact-tab">
                                                {tab === "assign_campaign" ?
                                                    <CampaignAssign
                                                        campaigns={campaigns}
                                                        isLoadingCA={isLoadingCA}
                                                        isLoadingPC={isLoadingPC}
                                                        numberList={numberListA}
                                                        getAllPhoneCampaigns={getAllPhoneCampaignsAvailable}
                                                        getAllCampaigns={getAllCampaigns}
                                                        handlerGetNumberList={this.handlerGetNumberList}
                                                        persons={persons}
                                                        language={session ? session.language : 'en'}
                                                        person_id={id}
                                                        createAssignCampain={createAssignCampain}
                                                        isLoadingCAC={isLoadingCAC}
                                                        entreprises={entreprises}
                                                        mcac={mcac}
                                                        getAllPersons={getAllPersons}
                                                        getAllEntreprises={getAllEntreprises}
                                                        resetEmergencyCall={resetEmergencyCall}
                                                        resetStateClient={resetStateClient}

                                                    />
                                                    : null}
                                            </div>

                                            <div class="tab-pane fade show" id="recording_call" role="tabpanel"
                                                 aria-labelledby="contact-tab">
                                                {tab === "recording_call" ?
                                                    <RecordingCall
                                                        persons={persons}
                                                        language={session ? session.language : 'en'}
                                                        rcCall={rcCall}
                                                        isLoadingRC={isLoadingRC}
                                                        getAllPhones={getAllPhones}
                                                        phones={phones}
                                                    />
                                                    : null}
                                            </div>


                                            <div class="tab-pane fade show" id="emergency_call" role="tabpanel"
                                                 aria-labelledby="contact-tab">
                                                {tab === "emergency_call" ?
                                                    <EmergencyCall
                                                        persons={persons}
                                                        language={session ? session.language : 'en'}
                                                        emCalls={emCalls}
                                                        isLoadingRC={isLoadingRC}


                                                    />
                                                    : null}
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                {modalVisible ?
                    <AgentModal open={modalVisible}
                                resetModal={this.resetModal}
                                language={session ? session.language : 'en'}
                                selectedData={this.state.selectedData ? this.state.selectedData : []}
                                show={show}
                                display={display}
                                agS={agS}
                                tsData={tsData}/> : null}
            </>
        )
    }
}

export default AgentManager