export default {
    "app.label.authority": "Authorities",
    "app.label.market": "Markets",
    "app.label.document": "Documents",
    "app.label.user": "Users",
    "app.label.male": "Male",
    "app.label.female": "Female",

    "app.label.french": "French",
    "app.label.english": "English",
    "app.title.setting": "Personnal Informations",
    "app.label.statistic": "Statistics",
    "app.label.project": "Project",
    "app.label.setting": "Setting",

    "app.label.detail": "Details",
    "app.label.edit": "Update",
    "app.label.delete": "Delete.",
    "app.label.cancel": "Cancel",
    "app.label.save": "Save",


    "app.label.code": "Code",
    "app.label.username": "username",
    "app.label.language": "Language",
    "app.label.password": "Password",
    "app.label.repeatPassword": "Password again",
    "app.label.name": "Last name",
    "app.label.description": "Description",
    "app.label.phone": "Phone",
    "app.label.email": "Email",
    "app.label.address": "Address",
    "app.label.firstname": "First name",
    "app.label.fullname": "Full Name",
    "app.label.lastname": "Last name",
    "app.label.function": "Function",
    "app.label.department": "Department",
    "app.label.position": "Position",
    "app.label.gender": "Gender",
    "app.label.bloodType": "Blood type",
    "app.label.logout":"Logout",
    "app.label.profile":"Profile",


    "app.label.person_id": "ID",
    "app.label.site": "Site",
    "app.label.nif_cin": "CIN/NIF",
    "app.label.saveSuccess": "Save successfully",
    "app.label.saveError": "Failled",
    "app.label.home": "Home",
    "app.label.editTimesheet": "Edit your Timesheet",
    "app.label.allReadyProjectAssign": "You are already assigned to this project",

    "app.label.project_name": "Project name",
    "app.label.percentage": "Percentage",
    "app.label.project_list": "Project list",
    "app.label.project_name_empty": "Project name can not be empty",
    "app.label.percentage_empty": "The percentage must not be empty and must not be less than or equal to 0",
    "app.label.add": "Add",
    "app.label.firstTooltip": "First page",
    "app.label.lastTooltip": "Next page",
    "app.label.previousTooltip": "Previous page",
    "app.label.nextTooltip": "next page",
    "app.label.labelRowsSelect": "Rows",
    "app.label.labelDisplayedRows": "{from}-{to} to {count}",
    "app.label.searchTooltip": "Search",
    "app.label.searchPlaceholder": "Search",

    "app.label.next": "Next",
    "app.label.today": "Today",
    "app.label.back": "Back",
    "app.label.month": "Month",
    "app.label.day": "Day",
    "app.label.week": "Week",
    "app.label.time": "Time",
    "app.label.allday": "All day",
    "app.label.more": "more",
    "app.label.timesheet": "Timesheet",
    "app.label.project_assign": "Assigned project",
    "app.label.hours_number": "Number of hours",
    "app.label.update": "Update",
    "app.label.welcome": "Welcom to Seedlee",
    "app.label.dashboard": "Dashboard",

    "app.label.art_withdrawal_in_time": "Timely withdrawal of ARVS",
    "app.label.art_retention_on_twelve_months": "Retention on ART at 12 months",
    "app.label.viral_load_test_performance": "Viral coverage after 12 months",
    "app.label.out_of_pharmacy_stock": "Pharmacy out of stock",
    "app.label.viral_load_suppression": "Viral suppression at 12 months",
    "app.label.appropriate_transition_to_second_line": "Appropriate switch to second-line ART",
    "app.label.lost_patients": "Patients lost to follow-up",
    "app.label.iap": "IAP",
    "app.label.iap.numerator": "Numerator",
    "app.label.iap.denominator": "Denominator",
    "app.label.iap_list": "IAP list",
    "app.label.administration": "Administration",
    "app.label.service_name": "Service name",
    "app.label.created_date": "Created date",
    "app.label.service_list": "Service list",
    "app.label.creator": "Create by",
    "app.label.position_name": "Position name",
    "app.label.position_list": "Positions list",
    "app.label.entreprise_list": "Entreprise list",
    "app.label.emptyDataSourceMessage": "No records to display",
    "app.label.entr_name": "Business name",
    "app.label.entr_type": "Entreprise type",
    "app.label.company": "Company",
    "app.label.industry": "Industry",
    "app.title.entreprise": "Entreprise",
    "app.label.entreprise": "Entreprise",
    "app.label.service": "Service",
    "app.label.person_position": "Title",
    "app.label.create_person": "Create a person",
    "app.label.birthdate": "Birth date",
    "app.label.person_form": "Person from register",
    "app.label.access": "You do not have access to this system ... please contact the Administration service",
    "app.label.create_cient": "Create a customer",
    "app.label.client_form": "Customer registration form",
    "app.label.username_or_email_exists": "This username or email already exists",
    "app.label.client_list": "Customers Lists",
    "app.label.year": " Year(s)",
    "app.label.client": "Customer(s)",
    "app.label.upload_csv_file": "Upload csv file",
    "app.label.campaign": "Caimpaign",

    "app.label.campaign_name": "Campagne title",
    "app.label.campaign_slogan": "Campaign slogan",
    "app.label.start_date": "Start date",
    "app.label.end_date": "End date",
    "app.label.start": "Start",
    "app.label.end": "End",
    "app.label.campaing_programmation": "Programming of the day",
    "app.label.campaing_period": "Period of",
    "app.label.assign_campaign_to_agent": "Assign campaign to  agent",
    "app.label.extension_number": "Extension number",
    "app.label.extension_group": "Group",
    "app.label.extension_status": "Statut",
    "app.label.extension_list": "Extension list",
    "app.label.available": "Available",
    "app.label.unvailable": "Unvailable",
    "app.label.agent_session": "Agents session",
    "app.label.signin": "Entrance",
    "app.label.signout": "Exit",
    "app.label.list_of_agent": "List of Agents",
    "app.label.search": "Search",
    "app.label.number_qty": "Phone number quantity",
    "app.label.campaign_list": "Campaign list",
    "app.label.assign": "Assign",
    "app.label.assigned_campaign": "Assigned campaign",
    "app.label.campaign_assign_list": "Assigned campaign list",
    "app.label.person": "User management",
    "app.label.client_menagement": "Client management",
    "app.label.agent_allready_Assign": "Agent is already assigned to this campaign",
    "app.label.phone_exists": "One of the phone numbers is already assigned to an agent for this campaign",
    "app.label.campaign_management": "Campaign management",
    "app.label.campaign_creation": "Campaign creation",
    "app.label.new_client": "New",
    "app.label.status": "Status",
    "app.label.ville": "City",
    "app.label.postal_code": "Postal code",

    "app.label.anonym_society": "Anonimous society",
    "app.label.individual_society": "Individual company",
    "app.label.entr_nature": "Juridical nature",
    "app.label.entr_etablished": "Established since",
    "app.label.location": "Location",
    "app.label.entr_patent_number": "Patent number ",
    "app.label.flat_rate_type": "Flat rate type ",

    "app.label.bank_officier_name": "Banking officer",
    "app.label.bank_account_number": "Account number",
    "app.label.bank_account_name": "Account name",
    "app.label.bank_name": "Name of the bank",
    "app.label.previous": "Previous",
    "app.label.step": "Step",
    "app.label.entr_logo": "Entreprise logo",
    "app.label.entr_infomation": "Entreprise Informations",
    "app.label.person_contact": "Contact person",
    "app.label.rh_reponsable": "Human resources manager",
    "app.label.reponsable_marketing": "Marketing and sales manager :",
    "app.label.reponsable_comptability": "Accounting Manager :",
    "app.label.dg": "General manager :",

    "app.label.signature": "Signature",
    "app.label.date": "Date",
    "app.label.sign_name": "Name of signatory",
    "app.label.sign_fonction": "Fonction",
    "app.label.noMatchValue": "Passwords must match",
    "app.label.change": "Change",
    "app.label.repeatpassword": "Retype the password",
    "app.label.username_and_password": "Username or password is incorrect",
    "app.label.document_name": "Document name",
    "app.label.prefix_list": "Prefix list",
    "app.label.prefix_number": "Prefix number",
    "app.label.emergency": "Emergency",
    "app.label.newpassword": "New password",
    "app.label.passwordMissed": "Password mismatched",
    "app.label.email_invalid": "Email invalid",
    "app.label.required.email": "Email required",
    "app.label.delete_campaign_success": "The campaign has been deleted",
    "app.label.delete_campaign_failled": "Delete campaign failed",
    "app.label.u.feedback": "Feedback",


};
