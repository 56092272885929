import React from 'react'
import  SoftPhone  from 'react-softphone'
import { WebSocketInterface } from 'jssip';

const config = {
    domain: '127.0.0.1', // sip-server@your-domain.io
    uri: 'pjsip:3001@127.0.0.1', // sip:sip-user@your-domain.io
    password: '1234', //  PASSWORD ,
    ws_servers: 'wss://3001@127.0.0.1:8089/ws', //ws server
    sockets: new WebSocketInterface('wss://127.0.0.1:8089/ws'),
    display_name: '3001',//jssip Display Name
    debug: false // Turn debug messages on
};

const setConnectOnStartToLocalStorage =(newValue)=>{
// Handle save the auto connect value to local storage
 //   console.log("----------------------",newValue)
    return true
}
const setNotifications =(newValue)=>{
// Handle save the Show notifications of an incoming call to local storage
    return true
}
const setCallVolume =(newValue)=>{
// Handle save the call Volume value to local storage
    return true
}
const setRingVolume =(newValue)=>{
// Handle save the Ring Volume value to local storage
    return true
}

const  App=()=> {
    return (
        <div className="App" style={{position:'absolute', left:'40px', top:'30px'}}>
            <header className="App-header">
                <SoftPhone
                    callVolume={33} //Set Default callVolume
                    ringVolume={44} //Set Default ringVolume
                    connectOnStart={true} //Auto connect to sip
                    notifications={true} //Show Browser Notification of an incoming call
                    config={config} //Voip config
                    setConnectOnStartToLocalStorage={setConnectOnStartToLocalStorage} // Callback function
                    setNotifications={setNotifications} // Callback function
                    setCallVolume={setCallVolume} // Callback function
                    setRingVolume={setRingVolume} // Callback function
                    timelocale={'UTC+3'} //Set time local for call history
                />
            </header>
        </div>
    );
}

export default App;