import React from 'react';
import {connect} from "react-redux"
import {IntlProvider} from "react-intl"
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {loginRoutes as routes} from "./constants/router";
import messages from "./constants/messages"

function BaseLayout(props) {
    const {locale} = props;
    return (
        <IntlProvider locale={'fr'} messages={messages['fr']}>
            <Router>
                <body class="jumbo-page">
                <main class="admin-main">
                    <Switch>
                        {routes.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact}
                                   component={route.navbar}/>
                        ))}
                    </Switch>
                </main>
                </body>
            </Router>
        </IntlProvider>
    );
}

const mapStateToProps = ({settingReducer}) => ({
    locale: settingReducer.locale
})

export default connect(
    mapStateToProps,
    null
)(BaseLayout)

