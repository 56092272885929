import * as validator from '../../../constants/validator'
import types from '../../../actions/type'
import {validateEmail} from "../../../constants/validator";


export const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = 'app.label.required';
    }
    errors.email = validator.validateEmail('email', values.email, true)
    if (!values.rpassword) {
        errors.rpassword = 'app.label.required';
    }
    if (!values.repeatpassword) {
        errors.repeatpassword = 'app.label.required';
    } else if (values.repeatpassword !== values.rpassword) {
        errors.repeatpassword = 'app.label.passwordMissed';
    }

    return errors;


};
