import types from "../type"


/**
 ------------------------------------------------
 NOTE Switch locale pack action creator
 -----------------------------------------------
 */


//ANCHOR add message fail
export const changeLocale = locale => ({
    type: types.CHANGE_LOCALE_REQUEST,
    payload: {locale: locale}
})

export const changeLocaleSuccess = locale => ({
    type: types.CHANGE_LOCALE_REQUEST_SUCCESS,
    payload: {locale: locale}
})

export const changeLocaleFailled = localeM => ({
    type: types.CHANGE_LOCALE_REQUEST_FAILLED,
    payload: {localeM: localeM}
})

export const getUserSessionInfo = sessionId => ({
    type: types.GET_USER_SESSION_INFO,
    payload: {sessionId: sessionId, isLoadingS: true}
})

export const getUserSessionInfoSuccess = session => ({
    type: types.GET_USER_SESSION_SUCCESS,
    payload: {session: session, isLoadingS: false}
})

export const getUserSessionInfoFailled = sessionM => ({
    type: types.GET_USER_SESSION_FAILLED,
    payload: {sessionM: sessionM, isLoadingS: true}
})


export const signoutAgent = sno => ({
    type: types.SIGNOUT_AGENT,
    payload: {isLoading: true, sno: sno}
});


export const signoutAgentSuccess = msno => {
    return {
        type: types.SIGNOUT_AGENT_SUCCESS,
        payload: {isLoading: false, msno: msno, message: "success", msItem: null, type: "agent_login"}
    }
}


export const signoutAgentFailled = msno => {
    return {
        type: types.SIGNOUT_AGENT_FAILLED,
        payload: {isLoading: false, message: `app.label.${msno}`}
    };
}


export const getFeedbackStatistic = statId => ({
    type: types.GET_FEEDBACK_STATISTIC,
    payload: {isLoadingFS: true, statId: statId}
});


export const getFeedbackStatisticSuccess = feedStats => {
    return {
        type: types.GET_FEEDBACK_STATISTIC_SUCCESS,
        payload: {isLoadingFS: false, feedStats: feedStats}
    }
}


export const getFeedbackStatisticFailled = () => {
    return {
        type: types.GET_FEEDBACK_STATISTIC_FAILED,
        payload: {isLoadingFS: false, mSFS: `ERROR`}
    };
}



