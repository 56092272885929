import React from 'react'
import {ResetPasswordModel, SigninModel} from '../../constants/model';
import Form from './form';

const ResetPassword = ({SwitchToSignup, isLoading, message, sn, resetPassword, resetStateResetPassword, intl,hideModal}) => {


    const submit = values => {

        const snEntity = new ResetPasswordModel({...values});
        resetPassword(snEntity);
    };


    return (
        <Form
            onSubmit={submit}
            message={message}
            intl={intl}
            resetStateResetPassword={resetStateResetPassword}
            isLoading={isLoading}
            SwitchToSignup={SwitchToSignup}
            sn={sn}
            hideModal={hideModal}
        />
    )
}
export default ResetPassword;