import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { connect, useSelector } from 'react-redux'
import { validate } from "./validator";
import { renderField } from "../../../../../../common/FormComponents";
import moment from 'moment'


const onClearForm = (reset, resetStateClient) => {
    reset('cleint');
    resetStateClient();
}

const Form = props => {
    const { handleSubmit, reset, pristine, submitting, intl, isLoading, isLoadingCA, selectedData } = props;
    // if (mc === "success") onClearForm(reset, resetStateClient)


    const clientTypes = ["Particulier", "Entreprise"]

    const [isEntreprise, setIsEntreprise] = useState(false);

    const handleChangeTypeServiceType = type => {

        if (type === "Entreprise") {
            setIsEntreprise(true)
        } else {
            setIsEntreprise(false)
        }
    }

    const { entreprises } = useSelector(state => ({
        entreprises: state?.entrepriseReducer?.entreprises || [],
    }));


    return (
        <form onSubmit={handleSubmit}>
            <div className="form-row m-4">
                <div className="form-group floating-label show-label col-md-12">
                    <Field
                        className="form-control"
                        id="client_name"
                        name="client_name"
                        component={renderField}
                        type="text"
                        label="app.label.client_name"
                        placeholder="app.label.client_name"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-12">
                    <Field
                        className="form-control"
                        id="client_phone"
                        name="client_phone"
                        component={renderField}
                        type="text"
                        label="app.label.client_phone"
                        placeholder="app.label.client_phone"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-12">
                    <Field
                        className="form-control"
                        id="client_type_service"
                        name="service_type"
                        component={renderField}
                        onChange={(e) => handleChangeTypeServiceType(e.target.value)}
                        type="select"
                        label="app.label.client_type_service"
                        placeholder="app.label.client_type_service"
                        options={clientTypes ? clientTypes.map(value => ({ id: value, name: value })) : []}
                    />

                </div>

                {isEntreprise ?
                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="entreprise_name"
                            name="client_entreprise"
                            component={renderField}
                            type="text"
                            label="app.label.entreprise_name"
                            placeholder="app.label.entreprise_name"
                        />

                    </div> : null}


                <div className="form-group floating-label show-label col-md-12">
                    <Field
                        className="form-control"
                        id="title"
                        name="title_name"
                        component={renderField}
                        type="text"
                        label="app.label.title"
                        placeholder="app.label.title"
                    />

                </div>


                <div className="form-group floating-label show-label col-md-12">
                    <Field
                        className="form-control"
                        id="title"
                        name="description"
                        component={renderField}
                        type="textarea"
                        label="app.label.description"
                        placeholder="app.label.description"
                    />

                </div>
                <div className="form-group floating-label show-label col-md-12">
                    <Field
                        className="form-control"
                        id="entreprise"
                        name="entreprise"
                        component={renderField}
                        type="select"
                        label="app.label.entreprise"
                        placeholder="app.label.entreprise"
                        options={entreprises ? entreprises.map(value => ({ id: value.id, name: value.entr_name })) : []}
                    />

                </div>

                <div class="col-md-12">
                {!selectedData?.id ?<button type="submit"
                        class="btn btn-success btn-cta waves-effect text-center m-b-20 float-right"
                        disabled={pristine || submitting}> <FormattedMessage id={`app.label.create`} defaultMessage={`Creer`} />
                    </button>:null}
                    {isLoadingCA == true ?
                        <div id="loader">
                            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                        </div> : null}

                </div>


            </div>

        </form>


    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: { ...props.cInfo ? { ...props.cInfo } : null }

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'task',
    enableReinitialize: true,
    validate,
})(Form));

