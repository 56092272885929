import types from "../actions/type";


const defaultState = {
    isLoadingSt: false,
    message: null,
    mst: null,
    stats: null,
    entreprise_id: null,
    criteria: null,
}

const statisticReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_ALL_STATISTIC:
            return {
                ...state,
                isLoadingSt: action.payload.isLoadingSt,
                criteria: action.payload.criteria
            };


        case types.GET_ALL_STATISTIC_SUCCESS:
            return {
                ...state,
                isLoadingSt: action.payload.isLoadingSt,
                stats: action.payload.stats,
                criteria: null
            };


        case types.GET_ALL_STATISTIC_FAILLED:
            return {
                ...state,
                mst: action.payload.mst,
                isLoadingSt: action.payload.isLoadingSt
            };


        default:
            return state

    }
}


export default statisticReducer