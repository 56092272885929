import { put, call, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/creator/pbx";
import types from "../actions/type";
import * as api from "../services/pbx";
import { getAllIncomingCall, updateIncomingCall } from "../services/pbx";


export const handleGetAllPbxReport = function* (action) {

    const { criteria } = action.payload

    try {
        const getSv = yield call(api.getAllPbxReport, criteria);
        if (getSv.data?.reports?.length > 0) {
            yield put(actions.getAllPbxReportSuccess(getSv.data))
        } else {
            yield put(actions.getAllPbxReportFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPbxReportFailled("error"))
    }
};


export const handleGetAllIncomingCalls = function* (action) {

    const { criteria } = action.payload

    try {
        const getSv = yield call(api.getAllIncomingCall, criteria);
        if (getSv.data?.length > 0) {
            yield put(actions.getAllIncomingCallSuccess(getSv.data))
        } else {
            yield put(actions.getAllIncomingCallFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllIncomingCallFailled("error"))
    }
};

export const handleGetAllOutgoingCalls = function* (action) {

    const { criteria } = action.payload

    try {
        const getSv = yield call(api.getAllOuntgoingCall, criteria);
        if (getSv.data?.length > 0) {
            yield put(actions.getAllOutgoingCallSuccess(getSv.data))
        } else {
            yield put(actions.getAllOutgoingCallFailled(getSv.data.message))
        }

    } catch (error) {
        yield put(actions.getAllOutgoingCallFailled("error"))
    }
};


export const handleUpdateIncomingCall = function* (action) {
    const { phone } = action.payload;
    try {
        const pos = yield call(api.updateIncomingCall, phone);
        if (pos.data.uniqueId !== null) {
            yield put(actions.updateIncomingCallSuccess(phone.language, pos.data))

        } else {
            yield put(actions.updateIncomingCallFailled({ ms: pos.data.message, locale: phone.language }))
        }
    } catch (error) {
        yield put(actions.updateIncomingCallFailled({ ms: 'app.label.saveError', locale: phone.language }))
    }
};

export const pbxSaga = [
    takeLatest(types.GET_ALL_PBX_REPORTS, handleGetAllPbxReport),
    takeLatest(types.GET_ALL_INCOMING_CALL_REPORTS, handleGetAllIncomingCalls),
    takeLatest(types.GET_ALL_OUTGOING_CALL_REPORTS, handleGetAllOutgoingCalls),
    takeLatest(types.UPDATE_INCOMING_CALL, handleUpdateIncomingCall),
];
