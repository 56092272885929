import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getCookie, setCookie } from "react-use-cookie";
import Loader from "../../../../common/loader";
import { app } from "../../../../constants/config";
import AgentModal from "./Calendar/agentModal";
import Calendar from './Calendar/Calendar'

const ClientCalendar = ({ session,getAllEntreprises,getAllCalendarEvents,changeView,
    changeEvents,getCalendarEventInfo,createCalendarEvent,calendarEventChangeStatus,updateCalendarEvent,getAllCalendarEventsByAgent,setViewCalendarEvent }) => {

    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const handleShowModal = (data) => {
        setShow(true)
        setSelectedData(data)
    }

    const handleHideModal = () => {
        setShow(false)
    }

    useEffect(() => {
        getAllEntreprises();
        getAllCalendarEventsByAgent(session?.person_id)

        let entity = getCookie('event') ? JSON.parse(getCookie('event')) : null;

        if (entity) {
            setShow(true)
            setSelectedData(entity)
            getCalendarEventInfo(entity)
           
            setTimeout(() => {
                setCookie('event', null)
                setViewCalendarEvent({ view_type: "agent", isView: 1, id: entity.id, entreprise: entity.entreprise,user_id:session?.person_id })

            }, 3000);
        }
    }, [])

    const {events,view,cInfo,ms} = useSelector(state => ({
        events: state?.eventReducer?.events || [],
        cInfo: state?.eventReducer?.cInfo || null,
        ms: state?.eventReducer?.ms || null,
        view: state?.agentReducer?.view || [],
    }));
    

    return (
        <React.Fragment>
            <section class="admin-content" onAbort={() => this.resetModal(1)}>

                <div class="container-fluid bg-dark m-b-30">
                    <div class="row  p-b-60 p-t-20">

                   
                        <div class="col-md-3 text-white p-b-30">
                            
                            <div class="media">

                                <div class="avatar mr-3  avatar-xl">

                                    {session ?
                                        <img
                                            src={session ? session.photo ? app.BASE_IMAGE_URL + '/models/images/' + session.photo + '?' + new Date() : "/assets/img/avatar.png" : "/assets/img/avatar.png"}
                                            alt="..." class="avatar-img rounded-circle" /> :
                                        <Loader />}
                                </div>
                                <div class="media-body m-auto">
                                    <h5 class="mt-0">{session ? session.fullname : null}
                                    </h5>
                                    <div class="opacity-75">{session ? session.role : null}</div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-3 text-white p-b-30">
                            <p>
                                <ol>
                                    <li className="m-2"><span style={{background:"#33B6FF",color:"#33B6FF"}}>[.]</span> Creé</li>
                                    <li className="m-2" ><span style={{background:"#7C33FF",color:"#7C33FF"}}>[.]</span> Modifié</li>
                                    <li className="m-2"><span style={{background:"#037B0F",color:"#037B0F"}}>[.]</span> Publié</li>
                                </ol>
                            </p>
                        </div>
                        <div class="col-md-6 text-white my-auto text-md-right p-b-30">

                            <div class="">
                                <h2 class="opacity-75"><a href="#!">
                                    <FormattedMessage id={`app.label.Calendar`}
                                        defaultMessage={`Calendrier`} /></a>
                                </h2>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="container-fluid pull-up">
                    <div class="row ">
                        <div class="col-lg-12">
                            <Calendar handleShowModal={handleShowModal} locale={session ? session.language : 'fr'}
                             calendarInfos={events} 
                             changeView={changeView} changeEvents={changeEvents}
                             view={view}
                             getCalendarEventInfo={getCalendarEventInfo}
                             />
                        </div>
                    </div>
                </div>
            </section>

            <AgentModal
                show={show}
                handleHideModal={handleHideModal}
                selectedData={selectedData}
                cInfo={cInfo}
                createCalendarEvent={createCalendarEvent}
                session={session}
                calendarEventChangeStatus={calendarEventChangeStatus}
                ms={ms}
                getAllCalendarEvents={getAllCalendarEvents}
                setShow={setShow}
                updateCalendarEvent={updateCalendarEvent}
            />
        </React.Fragment>
    )
}

export default ClientCalendar;