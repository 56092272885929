import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getAllServices = () => {
    return instance.get(`/service/index.php`)
};


export const createService = service => {
    return instance.post(`/service/create/index.php`, service)
};


export const updateService = service => {
    return instance.put(`/service/update/index.php`, service)
};

export const deleteService = id => {
    return instance.get(`/service/delete/index.php?id_service=${id}`)
};

