import * as React from "react";
import { Bar, Line } from "react-chartjs-2";

const ReportCall = props => {

    const { intl, reportCalls, getAllPbxReportClient, stat, statByDays,outgoingByday,missedByday,prefixIds} = props


    const legend = {
        display: true,
        position: "left",
        labels: {
            fontColor: "#323130",
            fontSize: 14
        }
    };
    
    const rand = () => Math.floor(Math.random() * 255)

    const data = {
        labels: reportCalls && reportCalls.length > 0 ? reportCalls.map(value => value.user_name + "(" + value.extension + ")") : [],
        datasets: [
            {
                label: '# appels entrants',
                data: reportCalls && reportCalls.length > 0 ? reportCalls.map(value => value.num_incoming_call) : [],
                backgroundColor: '#448AFF',
            },
            {
                label: '# appels sortants',
                data: reportCalls && reportCalls.length > 0 ? reportCalls.map(value => value.num_outgoing_call) : [],
                backgroundColor: '#E040FB',
            },
            {
                label: '# appels perdus',
                data: reportCalls && reportCalls.length > 0 ? reportCalls.map(value => value.num_incoming_call_no_answer) : [],
                backgroundColor: 'rgb(255, 99, 132)',
            },
        ],
    }

    const options = {
        title: {
            display: true,
            text: "Nombre d'appels entrants,sortants et manqués par agent",
            fontSize: 16
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: false,
                    },
                },
            ],
        },
    }

    const toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div class="card card-custom">
                
                    <div class="card-body">
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="kt_tab_pane_7_1" role="tabpanel"
                                aria-labelledby="kt_tab_pane_7_1">
                                <div className="row">
                                    <div class="col-lg-2 col-md-2 m-b-30">
                                        <div class="card m-b-30"
                                            style={{
                                                background: `#448AFF`,
                                                color: '#FFF'
                                            }}>
                                            <div class="card-body">
                                                <div class="row p-t-20 p-b-20">
                                                    <div class="my-auto col-md-12">

                                                        <h1 class="m-0"> {stat && stat?.num_incoming_call || 0}</h1>
                                                        <p class="m-0 text-white fw-600">Nombre d'appel entrants</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-3 m-b-30">
                                        <div class="card m-b-30"
                                            style={{
                                                background: `#E040FB`,
                                                color: '#FFF'
                                            }}>
                                            <div class="card-body">
                                                <div class="row p-t-20 p-b-20">
                                                    <div class="my-auto col-md-12">

                                                        <h1 class="m-0"> {stat && stat?.num_outgoing_call || 0}</h1>
                                                        <p class="m-0 text-white fw-600">Nombre d'appels sortants</p>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-3 m-b-30">
                                        <div class="card m-b-30"
                                            style={{
                                                background: `rgb(255, 99, 132)`,
                                                color: '#FFF'
                                            }}>
                                            <div class="card-body">
                                                <div class="row p-t-20 p-b-20">
                                                    <div class="my-auto col-md-12">

                                                        <h1 class="m-0"> {stat && stat?.num_incoming_call_no_answer || 0}</h1>
                                                        <p class="m-0 text-white fw-600">Nombre d'appels entrants manqués</p>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2">
                                        <div class="card m-b-30"
                                            style={{
                                                background: `#7C4DFF`,
                                                color: '#FFF'
                                            }}>
                                            <div class="card-body">
                                                <div class="row p-t-20 p-b-20">
                                                    <div class="my-auto col-md-12">

                                                        <h1 class="m-0">{stat?.duration_incoming_call || 0}</h1>
                                                        <p class="m-0 text-white fw-600">Durée des appels entrants en secondes</p>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2 m-b-30">
                                        <div class="card m-b-30"
                                            style={{
                                                background: `#40C4FF`,
                                                color: '#FFF'
                                            }}>
                                            <div class="card-body">
                                                <div class="row p-t-20 p-b-20">
                                                    <div class="my-auto col-md-12">

                                                        <h1 class="m-0"> {stat?.duration_outgoing_call || 0}</h1>
                                                        <p class="m-0 text-white fw-600">Durée des appels sortants en seconde</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-12 m-b-30">
                                        <div class="card m-b-30"
                                            style={{
                                                background: `rgb(255, 99, 132)`,
                                                color: '#FFF'
                                            }}>
                                            <div class="card-body">
                                                <div class="row p-t-20 p-b-20">
                                                    <div class="my-auto col-md-12">

                                                        <h1 class="m-0"> {stat && stat?.duration_incoming_call_missed || 0}</h1>
                                                        <p class="m-0 text-white fw-600">Durée totale Appels entrants manqués en seconde</p>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <Bar data={data} options={options} />
                                    </div>
                                    
                                    <div className="col-lg-6">
                                        <Line
                                            data={{
                                                labels: statByDays.length > 0 ? statByDays.map(value => value.caldate) : [],
                                                datasets: [
                                                    {
                                                        label: '# Appels',
                                                        fill: true,
                                                        lineTension: 0.5,
                                                        backgroundColor: '#448AFF',
                                                        borderColor: 'rgba(0,0,0,1)',
                                                        borderWidth: 2,
                                                        data: statByDays.length > 0 ? statByDays.map(value => value.countCall) : []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "Tableau de performance quotidienne sur le nombre d'appels entrant",
                                                    fontSize: 16
                                                },
                                                legend: {
                                                    display: false,
                                                    position: 'left'
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-6">
                                        <Line
                                            data={{
                                                labels: outgoingByday.length > 0 ? outgoingByday.map(value => value.caldate) : [],
                                                datasets: [
                                                    {
                                                        label: '# Appels',
                                                        fill: true,
                                                        lineTension: 0.5,
                                                        backgroundColor: '#E040FB',
                                                        borderColor: 'rgba(0,0,0,1)',
                                                        borderWidth: 2,
                                                        data: outgoingByday.length > 0 ? outgoingByday.map(value => value.countCall) : []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "Tableau de performance quotidienne sur le nombre d'appels sortants",
                                                    fontSize: 16
                                                },
                                                legend: {
                                                    display: false,
                                                    position: 'left'
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-6">
                                        <Line
                                            data={{
                                                labels: missedByday.length > 0 ? missedByday.map(value => value.caldate) : [],
                                                datasets: [
                                                    {
                                                        label: '# Appels',
                                                        fill: true,
                                                        lineTension: 0.5,
                                                        backgroundColor: 'rgb(255, 99, 132)',
                                                        borderColor: 'rgba(0,0,0,1)',
                                                        borderWidth: 2,
                                                        data: missedByday.length > 0 ? missedByday.map(value => value.countCall) : []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "Tableau de performance quotidienne sur le nombre d'appels entrants manqués",
                                                    fontSize: 16
                                                },
                                                legend: {
                                                    display: false,
                                                    position: 'left'
                                                }
                                            }}
                                        />
                                    </div>

                                    
                                </div>

                            </div>
                            <div class="tab-pane fade" id="kt_tab_pane_7_2" role="tabpanel"
                                aria-labelledby="kt_tab_pane_7_2">
                                {/*<ReportCAllTable reportCalls={reportCalls}/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
        ;
}

export default React.memo(ReportCall)

