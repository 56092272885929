/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getIntlMsg, intl } from "../../constants/utils";
import { injectIntl, createIntl, createIntlCache } from "react-intl"


toast.configure()


export const getAllPbxReportClient = criteria => ({
    type: types.CLIENT_GET_ALL_PBX_REPORTS,
    payload: { isLoadingPBXC: true, criteria: criteria }
});

export const getAllPbxReportClientSuccess = cReports => ({
    type: types.CLIENT_GET_ALL_PBX_REPORTS_SUCCESS,
    payload: { isLoadingPBXC: false, cReports: cReports }
});


export const getAllPbxReportClientFailled = () => ({
    type: types.CLIENT_GET_ALL_PBX_REPORTS_FAILLED,
    payload: { isLoadingPBXC: false }
});


export const getAllIncomingCallCLient = criteria => ({
    type: types.CLIENT_GET_ALL_INCOMING_CALL_REPORTS,
    payload: { isLoadingCIC: true, criteria: criteria }
});

export const getAllIncomingCallCLientSuccess = cIncomingCalls => ({
    type: types.CLIENT_GET_ALL_INCOMING_CALL_REPORTS_SUCCESS,
    payload: { isLoadingCIC: false, cIncomingCalls: cIncomingCalls }
});


export const getAllIncomingCallCLientFailled = () => ({
    type: types.CLIENT_GET_ALL_INCOMING_CALL_REPORTS_FAILLED,
    payload: { isLoadingCIC: false }
});


export const getAllOutgoingCallClient = criteria => ({
    type: types.CLIENT_GET_ALL_OUTGOING_CALL_REPORTS,
    payload: { isLoadingCOC: true, criteria: criteria }
});

export const getAllOutgoingCallClientSuccess = cOutgoingCalls => ({
    type: types.CLIENT_GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS,
    payload: { isLoadingCOC: false, cOutgoingCalls: cOutgoingCalls }
});


export const getAllOutgoingCallClientFailled = () => ({
    type: types.CLIENT_GET_ALL_OUTGOING_CALL_REPORTS_FAILLED,
    payload: { isLoadingCOC: false }
});




