import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getAllPositions = () => {
    return instance.get(`/position/index.php`)
};


export const createPosition = position => {
    return instance.post(`/position/create/index.php`, position)
};


export const updatePosition = position => {
    return instance.put(`/position/update/index.php`, position)
};


export const deletePosition = id => {
    return instance.get(`/position/delete/index.php?id_position=${id}`)
};
