/**
 * Created by louidorpc on 9/12/20.
 */
import React from 'react'
import {app} from '../../constants/config'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization} from '../../constants/utils'
import Loader from '../../common/loader'


const CampainModal = ({numbers, language, isLoadingPC, numberList, show, display, removeModal}) => {


    console.log("=========zzzzzzzzzzzz===========",numberList)

    const columns = [

        {
            title: getIntlMsg(intl(language), `app.label.fullname`),
            field: 'firstname', type: 'text',
            render: row => (row.firstname + " " + row.lastname)
        },
        {
            title: getIntlMsg(intl(language), `app.label.phone`),
            field: 'phone', type: 'text',
        }, {
            title: getIntlMsg(intl(language), `app.label.note`),
            type: 'text',
            field: "note",
            render: row => (row.status === "+0 " ?
                <span class="badge badge-soft-danger"> {row.note} </span> :
                row.note)
        },
        {
            title: getIntlMsg(intl(language), `app.label.status`),
            type: 'text',
            field: "status",
            render: row => (row.status === "+0 " ?
                <span class="badge badge-soft-success"> {getIntlMsg(intl(language), `app.label.new_client`)} </span> :
                null)
        },
    ]
    return (
        <div class={`modal fade modal-slide-right  ${show}`} id="slideRightModal" tabindex="-1" role="dialog"
             aria-labelledby="slideRightModalLabel" aria-hidden="true" style={{display: display}}>
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"
                            id="slideRightModalLabel">{getIntlMsg(intl(language), 'app.label.campaign_phone_list')}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">


                        {numberList && numberList.length > 0 ?
                            <MaterialTable
                                title={'Liste'}
                                columns={columns}
                                data={numberList ? numberList : []}
                                icon='unfold_less'
                                localization={Localization(language)}
                                options={{
                                    headerStyle: {
                                        backgroundColor: '#01579b',
                                        color: '#FFF'
                                    },
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    exportAllData: true
                                }}
                            />
                            : <div style={{margin: '0px'}}><Loader/></div>}


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CampainModal;