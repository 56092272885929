/**
 * Created by louidorpc on 1/9/21.
 */
import React, { useEffect, useState } from 'react'
import EmForm from './emergencyForm';
import { Emergency } from '../../../constants/model';
// import { intl } from "../../../constants/utils";
import EmergencyList from './list';
import { injectIntl, FormattedMessage } from "react-intl"


const EmergencyWapper = injectIntl(({
    createEmergencyCall,
    language,
    creator,
    mcac,
    resetEmergencyCall,
    infouser,
    getAllEntreprises,
    emCalls, getAllEmergencyCall, intl, editEmergencyCall,deleteEmergencyCall }) => {

    const [emergencyInfo, setEmergencyInfo] = useState(null)

    useEffect(() => {
        getAllEntreprises();
        getAllEmergencyCall()

    }, [])

    const submitSetting = values => {
        let entity = new Emergency(values);
        entity.language = language;
        entity.creator = creator;
        if (emergencyInfo?.id) {
            entity.id = emergencyInfo?.id
            entity.file_name = emergencyInfo?.file_name
            editEmergencyCall(entity)
        } else {
            createEmergencyCall(entity)
        }

    };


    return (
        <React.Fragment>
            <section className="admin-content">

                <div className="container-fluid bg-dark m-b-30">
                    <div className="row  p-b-60 p-t-20">
                        <div className="col-md-6 text-white my-auto text-md-right p-b-30">

                            <div className="">
                                <h2 className="opacity-75"><a href="#!">
                                    Urgence</a>
                                </h2>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container-fluid pull-up">
                    <div className="row ">
                        <div className="col-lg-3">
                            <div className="card m-b-30">
                                <div className="card-header">
                                    <div className="card-title"></div>
                                </div>
                                <div className="card-body">
                                    <EmForm onSubmit={submitSetting} mcac={mcac} resetEmergencyCall={resetEmergencyCall} intl={intl} language={language} emergencyInfo={emergencyInfo} setEmergencyInfo={setEmergencyInfo}/>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-9">

                            <div className="card-body">
                                <EmergencyList language={language} emCalls={emCalls} setEmergencyInfo={setEmergencyInfo} deleteEmergencyCall={deleteEmergencyCall}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
})

export default EmergencyWapper;