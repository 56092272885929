import axios from "axios"
import { app } from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const getAllPbxReport = criteria => {
    return instance.get(`/reportcall/index.php?startDate=${criteria?.startDate}&endDate=${criteria?.endDate}&extension=${criteria?.extension || ""}`)
};

export const getAllIncomingCall = criteria => {
    return instance.get(`/reportcall/incoming/index.php?startDate=${criteria?.startDate}&endDate=${criteria?.endDate}&extension=${criteria?.extension || ""}`)
};

export const getAllOuntgoingCall = criteria => {
    return instance.get(`/reportcall/outgoing/index.php?startDate=${criteria?.startDate}&endDate=${criteria?.endDate}&extension=${criteria?.extension || ""}`)
};


export const getAllPbxReportClient = criteria => {
    return instance.get(`/client/reportcall/index.php?startDate=${criteria?.startDate}&endDate=${criteria?.endDate}&extension=${criteria?.extension || ""}`)
};

export const getAllIncomingCallClient = criteria => {
    return instance.get(`/client/reportcall/incoming/index.php?startDate=${criteria?.startDate}&endDate=${criteria?.endDate}&extension=${criteria?.extension || ""}`)
};

export const getAllOuntgoingCallClient = criteria => {
    return instance.get(`/client/reportcall/outgoing/index.php?startDate=${criteria?.startDate}&endDate=${criteria?.endDate}&extension=${criteria?.extension || ""}`)
};


export const getAllPbxReportAgent = ent_id => {
    return instance.get(`/agent/reportcall/index.php?entreprise_id=${ent_id}`)
};

export const getAllIncomingCallAgent = ent_id => {
    return instance.get(`/agent/reportcall/incoming/index.php?entreprise_id=${ent_id}`)
};

export const getAllOuntgoingCallAgent = ent_id => {
    return instance.get(`/agent/reportcall/outgoing/index.php?entreprise_id=${ent_id}`)
};

export const updateIncomingCall = phone => {
    return instance.post(`/reportcall/update/index.php`, phone)
};






