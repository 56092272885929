import {put, take, call, takeLatest} from "redux-saga/effects";
import * as actions from "../actions/creator/client";
import * as sActions from "../actions/creator/setting";
import types from "../actions/type";
import * as api from "../services/client";
import moment from 'moment'


export const handleResetStateClient = function* () {
    yield put(actions.resetStateClientSuccess())
};


export const handleGetCampaignItem = function* (action) {

    const {cpItem} = action.payload;
    yield put(actions.getCampaignItemSuccess(cpItem))
};

export const handleGetAllCLients = function* () {

    try {

        const getPs = yield call(api.getAllClients);
        if (getPs.data.length > 0) {
            yield put(actions.getAllClientsSuccess(getPs.data))
        } else {
            yield put(actions.getAllClientsFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllClientsFailled("error"))
    }
};


export const handleUpdateCLient = function* (action) {
    const {upc} = action.payload;
    try {
        const upPs = yield call(api.updatePersonInfo, upc);
        if (upPs.data.message === "success") {
            yield put(actions.updateClientByIdSuccess(upc.language))
            // yield put(sActions.getUserSessionInfo(upPs.data.id))
            yield put(actions.getAllClients());
        } else {
            yield put(actions.updateClientByIdFailled(upc.language))
        }
    } catch (error) {
        yield put(actions.updateClientByIdFailled(upc.language))
    }
};


export const handleCreateCLient = function* (action) {
    const {client} = action.payload;
    try {
        const upPs = yield call(api.CreateClient, client);
        if (upPs.data.message === "all saving success") {
            yield put(actions.createClientSuccess(client.language))
            // yield put(sActions.getUserSessionInfo(upPs.data.id))
            yield put(actions.getAllClients());
        } else {
            yield put(actions.createClientFailled({lg: client.language, mc: upPs.data.message}))
        }
    } catch (error) {
        yield put(actions.createClientFailled({lg: client.language, mc: 'save_error'}))
    }
};


export const handleCreateCustomer = function* (action) {
    const {customer} = action.payload;
    try {
        const upPs = yield call(api.createCustomer, customer);
        if (upPs.data.message === "success") {
            yield put(actions.createCustomerSuccess('fr'))
            // yield put(sActions.getUserSessionInfo(upPs.data.id))
            yield put(actions.getAllCustomers());
        } else {
            yield put(actions.createCustomerFailled({lg: 'fr', mc: upPs.data.message}))
        }
    } catch (error) {
        yield put(actions.createCustomerFailled({lg: 'fr', mc: 'save_error'}))
    }
};


export const handleGetAllCustomers = function* () {

    try {

        const getPs = yield call(api.getAllCustomers);
        if (getPs.data.length > 0) {
            yield put(actions.getAllCustomersSuccess(getPs.data))
        } else {
            yield put(actions.getAllCustomersFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllCustomersFailled("error"))
    }
};


// export const handleGetAllCampaigns = function*() {
//
//     try {
//
//         const getPs = yield call(api.getAllCampaigns);
//         if (getPs.data.length > 0) {
//             yield put(actions.getAllCampaignsSuccess(getPs.data))
//         } else {
//             yield put(actions.getAllCampaignsFailled(getPs.data.message))
//         }
//
//     } catch (error) {
//         yield put(actions.getAllCampaignsFailled("error"))
//     }
// };

export const handleGetAllCampaigns = function* (action) {
    const {id} = action.payload;

    try {

        const getPs = id ? yield call(api.getAllCampaignsById, id) : yield call(api.getAllCampaigns);
        if (getPs.data.length > 0) {
            yield put(actions.getAllCampaignsSuccess(getPs.data))
        } else {
            yield put(actions.getAllCampaignsFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllCampaignsFailled("error"))
    }
};


export const handleGetAllCampaignsById = function* (action) {
    const {id} = action.payload;

    try {

        const getPs = yield call(api.getAllCampaignsById, id);
        if (getPs.data.length > 0) {
            yield put(actions.getAllCampaignsByIdSuccess(getPs.data))
        } else {
            yield put(actions.getAllCampaignsByIdFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllCampaignsByIdFailled("error"))
    }
};


export const handleCreateCampain = function* (action) {
    const {campaign} = action.payload;
    try {
        const upPs = yield call(api.createCampaign, campaign);
        if (upPs.data.message === "success") {
            yield put(actions.createCampaignSuccess('fr'))
            // yield put(sActions.getUserSessionInfo(upPs.data.id))
            yield put(actions.getAllCampaignsById(upPs.data.id));
        } else {
            yield put(actions.createCampaignFailled({lg: 'fr', mc: upPs.data.message}))
        }
    } catch (error) {
        yield put(actions.createCampaignFailled({lg: 'fr', mc: 'save_error'}))
    }
};


export const handleEditCampain = function* (action) {
    const {campaign} = action.payload;
    try {
        const upPs = yield call(api.editCampaign, campaign);
        if (upPs.data.message === "success") {

            yield put(actions.editCampaignSuccess('fr'))
            // yield put(actions.getAllCampaigns({id: upPs.date.entreprise_id}));
            // yield put(sActions.getUserSessionInfo(upPs.data.id))

        } else {
            yield put(actions.editCampaignFailled({lg: 'fr', mc: upPs.data.message}))
        }
    } catch (error) {
        yield put(actions.editCampaignFailled({lg: 'fr', mc: 'save_error'}))
    }
};


export const handleGetAllPhoneCampaigns = function* ({payload}) {

    const {code} = payload;

    try {

        const getPs = yield call(api.getAllPhoneCampaigns, code);
        if (getPs.data.length > 0) {
            yield put(actions.getAllPhoneCampaignsSuccess(getPs.data))
        } else {
            yield put(actions.getAllPhoneCampaignsFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPhoneCampaignsFailled("error"))
    }
};


export const handleGetCampaigns = function* () {

    try {

        const getPs = yield call(api.getAllCampaigns);
        if (getPs.data.length > 0) {
            yield put(actions.getAllCampaignsSuccess(getPs.data))
        } else {
            yield put(actions.getAllCampaignsFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllCampaignsFailled("error"))
    }
};


export const handleChangeView = function* (action) {
    try {
        const {view} = action.payload;
        yield put(actions.changeViewSuccess(view))
    } catch (e) {

    }
};

export const handleChangeEvent = function* (action) {
    try {
        const {event} = action.payload;
        yield put(actions.changeEventsSuccess(event))
    } catch (e) {

    }
};

export const handleGetSelectedDataFromCalendar = function* (action) {
    try {
        const {tsData} = action.payload;
        yield put(actions.getSelectedDataFromCalendarSuccess(tsData))
    } catch (e) {

    }
};


export const handleGetAllCampaignToCalendar = function* (action) {

    try {
        const {pid} = action.payload;
        const getT = yield call(api.getAllCampaignToCalendar, pid);

        let result = getT.data;

        if (result.length > 0) {
            result.map((event, index) => {
                result[index].title = `${event.campaign_name}`;
                result[index].desc = `Desc of event ${index + 1}`;
                result[index].start = new moment(event.start);
                result[index].end = new moment(event.end);

            });

            let ev = result;

            yield put(actions.getAllCampaignToCalendarSuccess(ev))

        } else {
            yield put(actions.getAllCampaignToCalendarFailled(getT.data.message))
        }

    } catch (error) {
        yield put(actions.getAllCampaignToCalendarFailled("error"))
    }
};


export const handleGetAllPhoneCampaignsAvailable = function* ({payload}) {

    const {code} = payload;

    try {

        const getPs = yield call(api.getAllPhoneCampaignsAvailable, code);
        if (getPs.data.length > 0) {
            yield put(actions.getAllPhoneCampaignsAvailableSuccess(getPs.data))
        } else {
            yield put(actions.getAllPhoneCampaignsAvailableFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllPhoneCampaignsAvailableFailled("error"))
    }
};


export const handleGetAllReport = function* () {

    try {

        const getPs = yield call(api.getAllReports);
        if (getPs.data.length > 0) {
            yield put(actions.getAllReportsSuccess(getPs.data))
        } else {
            yield put(actions.getAllReportsFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllReportsFailled("error"))
    }
};


export const handleGetAllRecordingCalls = function* ({payload}) {

    const {entr_id} = payload;

    try {

        const getPs = yield call(api.getAllRecordingCalls, entr_id);
        if (getPs.data.length > 0) {
            yield put(actions.getAllRecordingCallByEntrIdSuccess(getPs.data))
        } else {
            yield put(actions.getAllRecordingCallByEntrIdFailled(getPs.data.message))
        }

    } catch (error) {
        yield put(actions.getAllRecordingCallByEntrIdFailled("error"))
    }
};

export const handleDeleteCampain = function* (action) {
    const {id} = action.payload;
    try {
        const upPs = yield call(api.deleteCampaign, id);
        if (upPs.data.message === "success") {
            yield put(actions.deleteCampaignsByIdSuccess(upPs.data.campaign_code))
        } else {
            yield put(actions.deleteCampaignsByIdFailled());
        }
    } catch (error) {
        yield put(actions.deleteCampaignsByIdFailled());
    }
};


export const clientSaga = [
    takeLatest(types.CLIENT_RESET_STATE, handleResetStateClient),
    takeLatest(types.UPDATE_CLIENT, handleUpdateCLient),
    takeLatest(types.GET_ALL_CLIENTS, handleGetAllCLients),
    takeLatest(types.CREATE_CLIENT, handleCreateCLient),
    takeLatest(types.CREATE_CUSTOMER, handleCreateCustomer),
    takeLatest(types.GET_ALL_CUSTOMERS, handleGetAllCustomers),
    takeLatest(types.GET_ALL_CAMPAIGN, handleGetAllCampaigns),
    takeLatest(types.CREATE_CAMPAIGN, handleCreateCampain),
    takeLatest(types.EDIT_CAMPAIGN, handleEditCampain),
    takeLatest(types.GET_ALL_PHONE_CAMPAIGN_BY_CODE, handleGetAllPhoneCampaigns),
    takeLatest(types.GET_ALL_PHONE_CAMPAIGN_AVAILABLE_BY_CODE, handleGetAllPhoneCampaignsAvailable),
    takeLatest(types.GET_CAMPAIGN_ITEM, handleGetCampaignItem),

    takeLatest(types.CALENDAR_EVENT, handleChangeEvent),
    takeLatest(types.CALENDAR_CHANGE_VIEW, handleChangeView),
    takeLatest(types.GET_SELECTED_DATE_FROM_CALENDAR, handleGetSelectedDataFromCalendar),
    takeLatest(types.GET_ALL_CAMPAIGNS_TO_CALENDAR, handleGetAllCampaignToCalendar),
    takeLatest(types.GET_ALL_REPORTS, handleGetAllReport),
    takeLatest(types.GET_ALL_RECORDING_CALL_BY_ENTR_ID, handleGetAllRecordingCalls),
    takeLatest(types.GET_CAMPAIGNS_BY_ID, handleGetAllCampaignsById),
    takeLatest(types.DELETE_CAMPAIGN, handleDeleteCampain),
];
