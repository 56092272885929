import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import Form from "./form";

const DaysreportForm = ({ createDaysReport, editDaysReport, session, daysReportInfo, ms, handleSuccess }) => {


    const [time, setTime] = useState(null);

    const handleSubmit = values => {

        if (values) {

            const entity = {
                ...values,
                creator: session?.person_id
            }

            if (daysReportInfo?.id)
                editDaysReport(entity)
            else
                createDaysReport(entity)
        }

    }


    return (
        <React.Fragment>
            <Form onSubmit={handleSubmit} daysReportInfo={daysReportInfo}  ms={ms} handleSuccess={handleSuccess} />

        </React.Fragment>
    );
}

export default DaysreportForm;