import types from "../actions/type"

const defaultState = {
    isLoading: false,
    message: null,
    sid: null,
    site: null,
    sites: [],
    usid: null,
    msite: null
}

const siteReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.RESET_SITES:
            return {...state, message: action.payload.message, msite: null};

        case types.RESET_SITES_SUCCESS :
            return {
                ...state,
                message: action.payload.message,
                isLoading: true,
                msite: action.payload.msite
            };

        case types.GET_SITE_BY_ID:
            return {
                ...state,
                sid: action.payload.sid,
                isLoading: action.payload.isLoading
            };

        case types.GET_SITE_BY_ID_SUCCESS:
            return {
                ...state,
                msite: action.payload.msite,
                isLoading: action.payload.isLoading,
                site: action.payload.site
            };


        case types.GET_SITE_BY_ID_FAILLED:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                msite: action.payload.msite
            };


        case types.UPDATE_SITE:
            return {
                ...state,
                usid: action.payload.usid,
                isLoading: action.payload.isLoading
            };

        case types.UPDATE_SITE_SUCCESS:
            return {
                ...state,
                msite: action.payload.msite,
                isLoading: action.payload.isLoading
            };


        case types.UPDATE_SITE_FAILLED:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                msite: action.payload.msite
            };


        case types.GET_SITES:
            return {
                ...state,
                isLoading: action.payload.isLoading
            };

        case types.GET_SITES_SUCCESS:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                sites: action.payload.sites
            };


        case types.GET_SITES_FAILLED:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                msite: action.payload.msite
            };


        case types.CREATE_SITE:
            return {
                ...state,
                site: action.payload.site,
                isLoading: action.payload.isLoading
            };

        case types.CREATE_SITE_SUCCESS:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                msite: action.payload.msite
            };


        case types.CREATE_SITE_FAILLED:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                msite: action.payload.msite
            };


        default:
            return state

    }
}


export default siteReducer
