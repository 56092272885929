/**
 * Created by louidorpc on 9/4/20.
 */
/**
 * Created by louidorpc on 8/16/20.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization} from "../../../constants/utils";
import {app} from '../../../constants/config';
import Loader from '../../../common/loader'
import swal from 'sweetalert';

const TablePosition = props => {

    const {
        language,
        entreprises,
        person_id,
        getEntrepriseInfo,
        campaignsId,
        getAllPhoneCampaigns,
        getCampaignItem,
        deleteCampaignsById
    } = props;


    const DeleteCampaign = et => {
        console.log("=+++++++++===============",et)

        swal({
            title: "Êtes-vous sûr?",
            text: "de supprimer cette campagne!",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    deleteCampaignsById(et.campaing_code)
                } else {
                }
            });


    }

    const columns = [

        {
            title: getIntlMsg(intl(language), `app.label.code`),
            field: 'campaing_code', type: 'text',
            cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF'
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        {
            title: getIntlMsg(intl(language), `app.label.campaign_name`),
            field: 'campaign_name', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.campaign_slogan`),
            field: 'campaign_slogan', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.start_date`),
            field: 'start_date', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.end_date`),
            field: 'end_date', type: 'text',
        },
        {
            title: getIntlMsg(intl(language), `app.label.campaing_programmation`),
            type: 'text', render: row => (row.start_hour + " - " + row.end_hour)
        },
        {
            title: getIntlMsg(intl(language), `app.label.creator`),
            field: 'username', type: 'text',
        }
    ]
    if (campaignsId.length > 0)
        return (
            <MaterialTable
                title={'CLient'}
                columns={columns}
                data={campaignsId ? campaignsId : []}
                icon='unfold_less'
                localization={Localization(language)}
                options={{
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportAllData: true
                }}
                actions={[{
                    icon: row => (
                        <i className="mdi mdi-eye"
                           data-toggle="modal"
                           data-target="#slideRightModal"></i>
                    ),
                    tooltip: 'View',
                    onClick: (e, row) => {
                        getAllPhoneCampaigns(row.campaing_code)
                    }
                },
                    {
                        icon: row => (
                            <i className="mdi mdi-circle-edit-outline"></i>
                        ),
                        tooltip: 'Edit',
                        onClick: (e, row) => {
                            getCampaignItem(row)
                        }
                    },
                    {
                        icon: row => (
                            <i className="mdi mdi-delete"></i>
                        ),
                        tooltip: 'Delete',
                        onClick: (e, row) => {
                            DeleteCampaign(row)
                        }
                    }
                ]
                }

            />
        )
    else return (<div style={{margin: '0px'}}><Loader/></div>)
}


export default TablePosition