/**
 * Created by louidorpc on 1/9/21.
 */
 import React from 'react';
 import EmbededRepport from '../../components/entreprises';
 import createHistory from 'history/createBrowserHistory';
 
 function RepportNote() {
 
 
     const infouser = JSON.parse(sessionStorage.getItem('userData') ? sessionStorage.getItem('userData') : null);
     const history = createHistory();
 
 
     if (infouser.position_name === "Agent"  && infouser.entr_name === "Seedlee") {
 
         return (
             <EmbededRepport/>
         )
     } else {
         history.goBack('/')
     }
 
 
 }
 
 export default RepportNote;