import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import actions from "../actions/creator"
import Calendar from "./mothsreport"
import {injectIntl} from 'react-intl';

const mapStateToProps = ({personReducer, settingReducer, intl, statisticReducer, clientReducer, history, siteReducer, serviceReducer, positionReducer, entrepriseReducer, extensionReducer,prefixReducer}) => ({
    person: personReducer,
    intl: intl,
    history: history,
    sr: settingReducer,
    st: siteReducer,
    stat: statisticReducer,
    sv: serviceReducer,
    ...positionReducer,
    ...entrepriseReducer,
    ...clientReducer,
    ...extensionReducer,
    ...prefixReducer

});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default (injectIntl(connect(mapStateToProps, mapDispatchToProps)(Calendar)))
