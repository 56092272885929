import {put, take, call, takeLatest} from "redux-saga/effects";
import * as actions from "../actions/creator/login";
import types from "../actions/type";
import * as api from "../services/login";


export const handleSwitchToSignin = function* (action) {
    const {type} = action.payload;

    yield put(actions.loginSwitch(type))
};

export const handleSwitchToSignup = function* (action) {
    const {type} = action.payload;

    yield put(actions.loginSwitch(type))
};


export const handleResetStateSignin = function* () {
    yield put(actions.resetStateSigninSuccess())
};

export const handleResetStatePassword = function* () {
    yield put(actions.resetStateResetPasswordSuccess())
};

export const handleResetStateSignup = function* () {
    yield put(actions.resetStateSignupSuccess())
};


export const handleSignin = function* (action) {

    try {
        const {sn} = action.payload;
        const savedSn = yield call(api.Signin, sn);
        if (savedSn.data.message === "success") {
            yield put(actions.signinSuccess(savedSn.data))
        } else {
            yield put(actions.signinFailled(savedSn.data.message))
        }

    } catch (error) {
        yield put(actions.signinFailled("error"))
    }
};


export const handleSignup = function* (action) {

    try {
        const {sp} = action.payload;
        const savedSp = yield call(api.Signup, sp);
        if (savedSp.data.response === "all saving success") {
            yield put(actions.signupSuccess(savedSp.data))
        } else {
            yield put(actions.signupFailled(savedSp.data.response))
        }

    } catch (error) {
        yield put(actions.signupFailled("error"))
    }
};


export const handleSigninAgent = function* (action) {

    try {
        const {sna} = action.payload;
        const savedSn = yield call(api.SigninAgent, sna);
        if (savedSn.data.message === "success") {
            yield put(actions.signinAgentSuccess(savedSn.data))
        } else {
            yield put(actions.signinAgentFailled(savedSn.data.message))
        }

    } catch (error) {
        yield put(actions.signinAgentFailled("error"))
    }
};

export const handleResetPassword = function* (action) {

    try {
        const {sna} = action.payload;
        const savedSn = yield call(api.ResetPassword, sna);
        if (savedSn.data.message === "success") {
            yield put(actions.resetPasswordSuccess(savedSn.data))
        } else {
            yield put(actions.resetPasswordFailled(savedSn.data.message))
        }

    } catch (error) {
        yield put(actions.resetPasswordFailled("error"))
    }
};


export const loginSaga = [
    takeLatest(types.SWITCH_TO_SIGNIN, handleSwitchToSignin),
    takeLatest(types.SWITCH_TO_SIGNUP, handleSwitchToSignup),
    takeLatest(types.SIGNIN, handleSignin),
    takeLatest(types.SIGNIN_AGENT, handleSigninAgent),
    takeLatest(types.SIGNUP, handleSignup),
    takeLatest(types.RESET_PASSWORD, handleResetPassword),
    takeLatest(types.RESET_PASSWORD, handleResetStatePassword),
];
