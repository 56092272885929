import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {validate, normalizeCin, normalizePhone} from "./validator";
import {renderField} from "../../../../common/FormComponents";
import UploadCSV from '../../../../common/uploadCsv'
import {getIntlMsg} from "../../../../constants/utils"
import ClientForm from './clientForm'


const Form = props => {
    const {handleSubmit, reset, submitting, intl, isLoadingE, getAllCampaignsById,resetList,setlistof, removeItemClient, resetListClient, cpItem, form, msEnt, resetStateClient, clientList, resetFile, entreprise_id, language, handleGetData, listof, mc, setClientList} = props;

    // const [listOfClient, setListOfClient] = useState(listof?listof:[]);

    const handleSetListOfClient = l => {
        const list = [];

        // setListOfClient(l)
        setClientList(l)

        console.log("))))))))))))", listof)
    }

    const onClearForm = () => {
        // resetFile();
        resetList()
        setClientList([]);

        // getAllCampaignsById(entreprise_id);
        reset('campaign');
        resetStateClient();

    }


    const removeItem = i => {

        const list = [...clientList];
        list.splice(i, 1);
        removeItemClient(list);

    }

    const campains = [
        {id: "Appel de courtoisie et sondage", value: "Appel de courtoisie et sondage"},
        {id: "Appels de prospection", value: "Appels de prospection"},
        {id: "Appels de recouvrement", value: "Appels de recouvrement"},
        {id: "Service à la clientèle", value: "Service à la clientèle"},
        {id: "Relancement des Clients Dormants", value: "Relancement des Clients Dormants"},
        {id: "Suivis Des Demandes de Pro Forma", value: "Suivis Des Demandes de Pro Forma"},
        {id: "Mise à jour de la liste des Clients ", value: "Mise à jour de la liste des Clients"},
        {id: "Étude de marché", value: "Étude de marché"},
        {id: "Gestion de Résaux Siciaux", value: "Gestion de Résaux Siciaux"},
    ];


    if (mc === "success") onClearForm()
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-row">

                {cpItem && cpItem.id ? <>
                    <div className="form-group floating-label show-label col-md-6" style={{display: 'block'}}>
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="id"
                            component={renderField}
                            disabled={true}
                            type="text"
                            label="app.label.code"
                            placeholder="app.label.person_id"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-6" style={{display: 'block'}}>
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="campaing_code"
                            component={renderField}
                            disabled={true}
                            type="text"
                            label="app.label.code"
                            placeholder="app.label.person_id"
                        />

                    </div>
                    </> : null}

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="campaign_name"
                        component={renderField}
                        options={[
                            {id: "Appel de courtoisie et sondage", name: "Appel de courtoisie et sondage"},
                            {id: "Appels de prospection", name: "Appels de prospection"},
                            {id: "Appels de recouvrement", name: "Appels de recouvrement"},
                            {id: "Service à la clientèle", name: "Service à la clientèle"},
                            {id: "Relancement des Clients Dormants", name: "Relancement des Clients Dormants"},
                            {id: "Suivis Des Demandes de Pro Forma", name: "Suivis Des Demandes de Pro Forma"},
                            {id: "Mise à jour de la liste des Clients ", name: "Mise à jour de la liste des Clients"},
                            {id: "Étude de marché", name: "Étude de marché"},
                            {id: "Gestion de Résaux Siciaux", name: "Gestion de Résaux Siciaux"},
                            {id: "Confirmation de Rendez Vous pour les clients existants", name: "Confirmation de Rendez Vous pour les clients existants"},
                            {id: "Sondage Service après Vente", name: "Sondage Service après Vente"},
                            {id: "Service d'annonces et de Promotions", name: "Service d'annonces et de Promotions"},
                            {id: "Service Expérience Clients", name: "Service Expérience Clients"},
                            {id: "Service Personnalisé", name: "Service Personnalisé"},
                        ]}
                        type="select"
                        label="app.label.campaign_name"
                        placeholder="app.label.campaign_name"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="campaign_slogan"
                        component={renderField}
                        type="text"
                        label="app.label.campaign_slogan"
                        placeholder="app.label.campaign_slogan"
                    />

                </div>

                <div className="col-md-12">
                    <label> <FormattedMessage id={`app.label.campaing_period`} defaultMessage={`Periode du :`}/></label>
                </div>
                <div className="form-group floating-label show-label col-md-6 ">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="start_date"
                        component={renderField}
                        type="date"
                        label="app.label.start_date"
                        placeholder="app.label.start_date"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="end_date"
                        component={renderField}
                        type="date"
                        label="app.label.end_date"
                        placeholder="app.label.end_date"
                    />

                </div>


                <div className="col-md-12">
                    <label> <FormattedMessage id={`app.label.campaing_programmation`}
                                              defaultMessage={`Programation du jour :`}/></label>
                </div>
                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="datetimepicker1"
                        name="start_hour"
                        component={renderField}
                        type="time"
                        label="app.label.start"
                        placeholder="app.label.start"
                    />

                </div>

                <div className="form-group floating-label show-label col-md-6">
                    <Field
                        className="form-control"
                        id="datetimepicker1"
                        name="end_hour"
                        component={renderField}
                        type="time"
                        label="app.label.end"
                        placeholder="app.label.end"
                    />

                </div>


                <div className="form-group floating-label show-label col-md-12">
                    <div class="card card-box">
                        <div class="card-head">
                            <header>Téléverser la liste des clients<br></br>
                                <UploadCSV entreprise_id={entreprise_id} language={language}
                                           getData={setlistof} mc={mc} reset={reset} resetStateClient={resetStateClient}/>
                            </header>

                        </div>
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-md-12" style={{overflow: "auto", width: "100%", maxHeight: "200px"}}>
                                    {listof.length > 0 ? listof.map((v, i) => {
                                            if (v.Nom !== "Liste des clients AEROPOST")
                                                return (
                                                    <div className="list-group-item d-flex  align-items-center">
                                                        <div class="details">
                                                            <div class="title">

                                                                <span className="float-left">{i + 1}  </span> - <i
                                                                class="fa fa-phone mr-2"></i>
                                                                <a
                                                                    style={{color: "#1a237e"}}>{v.Telephone}</a> <span
                                                                className="float-right ml-4">{v.Nom}
                                                                - {v.Prenom}</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                        }) : null}

                                </div>

                            </div>
                        </div>


                    </div>

                </div>


                <div className="col-md-12">
                    <ClientForm setClientList={handleSetListOfClient} removeItem={removeItem}/>
                </div>


                <div class="col-md-12">
                    {listof.length > 0 ?
                        <button type="submit"
                                class="btn btn-success btn-cta waves-effect text-center m-b-20 float-right"
                                disabled={submitting}>
                            <FormattedMessage id={`app.label.save`} defaultMessage={`Label Name`}/>
                        </button> : null}
                    {isLoadingE === true ?
                        <div id="loader">
                            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                        </div> : null}

                </div>
            </div>
            <div class="error-handling-messages">
                {msEnt != "success" ?
                    <div id="error"> {msEnt}</div> : null}
            </div>

        </form>


    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: props.cpItem ? props.cpItem : null

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'campaign',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(Form));

