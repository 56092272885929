import types from "../actions/type"

const defaultState = {
    isLoadingSv: false,
    message: null,
    service: null,
    upsv: null,
    mssv: null,
    id: null,
    services: [],
}

const serviceReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.GET_ALL_SERVICES:
            return {
                ...state,
                isLoadingSv: action.payload.isLoadingSv
            };

        case types.GET_ALL_SERVICES_SUCCESS:
            return {
                ...state,
                mssv: action.payload.mssv,
                isLoadingSv: action.payload.isLoadingSv,
                services: action.payload.services
            };

        case types.GET_ALL_SERVICES_FAILLED:
            return {
                ...state,
                mssv: action.payload.mssv,
                isLoadingSv: action.payload.isLoadingSv
            };


        case types.CREATE_SERVICE:
            return {
                ...state,
                isLoadingSv: action.payload.isLoadingSv,
                service: action.payload.service
            };

        case types.UPDATE_SERVICE:
            return {
                ...state,
                isLoadingSv: action.payload.isLoadingSv,
                upsv: action.payload.upsv
            };

        case types.DELETE_SERVICE:
            return {
                ...state,
                id: action.payload.id,
            };

        case types.DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                services: state.services.filter(item => item.id !== action.payload?.id)
            };

        case types.CREATE_SERVICE_SUCCESS:
        case types.UPDATE_SERVICE_SUCCESS:
            return {
                ...state,
                mssv: action.payload.mssv,
                isLoadingSv: action.payload.isLoadingSv
            };

        case types.CREATE_SERVICE_FAILLED:
        case types.UPDATE_SERVICE_FAILLED:
        case types.DELETE_SERVICE_FAILLED:
            return {
                ...state,
                mssv: action.payload.mssv,
                isLoadingSv: action.payload.isLoadingSv
            };


        default:
            return state

    }
}


export default serviceReducer