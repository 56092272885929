import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { connect, useSelector } from 'react-redux'
import { validate } from "./validator";
import { renderField } from "../../../../../common/FormComponents";
import moment from 'moment'
import { FileInput } from '../../../../../common/FileInput'



const Form = props => {
    const { handleSubmit, pristine, submitting, isLoadingCA,ms,handleSuccess, reset } = props;
    // if (mc === "success") onClearForm(reset, resetStateClient)

    const { entreprises } = useSelector(state => ({
        entreprises: state?.entrepriseReducer?.entreprises || [],
    }));


    const handleCreateDaysreportSuccess=()=>{
        handleSuccess()
        reset('monthReport')
    }

    if (ms === "success") handleCreateDaysreportSuccess()
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <div className="form-row m-4">

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="title"
                            name="start_date"
                            component={renderField}
                            type="date"
                            label="app.label.date"
                            placeholder="app.label.date"
                        />

                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="entreprise"
                            name="entreprise_id"
                            component={renderField}
                            type="select"
                            label="app.label.entreprise"
                            placeholder="app.label.entreprise"
                            options={entreprises ? entreprises.map(value => ({ id: value.id, name: value.entr_name })) : []}
                        />

                    </div>
                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="title"
                            name="description"
                            component={renderField}
                            type="textarea"
                            label="app.label.description"
                            placeholder="app.label.description"
                        />

                    </div>
                    <div className="form-group floating-label show-label col-md-12">
                        <h5><a href="#">Téleverser le document</a></h5>

                        <Field name="uploadFile" component={FileInput} type="file" id="uploadFile"
                        />{' '} <label
                            htmlFor="byMail"></label>
                    </div>

                    <div class="col-md-12">
                        <button type="submit"
                            class="btn btn-primary btn-cta waves-effect text-center m-b-20 float-right"
                            disabled={pristine || submitting}>
                            <FormattedMessage id={`app.label.save`} defaultMessage={`Modifier`} />
                        </button>

                        {isLoadingCA == true ?
                            <div id="loader">
                                <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                            </div> : null}

                    </div>


                </div>

            </form>
        </React.Fragment>


    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: props?.monthReportInfo || null

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'monthReport',
    enableReinitialize: true,
    validate,
})(Form));

