/**
 * Created by louidorpc on 8/14/20.
 */

import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getIntlMsg, intl} from "../../constants/utils";
import {injectIntl, createIntl, createIntlCache} from "react-intl"


toast.configure()


export const getAllPbxReportAgent = ent_id => ({
    type: types.AGENT_GET_ALL_PBX_REPORTS,
    payload: {isLoadingPBXC: true, ent_id: ent_id}
});

export const getAllPbxReportAgentSuccess = cReports => ({
    type: types.AGENT_GET_ALL_PBX_REPORTS_SUCCESS,
    payload: {isLoadingPBXC: false, cReports: cReports}
});


export const getAllPbxReportAgentFailled = () => ({
    type: types.AGENT_GET_ALL_PBX_REPORTS_FAILLED,
    payload: {isLoadingPBXC: false}
});


export const getAllIncomingCallAgent = ent_id => ({
    type: types.AGENT_GET_ALL_INCOMING_CALL_REPORTS,
    payload: {isLoadingCIC: true,ent_id}
});

export const getAllIncomingCallAgentSuccess = cIncomingCalls => ({
    type: types.AGENT_GET_ALL_INCOMING_CALL_REPORTS_SUCCESS,
    payload: {isLoadingCIC: false, cIncomingCalls: cIncomingCalls}
});


export const getAllIncomingCallAgentFailled = () => ({
    type: types.AGENT_GET_ALL_INCOMING_CALL_REPORTS_FAILLED,
    payload: {isLoadingCIC: false}
});


export const getAllOutgoingCallAgent = ent_id => ({
    type: types.AGENT_GET_ALL_OUTGOING_CALL_REPORTS,
    payload: {isLoadingCOC: true,ent_id}
});

export const getAllOutgoingCallAgentSuccess = cOutgoingCalls => ({
    type: types.AGENT_GET_ALL_OUTGOING_CALL_REPORTS_SUCCESS,
    payload: {isLoadingCOC: false, cOutgoingCalls: cOutgoingCalls}
});


export const getAllOutgoingCallAgentFailled = () => ({
    type: types.AGENT_GET_ALL_OUTGOING_CALL_REPORTS_FAILLED,
    payload: {isLoadingCOC: false}
});




