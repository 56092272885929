import {all} from "redux-saga/effects"
import {settingSaga} from "./setting"
import {loginSaga} from "./login"
import {personSaga} from "./person"
import {siteSaga} from "./sites"
import {statisticSaga} from "./statistic"
import {serviceSaga} from "./service"
import {positionSaga} from "./position"
import {entrepriseSaga} from "./entreprise"
import {clientSaga} from "./client"
import {extensionSaga} from "./extension"
import {agentSaga} from "./agent"
import {campaignSaga} from "./campaign"
import {prefixSaga} from "./prefix"
import {pbxSaga} from "./pbx"
import {clientPbxSaga} from "./clientpbx"
import {feedbackSaga} from "./feedback"
import {eventSaga} from "./event"
import {linkSaga} from "./links"
import {daysreportSaga} from "./daysreport"
import {monthreportSaga} from "./monthreport"
import {agentPbxSaga} from "./agentpbx"

export default function* root() {
    yield all([
        ...settingSaga,
        ...loginSaga,
        ...personSaga,
        ...siteSaga,
        ...statisticSaga,
        ...serviceSaga,
        ...positionSaga,
        ...entrepriseSaga,
        ...clientSaga,
        ...extensionSaga,
        ...agentSaga,
        ...campaignSaga,
        ...prefixSaga,
        ...pbxSaga,
        ...clientPbxSaga,
        ...feedbackSaga,
        ...eventSaga,
        ...linkSaga,
        ...daysreportSaga,
        ...monthreportSaga,
        ...agentPbxSaga
    ])
}
