import moment from 'moment';

const events = [
  {
    allDay: true,
    start: '2019-08-13T12:41:31.046Z',
    end: '2019-08-13T12:41:31.046Z'
  }
];

const datediff = new moment(new Date()).diff(
  new moment('2019-08-13T18:23:07.322Z'),
  'days'
);
events.forEach((event, index) => {
  events[index].title = `LOUIDOR, Jean Paul ${index + 1}`;
  events[index].desc = `Desc of event ${index + 1}`;
  events[index].start = new moment(event.start).add(datediff, 'days').toDate();
  events[index].end = new moment(event.end).add(datediff, 'days').toDate();
});
export default events.splice(0, 10);
