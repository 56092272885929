import types from "../actions/type"

const defaultState = {
    isLoadingE: false,
    message: null,
    entr_name: null,
    entreprise: null,
    entrepriseInfo: null,
    upent: null,
    msEnt: null,
    entreprises: [],
    documents: [],
}

const entrepriseReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.RESET_ENTREPRISE:
            return {
                ...state
            };

        case types.RESET_ENTREPRISE_SUCCESS:
            return {
                ...state,
                msEnt: action.payload.msEnt, entrepriseInfo: action.payload.entrepriseInfo
            };

        case types.GET_ALL_ENTREPRISES:
            return {
                ...state,
                isLoadingE: action.payload.isLoadingE
            };

        case types.GET_ALL_ENTREPRISES_SUCCESS:
            return {
                ...state,
                msEnt: action.payload.msEnt,
                isLoadingE: action.payload.isLoadingE,
                entreprises: action.payload.entreprises
            };

        case types.GET_ALL_ENTREPRISES_FAILLED:
            return {
                ...state,
                msEnt: action.payload.msEnt,
                isLoadingE: action.payload.isLoadingE
            };


        case types.CREATE_ENTREPRISE:
            return {
                ...state,
                isLoadingE: action.payload.isLoadingE,
                entreprise: action.payload.entreprise
            };

        case types.UPDATE_SERVICE:
            return {
                ...state,
                isLoadingE: action.payload.isLoadingE,
                upent: action.payload.upent
            };


        case types.GET_ENTREPRISE_INFO:
            return {
                ...state,
                entrepriseInfo: action.payload.entrepriseInfo
            };

        case types.GET_ALL_DOCUMENTS:
            return {
                ...state,
                entr_name: action.payload.entr_name
            };

        case types.GET_ALL_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documents: action.payload.documents
            };

        case types.GET_ALL_DOCUMENTS_FAILLED:
            return {
                ...state,
                ms: action.payload.ms,
                documents:[]
            };
        case types.GET_ENTREPRISE_INFO_SUCCESS:
            return {
                ...state,
                entrepriseInfo: action.payload.entrepriseInfo
            };

        case types.CREATE_ENTREPRISE_SUCCESS:
        case types.UPDATE_ENTREPRISE_SUCCESS:
            return {
                ...state,
                msEnt: action.payload.msEnt,
                isLoadingE: action.payload.isLoadingE
            };

        case types.CREATE_ENTREPRISE_FAILLED:
        case types.UPDATE_ENTREPRISE_FAILLED:
            return {
                ...state,
                msEnt: action.payload.msEnt,
                isLoadingE: action.payload.isLoadingE
            };


        default:
            return state

    }
}


export default entrepriseReducer