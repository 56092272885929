/**
 * Created by louidorpc on 9/9/20.
 */
import React from 'react'
import TablePosition from './positionTable'
import {deletePosition} from "../../../actions/creator/position";

const Service = ({language, createPosition, updatePosition, positions, person_id,deletePosition}) => {

    return (

        <TablePosition language={language} createPosition={createPosition} updatePosition={updatePosition}
                       positions={positions} person_id={person_id} deletePosition={deletePosition}/>

    )
}

export default Service;